import { Detail } from "@/components/Page/Etl/Detail";
import {
	AccessType,
	getGraphqlTypeByAccess,
	useAccessesQuery,
} from "@/modules/Etl/Accesses";
import {
	IntegrationFeedConfigSelector,
	useUpdateIntegrationFeedConfig,
} from "@/modules/Etl/IntegrationFeedConfigs/logic";
import { useRunEtl } from "@/modules/Etl/IntegrationFeedInstances/logic";
import { getFeedOptions } from "@/modules/Etl/IntegrationFeedParams/ui/FeedMultiselect";
import { useForm } from "@/modules/Form/logic";
import { useOrganizationsQuery } from "@/modules/Organizations/logic";
import { useRouter } from "@/modules/Router";
import { useTypedQuery } from "@/services/graphApi";
import {
	ApplovinReportType,
	Feed,
	ReportType,
} from "@/services/graphApi/graphql-zeus";
import { Form } from "@/ui/components/Form/components/Form";
import { UIKit } from "@/ui/components/Form/uiKit";
import {
	DestinationsField,
	DestinationsMatch,
} from "@/ui/components/Form/uiKit/Destinations";
import { BooleanField, BooleanMatch } from "@/ui/components/Form/uiKit/Boolean";
import {
	FacebookBreakdownsField,
	FacebookBreakdownsMatch,
} from "@/ui/components/Form/uiKit/Breakdowns";
import { FeedField, FeedMatch } from "@/ui/components/Form/uiKit/Feed";
import {
	FeedTypeField,
	FeedTypeMatch,
} from "@/ui/components/Form/uiKit/FeedType";
import { IntField, IntMatch } from "@/ui/components/Form/uiKit/Int";
import {
	IntegrationFeedParamsCreateInputField,
	IntegrationFeedParamsCreateInputMatch,
} from "@/ui/components/Form/uiKit/IntegrationFeedParamsCreateInput";
import { ObjectField, ObjectMatch } from "@/ui/components/Form/uiKit/Object";
import {
	OrganizationAttributesField,
	OrganizationAttributesMatch,
} from "@/ui/components/Form/uiKit/OrganizationAttributes";
import {
	OrganizationRoleField,
	OrganizationRoleMatch,
} from "@/ui/components/Form/uiKit/OrganizationRole";
import {
	EnumTypeField,
	EnumTypeMatch,
} from "@/ui/components/Form/uiKit/EnumType";
import {
	ScraperGameField,
	ScraperGameMatch,
} from "@/ui/components/Form/uiKit/ScraperGames";
import { StringField, StringMatch } from "@/ui/components/Form/uiKit/String";
import {
	UpdateManyInputField,
	UpdateManyInputMatch,
} from "@/ui/components/Form/uiKit/UpdateManyInput";
import {
	WhereUniqueInputField,
	WhereUniqueInputMatch,
} from "@/ui/components/Form/uiKit/WhereUniqueInput";
import { Modal } from "@/views";
import { ButtonWithLoading } from "@/views/ButtonWithLoading";
import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { createStore } from "reusable";

const options = getFeedOptions();

export const useConfigsDetail = createStore(() => {
	const [_remove, setRemove] = useState<() => void>(() => () => {});
	const { navigate } = useRouter();

	const close = useCallback(() => {
		navigate({ route: "etl-configs" });
	}, [navigate]);

	const open = useCallback(
		({ feedType, id }: { id: string; feedType: Feed }) => {
			navigate({
				route: "etl-configs-detail",
				params: { id, feedType },
			});
		},
		[navigate]
	);

	const remove = useCallback(() => {
		close();
		_remove();
	}, [_remove, close]);

	return {
		close,
		open,
		remove,
		setRemove,
	};
});

type Props = {
	item: { id: string; params: { feedType: Feed } };
};

export const IntegrationFeedConfigDetailExisting = ({ item }: Props) => {
	const {
		id,
		params: { feedType },
	} = item;
	const { close, remove } = useConfigsDetail();
	const state = useTypedQuery({
		integrationFeedConfig: [
			{ where: { id } },
			IntegrationFeedConfigSelector(feedType),
		],
	});

	const [update, updateState] = useUpdateIntegrationFeedConfig(feedType);
	const [runEtl, runEtlState] = useRunEtl();
	const form = useForm();

	useEffect(() => {
		if (state.data) {
			form.setValue(
				"",
				JSON.parse(
					JSON.stringify(
						state.data.integrationFeedConfig,
						configToEditableConfig
					)
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const ui = useMemo(
		() => uiKit(form.getValues("params.ironSource.adRevenue")),
		[form]
	);

	return (
		<Modal className="w-modal h-modal" isOpened={true} onClose={close}>
			<Detail
				title={
					options.find((option) => option.value === feedType)
						?.label || ""
				}
				close={close}
				loading={
					state.loading || Object.keys(form.getValues()).length === 0
				}
				Content={
					<Form
						className="p-4 overflow-overlay h-full bg-white"
						ui={ui}
						input="IntegrationFeedConfigUpdateInput"
						form={form}
						data={form.getValues()}
						Buttons={() => {
							return (
								<div className="flex mt-4">
									<div className="mr-2">
										<ButtonWithLoading
											loading={updateState.loading}
											onClick={() => {
												const {
													id,
													...data
												} = form.getValues();

												update({
													variables: {
														data,
														where: {
															id,
														},
													},
												});
											}}
										>
											Update
										</ButtonWithLoading>
									</div>
									<div className="mr-2">
										<button
											className="btn-primary"
											onClick={() => {
												remove();
												close();
											}}
										>
											Delete
										</button>
									</div>
									<div className="mr-2">
										<ButtonWithLoading
											loading={runEtlState.loading}
											onClick={() => {
												const transformedItem = JSON.parse(
													JSON.stringify(
														form.getValues(),
														editableConfigToInstance
													)
												);
												runEtl({
													variables: {
														data: {
															organizationAttributes:
																transformedItem.organizationAttributes,
															params:
																transformedItem.params,
															config: {
																id:
																	transformedItem.id,
															},
														},
													},
												});
											}}
										>
											Run once
										</ButtonWithLoading>
									</div>
								</div>
							);
						}}
					/>
				}
			/>
		</Modal>
	);
};

const uiKit = (showS3: boolean): UIKit<any>[] => [
	[OrganizationRoleMatch(), OrganizationRoleField],
	[EnumTypeMatch("ApplovinReportType"), EnumTypeField(ApplovinReportType)],
	[
		EnumTypeMatch("ReportType"),
		EnumTypeField(ReportType, ReportType.PARTNERS_BY_DATE_REPORT),
	],
	[FeedTypeMatch(), FeedTypeField],
	[
		IntegrationFeedParamsCreateInputMatch(),
		IntegrationFeedParamsCreateInputField,
	],
	[FeedMatch(), FeedField],
	[
		WhereUniqueInputMatch("OrganizationWhereUniqueInput"),
		WhereUniqueInputField({
			useRemoteData: () => useOrganizationsQuery(),
			key: "organizations",
		}),
	],
	...Object.values(AccessType).map((accessType) => {
		return [
			WhereUniqueInputMatch(
				`${getGraphqlTypeByAccess(accessType)}WhereUniqueInput`
			),
			WhereUniqueInputField({
				// @ts-ignore
				useRemoteData: () => useAccessesQuery(accessType),
				key: accessType,
			}),
		] as UIKit<any>;
	}),
	[OrganizationAttributesMatch(), OrganizationAttributesField],
	[
		DestinationsMatch("bqDestinations"),
		DestinationsField,
	],
	[
		DestinationsMatch("s3Destinations"),
		showS3 ? DestinationsField : () => null,
	],
	[UpdateManyInputMatch(), UpdateManyInputField],
	[FacebookBreakdownsMatch(), FacebookBreakdownsField],
	[ScraperGameMatch(), ScraperGameField],
	// // ...basicKit
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[IntMatch(), IntField],
	[BooleanMatch(), BooleanField],
];

const configToEditableConfig = (key: string, value: any) => {
	if (key === "") {
		return value;
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "createdAt" || key === "updatedAt") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (
		key === "organizationAttributes" ||
		key === "bqDestinations" ||
		key === "s3Destinations"
	) {
		return {
			create: [],
			delete: [],
			update: value,
		};
	}
	return value;
};

const editableConfigToInstance = (key: string, value: any) => {
	if (key === "") {
		const { enabled, eligibleAt, ...rest } = value;
		return rest;
	}
	if (value === null) {
		return;
	}
	if (key === "organizationAttributes" && value.update) {
		const updated = value.update.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
		return updated.concat(value.create);
	}

	if (key === "bqDestinations" || key === "s3Destinations") {
		const created = value.create.filter((item: any) => item.enabled);
		const updated = value.update
			.filter((item: any) => item.enabled)
			.map((item: any) => {
				const { id, ...rest } = item;
				return rest;
			});
		return updated.concat(created);
	}
	return value;
};
