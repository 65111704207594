import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useLibringAccessesQuery = () =>
	useTypedQuery(
		{
			libringAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				LibringAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useLibringAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			libringAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				LibringAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useLibringAccessQuery = () =>
	useTypedLazyQuery(
		{
			libringAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				LibringAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateLibringAccess = () => {
	return useTypedMutation({
		createLibringAccess: [
			{
				data: $`data`,
			},
			LibringAccessSelector,
		],
	});
};
export const useUpdateLibringAccess = () => {
	return useTypedMutation({
		updateLibringAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			LibringAccessSelector,
		],
	});
};
export const useDeleteLibringAccess = () => {
	return useTypedMutation({
		deleteLibringAccess: [
			{
				where: $`where`,
			},
			LibringAccessSelector,
		],
	});
};

export const LibringAccessSelector = ZeusSelect<ValueTypes["LibringAccess"]>()({
	id: true,
	descriptiveName: true,
	apiToken: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
