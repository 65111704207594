import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useSnapchatAccessesQuery = () =>
	useTypedQuery(
		{
			snapchatAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				SnapchatAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useSnapchatAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			snapchatAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				SnapchatAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useSnapchatAccessQuery = () =>
	useTypedLazyQuery(
		{
			snapchatAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				SnapchatAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateSnapchatAccess = () => {
	return useTypedMutation({
		createSnapchatAccess: [
			{
				data: $`data`,
			},
			SnapchatAccessSelector,
		],
	});
};
export const useUpdateSnapchatAccess = () => {
	return useTypedMutation({
		updateSnapchatAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			SnapchatAccessSelector,
		],
	});
};
export const useDeleteSnapchatAccess = () => {
	return useTypedMutation({
		deleteSnapchatAccess: [
			{
				where: $`where`,
			},
			SnapchatAccessSelector,
		],
	});
};

export const SnapchatAccessSelector = ZeusSelect<ValueTypes["SnapchatAccess"]>()({
	id: true,
	descriptiveName: true,
	adAccountID: true,
	clientID: true,
	clientSecret: true,
	refreshToken: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
