/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["Action"]: AliasType<{
	error?:true,
	message?:true,
	startDate?:true,
	stopDate?:true,
		__typename?: true
}>;
	["AggregateBigQueryCheck"]: AliasType<{
	count?:true,
		__typename?: true
}>;
	["AggregateCampaignConsistencyCheck"]: AliasType<{
	count?:true,
		__typename?: true
}>;
	["AggregateDailyConsistencyCheck"]: AliasType<{
	count?:true,
		__typename?: true
}>;
	["AggregateIntegrationFeedConfig"]: AliasType<{
	count?:true,
		__typename?: true
}>;
	["AggregateIntegrationFeedInstance"]: AliasType<{
	count?:true,
		__typename?: true
}>;
	["AppleAccess"]: AliasType<{
	accountName?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
	login?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	password?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["AppleAccessCreateInput"]: {
	accountName:string,
	descriptiveName:string,
	id?:string,
	login:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	password:string
};
	["AppleAccessOrderByInput"]:AppleAccessOrderByInput;
	["AppleAccessUpdateInput"]: {
	accountName?:string,
	descriptiveName?:string,
	login?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	password?:string
};
	["AppleAccessWhereInput"]: {
	accountName?:string,
	accountName_contains?:string,
	accountName_ends_with?:string,
	accountName_gt?:string,
	accountName_gte?:string,
	accountName_in?:string[],
	accountName_lt?:string,
	accountName_lte?:string,
	accountName_not?:string,
	accountName_not_contains?:string,
	accountName_not_ends_with?:string,
	accountName_not_in?:string[],
	accountName_not_starts_with?:string,
	accountName_starts_with?:string,
	AND?:ValueTypes["AppleAccessWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	login?:string,
	login_contains?:string,
	login_ends_with?:string,
	login_gt?:string,
	login_gte?:string,
	login_in?:string[],
	login_lt?:string,
	login_lte?:string,
	login_not?:string,
	login_not_contains?:string,
	login_not_ends_with?:string,
	login_not_in?:string[],
	login_not_starts_with?:string,
	login_starts_with?:string,
	NOT?:ValueTypes["AppleAccessWhereInput"][],
	OR?:ValueTypes["AppleAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	password?:string,
	password_contains?:string,
	password_ends_with?:string,
	password_gt?:string,
	password_gte?:string,
	password_in?:string[],
	password_lt?:string,
	password_lte?:string,
	password_not?:string,
	password_not_contains?:string,
	password_not_ends_with?:string,
	password_not_in?:string[],
	password_not_starts_with?:string,
	password_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["AppleAccessWhereUniqueInput"]: {
	id:string
};
	["AppleFeed"]: AliasType<{
	appleAccess?:ValueTypes["AppleAccess"],
games?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ScraperGameOrderByInput"],	skip?:number,	where?:ValueTypes["ScraperGameWhereInput"]},ValueTypes["ScraperGame"]],
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	reporter?:true,
	scraper?:true,
	usePST?:true,
		__typename?: true
}>;
	["AppleFeedCreateWithoutParamsInput"]: {
	appleAccess:ValueTypes["AppleAccessWhereUniqueInput"],
	games?:ValueTypes["ScraperGameCreateInput"][],
	id?:string,
	reporter?:boolean,
	scraper?:boolean,
	usePST?:boolean
};
	["AppleFeedWhereInput"]: {
	AND?:ValueTypes["AppleFeedWhereInput"][],
	appleAccess?:ValueTypes["AppleAccessWhereInput"],
	games_every?:ValueTypes["ScraperGameWhereInput"],
	games_none?:ValueTypes["ScraperGameWhereInput"],
	games_some?:ValueTypes["ScraperGameWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppleFeedWhereInput"][],
	OR?:ValueTypes["AppleFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reporter?:boolean,
	reporter_not?:boolean,
	scraper?:boolean,
	scraper_not?:boolean,
	usePST?:boolean,
	usePST_not?:boolean
};
	["AppleSearchAdsAccess"]: AliasType<{
	certificate?:true,
	certificateKey?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["AppleSearchAdsAccessCreateInput"]: {
	certificate:string,
	certificateKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["AppleSearchAdsAccessOrderByInput"]:AppleSearchAdsAccessOrderByInput;
	["AppleSearchAdsAccessUpdateInput"]: {
	certificate?:string,
	certificateKey?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["AppleSearchAdsAccessWhereInput"]: {
	AND?:ValueTypes["AppleSearchAdsAccessWhereInput"][],
	certificate?:string,
	certificate_contains?:string,
	certificate_ends_with?:string,
	certificate_gt?:string,
	certificate_gte?:string,
	certificate_in?:string[],
	certificate_lt?:string,
	certificate_lte?:string,
	certificate_not?:string,
	certificate_not_contains?:string,
	certificate_not_ends_with?:string,
	certificate_not_in?:string[],
	certificate_not_starts_with?:string,
	certificate_starts_with?:string,
	certificateKey?:string,
	certificateKey_contains?:string,
	certificateKey_ends_with?:string,
	certificateKey_gt?:string,
	certificateKey_gte?:string,
	certificateKey_in?:string[],
	certificateKey_lt?:string,
	certificateKey_lte?:string,
	certificateKey_not?:string,
	certificateKey_not_contains?:string,
	certificateKey_not_ends_with?:string,
	certificateKey_not_in?:string[],
	certificateKey_not_starts_with?:string,
	certificateKey_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppleSearchAdsAccessWhereInput"][],
	OR?:ValueTypes["AppleSearchAdsAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["AppleSearchAdsAccessWhereUniqueInput"]: {
	id:string
};
	["AppleSearchAdsFeed"]: AliasType<{
	appleSearchAdsAccess?:ValueTypes["AppleSearchAdsAccess"],
	groupBy?:true,
	id?:true,
	orgId?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["AppleSearchAdsFeedCreateWithoutParamsInput"]: {
	appleSearchAdsAccess:ValueTypes["AppleSearchAdsAccessWhereUniqueInput"],
	groupBy:string[],
	id?:string,
	orgId:string
};
	["AppleSearchAdsFeedWhereInput"]: {
	AND?:ValueTypes["AppleSearchAdsFeedWhereInput"][],
	appleSearchAdsAccess?:ValueTypes["AppleSearchAdsAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppleSearchAdsFeedWhereInput"][],
	OR?:ValueTypes["AppleSearchAdsFeedWhereInput"][],
	orgId?:string,
	orgId_contains?:string,
	orgId_ends_with?:string,
	orgId_gt?:string,
	orgId_gte?:string,
	orgId_in?:string[],
	orgId_lt?:string,
	orgId_lte?:string,
	orgId_not?:string,
	orgId_not_contains?:string,
	orgId_not_ends_with?:string,
	orgId_not_in?:string[],
	orgId_not_starts_with?:string,
	orgId_starts_with?:string,
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["ApplovinAccess"]: AliasType<{
	apiKey?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["ApplovinAccessCreateInput"]: {
	apiKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["ApplovinAccessOrderByInput"]:ApplovinAccessOrderByInput;
	["ApplovinAccessUpdateInput"]: {
	apiKey?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["ApplovinAccessWhereInput"]: {
	AND?:ValueTypes["ApplovinAccessWhereInput"][],
	apiKey?:string,
	apiKey_contains?:string,
	apiKey_ends_with?:string,
	apiKey_gt?:string,
	apiKey_gte?:string,
	apiKey_in?:string[],
	apiKey_lt?:string,
	apiKey_lte?:string,
	apiKey_not?:string,
	apiKey_not_contains?:string,
	apiKey_not_ends_with?:string,
	apiKey_not_in?:string[],
	apiKey_not_starts_with?:string,
	apiKey_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["ApplovinAccessWhereInput"][],
	OR?:ValueTypes["ApplovinAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["ApplovinAccessWhereUniqueInput"]: {
	id:string
};
	["ApplovinFeed"]: AliasType<{
	applovinAccess?:ValueTypes["ApplovinAccess"],
	gameIds?:true,
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	reportType?:true,
		__typename?: true
}>;
	["ApplovinFeedCreateWithoutParamsInput"]: {
	applovinAccess:ValueTypes["ApplovinAccessWhereUniqueInput"],
	gameIds:string[],
	id?:string,
	reportType:ValueTypes["ApplovinReportType"]
};
	["ApplovinFeedWhereInput"]: {
	AND?:ValueTypes["ApplovinFeedWhereInput"][],
	applovinAccess?:ValueTypes["ApplovinAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["ApplovinFeedWhereInput"][],
	OR?:ValueTypes["ApplovinFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reportType?:ValueTypes["ApplovinReportType"],
	reportType_in?:ValueTypes["ApplovinReportType"][],
	reportType_not?:ValueTypes["ApplovinReportType"],
	reportType_not_in?:ValueTypes["ApplovinReportType"][]
};
	["ApplovinReportType"]:ApplovinReportType;
	["AppsFlyerAccess"]: AliasType<{
	apiToken?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["AppsFlyerAccessCreateInput"]: {
	apiToken:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["AppsFlyerAccessOrderByInput"]:AppsFlyerAccessOrderByInput;
	["AppsFlyerAccessUpdateInput"]: {
	apiToken?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["AppsFlyerAccessWhereInput"]: {
	AND?:ValueTypes["AppsFlyerAccessWhereInput"][],
	apiToken?:string,
	apiToken_contains?:string,
	apiToken_ends_with?:string,
	apiToken_gt?:string,
	apiToken_gte?:string,
	apiToken_in?:string[],
	apiToken_lt?:string,
	apiToken_lte?:string,
	apiToken_not?:string,
	apiToken_not_contains?:string,
	apiToken_not_ends_with?:string,
	apiToken_not_in?:string[],
	apiToken_not_starts_with?:string,
	apiToken_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppsFlyerAccessWhereInput"][],
	OR?:ValueTypes["AppsFlyerAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["AppsFlyerAccessWhereUniqueInput"]: {
	id:string
};
	["AppsFlyerCampaignFeed"]: AliasType<{
	appsFlyerAccess?:ValueTypes["AppsFlyerAccess"],
	attributedTouchType?:true,
	currency?:true,
	eventName?:true,
	gameId?:true,
	id?:true,
	mediaSource?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	reattr?:true,
	reportType?:true,
	timezone?:true,
		__typename?: true
}>;
	["AppsFlyerCampaignFeedCreateWithoutParamsInput"]: {
	appsFlyerAccess:ValueTypes["AppsFlyerAccessWhereUniqueInput"],
	attributedTouchType?:string,
	currency?:string,
	eventName?:string,
	gameId:string,
	id?:string,
	mediaSource?:string,
	reattr?:boolean,
	reportType?:ValueTypes["ReportType"],
	timezone?:string
};
	["AppsFlyerCampaignFeedWhereInput"]: {
	AND?:ValueTypes["AppsFlyerCampaignFeedWhereInput"][],
	appsFlyerAccess?:ValueTypes["AppsFlyerAccessWhereInput"],
	attributedTouchType?:string,
	attributedTouchType_contains?:string,
	attributedTouchType_ends_with?:string,
	attributedTouchType_gt?:string,
	attributedTouchType_gte?:string,
	attributedTouchType_in?:string[],
	attributedTouchType_lt?:string,
	attributedTouchType_lte?:string,
	attributedTouchType_not?:string,
	attributedTouchType_not_contains?:string,
	attributedTouchType_not_ends_with?:string,
	attributedTouchType_not_in?:string[],
	attributedTouchType_not_starts_with?:string,
	attributedTouchType_starts_with?:string,
	currency?:string,
	currency_contains?:string,
	currency_ends_with?:string,
	currency_gt?:string,
	currency_gte?:string,
	currency_in?:string[],
	currency_lt?:string,
	currency_lte?:string,
	currency_not?:string,
	currency_not_contains?:string,
	currency_not_ends_with?:string,
	currency_not_in?:string[],
	currency_not_starts_with?:string,
	currency_starts_with?:string,
	eventName?:string,
	eventName_contains?:string,
	eventName_ends_with?:string,
	eventName_gt?:string,
	eventName_gte?:string,
	eventName_in?:string[],
	eventName_lt?:string,
	eventName_lte?:string,
	eventName_not?:string,
	eventName_not_contains?:string,
	eventName_not_ends_with?:string,
	eventName_not_in?:string[],
	eventName_not_starts_with?:string,
	eventName_starts_with?:string,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	mediaSource?:string,
	mediaSource_contains?:string,
	mediaSource_ends_with?:string,
	mediaSource_gt?:string,
	mediaSource_gte?:string,
	mediaSource_in?:string[],
	mediaSource_lt?:string,
	mediaSource_lte?:string,
	mediaSource_not?:string,
	mediaSource_not_contains?:string,
	mediaSource_not_ends_with?:string,
	mediaSource_not_in?:string[],
	mediaSource_not_starts_with?:string,
	mediaSource_starts_with?:string,
	NOT?:ValueTypes["AppsFlyerCampaignFeedWhereInput"][],
	OR?:ValueTypes["AppsFlyerCampaignFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reattr?:boolean,
	reattr_not?:boolean,
	reportType?:ValueTypes["ReportType"],
	reportType_in?:ValueTypes["ReportType"][],
	reportType_not?:ValueTypes["ReportType"],
	reportType_not_in?:ValueTypes["ReportType"][],
	timezone?:string,
	timezone_contains?:string,
	timezone_ends_with?:string,
	timezone_gt?:string,
	timezone_gte?:string,
	timezone_in?:string[],
	timezone_lt?:string,
	timezone_lte?:string,
	timezone_not?:string,
	timezone_not_contains?:string,
	timezone_not_ends_with?:string,
	timezone_not_in?:string[],
	timezone_not_starts_with?:string,
	timezone_starts_with?:string
};
	["AppsFlyerDatalockerFeed"]: AliasType<{
	awsAccessKey?:true,
	bucketSecret?:true,
	eventNames?:true,
	homeFolder?:true,
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["AppsFlyerDatalockerFeedCreateWithoutParamsInput"]: {
	awsAccessKey:string,
	bucketSecret:string,
	eventNames:string[],
	homeFolder:string,
	id?:string
};
	["AppsFlyerDatalockerFeedWhereInput"]: {
	AND?:ValueTypes["AppsFlyerDatalockerFeedWhereInput"][],
	awsAccessKey?:string,
	awsAccessKey_contains?:string,
	awsAccessKey_ends_with?:string,
	awsAccessKey_gt?:string,
	awsAccessKey_gte?:string,
	awsAccessKey_in?:string[],
	awsAccessKey_lt?:string,
	awsAccessKey_lte?:string,
	awsAccessKey_not?:string,
	awsAccessKey_not_contains?:string,
	awsAccessKey_not_ends_with?:string,
	awsAccessKey_not_in?:string[],
	awsAccessKey_not_starts_with?:string,
	awsAccessKey_starts_with?:string,
	bucketSecret?:string,
	bucketSecret_contains?:string,
	bucketSecret_ends_with?:string,
	bucketSecret_gt?:string,
	bucketSecret_gte?:string,
	bucketSecret_in?:string[],
	bucketSecret_lt?:string,
	bucketSecret_lte?:string,
	bucketSecret_not?:string,
	bucketSecret_not_contains?:string,
	bucketSecret_not_ends_with?:string,
	bucketSecret_not_in?:string[],
	bucketSecret_not_starts_with?:string,
	bucketSecret_starts_with?:string,
	homeFolder?:string,
	homeFolder_contains?:string,
	homeFolder_ends_with?:string,
	homeFolder_gt?:string,
	homeFolder_gte?:string,
	homeFolder_in?:string[],
	homeFolder_lt?:string,
	homeFolder_lte?:string,
	homeFolder_not?:string,
	homeFolder_not_contains?:string,
	homeFolder_not_ends_with?:string,
	homeFolder_not_in?:string[],
	homeFolder_not_starts_with?:string,
	homeFolder_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppsFlyerDatalockerFeedWhereInput"][],
	OR?:ValueTypes["AppsFlyerDatalockerFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["AppsFlyerRawEventsFeed"]: AliasType<{
	appsFlyerAccess?:ValueTypes["AppsFlyerAccess"],
	eventName?:true,
	gameId?:true,
	id?:true,
	omitFields?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	reportType?:true,
		__typename?: true
}>;
	["AppsFlyerRawEventsFeedCreateWithoutParamsInput"]: {
	appsFlyerAccess:ValueTypes["AppsFlyerAccessWhereUniqueInput"],
	eventName:string,
	gameId:string,
	id?:string,
	omitFields:string[],
	reportType:string
};
	["AppsFlyerRawEventsFeedWhereInput"]: {
	AND?:ValueTypes["AppsFlyerRawEventsFeedWhereInput"][],
	appsFlyerAccess?:ValueTypes["AppsFlyerAccessWhereInput"],
	eventName?:string,
	eventName_contains?:string,
	eventName_ends_with?:string,
	eventName_gt?:string,
	eventName_gte?:string,
	eventName_in?:string[],
	eventName_lt?:string,
	eventName_lte?:string,
	eventName_not?:string,
	eventName_not_contains?:string,
	eventName_not_ends_with?:string,
	eventName_not_in?:string[],
	eventName_not_starts_with?:string,
	eventName_starts_with?:string,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppsFlyerRawEventsFeedWhereInput"][],
	OR?:ValueTypes["AppsFlyerRawEventsFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reportType?:string,
	reportType_contains?:string,
	reportType_ends_with?:string,
	reportType_gt?:string,
	reportType_gte?:string,
	reportType_in?:string[],
	reportType_lt?:string,
	reportType_lte?:string,
	reportType_not?:string,
	reportType_not_contains?:string,
	reportType_not_ends_with?:string,
	reportType_not_in?:string[],
	reportType_not_starts_with?:string,
	reportType_starts_with?:string
};
	["AppsFlyerRawInstallsFeed"]: AliasType<{
	appsFlyerAccess?:ValueTypes["AppsFlyerAccess"],
	gameId?:true,
	id?:true,
	omitFields?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	reportType?:true,
		__typename?: true
}>;
	["AppsFlyerRawInstallsFeedCreateWithoutParamsInput"]: {
	appsFlyerAccess:ValueTypes["AppsFlyerAccessWhereUniqueInput"],
	gameId:string,
	id?:string,
	omitFields:string[],
	reportType:string
};
	["AppsFlyerRawInstallsFeedWhereInput"]: {
	AND?:ValueTypes["AppsFlyerRawInstallsFeedWhereInput"][],
	appsFlyerAccess?:ValueTypes["AppsFlyerAccessWhereInput"],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppsFlyerRawInstallsFeedWhereInput"][],
	OR?:ValueTypes["AppsFlyerRawInstallsFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reportType?:string,
	reportType_contains?:string,
	reportType_ends_with?:string,
	reportType_gt?:string,
	reportType_gte?:string,
	reportType_in?:string[],
	reportType_lt?:string,
	reportType_lte?:string,
	reportType_not?:string,
	reportType_not_contains?:string,
	reportType_not_ends_with?:string,
	reportType_not_in?:string[],
	reportType_not_starts_with?:string,
	reportType_starts_with?:string
};
	["AppsFlyerScraperFeed"]: AliasType<{
	email?:true,
games?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ScraperGameOrderByInput"],	skip?:number,	where?:ValueTypes["ScraperGameWhereInput"]},ValueTypes["ScraperGame"]],
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	password?:true,
		__typename?: true
}>;
	["AppsFlyerScraperFeedCreateWithoutParamsInput"]: {
	email:string,
	games?:ValueTypes["ScraperGameCreateInput"][],
	id?:string,
	password:string
};
	["AppsFlyerScraperFeedWhereInput"]: {
	AND?:ValueTypes["AppsFlyerScraperFeedWhereInput"][],
	email?:string,
	email_contains?:string,
	email_ends_with?:string,
	email_gt?:string,
	email_gte?:string,
	email_in?:string[],
	email_lt?:string,
	email_lte?:string,
	email_not?:string,
	email_not_contains?:string,
	email_not_ends_with?:string,
	email_not_in?:string[],
	email_not_starts_with?:string,
	email_starts_with?:string,
	games_every?:ValueTypes["ScraperGameWhereInput"],
	games_none?:ValueTypes["ScraperGameWhereInput"],
	games_some?:ValueTypes["ScraperGameWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AppsFlyerScraperFeedWhereInput"][],
	OR?:ValueTypes["AppsFlyerScraperFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	password?:string,
	password_contains?:string,
	password_ends_with?:string,
	password_gt?:string,
	password_gte?:string,
	password_in?:string[],
	password_lt?:string,
	password_lte?:string,
	password_not?:string,
	password_not_contains?:string,
	password_not_ends_with?:string,
	password_not_in?:string[],
	password_not_starts_with?:string,
	password_starts_with?:string
};
	["AwsProject"]: AliasType<{
	accessKeyId?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
s3Destinations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["S3DestinationOrderByInput"],	skip?:number,	where?:ValueTypes["S3DestinationWhereInput"]},ValueTypes["S3Destination"]],
	secretAccessKey?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["AwsProjectCreateInput"]: {
	accessKeyId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	secretAccessKey:string
};
	["AwsProjectOrderByInput"]:AwsProjectOrderByInput;
	["AwsProjectUpdateInput"]: {
	accessKeyId?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	secretAccessKey?:string
};
	["AwsProjectWhereInput"]: {
	accessKeyId?:string,
	accessKeyId_contains?:string,
	accessKeyId_ends_with?:string,
	accessKeyId_gt?:string,
	accessKeyId_gte?:string,
	accessKeyId_in?:string[],
	accessKeyId_lt?:string,
	accessKeyId_lte?:string,
	accessKeyId_not?:string,
	accessKeyId_not_contains?:string,
	accessKeyId_not_ends_with?:string,
	accessKeyId_not_in?:string[],
	accessKeyId_not_starts_with?:string,
	accessKeyId_starts_with?:string,
	AND?:ValueTypes["AwsProjectWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["AwsProjectWhereInput"][],
	OR?:ValueTypes["AwsProjectWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	s3Destinations_every?:ValueTypes["S3DestinationWhereInput"],
	s3Destinations_none?:ValueTypes["S3DestinationWhereInput"],
	s3Destinations_some?:ValueTypes["S3DestinationWhereInput"],
	secretAccessKey?:string,
	secretAccessKey_contains?:string,
	secretAccessKey_ends_with?:string,
	secretAccessKey_gt?:string,
	secretAccessKey_gte?:string,
	secretAccessKey_in?:string[],
	secretAccessKey_lt?:string,
	secretAccessKey_lte?:string,
	secretAccessKey_not?:string,
	secretAccessKey_not_contains?:string,
	secretAccessKey_not_ends_with?:string,
	secretAccessKey_not_in?:string[],
	secretAccessKey_not_starts_with?:string,
	secretAccessKey_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["AwsProjectWhereUniqueInput"]: {
	id:string
};
	["BigQueryCheck"]: AliasType<{
	createdAt?:true,
	dataset?:true,
	id?:true,
	lastModified?:true,
	maxDate?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	projectId?:true,
	tableExists?:true,
	tableName?:true,
	tableReference?:true,
	tableSuffix?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["BigQueryCheckConnection"]: AliasType<{
	aggregate?:ValueTypes["AggregateBigQueryCheck"],
	edges?:ValueTypes["BigQueryCheckEdge"],
	pageInfo?:ValueTypes["PageInfo"],
		__typename?: true
}>;
	["BigQueryCheckEdge"]: AliasType<{
	cursor?:true,
	node?:ValueTypes["BigQueryCheck"],
		__typename?: true
}>;
	["BigQueryCheckOrderByInput"]:BigQueryCheckOrderByInput;
	["BigQueryCheckWhereInput"]: {
	AND?:ValueTypes["BigQueryCheckWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	dataset?:string,
	dataset_contains?:string,
	dataset_ends_with?:string,
	dataset_gt?:string,
	dataset_gte?:string,
	dataset_in?:string[],
	dataset_lt?:string,
	dataset_lte?:string,
	dataset_not?:string,
	dataset_not_contains?:string,
	dataset_not_ends_with?:string,
	dataset_not_in?:string[],
	dataset_not_starts_with?:string,
	dataset_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	lastModified?:ValueTypes["DateTime"],
	lastModified_gt?:ValueTypes["DateTime"],
	lastModified_gte?:ValueTypes["DateTime"],
	lastModified_in?:ValueTypes["DateTime"][],
	lastModified_lt?:ValueTypes["DateTime"],
	lastModified_lte?:ValueTypes["DateTime"],
	lastModified_not?:ValueTypes["DateTime"],
	lastModified_not_in?:ValueTypes["DateTime"][],
	maxDate?:ValueTypes["DateTime"],
	maxDate_gt?:ValueTypes["DateTime"],
	maxDate_gte?:ValueTypes["DateTime"],
	maxDate_in?:ValueTypes["DateTime"][],
	maxDate_lt?:ValueTypes["DateTime"],
	maxDate_lte?:ValueTypes["DateTime"],
	maxDate_not?:ValueTypes["DateTime"],
	maxDate_not_in?:ValueTypes["DateTime"][],
	NOT?:ValueTypes["BigQueryCheckWhereInput"][],
	OR?:ValueTypes["BigQueryCheckWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	projectId?:string,
	projectId_contains?:string,
	projectId_ends_with?:string,
	projectId_gt?:string,
	projectId_gte?:string,
	projectId_in?:string[],
	projectId_lt?:string,
	projectId_lte?:string,
	projectId_not?:string,
	projectId_not_contains?:string,
	projectId_not_ends_with?:string,
	projectId_not_in?:string[],
	projectId_not_starts_with?:string,
	projectId_starts_with?:string,
	tableExists?:boolean,
	tableExists_not?:boolean,
	tableName?:string,
	tableName_contains?:string,
	tableName_ends_with?:string,
	tableName_gt?:string,
	tableName_gte?:string,
	tableName_in?:string[],
	tableName_lt?:string,
	tableName_lte?:string,
	tableName_not?:string,
	tableName_not_contains?:string,
	tableName_not_ends_with?:string,
	tableName_not_in?:string[],
	tableName_not_starts_with?:string,
	tableName_starts_with?:string,
	tableReference?:string,
	tableReference_contains?:string,
	tableReference_ends_with?:string,
	tableReference_gt?:string,
	tableReference_gte?:string,
	tableReference_in?:string[],
	tableReference_lt?:string,
	tableReference_lte?:string,
	tableReference_not?:string,
	tableReference_not_contains?:string,
	tableReference_not_ends_with?:string,
	tableReference_not_in?:string[],
	tableReference_not_starts_with?:string,
	tableReference_starts_with?:string,
	tableSuffix?:string,
	tableSuffix_contains?:string,
	tableSuffix_ends_with?:string,
	tableSuffix_gt?:string,
	tableSuffix_gte?:string,
	tableSuffix_in?:string[],
	tableSuffix_lt?:string,
	tableSuffix_lte?:string,
	tableSuffix_not?:string,
	tableSuffix_not_contains?:string,
	tableSuffix_not_ends_with?:string,
	tableSuffix_not_in?:string[],
	tableSuffix_not_starts_with?:string,
	tableSuffix_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["BigQueryCheckWhereUniqueInput"]: {
	id?:string,
	tableReference?:string
};
	["BigQueryDestination"]: AliasType<{
	bqProject?:ValueTypes["BigQueryProject"],
	createdAt?:true,
	dataset?:true,
	descriptiveName?:true,
	enabled?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	tablePrefix?:true,
	tableSuffix?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["BigQueryDestinationCreateInput"]: {
	bqProject:ValueTypes["BigQueryProjectWhereUniqueInput"],
	dataset:string,
	descriptiveName:string,
	enabled:boolean,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	tablePrefix?:string,
	tableSuffix?:string
};
	["BigQueryDestinationOrderByInput"]:BigQueryDestinationOrderByInput;
	["BigQueryDestinationUpdateInput"]: {
	bqProject?:ValueTypes["BigQueryProjectWhereUniqueInput"],
	dataset?:string,
	descriptiveName?:string,
	enabled?:boolean,
	id:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	tablePrefix?:string,
	tableSuffix?:string
};
	["BigQueryDestinationUpdateManyInput"]: {
	create?:ValueTypes["BigQueryDestinationCreateInput"][],
	delete?:ValueTypes["BigQueryDestinationWhereUniqueInput"][],
	update?:ValueTypes["BigQueryDestinationUpdateInput"][]
};
	["BigQueryDestinationWhereInput"]: {
	AND?:ValueTypes["BigQueryDestinationWhereInput"][],
	bqProject?:ValueTypes["BigQueryProjectWhereInput"],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	dataset?:string,
	dataset_contains?:string,
	dataset_ends_with?:string,
	dataset_gt?:string,
	dataset_gte?:string,
	dataset_in?:string[],
	dataset_lt?:string,
	dataset_lte?:string,
	dataset_not?:string,
	dataset_not_contains?:string,
	dataset_not_ends_with?:string,
	dataset_not_in?:string[],
	dataset_not_starts_with?:string,
	dataset_starts_with?:string,
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["BigQueryDestinationWhereInput"][],
	OR?:ValueTypes["BigQueryDestinationWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	tablePrefix?:string,
	tablePrefix_contains?:string,
	tablePrefix_ends_with?:string,
	tablePrefix_gt?:string,
	tablePrefix_gte?:string,
	tablePrefix_in?:string[],
	tablePrefix_lt?:string,
	tablePrefix_lte?:string,
	tablePrefix_not?:string,
	tablePrefix_not_contains?:string,
	tablePrefix_not_ends_with?:string,
	tablePrefix_not_in?:string[],
	tablePrefix_not_starts_with?:string,
	tablePrefix_starts_with?:string,
	tableSuffix?:string,
	tableSuffix_contains?:string,
	tableSuffix_ends_with?:string,
	tableSuffix_gt?:string,
	tableSuffix_gte?:string,
	tableSuffix_in?:string[],
	tableSuffix_lt?:string,
	tableSuffix_lte?:string,
	tableSuffix_not?:string,
	tableSuffix_not_contains?:string,
	tableSuffix_not_ends_with?:string,
	tableSuffix_not_in?:string[],
	tableSuffix_not_starts_with?:string,
	tableSuffix_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["BigQueryDestinationWhereUniqueInput"]: {
	id:string
};
	["BigQueryProject"]: AliasType<{
bqDestinations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryDestinationOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryDestinationWhereInput"]},ValueTypes["BigQueryDestination"]],
	clientEmail?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	privateKey?:true,
	projectId?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["BigQueryProjectCreateInput"]: {
	clientEmail:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	privateKey:string,
	projectId:string
};
	["BigQueryProjectOrderByInput"]:BigQueryProjectOrderByInput;
	["BigQueryProjectUpdateInput"]: {
	clientEmail?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	privateKey?:string,
	projectId?:string
};
	["BigQueryProjectWhereInput"]: {
	AND?:ValueTypes["BigQueryProjectWhereInput"][],
	bqDestinations_every?:ValueTypes["BigQueryDestinationWhereInput"],
	bqDestinations_none?:ValueTypes["BigQueryDestinationWhereInput"],
	bqDestinations_some?:ValueTypes["BigQueryDestinationWhereInput"],
	clientEmail?:string,
	clientEmail_contains?:string,
	clientEmail_ends_with?:string,
	clientEmail_gt?:string,
	clientEmail_gte?:string,
	clientEmail_in?:string[],
	clientEmail_lt?:string,
	clientEmail_lte?:string,
	clientEmail_not?:string,
	clientEmail_not_contains?:string,
	clientEmail_not_ends_with?:string,
	clientEmail_not_in?:string[],
	clientEmail_not_starts_with?:string,
	clientEmail_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["BigQueryProjectWhereInput"][],
	OR?:ValueTypes["BigQueryProjectWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	privateKey?:string,
	privateKey_contains?:string,
	privateKey_ends_with?:string,
	privateKey_gt?:string,
	privateKey_gte?:string,
	privateKey_in?:string[],
	privateKey_lt?:string,
	privateKey_lte?:string,
	privateKey_not?:string,
	privateKey_not_contains?:string,
	privateKey_not_ends_with?:string,
	privateKey_not_in?:string[],
	privateKey_not_starts_with?:string,
	privateKey_starts_with?:string,
	projectId?:string,
	projectId_contains?:string,
	projectId_ends_with?:string,
	projectId_gt?:string,
	projectId_gte?:string,
	projectId_in?:string[],
	projectId_lt?:string,
	projectId_lte?:string,
	projectId_not?:string,
	projectId_not_contains?:string,
	projectId_not_ends_with?:string,
	projectId_not_in?:string[],
	projectId_not_starts_with?:string,
	projectId_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["BigQueryProjectWhereUniqueInput"]: {
	id:string
};
	["CampaignConsistencyCheck"]: AliasType<{
	campaignId?:true,
	createdAt?:true,
	date?:true,
	gameId?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	platform?:true,
	updatedAt?:true,
values?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["CampaignConsistencyValuesOrderByInput"],	skip?:number,	where?:ValueTypes["CampaignConsistencyValuesWhereInput"]},ValueTypes["CampaignConsistencyValues"]],
		__typename?: true
}>;
	["CampaignConsistencyCheckConnection"]: AliasType<{
	aggregate?:ValueTypes["AggregateCampaignConsistencyCheck"],
	edges?:ValueTypes["CampaignConsistencyCheckEdge"],
	pageInfo?:ValueTypes["PageInfo"],
		__typename?: true
}>;
	["CampaignConsistencyCheckEdge"]: AliasType<{
	cursor?:true,
	node?:ValueTypes["CampaignConsistencyCheck"],
		__typename?: true
}>;
	["CampaignConsistencyCheckOrderByInput"]:CampaignConsistencyCheckOrderByInput;
	["CampaignConsistencyCheckWhereInput"]: {
	AND?:ValueTypes["CampaignConsistencyCheckWhereInput"][],
	campaignId?:string,
	campaignId_contains?:string,
	campaignId_ends_with?:string,
	campaignId_gt?:string,
	campaignId_gte?:string,
	campaignId_in?:string[],
	campaignId_lt?:string,
	campaignId_lte?:string,
	campaignId_not?:string,
	campaignId_not_contains?:string,
	campaignId_not_ends_with?:string,
	campaignId_not_in?:string[],
	campaignId_not_starts_with?:string,
	campaignId_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	date?:ValueTypes["DateTime"],
	date_gt?:ValueTypes["DateTime"],
	date_gte?:ValueTypes["DateTime"],
	date_in?:ValueTypes["DateTime"][],
	date_lt?:ValueTypes["DateTime"],
	date_lte?:ValueTypes["DateTime"],
	date_not?:ValueTypes["DateTime"],
	date_not_in?:ValueTypes["DateTime"][],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["CampaignConsistencyCheckWhereInput"][],
	OR?:ValueTypes["CampaignConsistencyCheckWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	platform?:ValueTypes["Platform"],
	platform_in?:ValueTypes["Platform"][],
	platform_not?:ValueTypes["Platform"],
	platform_not_in?:ValueTypes["Platform"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][],
	values_every?:ValueTypes["CampaignConsistencyValuesWhereInput"],
	values_none?:ValueTypes["CampaignConsistencyValuesWhereInput"],
	values_some?:ValueTypes["CampaignConsistencyValuesWhereInput"]
};
	["CampaignConsistencyCheckWhereUniqueInput"]: {
	id?:string
};
	["CampaignConsistencyValues"]: AliasType<{
	clicks?:true,
	createdAt?:true,
	iapGrossRevenue?:true,
	id?:true,
	impressions?:true,
	installs?:true,
	payers?:true,
	purchases?:true,
	source?:true,
	spend?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["CampaignConsistencyValuesOrderByInput"]:CampaignConsistencyValuesOrderByInput;
	["CampaignConsistencyValuesWhereInput"]: {
	AND?:ValueTypes["CampaignConsistencyValuesWhereInput"][],
	clicks?:number,
	clicks_gt?:number,
	clicks_gte?:number,
	clicks_in?:number[],
	clicks_lt?:number,
	clicks_lte?:number,
	clicks_not?:number,
	clicks_not_in?:number[],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	iapGrossRevenue?:number,
	iapGrossRevenue_gt?:number,
	iapGrossRevenue_gte?:number,
	iapGrossRevenue_in?:number[],
	iapGrossRevenue_lt?:number,
	iapGrossRevenue_lte?:number,
	iapGrossRevenue_not?:number,
	iapGrossRevenue_not_in?:number[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	impressions?:number,
	impressions_gt?:number,
	impressions_gte?:number,
	impressions_in?:number[],
	impressions_lt?:number,
	impressions_lte?:number,
	impressions_not?:number,
	impressions_not_in?:number[],
	installs?:number,
	installs_gt?:number,
	installs_gte?:number,
	installs_in?:number[],
	installs_lt?:number,
	installs_lte?:number,
	installs_not?:number,
	installs_not_in?:number[],
	NOT?:ValueTypes["CampaignConsistencyValuesWhereInput"][],
	OR?:ValueTypes["CampaignConsistencyValuesWhereInput"][],
	payers?:number,
	payers_gt?:number,
	payers_gte?:number,
	payers_in?:number[],
	payers_lt?:number,
	payers_lte?:number,
	payers_not?:number,
	payers_not_in?:number[],
	purchases?:number,
	purchases_gt?:number,
	purchases_gte?:number,
	purchases_in?:number[],
	purchases_lt?:number,
	purchases_lte?:number,
	purchases_not?:number,
	purchases_not_in?:number[],
	source?:ValueTypes["ConsistencySource"],
	source_in?:ValueTypes["ConsistencySource"][],
	source_not?:ValueTypes["ConsistencySource"],
	source_not_in?:ValueTypes["ConsistencySource"][],
	spend?:number,
	spend_gt?:number,
	spend_gte?:number,
	spend_in?:number[],
	spend_lt?:number,
	spend_lte?:number,
	spend_not?:number,
	spend_not_in?:number[],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["ChartboostAccess"]: AliasType<{
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
	userId?:true,
	userSignature?:true,
		__typename?: true
}>;
	["ChartboostAccessCreateInput"]: {
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	userId:string,
	userSignature:string
};
	["ChartboostAccessOrderByInput"]:ChartboostAccessOrderByInput;
	["ChartboostAccessUpdateInput"]: {
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	userId?:string,
	userSignature?:string
};
	["ChartboostAccessWhereInput"]: {
	AND?:ValueTypes["ChartboostAccessWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["ChartboostAccessWhereInput"][],
	OR?:ValueTypes["ChartboostAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][],
	userId?:string,
	userId_contains?:string,
	userId_ends_with?:string,
	userId_gt?:string,
	userId_gte?:string,
	userId_in?:string[],
	userId_lt?:string,
	userId_lte?:string,
	userId_not?:string,
	userId_not_contains?:string,
	userId_not_ends_with?:string,
	userId_not_in?:string[],
	userId_not_starts_with?:string,
	userId_starts_with?:string,
	userSignature?:string,
	userSignature_contains?:string,
	userSignature_ends_with?:string,
	userSignature_gt?:string,
	userSignature_gte?:string,
	userSignature_in?:string[],
	userSignature_lt?:string,
	userSignature_lte?:string,
	userSignature_not?:string,
	userSignature_not_contains?:string,
	userSignature_not_ends_with?:string,
	userSignature_not_in?:string[],
	userSignature_not_starts_with?:string,
	userSignature_starts_with?:string
};
	["ChartboostAccessWhereUniqueInput"]: {
	id:string
};
	["ChartboostFeed"]: AliasType<{
	chartboostAccess?:ValueTypes["ChartboostAccess"],
	gameId?:true,
	groupBy?:true,
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["ChartboostFeedCreateWithoutParamsInput"]: {
	chartboostAccess:ValueTypes["ChartboostAccessWhereUniqueInput"],
	gameId:string,
	groupBy?:string,
	id?:string
};
	["ChartboostFeedWhereInput"]: {
	AND?:ValueTypes["ChartboostFeedWhereInput"][],
	chartboostAccess?:ValueTypes["ChartboostAccessWhereInput"],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	groupBy?:string,
	groupBy_contains?:string,
	groupBy_ends_with?:string,
	groupBy_gt?:string,
	groupBy_gte?:string,
	groupBy_in?:string[],
	groupBy_lt?:string,
	groupBy_lte?:string,
	groupBy_not?:string,
	groupBy_not_contains?:string,
	groupBy_not_ends_with?:string,
	groupBy_not_in?:string[],
	groupBy_not_starts_with?:string,
	groupBy_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["ChartboostFeedWhereInput"][],
	OR?:ValueTypes["ChartboostFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["ConsistencySource"]:ConsistencySource;
	["DailyConsistencyCheck"]: AliasType<{
	createdAt?:true,
	date?:true,
	gameId?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	platform?:true,
	updatedAt?:true,
values?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["DailyConsistencyValuesOrderByInput"],	skip?:number,	where?:ValueTypes["DailyConsistencyValuesWhereInput"]},ValueTypes["DailyConsistencyValues"]],
		__typename?: true
}>;
	["DailyConsistencyCheckConnection"]: AliasType<{
	aggregate?:ValueTypes["AggregateDailyConsistencyCheck"],
	edges?:ValueTypes["DailyConsistencyCheckEdge"],
	pageInfo?:ValueTypes["PageInfo"],
		__typename?: true
}>;
	["DailyConsistencyCheckEdge"]: AliasType<{
	cursor?:true,
	node?:ValueTypes["DailyConsistencyCheck"],
		__typename?: true
}>;
	["DailyConsistencyCheckOrderByInput"]:DailyConsistencyCheckOrderByInput;
	["DailyConsistencyCheckWhereInput"]: {
	AND?:ValueTypes["DailyConsistencyCheckWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	date?:ValueTypes["DateTime"],
	date_gt?:ValueTypes["DateTime"],
	date_gte?:ValueTypes["DateTime"],
	date_in?:ValueTypes["DateTime"][],
	date_lt?:ValueTypes["DateTime"],
	date_lte?:ValueTypes["DateTime"],
	date_not?:ValueTypes["DateTime"],
	date_not_in?:ValueTypes["DateTime"][],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["DailyConsistencyCheckWhereInput"][],
	OR?:ValueTypes["DailyConsistencyCheckWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	platform?:ValueTypes["Platform"],
	platform_in?:ValueTypes["Platform"][],
	platform_not?:ValueTypes["Platform"],
	platform_not_in?:ValueTypes["Platform"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][],
	values_every?:ValueTypes["DailyConsistencyValuesWhereInput"],
	values_none?:ValueTypes["DailyConsistencyValuesWhereInput"],
	values_some?:ValueTypes["DailyConsistencyValuesWhereInput"]
};
	["DailyConsistencyCheckWhereUniqueInput"]: {
	id?:string
};
	["DailyConsistencyValues"]: AliasType<{
	createdAt?:true,
	id?:true,
	installs?:true,
	purchases?:true,
	revenue?:true,
	source?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["DailyConsistencyValuesOrderByInput"]:DailyConsistencyValuesOrderByInput;
	["DailyConsistencyValuesWhereInput"]: {
	AND?:ValueTypes["DailyConsistencyValuesWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	installs?:number,
	installs_gt?:number,
	installs_gte?:number,
	installs_in?:number[],
	installs_lt?:number,
	installs_lte?:number,
	installs_not?:number,
	installs_not_in?:number[],
	NOT?:ValueTypes["DailyConsistencyValuesWhereInput"][],
	OR?:ValueTypes["DailyConsistencyValuesWhereInput"][],
	purchases?:number,
	purchases_gt?:number,
	purchases_gte?:number,
	purchases_in?:number[],
	purchases_lt?:number,
	purchases_lte?:number,
	purchases_not?:number,
	purchases_not_in?:number[],
	revenue?:number,
	revenue_gt?:number,
	revenue_gte?:number,
	revenue_in?:number[],
	revenue_lt?:number,
	revenue_lte?:number,
	revenue_not?:number,
	revenue_not_in?:number[],
	source?:ValueTypes["ConsistencySource"],
	source_in?:ValueTypes["ConsistencySource"][],
	source_not?:ValueTypes["ConsistencySource"],
	source_not_in?:ValueTypes["ConsistencySource"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["DateTime"]:unknown;
	["Error"]: AliasType<{
	createdAt?:true,
	id?:true,
	message?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["ErrorOrderByInput"]:ErrorOrderByInput;
	["ErrorWhereInput"]: {
	AND?:ValueTypes["ErrorWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	message?:string,
	message_contains?:string,
	message_ends_with?:string,
	message_gt?:string,
	message_gte?:string,
	message_in?:string[],
	message_lt?:string,
	message_lte?:string,
	message_not?:string,
	message_not_contains?:string,
	message_not_ends_with?:string,
	message_not_in?:string[],
	message_not_starts_with?:string,
	message_starts_with?:string,
	NOT?:ValueTypes["ErrorWhereInput"][],
	OR?:ValueTypes["ErrorWhereInput"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["FacebookAdsAccess"]: AliasType<{
	accessToken?:true,
	accountId?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["FacebookAdsAccessCreateInput"]: {
	accessToken:string,
	accountId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["FacebookAdsAccessOrderByInput"]:FacebookAdsAccessOrderByInput;
	["FacebookAdsAccessUpdateInput"]: {
	accessToken?:string,
	accountId?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["FacebookAdsAccessWhereInput"]: {
	accessToken?:string,
	accessToken_contains?:string,
	accessToken_ends_with?:string,
	accessToken_gt?:string,
	accessToken_gte?:string,
	accessToken_in?:string[],
	accessToken_lt?:string,
	accessToken_lte?:string,
	accessToken_not?:string,
	accessToken_not_contains?:string,
	accessToken_not_ends_with?:string,
	accessToken_not_in?:string[],
	accessToken_not_starts_with?:string,
	accessToken_starts_with?:string,
	accountId?:string,
	accountId_contains?:string,
	accountId_ends_with?:string,
	accountId_gt?:string,
	accountId_gte?:string,
	accountId_in?:string[],
	accountId_lt?:string,
	accountId_lte?:string,
	accountId_not?:string,
	accountId_not_contains?:string,
	accountId_not_ends_with?:string,
	accountId_not_in?:string[],
	accountId_not_starts_with?:string,
	accountId_starts_with?:string,
	AND?:ValueTypes["FacebookAdsAccessWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FacebookAdsAccessWhereInput"][],
	OR?:ValueTypes["FacebookAdsAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["FacebookAdsAccessWhereUniqueInput"]: {
	id:string
};
	["FacebookAdsFeed"]: AliasType<{
	additionalFields?:true,
breakdowns?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["FacebookBreakdownOrderByInput"],	skip?:number,	where?:ValueTypes["FacebookBreakdownWhereInput"]},ValueTypes["FacebookBreakdown"]],
	facebookAdsAccess?:ValueTypes["FacebookAdsAccess"],
	id?:true,
	levels?:true,
	omitFields?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	snapshot?:true,
		__typename?: true
}>;
	["FacebookAdsFeedCreateWithoutParamsInput"]: {
	additionalFields:string[],
	breakdowns:ValueTypes["FacebookBreakdownCreateInput"][],
	facebookAdsAccess:ValueTypes["FacebookAdsAccessWhereUniqueInput"],
	id?:string,
	levels:string[],
	omitFields:string[],
	snapshot?:boolean
};
	["FacebookAdsFeedWhereInput"]: {
	AND?:ValueTypes["FacebookAdsFeedWhereInput"][],
	breakdowns_every?:ValueTypes["FacebookBreakdownWhereInput"],
	breakdowns_none?:ValueTypes["FacebookBreakdownWhereInput"],
	breakdowns_some?:ValueTypes["FacebookBreakdownWhereInput"],
	facebookAdsAccess?:ValueTypes["FacebookAdsAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FacebookAdsFeedWhereInput"][],
	OR?:ValueTypes["FacebookAdsFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	snapshot?:boolean,
	snapshot_not?:boolean
};
	["FacebookBreakdown"]: AliasType<{
	id?:true,
	values?:true,
		__typename?: true
}>;
	["FacebookBreakdownCreateInput"]: {
	values:string[]
};
	["FacebookBreakdownOrderByInput"]:FacebookBreakdownOrderByInput;
	["FacebookBreakdownWhereInput"]: {
	AND?:ValueTypes["FacebookBreakdownWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FacebookBreakdownWhereInput"][],
	OR?:ValueTypes["FacebookBreakdownWhereInput"][]
};
	["FairbidAccess"]: AliasType<{
	clientId?:true,
	clientSecret?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["FairbidAccessCreateInput"]: {
	clientId:string,
	clientSecret:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["FairbidAccessOrderByInput"]:FairbidAccessOrderByInput;
	["FairbidAccessUpdateInput"]: {
	clientId?:string,
	clientSecret?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["FairbidAccessWhereInput"]: {
	AND?:ValueTypes["FairbidAccessWhereInput"][],
	clientId?:string,
	clientId_contains?:string,
	clientId_ends_with?:string,
	clientId_gt?:string,
	clientId_gte?:string,
	clientId_in?:string[],
	clientId_lt?:string,
	clientId_lte?:string,
	clientId_not?:string,
	clientId_not_contains?:string,
	clientId_not_ends_with?:string,
	clientId_not_in?:string[],
	clientId_not_starts_with?:string,
	clientId_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FairbidAccessWhereInput"][],
	OR?:ValueTypes["FairbidAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["FairbidAccessWhereUniqueInput"]: {
	id:string
};
	["FairbidUserReportFeed"]: AliasType<{
	appId?:true,
	fairbidAccess?:ValueTypes["FairbidAccess"],
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["FairbidUserReportFeedCreateWithoutParamsInput"]: {
	appId:string,
	fairbidAccess:ValueTypes["FairbidAccessWhereUniqueInput"],
	id?:string
};
	["FairbidUserReportFeedWhereInput"]: {
	AND?:ValueTypes["FairbidUserReportFeedWhereInput"][],
	appId?:string,
	appId_contains?:string,
	appId_ends_with?:string,
	appId_gt?:string,
	appId_gte?:string,
	appId_in?:string[],
	appId_lt?:string,
	appId_lte?:string,
	appId_not?:string,
	appId_not_contains?:string,
	appId_not_ends_with?:string,
	appId_not_in?:string[],
	appId_not_starts_with?:string,
	appId_starts_with?:string,
	fairbidAccess?:ValueTypes["FairbidAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FairbidUserReportFeedWhereInput"][],
	OR?:ValueTypes["FairbidUserReportFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["Feed"]:Feed;
	["FeedDateInput"]: AliasType<{
	dateDashFrom?:true,
	dateDashTo?:true,
	daysInPast?:true,
	id?:true,
	offset?:true,
		__typename?: true
}>;
	["FeedDateInputCreateInput"]: {
	dateDashFrom?:string,
	dateDashTo?:string,
	daysInPast?:number,
	id?:string,
	offset?:number
};
	["FeedDateInputWhereInput"]: {
	AND?:ValueTypes["FeedDateInputWhereInput"][],
	dateDashFrom?:string,
	dateDashFrom_contains?:string,
	dateDashFrom_ends_with?:string,
	dateDashFrom_gt?:string,
	dateDashFrom_gte?:string,
	dateDashFrom_in?:string[],
	dateDashFrom_lt?:string,
	dateDashFrom_lte?:string,
	dateDashFrom_not?:string,
	dateDashFrom_not_contains?:string,
	dateDashFrom_not_ends_with?:string,
	dateDashFrom_not_in?:string[],
	dateDashFrom_not_starts_with?:string,
	dateDashFrom_starts_with?:string,
	dateDashTo?:string,
	dateDashTo_contains?:string,
	dateDashTo_ends_with?:string,
	dateDashTo_gt?:string,
	dateDashTo_gte?:string,
	dateDashTo_in?:string[],
	dateDashTo_lt?:string,
	dateDashTo_lte?:string,
	dateDashTo_not?:string,
	dateDashTo_not_contains?:string,
	dateDashTo_not_ends_with?:string,
	dateDashTo_not_in?:string[],
	dateDashTo_not_starts_with?:string,
	dateDashTo_starts_with?:string,
	daysInPast?:number,
	daysInPast_gt?:number,
	daysInPast_gte?:number,
	daysInPast_in?:number[],
	daysInPast_lt?:number,
	daysInPast_lte?:number,
	daysInPast_not?:number,
	daysInPast_not_in?:number[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FeedDateInputWhereInput"][],
	offset?:number,
	offset_gt?:number,
	offset_gte?:number,
	offset_in?:number[],
	offset_lt?:number,
	offset_lte?:number,
	offset_not?:number,
	offset_not_in?:number[],
	OR?:ValueTypes["FeedDateInputWhereInput"][]
};
	["FixerFeed"]: AliasType<{
	currency?:true,
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["FixerFeedCreateWithoutParamsInput"]: {
	currency:string,
	id?:string
};
	["FixerFeedWhereInput"]: {
	AND?:ValueTypes["FixerFeedWhereInput"][],
	currency?:string,
	currency_contains?:string,
	currency_ends_with?:string,
	currency_gt?:string,
	currency_gte?:string,
	currency_in?:string[],
	currency_lt?:string,
	currency_lte?:string,
	currency_not?:string,
	currency_not_contains?:string,
	currency_not_ends_with?:string,
	currency_not_in?:string[],
	currency_not_starts_with?:string,
	currency_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["FixerFeedWhereInput"][],
	OR?:ValueTypes["FixerFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["GoogleAdsAccess"]: AliasType<{
	clientId?:true,
	clientSecret?:true,
	createdAt?:true,
	descriptiveName?:true,
	developerToken?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["GoogleAdsAccessCreateInput"]: {
	clientId:string,
	clientSecret:string,
	descriptiveName:string,
	developerToken:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["GoogleAdsAccessOrderByInput"]:GoogleAdsAccessOrderByInput;
	["GoogleAdsAccessUpdateInput"]: {
	clientId?:string,
	clientSecret?:string,
	descriptiveName?:string,
	developerToken?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["GoogleAdsAccessWhereInput"]: {
	AND?:ValueTypes["GoogleAdsAccessWhereInput"][],
	clientId?:string,
	clientId_contains?:string,
	clientId_ends_with?:string,
	clientId_gt?:string,
	clientId_gte?:string,
	clientId_in?:string[],
	clientId_lt?:string,
	clientId_lte?:string,
	clientId_not?:string,
	clientId_not_contains?:string,
	clientId_not_ends_with?:string,
	clientId_not_in?:string[],
	clientId_not_starts_with?:string,
	clientId_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	developerToken?:string,
	developerToken_contains?:string,
	developerToken_ends_with?:string,
	developerToken_gt?:string,
	developerToken_gte?:string,
	developerToken_in?:string[],
	developerToken_lt?:string,
	developerToken_lte?:string,
	developerToken_not?:string,
	developerToken_not_contains?:string,
	developerToken_not_ends_with?:string,
	developerToken_not_in?:string[],
	developerToken_not_starts_with?:string,
	developerToken_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["GoogleAdsAccessWhereInput"][],
	OR?:ValueTypes["GoogleAdsAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["GoogleAdsAccessWhereUniqueInput"]: {
	id:string
};
	["GoogleAdwordsFeed"]: AliasType<{
	customerAccountId?:true,
	geographicView?:true,
	googleAdsAccess?:ValueTypes["GoogleAdsAccess"],
	id?:true,
	loginCustomerId?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	refreshToken?:true,
		__typename?: true
}>;
	["GoogleAdwordsFeedCreateWithoutParamsInput"]: {
	customerAccountId:string,
	geographicView?:boolean,
	googleAdsAccess:ValueTypes["GoogleAdsAccessWhereUniqueInput"],
	id?:string,
	loginCustomerId:string,
	refreshToken:string
};
	["GoogleAdwordsFeedWhereInput"]: {
	AND?:ValueTypes["GoogleAdwordsFeedWhereInput"][],
	customerAccountId?:string,
	customerAccountId_contains?:string,
	customerAccountId_ends_with?:string,
	customerAccountId_gt?:string,
	customerAccountId_gte?:string,
	customerAccountId_in?:string[],
	customerAccountId_lt?:string,
	customerAccountId_lte?:string,
	customerAccountId_not?:string,
	customerAccountId_not_contains?:string,
	customerAccountId_not_ends_with?:string,
	customerAccountId_not_in?:string[],
	customerAccountId_not_starts_with?:string,
	customerAccountId_starts_with?:string,
	geographicView?:boolean,
	geographicView_not?:boolean,
	googleAdsAccess?:ValueTypes["GoogleAdsAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	loginCustomerId?:string,
	loginCustomerId_contains?:string,
	loginCustomerId_ends_with?:string,
	loginCustomerId_gt?:string,
	loginCustomerId_gte?:string,
	loginCustomerId_in?:string[],
	loginCustomerId_lt?:string,
	loginCustomerId_lte?:string,
	loginCustomerId_not?:string,
	loginCustomerId_not_contains?:string,
	loginCustomerId_not_ends_with?:string,
	loginCustomerId_not_in?:string[],
	loginCustomerId_not_starts_with?:string,
	loginCustomerId_starts_with?:string,
	NOT?:ValueTypes["GoogleAdwordsFeedWhereInput"][],
	OR?:ValueTypes["GoogleAdwordsFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string
};
	["GooglePlayAccess"]: AliasType<{
	bucketId?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	packageIdentifier?:true,
	sourceClientEmail?:true,
	sourcePrivateKey?:true,
	sourceProjectId?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["GooglePlayAccessCreateInput"]: {
	bucketId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	packageIdentifier?:string,
	sourceClientEmail:string,
	sourcePrivateKey:string,
	sourceProjectId:string
};
	["GooglePlayAccessOrderByInput"]:GooglePlayAccessOrderByInput;
	["GooglePlayAccessUpdateInput"]: {
	bucketId?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	packageIdentifier?:string,
	sourceClientEmail?:string,
	sourcePrivateKey?:string,
	sourceProjectId?:string
};
	["GooglePlayAccessWhereInput"]: {
	AND?:ValueTypes["GooglePlayAccessWhereInput"][],
	bucketId?:string,
	bucketId_contains?:string,
	bucketId_ends_with?:string,
	bucketId_gt?:string,
	bucketId_gte?:string,
	bucketId_in?:string[],
	bucketId_lt?:string,
	bucketId_lte?:string,
	bucketId_not?:string,
	bucketId_not_contains?:string,
	bucketId_not_ends_with?:string,
	bucketId_not_in?:string[],
	bucketId_not_starts_with?:string,
	bucketId_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["GooglePlayAccessWhereInput"][],
	OR?:ValueTypes["GooglePlayAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	packageIdentifier?:string,
	packageIdentifier_contains?:string,
	packageIdentifier_ends_with?:string,
	packageIdentifier_gt?:string,
	packageIdentifier_gte?:string,
	packageIdentifier_in?:string[],
	packageIdentifier_lt?:string,
	packageIdentifier_lte?:string,
	packageIdentifier_not?:string,
	packageIdentifier_not_contains?:string,
	packageIdentifier_not_ends_with?:string,
	packageIdentifier_not_in?:string[],
	packageIdentifier_not_starts_with?:string,
	packageIdentifier_starts_with?:string,
	sourceClientEmail?:string,
	sourceClientEmail_contains?:string,
	sourceClientEmail_ends_with?:string,
	sourceClientEmail_gt?:string,
	sourceClientEmail_gte?:string,
	sourceClientEmail_in?:string[],
	sourceClientEmail_lt?:string,
	sourceClientEmail_lte?:string,
	sourceClientEmail_not?:string,
	sourceClientEmail_not_contains?:string,
	sourceClientEmail_not_ends_with?:string,
	sourceClientEmail_not_in?:string[],
	sourceClientEmail_not_starts_with?:string,
	sourceClientEmail_starts_with?:string,
	sourcePrivateKey?:string,
	sourcePrivateKey_contains?:string,
	sourcePrivateKey_ends_with?:string,
	sourcePrivateKey_gt?:string,
	sourcePrivateKey_gte?:string,
	sourcePrivateKey_in?:string[],
	sourcePrivateKey_lt?:string,
	sourcePrivateKey_lte?:string,
	sourcePrivateKey_not?:string,
	sourcePrivateKey_not_contains?:string,
	sourcePrivateKey_not_ends_with?:string,
	sourcePrivateKey_not_in?:string[],
	sourcePrivateKey_not_starts_with?:string,
	sourcePrivateKey_starts_with?:string,
	sourceProjectId?:string,
	sourceProjectId_contains?:string,
	sourceProjectId_ends_with?:string,
	sourceProjectId_gt?:string,
	sourceProjectId_gte?:string,
	sourceProjectId_in?:string[],
	sourceProjectId_lt?:string,
	sourceProjectId_lte?:string,
	sourceProjectId_not?:string,
	sourceProjectId_not_contains?:string,
	sourceProjectId_not_ends_with?:string,
	sourceProjectId_not_in?:string[],
	sourceProjectId_not_starts_with?:string,
	sourceProjectId_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["GooglePlayAccessWhereUniqueInput"]: {
	id:string
};
	["GooglePlayFeed"]: AliasType<{
	googlePlayAccess?:ValueTypes["GooglePlayAccess"],
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["GooglePlayFeedCreateWithoutParamsInput"]: {
	googlePlayAccess:ValueTypes["GooglePlayAccessWhereUniqueInput"],
	id?:string
};
	["GooglePlayFeedWhereInput"]: {
	AND?:ValueTypes["GooglePlayFeedWhereInput"][],
	googlePlayAccess?:ValueTypes["GooglePlayAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["GooglePlayFeedWhereInput"][],
	OR?:ValueTypes["GooglePlayFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["HarvestAccess"]: AliasType<{
	accountId?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	token?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["HarvestAccessCreateInput"]: {
	accountId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	token:string
};
	["HarvestAccessOrderByInput"]:HarvestAccessOrderByInput;
	["HarvestAccessUpdateInput"]: {
	accountId?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	token?:string
};
	["HarvestAccessWhereInput"]: {
	accountId?:string,
	accountId_contains?:string,
	accountId_ends_with?:string,
	accountId_gt?:string,
	accountId_gte?:string,
	accountId_in?:string[],
	accountId_lt?:string,
	accountId_lte?:string,
	accountId_not?:string,
	accountId_not_contains?:string,
	accountId_not_ends_with?:string,
	accountId_not_in?:string[],
	accountId_not_starts_with?:string,
	accountId_starts_with?:string,
	AND?:ValueTypes["HarvestAccessWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["HarvestAccessWhereInput"][],
	OR?:ValueTypes["HarvestAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	token?:string,
	token_contains?:string,
	token_ends_with?:string,
	token_gt?:string,
	token_gte?:string,
	token_in?:string[],
	token_lt?:string,
	token_lte?:string,
	token_not?:string,
	token_not_contains?:string,
	token_not_ends_with?:string,
	token_not_in?:string[],
	token_not_starts_with?:string,
	token_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["HarvestAccessWhereUniqueInput"]: {
	id:string
};
	["HarvestFeed"]: AliasType<{
	harvestAccess?:ValueTypes["HarvestAccess"],
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	updatedSince?:true,
	updatedSinceDaysInPast?:true,
	updatedSinceOffset?:true,
		__typename?: true
}>;
	["HarvestFeedCreateWithoutParamsInput"]: {
	harvestAccess:ValueTypes["HarvestAccessWhereUniqueInput"],
	id?:string,
	updatedSince?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceOffset?:number
};
	["HarvestFeedWhereInput"]: {
	AND?:ValueTypes["HarvestFeedWhereInput"][],
	harvestAccess?:ValueTypes["HarvestAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["HarvestFeedWhereInput"][],
	OR?:ValueTypes["HarvestFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	updatedSince?:string,
	updatedSince_contains?:string,
	updatedSince_ends_with?:string,
	updatedSince_gt?:string,
	updatedSince_gte?:string,
	updatedSince_in?:string[],
	updatedSince_lt?:string,
	updatedSince_lte?:string,
	updatedSince_not?:string,
	updatedSince_not_contains?:string,
	updatedSince_not_ends_with?:string,
	updatedSince_not_in?:string[],
	updatedSince_not_starts_with?:string,
	updatedSince_starts_with?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceDaysInPast_gt?:number,
	updatedSinceDaysInPast_gte?:number,
	updatedSinceDaysInPast_in?:number[],
	updatedSinceDaysInPast_lt?:number,
	updatedSinceDaysInPast_lte?:number,
	updatedSinceDaysInPast_not?:number,
	updatedSinceDaysInPast_not_in?:number[],
	updatedSinceOffset?:number,
	updatedSinceOffset_gt?:number,
	updatedSinceOffset_gte?:number,
	updatedSinceOffset_in?:number[],
	updatedSinceOffset_lt?:number,
	updatedSinceOffset_lte?:number,
	updatedSinceOffset_not?:number,
	updatedSinceOffset_not_in?:number[]
};
	["InstanceState"]:InstanceState;
	["IntegrationFeedConfig"]: AliasType<{
	createdAt?:true,
	eligibleAt?:true,
	enabled?:true,
	id?:true,
instances?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["IntegrationFeedInstanceOrderByInput"],	skip?:number,	where?:ValueTypes["IntegrationFeedInstanceWhereInput"]},ValueTypes["IntegrationFeedInstance"]],
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	params?:ValueTypes["IntegrationFeedParams"],
	updatedAt?:true,
		__typename?: true
}>;
	["IntegrationFeedConfigConnection"]: AliasType<{
	aggregate?:ValueTypes["AggregateIntegrationFeedConfig"],
	edges?:ValueTypes["IntegrationFeedConfigEdge"],
	pageInfo?:ValueTypes["PageInfo"],
		__typename?: true
}>;
	["IntegrationFeedConfigCreateInput"]: {
	eligibleAt:string,
	enabled?:boolean,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	params:ValueTypes["IntegrationFeedParamsCreateInput"]
};
	["IntegrationFeedConfigEdge"]: AliasType<{
	cursor?:true,
	node?:ValueTypes["IntegrationFeedConfig"],
		__typename?: true
}>;
	["IntegrationFeedConfigOrderByInput"]:IntegrationFeedConfigOrderByInput;
	["IntegrationFeedConfigUpdateInput"]: {
	eligibleAt?:string,
	enabled?:boolean,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	params?:ValueTypes["IntegrationFeedParamsUpdateInput"]
};
	["IntegrationFeedConfigWhereInput"]: {
	AND?:ValueTypes["IntegrationFeedConfigWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	eligibleAt?:string,
	eligibleAt_contains?:string,
	eligibleAt_ends_with?:string,
	eligibleAt_gt?:string,
	eligibleAt_gte?:string,
	eligibleAt_in?:string[],
	eligibleAt_lt?:string,
	eligibleAt_lte?:string,
	eligibleAt_not?:string,
	eligibleAt_not_contains?:string,
	eligibleAt_not_ends_with?:string,
	eligibleAt_not_in?:string[],
	eligibleAt_not_starts_with?:string,
	eligibleAt_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	instances_every?:ValueTypes["IntegrationFeedInstanceWhereInput"],
	instances_none?:ValueTypes["IntegrationFeedInstanceWhereInput"],
	instances_some?:ValueTypes["IntegrationFeedInstanceWhereInput"],
	NOT?:ValueTypes["IntegrationFeedConfigWhereInput"][],
	OR?:ValueTypes["IntegrationFeedConfigWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["IntegrationFeedConfigWhereUniqueInput"]: {
	id:string
};
	["IntegrationFeedInstance"]: AliasType<{
	config?:ValueTypes["IntegrationFeedConfig"],
	createdAt?:true,
	failureError?:ValueTypes["Error"],
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	params?:ValueTypes["IntegrationFeedParams"],
retryErrors?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ErrorOrderByInput"],	skip?:number,	where?:ValueTypes["ErrorWhereInput"]},ValueTypes["Error"]],
	state?:true,
	stateMachineExecutionArn?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["IntegrationFeedInstanceConnection"]: AliasType<{
	aggregate?:ValueTypes["AggregateIntegrationFeedInstance"],
	edges?:ValueTypes["IntegrationFeedInstanceEdge"],
	pageInfo?:ValueTypes["PageInfo"],
		__typename?: true
}>;
	["IntegrationFeedInstanceCreateInput"]: {
	config?:ValueTypes["IntegrationFeedConfigWhereUniqueInput"],
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	params:ValueTypes["IntegrationFeedParamsCreateInput"]
};
	["IntegrationFeedInstanceEdge"]: AliasType<{
	cursor?:true,
	node?:ValueTypes["IntegrationFeedInstance"],
		__typename?: true
}>;
	["IntegrationFeedInstanceOrderByInput"]:IntegrationFeedInstanceOrderByInput;
	["IntegrationFeedInstanceWhereInput"]: {
	AND?:ValueTypes["IntegrationFeedInstanceWhereInput"][],
	config?:ValueTypes["IntegrationFeedConfigWhereInput"],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	failureError?:ValueTypes["ErrorWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["IntegrationFeedInstanceWhereInput"][],
	OR?:ValueTypes["IntegrationFeedInstanceWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	retryErrors_every?:ValueTypes["ErrorWhereInput"],
	retryErrors_none?:ValueTypes["ErrorWhereInput"],
	retryErrors_some?:ValueTypes["ErrorWhereInput"],
	state?:ValueTypes["InstanceState"],
	state_in?:ValueTypes["InstanceState"][],
	state_not?:ValueTypes["InstanceState"],
	state_not_in?:ValueTypes["InstanceState"][],
	stateMachineExecutionArn?:string,
	stateMachineExecutionArn_contains?:string,
	stateMachineExecutionArn_ends_with?:string,
	stateMachineExecutionArn_gt?:string,
	stateMachineExecutionArn_gte?:string,
	stateMachineExecutionArn_in?:string[],
	stateMachineExecutionArn_lt?:string,
	stateMachineExecutionArn_lte?:string,
	stateMachineExecutionArn_not?:string,
	stateMachineExecutionArn_not_contains?:string,
	stateMachineExecutionArn_not_ends_with?:string,
	stateMachineExecutionArn_not_in?:string[],
	stateMachineExecutionArn_not_starts_with?:string,
	stateMachineExecutionArn_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["IntegrationFeedInstanceWhereUniqueInput"]: {
	id?:string,
	stateMachineExecutionArn?:string
};
	["IntegrationFeedParams"]: AliasType<{
	apple?:ValueTypes["AppleFeed"],
	appleSearchAds?:ValueTypes["AppleSearchAdsFeed"],
	applovin?:ValueTypes["ApplovinFeed"],
	appsFlyerCampaign?:ValueTypes["AppsFlyerCampaignFeed"],
	appsFlyerDatalocker?:ValueTypes["AppsFlyerDatalockerFeed"],
	appsFlyerRawEvents?:ValueTypes["AppsFlyerRawEventsFeed"],
	appsFlyerRawInstalls?:ValueTypes["AppsFlyerRawInstallsFeed"],
	appsFlyerScraper?:ValueTypes["AppsFlyerScraperFeed"],
bqDestinations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryDestinationOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryDestinationWhereInput"]},ValueTypes["BigQueryDestination"]],
	chartboost?:ValueTypes["ChartboostFeed"],
	dateInput?:ValueTypes["FeedDateInput"],
	descriptiveName?:true,
	facebookAds?:ValueTypes["FacebookAdsFeed"],
	fairbidUserReport?:ValueTypes["FairbidUserReportFeed"],
	feedType?:true,
	fixer?:ValueTypes["FixerFeed"],
	googleAdwords?:ValueTypes["GoogleAdwordsFeed"],
	googlePlay?:ValueTypes["GooglePlayFeed"],
	harvest?:ValueTypes["HarvestFeed"],
	id?:true,
	ironSource?:ValueTypes["IronSourceFeed"],
	ironSourcePromotion?:ValueTypes["IronSourcePromotionFeed"],
	libring?:ValueTypes["LibringFeed"],
s3Destinations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["S3DestinationOrderByInput"],	skip?:number,	where?:ValueTypes["S3DestinationWhereInput"]},ValueTypes["S3Destination"]],
	snapchat?:ValueTypes["SnapchatFeed"],
	tiktokAdsAudience?:ValueTypes["TiktokAdsAudienceFeed"],
	unityAds?:ValueTypes["UnityAdsFeed"],
	unityAdvertiser?:ValueTypes["UnityAdvertiserFeed"],
		__typename?: true
}>;
	["IntegrationFeedParamsCreateInput"]: {
	apple?:ValueTypes["AppleFeedCreateWithoutParamsInput"],
	appleSearchAds?:ValueTypes["AppleSearchAdsFeedCreateWithoutParamsInput"],
	applovin?:ValueTypes["ApplovinFeedCreateWithoutParamsInput"],
	appsFlyerCampaign?:ValueTypes["AppsFlyerCampaignFeedCreateWithoutParamsInput"],
	appsFlyerDatalocker?:ValueTypes["AppsFlyerDatalockerFeedCreateWithoutParamsInput"],
	appsFlyerRawEvents?:ValueTypes["AppsFlyerRawEventsFeedCreateWithoutParamsInput"],
	appsFlyerRawInstalls?:ValueTypes["AppsFlyerRawInstallsFeedCreateWithoutParamsInput"],
	appsFlyerScraper?:ValueTypes["AppsFlyerScraperFeedCreateWithoutParamsInput"],
	bqDestinations:ValueTypes["BigQueryDestinationCreateInput"][],
	chartboost?:ValueTypes["ChartboostFeedCreateWithoutParamsInput"],
	dateInput?:ValueTypes["FeedDateInputCreateInput"],
	descriptiveName:string,
	facebookAds?:ValueTypes["FacebookAdsFeedCreateWithoutParamsInput"],
	fairbidUserReport?:ValueTypes["FairbidUserReportFeedCreateWithoutParamsInput"],
	feedType:ValueTypes["Feed"],
	fixer?:ValueTypes["FixerFeedCreateWithoutParamsInput"],
	googleAdwords?:ValueTypes["GoogleAdwordsFeedCreateWithoutParamsInput"],
	googlePlay?:ValueTypes["GooglePlayFeedCreateWithoutParamsInput"],
	harvest?:ValueTypes["HarvestFeedCreateWithoutParamsInput"],
	ironSource?:ValueTypes["IronSourceFeedCreateWithoutParamsInput"],
	ironSourcePromotion?:ValueTypes["IronSourcePromotionFeedCreateWithoutParamsInput"],
	libring?:ValueTypes["LibringFeedCreateWithoutParamsInput"],
	s3Destinations:ValueTypes["S3DestinationCreateInput"][],
	snapchat?:ValueTypes["SnapchatFeedCreateWithoutParamsInput"],
	tiktokAdsAudience?:ValueTypes["TiktokAdsAudienceFeedCreateWithoutParamsInput"],
	unityAds?:ValueTypes["UnityAdsFeedCreateWithoutParamsInput"],
	unityAdvertiser?:ValueTypes["UnityAdvertiserFeedCreateWithoutParamsInput"]
};
	["IntegrationFeedParamsUpdateInput"]: {
	apple?:ValueTypes["AppleFeedCreateWithoutParamsInput"],
	appleSearchAds?:ValueTypes["AppleSearchAdsFeedCreateWithoutParamsInput"],
	applovin?:ValueTypes["ApplovinFeedCreateWithoutParamsInput"],
	appsFlyerCampaign?:ValueTypes["AppsFlyerCampaignFeedCreateWithoutParamsInput"],
	appsFlyerDatalocker?:ValueTypes["AppsFlyerDatalockerFeedCreateWithoutParamsInput"],
	appsFlyerRawEvents?:ValueTypes["AppsFlyerRawEventsFeedCreateWithoutParamsInput"],
	appsFlyerRawInstalls?:ValueTypes["AppsFlyerRawInstallsFeedCreateWithoutParamsInput"],
	appsFlyerScraper?:ValueTypes["AppsFlyerScraperFeedCreateWithoutParamsInput"],
	bqDestinations?:ValueTypes["BigQueryDestinationUpdateManyInput"],
	chartboost?:ValueTypes["ChartboostFeedCreateWithoutParamsInput"],
	dateInput?:ValueTypes["FeedDateInputCreateInput"],
	descriptiveName?:string,
	facebookAds?:ValueTypes["FacebookAdsFeedCreateWithoutParamsInput"],
	fairbidUserReport?:ValueTypes["FairbidUserReportFeedCreateWithoutParamsInput"],
	feedType?:ValueTypes["Feed"],
	fixer?:ValueTypes["FixerFeedCreateWithoutParamsInput"],
	googleAdwords?:ValueTypes["GoogleAdwordsFeedCreateWithoutParamsInput"],
	googlePlay?:ValueTypes["GooglePlayFeedCreateWithoutParamsInput"],
	harvest?:ValueTypes["HarvestFeedCreateWithoutParamsInput"],
	ironSource?:ValueTypes["IronSourceFeedCreateWithoutParamsInput"],
	ironSourcePromotion?:ValueTypes["IronSourcePromotionFeedCreateWithoutParamsInput"],
	libring?:ValueTypes["LibringFeedCreateWithoutParamsInput"],
	s3Destinations?:ValueTypes["S3DestinationUpdateManyInput"],
	snapchat?:ValueTypes["SnapchatFeedCreateWithoutParamsInput"],
	tiktokAdsAudience?:ValueTypes["TiktokAdsAudienceFeedCreateWithoutParamsInput"],
	unityAds?:ValueTypes["UnityAdsFeedCreateWithoutParamsInput"],
	unityAdvertiser?:ValueTypes["UnityAdvertiserFeedCreateWithoutParamsInput"]
};
	["IntegrationFeedParamsWhereInput"]: {
	AND?:ValueTypes["IntegrationFeedParamsWhereInput"][],
	apple?:ValueTypes["AppleFeedWhereInput"],
	appleSearchAds?:ValueTypes["AppleSearchAdsFeedWhereInput"],
	applovin?:ValueTypes["ApplovinFeedWhereInput"],
	appsFlyerCampaign?:ValueTypes["AppsFlyerCampaignFeedWhereInput"],
	appsFlyerDatalocker?:ValueTypes["AppsFlyerDatalockerFeedWhereInput"],
	appsFlyerRawEvents?:ValueTypes["AppsFlyerRawEventsFeedWhereInput"],
	appsFlyerRawInstalls?:ValueTypes["AppsFlyerRawInstallsFeedWhereInput"],
	appsFlyerScraper?:ValueTypes["AppsFlyerScraperFeedWhereInput"],
	bqDestinations_every?:ValueTypes["BigQueryDestinationWhereInput"],
	bqDestinations_none?:ValueTypes["BigQueryDestinationWhereInput"],
	bqDestinations_some?:ValueTypes["BigQueryDestinationWhereInput"],
	chartboost?:ValueTypes["ChartboostFeedWhereInput"],
	dateInput?:ValueTypes["FeedDateInputWhereInput"],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	facebookAds?:ValueTypes["FacebookAdsFeedWhereInput"],
	fairbidUserReport?:ValueTypes["FairbidUserReportFeedWhereInput"],
	feedType?:ValueTypes["Feed"],
	feedType_in?:ValueTypes["Feed"][],
	feedType_not?:ValueTypes["Feed"],
	feedType_not_in?:ValueTypes["Feed"][],
	fixer?:ValueTypes["FixerFeedWhereInput"],
	googleAdwords?:ValueTypes["GoogleAdwordsFeedWhereInput"],
	googlePlay?:ValueTypes["GooglePlayFeedWhereInput"],
	harvest?:ValueTypes["HarvestFeedWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSource?:ValueTypes["IronSourceFeedWhereInput"],
	ironSourcePromotion?:ValueTypes["IronSourcePromotionFeedWhereInput"],
	libring?:ValueTypes["LibringFeedWhereInput"],
	NOT?:ValueTypes["IntegrationFeedParamsWhereInput"][],
	OR?:ValueTypes["IntegrationFeedParamsWhereInput"][],
	s3Destinations_every?:ValueTypes["S3DestinationWhereInput"],
	s3Destinations_none?:ValueTypes["S3DestinationWhereInput"],
	s3Destinations_some?:ValueTypes["S3DestinationWhereInput"],
	snapchat?:ValueTypes["SnapchatFeedWhereInput"],
	tiktokAdsAudience?:ValueTypes["TiktokAdsAudienceFeedWhereInput"],
	unityAds?:ValueTypes["UnityAdsFeedWhereInput"],
	unityAdvertiser?:ValueTypes["UnityAdvertiserFeedWhereInput"]
};
	["IronSourceAccess"]: AliasType<{
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	refreshToken?:true,
	secretKey?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["IronSourceAccessCreateInput"]: {
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	refreshToken:string,
	secretKey:string
};
	["IronSourceAccessOrderByInput"]:IronSourceAccessOrderByInput;
	["IronSourceAccessUpdateInput"]: {
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	refreshToken?:string,
	secretKey?:string
};
	["IronSourceAccessWhereInput"]: {
	AND?:ValueTypes["IronSourceAccessWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["IronSourceAccessWhereInput"][],
	OR?:ValueTypes["IronSourceAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string,
	secretKey?:string,
	secretKey_contains?:string,
	secretKey_ends_with?:string,
	secretKey_gt?:string,
	secretKey_gte?:string,
	secretKey_in?:string[],
	secretKey_lt?:string,
	secretKey_lte?:string,
	secretKey_not?:string,
	secretKey_not_contains?:string,
	secretKey_not_ends_with?:string,
	secretKey_not_in?:string[],
	secretKey_not_starts_with?:string,
	secretKey_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["IronSourceAccessWhereUniqueInput"]: {
	id:string
};
	["IronSourceFeed"]: AliasType<{
	adRevenue?:true,
	appKey?:true,
	id?:true,
	ironSourceAccess?:ValueTypes["IronSourceAccess"],
	params?:ValueTypes["IntegrationFeedParams"],
	reportBreakdowns?:true,
	reportMetrics?:true,
	reports?:true,
		__typename?: true
}>;
	["IronSourceFeedCreateWithoutParamsInput"]: {
	adRevenue?:boolean,
	appKey:string,
	id?:string,
	ironSourceAccess:ValueTypes["IronSourceAccessWhereUniqueInput"],
	reportBreakdowns:string[],
	reportMetrics:string[],
	reports?:boolean
};
	["IronSourceFeedWhereInput"]: {
	adRevenue?:boolean,
	adRevenue_not?:boolean,
	AND?:ValueTypes["IronSourceFeedWhereInput"][],
	appKey?:string,
	appKey_contains?:string,
	appKey_ends_with?:string,
	appKey_gt?:string,
	appKey_gte?:string,
	appKey_in?:string[],
	appKey_lt?:string,
	appKey_lte?:string,
	appKey_not?:string,
	appKey_not_contains?:string,
	appKey_not_ends_with?:string,
	appKey_not_in?:string[],
	appKey_not_starts_with?:string,
	appKey_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSourceAccess?:ValueTypes["IronSourceAccessWhereInput"],
	NOT?:ValueTypes["IronSourceFeedWhereInput"][],
	OR?:ValueTypes["IronSourceFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reports?:boolean,
	reports_not?:boolean
};
	["IronSourcePromotionDeviceType"]:IronSourcePromotionDeviceType;
	["IronSourcePromotionFeed"]: AliasType<{
	adUnit?:true,
	breakdowns?:true,
	bundleIds?:true,
	campaignIds?:true,
	countries?:true,
	creativeIds?:true,
	deviceType?:true,
	id?:true,
	ironSourceAccess?:ValueTypes["IronSourceAccess"],
	metrics?:true,
	os?:true,
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["IronSourcePromotionFeedCreateWithoutParamsInput"]: {
	adUnit?:string,
	breakdowns:string[],
	bundleIds:string[],
	campaignIds:number[],
	countries:string[],
	creativeIds:number[],
	deviceType?:ValueTypes["IronSourcePromotionDeviceType"],
	id?:string,
	ironSourceAccess:ValueTypes["IronSourceAccessWhereUniqueInput"],
	metrics:string[],
	os?:ValueTypes["IronSourcePromotionOsType"]
};
	["IronSourcePromotionFeedWhereInput"]: {
	adUnit?:string,
	adUnit_contains?:string,
	adUnit_ends_with?:string,
	adUnit_gt?:string,
	adUnit_gte?:string,
	adUnit_in?:string[],
	adUnit_lt?:string,
	adUnit_lte?:string,
	adUnit_not?:string,
	adUnit_not_contains?:string,
	adUnit_not_ends_with?:string,
	adUnit_not_in?:string[],
	adUnit_not_starts_with?:string,
	adUnit_starts_with?:string,
	AND?:ValueTypes["IronSourcePromotionFeedWhereInput"][],
	deviceType?:ValueTypes["IronSourcePromotionDeviceType"],
	deviceType_in?:ValueTypes["IronSourcePromotionDeviceType"][],
	deviceType_not?:ValueTypes["IronSourcePromotionDeviceType"],
	deviceType_not_in?:ValueTypes["IronSourcePromotionDeviceType"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSourceAccess?:ValueTypes["IronSourceAccessWhereInput"],
	NOT?:ValueTypes["IronSourcePromotionFeedWhereInput"][],
	OR?:ValueTypes["IronSourcePromotionFeedWhereInput"][],
	os?:ValueTypes["IronSourcePromotionOsType"],
	os_in?:ValueTypes["IronSourcePromotionOsType"][],
	os_not?:ValueTypes["IronSourcePromotionOsType"],
	os_not_in?:ValueTypes["IronSourcePromotionOsType"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["IronSourcePromotionOsType"]:IronSourcePromotionOsType;
	["LibringAccess"]: AliasType<{
	apiToken?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["LibringAccessCreateInput"]: {
	apiToken:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["LibringAccessOrderByInput"]:LibringAccessOrderByInput;
	["LibringAccessUpdateInput"]: {
	apiToken?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["LibringAccessWhereInput"]: {
	AND?:ValueTypes["LibringAccessWhereInput"][],
	apiToken?:string,
	apiToken_contains?:string,
	apiToken_ends_with?:string,
	apiToken_gt?:string,
	apiToken_gte?:string,
	apiToken_in?:string[],
	apiToken_lt?:string,
	apiToken_lte?:string,
	apiToken_not?:string,
	apiToken_not_contains?:string,
	apiToken_not_ends_with?:string,
	apiToken_not_in?:string[],
	apiToken_not_starts_with?:string,
	apiToken_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["LibringAccessWhereInput"][],
	OR?:ValueTypes["LibringAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["LibringAccessWhereUniqueInput"]: {
	id:string
};
	["LibringFeed"]: AliasType<{
	appName?:true,
	dataType?:true,
	id?:true,
	libringAccess?:ValueTypes["LibringAccess"],
	params?:ValueTypes["IntegrationFeedParams"],
		__typename?: true
}>;
	["LibringFeedCreateWithoutParamsInput"]: {
	appName:string,
	dataType:string,
	id?:string,
	libringAccess:ValueTypes["LibringAccessWhereUniqueInput"]
};
	["LibringFeedWhereInput"]: {
	AND?:ValueTypes["LibringFeedWhereInput"][],
	appName?:string,
	appName_contains?:string,
	appName_ends_with?:string,
	appName_gt?:string,
	appName_gte?:string,
	appName_in?:string[],
	appName_lt?:string,
	appName_lte?:string,
	appName_not?:string,
	appName_not_contains?:string,
	appName_not_ends_with?:string,
	appName_not_in?:string[],
	appName_not_starts_with?:string,
	appName_starts_with?:string,
	dataType?:string,
	dataType_contains?:string,
	dataType_ends_with?:string,
	dataType_gt?:string,
	dataType_gte?:string,
	dataType_in?:string[],
	dataType_lt?:string,
	dataType_lte?:string,
	dataType_not?:string,
	dataType_not_contains?:string,
	dataType_not_ends_with?:string,
	dataType_not_in?:string[],
	dataType_not_starts_with?:string,
	dataType_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	libringAccess?:ValueTypes["LibringAccessWhereInput"],
	NOT?:ValueTypes["LibringFeedWhereInput"][],
	OR?:ValueTypes["LibringFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"]
};
	["Mutation"]: AliasType<{
abortEtl?: [{	stateMachineExecutionArn:string},ValueTypes["Action"]],
archiveView?: [{	id:string},ValueTypes["View"]],
copyView?: [{	id:string},ValueTypes["View"]],
createAppleAccess?: [{	data:ValueTypes["AppleAccessCreateInput"]},ValueTypes["AppleAccess"]],
createAppleSearchAdsAccess?: [{	data:ValueTypes["AppleSearchAdsAccessCreateInput"]},ValueTypes["AppleSearchAdsAccess"]],
createApplovinAccess?: [{	data:ValueTypes["ApplovinAccessCreateInput"]},ValueTypes["ApplovinAccess"]],
createAppsFlyerAccess?: [{	data:ValueTypes["AppsFlyerAccessCreateInput"]},ValueTypes["AppsFlyerAccess"]],
createAwsProject?: [{	data:ValueTypes["AwsProjectCreateInput"]},ValueTypes["AwsProject"]],
createBigQueryProject?: [{	data:ValueTypes["BigQueryProjectCreateInput"]},ValueTypes["BigQueryProject"]],
createChartboostAccess?: [{	data:ValueTypes["ChartboostAccessCreateInput"]},ValueTypes["ChartboostAccess"]],
createFacebookAdsAccess?: [{	data:ValueTypes["FacebookAdsAccessCreateInput"]},ValueTypes["FacebookAdsAccess"]],
createFairbidAccess?: [{	data:ValueTypes["FairbidAccessCreateInput"]},ValueTypes["FairbidAccess"]],
createGoogleAdsAccess?: [{	data:ValueTypes["GoogleAdsAccessCreateInput"]},ValueTypes["GoogleAdsAccess"]],
createGooglePlayAccess?: [{	data:ValueTypes["GooglePlayAccessCreateInput"]},ValueTypes["GooglePlayAccess"]],
createHarvestAccess?: [{	data:ValueTypes["HarvestAccessCreateInput"]},ValueTypes["HarvestAccess"]],
createIntegrationFeedConfig?: [{	data:ValueTypes["IntegrationFeedConfigCreateInput"]},ValueTypes["IntegrationFeedConfig"]],
createIronSourceAccess?: [{	data:ValueTypes["IronSourceAccessCreateInput"]},ValueTypes["IronSourceAccess"]],
createLibringAccess?: [{	data:ValueTypes["LibringAccessCreateInput"]},ValueTypes["LibringAccess"]],
createOrganization?: [{	name:string},ValueTypes["Organization"]],
createSnapchatAccess?: [{	data:ValueTypes["SnapchatAccessCreateInput"]},ValueTypes["SnapchatAccess"]],
createTiktokAccess?: [{	data:ValueTypes["TiktokAccessCreateInput"]},ValueTypes["TiktokAccess"]],
createUnityAccess?: [{	data:ValueTypes["UnityAccessCreateInput"]},ValueTypes["UnityAccess"]],
createView?: [{	name:string,	urlQueryParams:string},ValueTypes["View"]],
deleteAppleAccess?: [{	where:ValueTypes["AppleAccessWhereUniqueInput"]},ValueTypes["AppleAccess"]],
deleteAppleSearchAdsAccess?: [{	where:ValueTypes["AppleSearchAdsAccessWhereUniqueInput"]},ValueTypes["AppleSearchAdsAccess"]],
deleteApplovinAccess?: [{	where:ValueTypes["ApplovinAccessWhereUniqueInput"]},ValueTypes["ApplovinAccess"]],
deleteAppsFlyerAccess?: [{	where:ValueTypes["AppsFlyerAccessWhereUniqueInput"]},ValueTypes["AppsFlyerAccess"]],
deleteAwsProject?: [{	where:ValueTypes["AwsProjectWhereUniqueInput"]},ValueTypes["AwsProject"]],
deleteBigQueryProject?: [{	where:ValueTypes["BigQueryProjectWhereUniqueInput"]},ValueTypes["BigQueryProject"]],
deleteChartboostAccess?: [{	where:ValueTypes["ChartboostAccessWhereUniqueInput"]},ValueTypes["ChartboostAccess"]],
deleteFacebookAdsAccess?: [{	where:ValueTypes["FacebookAdsAccessWhereUniqueInput"]},ValueTypes["FacebookAdsAccess"]],
deleteFairbidAccess?: [{	where:ValueTypes["FairbidAccessWhereUniqueInput"]},ValueTypes["FairbidAccess"]],
deleteGoogleAdsAccess?: [{	where:ValueTypes["GoogleAdsAccessWhereUniqueInput"]},ValueTypes["GoogleAdsAccess"]],
deleteGooglePlayAccess?: [{	where:ValueTypes["GooglePlayAccessWhereUniqueInput"]},ValueTypes["GooglePlayAccess"]],
deleteHarvestAccess?: [{	where:ValueTypes["HarvestAccessWhereUniqueInput"]},ValueTypes["HarvestAccess"]],
deleteIntegrationFeedConfig?: [{	where:ValueTypes["IntegrationFeedConfigWhereUniqueInput"]},ValueTypes["IntegrationFeedConfig"]],
deleteIronSourceAccess?: [{	where:ValueTypes["IronSourceAccessWhereUniqueInput"]},ValueTypes["IronSourceAccess"]],
deleteLibringAccess?: [{	where:ValueTypes["LibringAccessWhereUniqueInput"]},ValueTypes["LibringAccess"]],
deleteOrganization?: [{	organizationId:string},ValueTypes["Organization"]],
deleteSnapchatAccess?: [{	where:ValueTypes["SnapchatAccessWhereUniqueInput"]},ValueTypes["SnapchatAccess"]],
deleteTiktokAccess?: [{	where:ValueTypes["TiktokAccessWhereUniqueInput"]},ValueTypes["TiktokAccess"]],
deleteUnityAccess?: [{	where:ValueTypes["UnityAccessWhereUniqueInput"]},ValueTypes["UnityAccess"]],
deleteView?: [{	id:string},ValueTypes["View"]],
inviteUser?: [{	email:string,	organizationId:string},ValueTypes["OrganizationMembership"]],
publishView?: [{	id:string,	organizationName:string},ValueTypes["View"]],
restoreView?: [{	id:string},ValueTypes["View"]],
runEtl?: [{	data:ValueTypes["IntegrationFeedInstanceCreateInput"]},ValueTypes["Result"]],
setUserRoles?: [{	organizationId:string,	roles:ValueTypes["UserRole"][],	userId:string},ValueTypes["User"]],
updateAppleAccess?: [{	data:ValueTypes["AppleAccessUpdateInput"],	where:ValueTypes["AppleAccessWhereUniqueInput"]},ValueTypes["AppleAccess"]],
updateAppleSearchAdsAccess?: [{	data:ValueTypes["AppleSearchAdsAccessUpdateInput"],	where:ValueTypes["AppleSearchAdsAccessWhereUniqueInput"]},ValueTypes["AppleSearchAdsAccess"]],
updateApplovinAccess?: [{	data:ValueTypes["ApplovinAccessUpdateInput"],	where:ValueTypes["ApplovinAccessWhereUniqueInput"]},ValueTypes["ApplovinAccess"]],
updateAppsFlyerAccess?: [{	data:ValueTypes["AppsFlyerAccessUpdateInput"],	where:ValueTypes["AppsFlyerAccessWhereUniqueInput"]},ValueTypes["AppsFlyerAccess"]],
updateAwsProject?: [{	data:ValueTypes["AwsProjectUpdateInput"],	where:ValueTypes["AwsProjectWhereUniqueInput"]},ValueTypes["AwsProject"]],
updateBigQueryProject?: [{	data:ValueTypes["BigQueryProjectUpdateInput"],	where:ValueTypes["BigQueryProjectWhereUniqueInput"]},ValueTypes["BigQueryProject"]],
updateChartboostAccess?: [{	data:ValueTypes["ChartboostAccessUpdateInput"],	where:ValueTypes["ChartboostAccessWhereUniqueInput"]},ValueTypes["ChartboostAccess"]],
updateFacebookAdsAccess?: [{	data:ValueTypes["FacebookAdsAccessUpdateInput"],	where:ValueTypes["FacebookAdsAccessWhereUniqueInput"]},ValueTypes["FacebookAdsAccess"]],
updateFairbidAccess?: [{	data:ValueTypes["FairbidAccessUpdateInput"],	where:ValueTypes["FairbidAccessWhereUniqueInput"]},ValueTypes["FairbidAccess"]],
updateGoogleAdsAccess?: [{	data:ValueTypes["GoogleAdsAccessUpdateInput"],	where:ValueTypes["GoogleAdsAccessWhereUniqueInput"]},ValueTypes["GoogleAdsAccess"]],
updateGooglePlayAccess?: [{	data:ValueTypes["GooglePlayAccessUpdateInput"],	where:ValueTypes["GooglePlayAccessWhereUniqueInput"]},ValueTypes["GooglePlayAccess"]],
updateHarvestAccess?: [{	data:ValueTypes["HarvestAccessUpdateInput"],	where:ValueTypes["HarvestAccessWhereUniqueInput"]},ValueTypes["HarvestAccess"]],
updateIntegrationFeedConfig?: [{	data:ValueTypes["IntegrationFeedConfigUpdateInput"],	where:ValueTypes["IntegrationFeedConfigWhereUniqueInput"]},ValueTypes["IntegrationFeedConfig"]],
updateIronSourceAccess?: [{	data:ValueTypes["IronSourceAccessUpdateInput"],	where:ValueTypes["IronSourceAccessWhereUniqueInput"]},ValueTypes["IronSourceAccess"]],
updateLibringAccess?: [{	data:ValueTypes["LibringAccessUpdateInput"],	where:ValueTypes["LibringAccessWhereUniqueInput"]},ValueTypes["LibringAccess"]],
updateSnapchatAccess?: [{	data:ValueTypes["SnapchatAccessUpdateInput"],	where:ValueTypes["SnapchatAccessWhereUniqueInput"]},ValueTypes["SnapchatAccess"]],
updateTiktokAccess?: [{	data:ValueTypes["TiktokAccessUpdateInput"],	where:ValueTypes["TiktokAccessWhereUniqueInput"]},ValueTypes["TiktokAccess"]],
updateUnityAccess?: [{	data:ValueTypes["UnityAccessUpdateInput"],	where:ValueTypes["UnityAccessWhereUniqueInput"]},ValueTypes["UnityAccess"]],
updateView?: [{	id:string,	name:string,	urlQueryParams:string},ValueTypes["View"]],
		__typename?: true
}>;
	["Organization"]: AliasType<{
awsProjects?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["AwsProjectOrderByInput"],	skip?:number,	where?:ValueTypes["AwsProjectWhereInput"]},ValueTypes["AwsProject"]],
bqProjects?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryProjectOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryProjectWhereInput"]},ValueTypes["BigQueryProject"]],
	createdAt?:true,
	id?:true,
	name?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
organizationMemberships?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationMembershipOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationMembershipWhereInput"]},ValueTypes["OrganizationMembership"]],
	updatedAt?:true,
views?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ViewOrderByInput"],	skip?:number,	where?:ValueTypes["ViewWhereInput"]},ValueTypes["View"]],
		__typename?: true
}>;
	["OrganizationAttribute"]: AliasType<{
	createdAt?:true,
	id?:true,
	organization?:ValueTypes["Organization"],
	role?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["OrganizationAttributeCreateInput"]: {
	organization:ValueTypes["OrganizationWhereUniqueInput"],
	role:ValueTypes["OrganizationRole"]
};
	["OrganizationAttributeOrderByInput"]:OrganizationAttributeOrderByInput;
	["OrganizationAttributeUpdateInput"]: {
	id:string,
	organization:ValueTypes["OrganizationWhereUniqueInput"],
	role:ValueTypes["OrganizationRole"]
};
	["OrganizationAttributeUpdateManyInput"]: {
	create?:ValueTypes["OrganizationAttributeCreateInput"][],
	delete?:ValueTypes["OrganizationAttributeWhereUniqueInput"][],
	update?:ValueTypes["OrganizationAttributeUpdateInput"][]
};
	["OrganizationAttributeWhereInput"]: {
	AND?:ValueTypes["OrganizationAttributeWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["OrganizationAttributeWhereInput"][],
	OR?:ValueTypes["OrganizationAttributeWhereInput"][],
	organization?:ValueTypes["OrganizationWhereInput"],
	role?:ValueTypes["OrganizationRole"],
	role_in?:ValueTypes["OrganizationRole"][],
	role_not?:ValueTypes["OrganizationRole"],
	role_not_in?:ValueTypes["OrganizationRole"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["OrganizationAttributeWhereUniqueInput"]: {
	id:string
};
	["OrganizationMembership"]: AliasType<{
	createdAt?:true,
	id?:true,
	organization?:ValueTypes["Organization"],
	role?:true,
	updatedAt?:true,
	user?:ValueTypes["User"],
		__typename?: true
}>;
	["OrganizationMembershipOrderByInput"]:OrganizationMembershipOrderByInput;
	["OrganizationMembershipWhereInput"]: {
	AND?:ValueTypes["OrganizationMembershipWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["OrganizationMembershipWhereInput"][],
	OR?:ValueTypes["OrganizationMembershipWhereInput"][],
	organization?:ValueTypes["OrganizationWhereInput"],
	role?:ValueTypes["UserRole"],
	role_in?:ValueTypes["UserRole"][],
	role_not?:ValueTypes["UserRole"],
	role_not_in?:ValueTypes["UserRole"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][],
	user?:ValueTypes["UserWhereInput"]
};
	["OrganizationOrderByInput"]:OrganizationOrderByInput;
	["OrganizationRole"]:OrganizationRole;
	["OrganizationWhereInput"]: {
	AND?:ValueTypes["OrganizationWhereInput"][],
	awsProjects_every?:ValueTypes["AwsProjectWhereInput"],
	awsProjects_none?:ValueTypes["AwsProjectWhereInput"],
	awsProjects_some?:ValueTypes["AwsProjectWhereInput"],
	bqProjects_every?:ValueTypes["BigQueryProjectWhereInput"],
	bqProjects_none?:ValueTypes["BigQueryProjectWhereInput"],
	bqProjects_some?:ValueTypes["BigQueryProjectWhereInput"],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:ValueTypes["OrganizationWhereInput"][],
	OR?:ValueTypes["OrganizationWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationMemberships_every?:ValueTypes["OrganizationMembershipWhereInput"],
	organizationMemberships_none?:ValueTypes["OrganizationMembershipWhereInput"],
	organizationMemberships_some?:ValueTypes["OrganizationMembershipWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][],
	views_every?:ValueTypes["ViewWhereInput"],
	views_none?:ValueTypes["ViewWhereInput"],
	views_some?:ValueTypes["ViewWhereInput"]
};
	["OrganizationWhereUniqueInput"]: {
	id?:string,
	name?:string
};
	["PageInfo"]: AliasType<{
	endCursor?:true,
	hasNextPage?:true,
	hasPreviousPage?:true,
	startCursor?:true,
		__typename?: true
}>;
	["Platform"]:Platform;
	["Query"]: AliasType<{
appleAccess?: [{	where:ValueTypes["AppleAccessWhereUniqueInput"]},ValueTypes["AppleAccess"]],
appleAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["AppleAccessOrderByInput"],	skip?:number,	where?:ValueTypes["AppleAccessWhereInput"]},ValueTypes["AppleAccess"]],
appleSearchAdsAccess?: [{	where:ValueTypes["AppleSearchAdsAccessWhereUniqueInput"]},ValueTypes["AppleSearchAdsAccess"]],
appleSearchAdsAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["AppleSearchAdsAccessOrderByInput"],	skip?:number,	where?:ValueTypes["AppleSearchAdsAccessWhereInput"]},ValueTypes["AppleSearchAdsAccess"]],
applovinAccess?: [{	where:ValueTypes["ApplovinAccessWhereUniqueInput"]},ValueTypes["ApplovinAccess"]],
applovinAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ApplovinAccessOrderByInput"],	skip?:number,	where?:ValueTypes["ApplovinAccessWhereInput"]},ValueTypes["ApplovinAccess"]],
appsFlyerAccess?: [{	where:ValueTypes["AppsFlyerAccessWhereUniqueInput"]},ValueTypes["AppsFlyerAccess"]],
appsFlyerAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["AppsFlyerAccessOrderByInput"],	skip?:number,	where?:ValueTypes["AppsFlyerAccessWhereInput"]},ValueTypes["AppsFlyerAccess"]],
awsProject?: [{	where:ValueTypes["AwsProjectWhereUniqueInput"]},ValueTypes["AwsProject"]],
awsProjects?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["AwsProjectOrderByInput"],	skip?:number,	where?:ValueTypes["AwsProjectWhereInput"]},ValueTypes["AwsProject"]],
bigQueryCheck?: [{	where:ValueTypes["BigQueryCheckWhereUniqueInput"]},ValueTypes["BigQueryCheck"]],
bigQueryChecks?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryCheckOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryCheckWhereInput"]},ValueTypes["BigQueryCheck"]],
bigQueryChecksConnection?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryCheckOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryCheckWhereInput"]},ValueTypes["BigQueryCheckConnection"]],
bigQueryDestination?: [{	where:ValueTypes["BigQueryDestinationWhereUniqueInput"]},ValueTypes["BigQueryDestination"]],
bigQueryDestinations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryDestinationOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryDestinationWhereInput"]},ValueTypes["BigQueryDestination"]],
bigQueryProject?: [{	where:ValueTypes["BigQueryProjectWhereUniqueInput"]},ValueTypes["BigQueryProject"]],
bigQueryProjects?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["BigQueryProjectOrderByInput"],	skip?:number,	where?:ValueTypes["BigQueryProjectWhereInput"]},ValueTypes["BigQueryProject"]],
campaignConsistencyCheck?: [{	where:ValueTypes["CampaignConsistencyCheckWhereUniqueInput"]},ValueTypes["CampaignConsistencyCheck"]],
campaignConsistencyChecks?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["CampaignConsistencyCheckOrderByInput"],	skip?:number,	where?:ValueTypes["CampaignConsistencyCheckWhereInput"]},ValueTypes["CampaignConsistencyCheck"]],
campaignConsistencyChecksConnection?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["CampaignConsistencyCheckOrderByInput"],	skip?:number,	where?:ValueTypes["CampaignConsistencyCheckWhereInput"]},ValueTypes["CampaignConsistencyCheckConnection"]],
chartboostAccess?: [{	where:ValueTypes["ChartboostAccessWhereUniqueInput"]},ValueTypes["ChartboostAccess"]],
chartboostAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ChartboostAccessOrderByInput"],	skip?:number,	where?:ValueTypes["ChartboostAccessWhereInput"]},ValueTypes["ChartboostAccess"]],
dailyConsistencyCheck?: [{	where:ValueTypes["DailyConsistencyCheckWhereUniqueInput"]},ValueTypes["DailyConsistencyCheck"]],
dailyConsistencyChecks?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["DailyConsistencyCheckOrderByInput"],	skip?:number,	where?:ValueTypes["DailyConsistencyCheckWhereInput"]},ValueTypes["DailyConsistencyCheck"]],
dailyConsistencyChecksConnection?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["DailyConsistencyCheckOrderByInput"],	skip?:number,	where?:ValueTypes["DailyConsistencyCheckWhereInput"]},ValueTypes["DailyConsistencyCheckConnection"]],
facebookAdsAccess?: [{	where:ValueTypes["FacebookAdsAccessWhereUniqueInput"]},ValueTypes["FacebookAdsAccess"]],
facebookAdsAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["FacebookAdsAccessOrderByInput"],	skip?:number,	where?:ValueTypes["FacebookAdsAccessWhereInput"]},ValueTypes["FacebookAdsAccess"]],
fairbidAccess?: [{	where:ValueTypes["FairbidAccessWhereUniqueInput"]},ValueTypes["FairbidAccess"]],
fairbidAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["FairbidAccessOrderByInput"],	skip?:number,	where?:ValueTypes["FairbidAccessWhereInput"]},ValueTypes["FairbidAccess"]],
googleAdsAccess?: [{	where:ValueTypes["GoogleAdsAccessWhereUniqueInput"]},ValueTypes["GoogleAdsAccess"]],
googleAdsAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["GoogleAdsAccessOrderByInput"],	skip?:number,	where?:ValueTypes["GoogleAdsAccessWhereInput"]},ValueTypes["GoogleAdsAccess"]],
googlePlayAccess?: [{	where:ValueTypes["GooglePlayAccessWhereUniqueInput"]},ValueTypes["GooglePlayAccess"]],
googlePlayAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["GooglePlayAccessOrderByInput"],	skip?:number,	where?:ValueTypes["GooglePlayAccessWhereInput"]},ValueTypes["GooglePlayAccess"]],
harvestAccess?: [{	where:ValueTypes["HarvestAccessWhereUniqueInput"]},ValueTypes["HarvestAccess"]],
harvestAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["HarvestAccessOrderByInput"],	skip?:number,	where?:ValueTypes["HarvestAccessWhereInput"]},ValueTypes["HarvestAccess"]],
integrationFeedConfig?: [{	where:ValueTypes["IntegrationFeedConfigWhereUniqueInput"]},ValueTypes["IntegrationFeedConfig"]],
integrationFeedConfigs?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["IntegrationFeedConfigOrderByInput"],	skip?:number,	where?:ValueTypes["IntegrationFeedConfigWhereInput"]},ValueTypes["IntegrationFeedConfig"]],
integrationFeedConfigsConnection?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["IntegrationFeedConfigOrderByInput"],	skip?:number,	where?:ValueTypes["IntegrationFeedConfigWhereInput"]},ValueTypes["IntegrationFeedConfigConnection"]],
integrationFeedInstance?: [{	where:ValueTypes["IntegrationFeedInstanceWhereUniqueInput"]},ValueTypes["IntegrationFeedInstance"]],
integrationFeedInstances?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["IntegrationFeedInstanceOrderByInput"],	skip?:number,	where?:ValueTypes["IntegrationFeedInstanceWhereInput"]},ValueTypes["IntegrationFeedInstance"]],
integrationFeedInstancesConnection?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["IntegrationFeedInstanceOrderByInput"],	skip?:number,	where?:ValueTypes["IntegrationFeedInstanceWhereInput"]},ValueTypes["IntegrationFeedInstanceConnection"]],
ironSourceAccess?: [{	where:ValueTypes["IronSourceAccessWhereUniqueInput"]},ValueTypes["IronSourceAccess"]],
ironSourceAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["IronSourceAccessOrderByInput"],	skip?:number,	where?:ValueTypes["IronSourceAccessWhereInput"]},ValueTypes["IronSourceAccess"]],
libringAccess?: [{	where:ValueTypes["LibringAccessWhereUniqueInput"]},ValueTypes["LibringAccess"]],
libringAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["LibringAccessOrderByInput"],	skip?:number,	where?:ValueTypes["LibringAccessWhereInput"]},ValueTypes["LibringAccess"]],
	me?:ValueTypes["User"],
organization?: [{	where:ValueTypes["OrganizationWhereUniqueInput"]},ValueTypes["Organization"]],
organizations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationWhereInput"]},ValueTypes["Organization"]],
s3Destination?: [{	where:ValueTypes["S3DestinationWhereUniqueInput"]},ValueTypes["S3Destination"]],
s3Destinations?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["S3DestinationOrderByInput"],	skip?:number,	where?:ValueTypes["S3DestinationWhereInput"]},ValueTypes["S3Destination"]],
snapchatAccess?: [{	where:ValueTypes["SnapchatAccessWhereUniqueInput"]},ValueTypes["SnapchatAccess"]],
snapchatAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["SnapchatAccessOrderByInput"],	skip?:number,	where?:ValueTypes["SnapchatAccessWhereInput"]},ValueTypes["SnapchatAccess"]],
tiktokAccess?: [{	where:ValueTypes["TiktokAccessWhereUniqueInput"]},ValueTypes["TiktokAccess"]],
tiktokAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["TiktokAccessOrderByInput"],	skip?:number,	where?:ValueTypes["TiktokAccessWhereInput"]},ValueTypes["TiktokAccess"]],
unityAccess?: [{	where:ValueTypes["UnityAccessWhereUniqueInput"]},ValueTypes["UnityAccess"]],
unityAccesses?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["UnityAccessOrderByInput"],	skip?:number,	where?:ValueTypes["UnityAccessWhereInput"]},ValueTypes["UnityAccess"]],
users?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["UserOrderByInput"],	skip?:number,	where?:ValueTypes["UserWhereInput"]},ValueTypes["User"]],
view?: [{	where:ValueTypes["ViewWhereUniqueInput"]},ValueTypes["View"]],
views?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ViewOrderByInput"],	skip?:number,	where?:ValueTypes["ViewWhereInput"]},ValueTypes["View"]],
		__typename?: true
}>;
	["ReportType"]:ReportType;
	["Result"]: AliasType<{
	actions?:ValueTypes["Action"],
		__typename?: true
}>;
	["S3Destination"]: AliasType<{
	awsProject?:ValueTypes["AwsProject"],
	bucketName?:true,
	createdAt?:true,
	descriptiveName?:true,
	enabled?:true,
	id?:true,
	objectSuffix?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	pathPrefix?:true,
	region?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["S3DestinationCreateInput"]: {
	awsProject:ValueTypes["AwsProjectWhereUniqueInput"],
	bucketName:string,
	descriptiveName:string,
	enabled:boolean,
	id?:string,
	objectSuffix?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	pathPrefix?:string,
	region:string
};
	["S3DestinationOrderByInput"]:S3DestinationOrderByInput;
	["S3DestinationUpdateInput"]: {
	awsProject?:ValueTypes["AwsProjectWhereUniqueInput"],
	bucketName?:string,
	descriptiveName?:string,
	enabled?:boolean,
	id:string,
	objectSuffix?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	pathPrefix?:string,
	region?:string
};
	["S3DestinationUpdateManyInput"]: {
	create?:ValueTypes["S3DestinationCreateInput"][],
	delete?:ValueTypes["S3DestinationWhereUniqueInput"][],
	update?:ValueTypes["S3DestinationUpdateInput"][]
};
	["S3DestinationWhereInput"]: {
	AND?:ValueTypes["S3DestinationWhereInput"][],
	awsProject?:ValueTypes["AwsProjectWhereInput"],
	bucketName?:string,
	bucketName_contains?:string,
	bucketName_ends_with?:string,
	bucketName_gt?:string,
	bucketName_gte?:string,
	bucketName_in?:string[],
	bucketName_lt?:string,
	bucketName_lte?:string,
	bucketName_not?:string,
	bucketName_not_contains?:string,
	bucketName_not_ends_with?:string,
	bucketName_not_in?:string[],
	bucketName_not_starts_with?:string,
	bucketName_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["S3DestinationWhereInput"][],
	objectSuffix?:string,
	objectSuffix_contains?:string,
	objectSuffix_ends_with?:string,
	objectSuffix_gt?:string,
	objectSuffix_gte?:string,
	objectSuffix_in?:string[],
	objectSuffix_lt?:string,
	objectSuffix_lte?:string,
	objectSuffix_not?:string,
	objectSuffix_not_contains?:string,
	objectSuffix_not_ends_with?:string,
	objectSuffix_not_in?:string[],
	objectSuffix_not_starts_with?:string,
	objectSuffix_starts_with?:string,
	OR?:ValueTypes["S3DestinationWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	pathPrefix?:string,
	pathPrefix_contains?:string,
	pathPrefix_ends_with?:string,
	pathPrefix_gt?:string,
	pathPrefix_gte?:string,
	pathPrefix_in?:string[],
	pathPrefix_lt?:string,
	pathPrefix_lte?:string,
	pathPrefix_not?:string,
	pathPrefix_not_contains?:string,
	pathPrefix_not_ends_with?:string,
	pathPrefix_not_in?:string[],
	pathPrefix_not_starts_with?:string,
	pathPrefix_starts_with?:string,
	region?:string,
	region_contains?:string,
	region_ends_with?:string,
	region_gt?:string,
	region_gte?:string,
	region_in?:string[],
	region_lt?:string,
	region_lte?:string,
	region_not?:string,
	region_not_contains?:string,
	region_not_ends_with?:string,
	region_not_in?:string[],
	region_not_starts_with?:string,
	region_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["S3DestinationWhereUniqueInput"]: {
	id:string
};
	["ScraperGame"]: AliasType<{
	createdAt?:true,
	gameId?:true,
	id?:true,
	name?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["ScraperGameCreateInput"]: {
	gameId:string,
	id?:string,
	name:string
};
	["ScraperGameOrderByInput"]:ScraperGameOrderByInput;
	["ScraperGameWhereInput"]: {
	AND?:ValueTypes["ScraperGameWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:ValueTypes["ScraperGameWhereInput"][],
	OR?:ValueTypes["ScraperGameWhereInput"][],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["SnapchatAccess"]: AliasType<{
	adAccountID?:true,
	clientID?:true,
	clientSecret?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	refreshToken?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["SnapchatAccessCreateInput"]: {
	adAccountID:string,
	clientID:string,
	clientSecret:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	refreshToken:string
};
	["SnapchatAccessOrderByInput"]:SnapchatAccessOrderByInput;
	["SnapchatAccessUpdateInput"]: {
	adAccountID?:string,
	clientID?:string,
	clientSecret?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	refreshToken?:string
};
	["SnapchatAccessWhereInput"]: {
	adAccountID?:string,
	adAccountID_contains?:string,
	adAccountID_ends_with?:string,
	adAccountID_gt?:string,
	adAccountID_gte?:string,
	adAccountID_in?:string[],
	adAccountID_lt?:string,
	adAccountID_lte?:string,
	adAccountID_not?:string,
	adAccountID_not_contains?:string,
	adAccountID_not_ends_with?:string,
	adAccountID_not_in?:string[],
	adAccountID_not_starts_with?:string,
	adAccountID_starts_with?:string,
	AND?:ValueTypes["SnapchatAccessWhereInput"][],
	clientID?:string,
	clientID_contains?:string,
	clientID_ends_with?:string,
	clientID_gt?:string,
	clientID_gte?:string,
	clientID_in?:string[],
	clientID_lt?:string,
	clientID_lte?:string,
	clientID_not?:string,
	clientID_not_contains?:string,
	clientID_not_ends_with?:string,
	clientID_not_in?:string[],
	clientID_not_starts_with?:string,
	clientID_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["SnapchatAccessWhereInput"][],
	OR?:ValueTypes["SnapchatAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["SnapchatAccessWhereUniqueInput"]: {
	id:string
};
	["SnapchatFeed"]: AliasType<{
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	reportDimension?:true,
	snapchatAccess?:ValueTypes["SnapchatAccess"],
	timezoneOffset?:true,
		__typename?: true
}>;
	["SnapchatFeedCreateWithoutParamsInput"]: {
	id?:string,
	reportDimension?:string,
	snapchatAccess:ValueTypes["SnapchatAccessWhereUniqueInput"],
	timezoneOffset?:number
};
	["SnapchatFeedWhereInput"]: {
	AND?:ValueTypes["SnapchatFeedWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["SnapchatFeedWhereInput"][],
	OR?:ValueTypes["SnapchatFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	reportDimension?:string,
	reportDimension_contains?:string,
	reportDimension_ends_with?:string,
	reportDimension_gt?:string,
	reportDimension_gte?:string,
	reportDimension_in?:string[],
	reportDimension_lt?:string,
	reportDimension_lte?:string,
	reportDimension_not?:string,
	reportDimension_not_contains?:string,
	reportDimension_not_ends_with?:string,
	reportDimension_not_in?:string[],
	reportDimension_not_starts_with?:string,
	reportDimension_starts_with?:string,
	snapchatAccess?:ValueTypes["SnapchatAccessWhereInput"],
	timezoneOffset?:number,
	timezoneOffset_gt?:number,
	timezoneOffset_gte?:number,
	timezoneOffset_in?:number[],
	timezoneOffset_lt?:number,
	timezoneOffset_lte?:number,
	timezoneOffset_not?:number,
	timezoneOffset_not_in?:number[]
};
	["TiktokAccess"]: AliasType<{
	accessToken?:true,
	advertiserId?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	updatedAt?:true,
		__typename?: true
}>;
	["TiktokAccessCreateInput"]: {
	accessToken:string,
	advertiserId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][]
};
	["TiktokAccessOrderByInput"]:TiktokAccessOrderByInput;
	["TiktokAccessUpdateInput"]: {
	accessToken?:string,
	advertiserId?:string,
	descriptiveName?:string,
	id:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"]
};
	["TiktokAccessWhereInput"]: {
	accessToken?:string,
	accessToken_contains?:string,
	accessToken_ends_with?:string,
	accessToken_gt?:string,
	accessToken_gte?:string,
	accessToken_in?:string[],
	accessToken_lt?:string,
	accessToken_lte?:string,
	accessToken_not?:string,
	accessToken_not_contains?:string,
	accessToken_not_ends_with?:string,
	accessToken_not_in?:string[],
	accessToken_not_starts_with?:string,
	accessToken_starts_with?:string,
	advertiserId?:string,
	advertiserId_contains?:string,
	advertiserId_ends_with?:string,
	advertiserId_gt?:string,
	advertiserId_gte?:string,
	advertiserId_in?:string[],
	advertiserId_lt?:string,
	advertiserId_lte?:string,
	advertiserId_not?:string,
	advertiserId_not_contains?:string,
	advertiserId_not_ends_with?:string,
	advertiserId_not_in?:string[],
	advertiserId_not_starts_with?:string,
	advertiserId_starts_with?:string,
	AND?:ValueTypes["TiktokAccessWhereInput"][],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["TiktokAccessWhereInput"][],
	OR?:ValueTypes["TiktokAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["TiktokAccessWhereUniqueInput"]: {
	id:string
};
	["TiktokAdsAudienceFeed"]: AliasType<{
	dimensions?:true,
	filterInactive?:true,
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	tiktokAccess?:ValueTypes["TiktokAccess"],
		__typename?: true
}>;
	["TiktokAdsAudienceFeedCreateWithoutParamsInput"]: {
	dimensions:string[],
	filterInactive?:boolean,
	id?:string,
	tiktokAccess:ValueTypes["TiktokAccessWhereUniqueInput"]
};
	["TiktokAdsAudienceFeedWhereInput"]: {
	AND?:ValueTypes["TiktokAdsAudienceFeedWhereInput"][],
	filterInactive?:boolean,
	filterInactive_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["TiktokAdsAudienceFeedWhereInput"][],
	OR?:ValueTypes["TiktokAdsAudienceFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	tiktokAccess?:ValueTypes["TiktokAccessWhereInput"]
};
	["UnityAccess"]: AliasType<{
	apiKey?:true,
	createdAt?:true,
	descriptiveName?:true,
	id?:true,
organizationAttributes?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationAttributeOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationAttributeWhereInput"]},ValueTypes["OrganizationAttribute"]],
	organizationId?:true,
	updatedAt?:true,
		__typename?: true
}>;
	["UnityAccessCreateInput"]: {
	apiKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:ValueTypes["OrganizationAttributeCreateInput"][],
	organizationId:string
};
	["UnityAccessOrderByInput"]:UnityAccessOrderByInput;
	["UnityAccessUpdateInput"]: {
	apiKey?:string,
	descriptiveName?:string,
	organizationAttributes?:ValueTypes["OrganizationAttributeUpdateManyInput"],
	organizationId?:string
};
	["UnityAccessWhereInput"]: {
	AND?:ValueTypes["UnityAccessWhereInput"][],
	apiKey?:string,
	apiKey_contains?:string,
	apiKey_ends_with?:string,
	apiKey_gt?:string,
	apiKey_gte?:string,
	apiKey_in?:string[],
	apiKey_lt?:string,
	apiKey_lte?:string,
	apiKey_not?:string,
	apiKey_not_contains?:string,
	apiKey_not_ends_with?:string,
	apiKey_not_in?:string[],
	apiKey_not_starts_with?:string,
	apiKey_starts_with?:string,
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["UnityAccessWhereInput"][],
	OR?:ValueTypes["UnityAccessWhereInput"][],
	organizationAttributes_every?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:ValueTypes["OrganizationAttributeWhereInput"],
	organizationId?:string,
	organizationId_contains?:string,
	organizationId_ends_with?:string,
	organizationId_gt?:string,
	organizationId_gte?:string,
	organizationId_in?:string[],
	organizationId_lt?:string,
	organizationId_lte?:string,
	organizationId_not?:string,
	organizationId_not_contains?:string,
	organizationId_not_ends_with?:string,
	organizationId_not_in?:string[],
	organizationId_not_starts_with?:string,
	organizationId_starts_with?:string,
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][]
};
	["UnityAccessWhereUniqueInput"]: {
	id:string
};
	["UnityAdsFeed"]: AliasType<{
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	unityAccess?:ValueTypes["UnityAccess"],
		__typename?: true
}>;
	["UnityAdsFeedCreateWithoutParamsInput"]: {
	id?:string,
	unityAccess:ValueTypes["UnityAccessWhereUniqueInput"]
};
	["UnityAdsFeedWhereInput"]: {
	AND?:ValueTypes["UnityAdsFeedWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["UnityAdsFeedWhereInput"][],
	OR?:ValueTypes["UnityAdsFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	unityAccess?:ValueTypes["UnityAccessWhereInput"]
};
	["UnityAdvertiserFeed"]: AliasType<{
	id?:true,
	params?:ValueTypes["IntegrationFeedParams"],
	targetIds?:true,
	unityAccess?:ValueTypes["UnityAccess"],
		__typename?: true
}>;
	["UnityAdvertiserFeedCreateWithoutParamsInput"]: {
	id?:string,
	targetIds:string[],
	unityAccess:ValueTypes["UnityAccessWhereUniqueInput"]
};
	["UnityAdvertiserFeedWhereInput"]: {
	AND?:ValueTypes["UnityAdvertiserFeedWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["UnityAdvertiserFeedWhereInput"][],
	OR?:ValueTypes["UnityAdvertiserFeedWhereInput"][],
	params?:ValueTypes["IntegrationFeedParamsWhereInput"],
	unityAccess?:ValueTypes["UnityAccessWhereInput"]
};
	["User"]: AliasType<{
	authProvider?:true,
	email?:true,
	id?:true,
	isSuperAdmin?:true,
	name?:true,
organizationMemberships?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["OrganizationMembershipOrderByInput"],	skip?:number,	where?:ValueTypes["OrganizationMembershipWhereInput"]},ValueTypes["OrganizationMembership"]],
	surname?:true,
views?: [{	after?:string,	before?:string,	first?:number,	last?:number,	orderBy?:ValueTypes["ViewOrderByInput"],	skip?:number,	where?:ValueTypes["ViewWhereInput"]},ValueTypes["View"]],
		__typename?: true
}>;
	["UserOrderByInput"]:UserOrderByInput;
	["UserRole"]:UserRole;
	["UserWhereInput"]: {
	AND?:ValueTypes["UserWhereInput"][],
	email?:string,
	email_contains?:string,
	email_ends_with?:string,
	email_gt?:string,
	email_gte?:string,
	email_in?:string[],
	email_lt?:string,
	email_lte?:string,
	email_not?:string,
	email_not_contains?:string,
	email_not_ends_with?:string,
	email_not_in?:string[],
	email_not_starts_with?:string,
	email_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ValueTypes["UserWhereInput"][],
	OR?:ValueTypes["UserWhereInput"][],
	organizationMemberships_every?:ValueTypes["OrganizationMembershipWhereInput"],
	organizationMemberships_none?:ValueTypes["OrganizationMembershipWhereInput"],
	organizationMemberships_some?:ValueTypes["OrganizationMembershipWhereInput"],
	views_every?:ValueTypes["ViewWhereInput"],
	views_none?:ValueTypes["ViewWhereInput"],
	views_some?:ValueTypes["ViewWhereInput"]
};
	["View"]: AliasType<{
	archivedAt?:true,
	author?:ValueTypes["User"],
	createdAt?:true,
	id?:true,
	name?:true,
	organization?:ValueTypes["Organization"],
	updatedAt?:true,
	urlQueryParams?:true,
		__typename?: true
}>;
	["ViewOrderByInput"]:ViewOrderByInput;
	["ViewWhereInput"]: {
	AND?:ValueTypes["ViewWhereInput"][],
	archivedAt?:ValueTypes["DateTime"],
	archivedAt_gt?:ValueTypes["DateTime"],
	archivedAt_gte?:ValueTypes["DateTime"],
	archivedAt_in?:ValueTypes["DateTime"][],
	archivedAt_lt?:ValueTypes["DateTime"],
	archivedAt_lte?:ValueTypes["DateTime"],
	archivedAt_not?:ValueTypes["DateTime"],
	archivedAt_not_in?:ValueTypes["DateTime"][],
	author?:ValueTypes["UserWhereInput"],
	createdAt?:ValueTypes["DateTime"],
	createdAt_gt?:ValueTypes["DateTime"],
	createdAt_gte?:ValueTypes["DateTime"],
	createdAt_in?:ValueTypes["DateTime"][],
	createdAt_lt?:ValueTypes["DateTime"],
	createdAt_lte?:ValueTypes["DateTime"],
	createdAt_not?:ValueTypes["DateTime"],
	createdAt_not_in?:ValueTypes["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:ValueTypes["ViewWhereInput"][],
	OR?:ValueTypes["ViewWhereInput"][],
	organization?:ValueTypes["OrganizationWhereInput"],
	updatedAt?:ValueTypes["DateTime"],
	updatedAt_gt?:ValueTypes["DateTime"],
	updatedAt_gte?:ValueTypes["DateTime"],
	updatedAt_in?:ValueTypes["DateTime"][],
	updatedAt_lt?:ValueTypes["DateTime"],
	updatedAt_lte?:ValueTypes["DateTime"],
	updatedAt_not?:ValueTypes["DateTime"],
	updatedAt_not_in?:ValueTypes["DateTime"][],
	urlQueryParams?:string,
	urlQueryParams_contains?:string,
	urlQueryParams_ends_with?:string,
	urlQueryParams_gt?:string,
	urlQueryParams_gte?:string,
	urlQueryParams_in?:string[],
	urlQueryParams_lt?:string,
	urlQueryParams_lte?:string,
	urlQueryParams_not?:string,
	urlQueryParams_not_contains?:string,
	urlQueryParams_not_ends_with?:string,
	urlQueryParams_not_in?:string[],
	urlQueryParams_not_starts_with?:string,
	urlQueryParams_starts_with?:string
};
	["ViewWhereUniqueInput"]: {
	id?:string
}
  }

export type PartialObjects = {
    ["Action"]: {
		__typename?: "Action";
			error?:string,
			message?:string,
			startDate?:string,
			stopDate?:string
	},
	["AggregateBigQueryCheck"]: {
		__typename?: "AggregateBigQueryCheck";
			count?:number
	},
	["AggregateCampaignConsistencyCheck"]: {
		__typename?: "AggregateCampaignConsistencyCheck";
			count?:number
	},
	["AggregateDailyConsistencyCheck"]: {
		__typename?: "AggregateDailyConsistencyCheck";
			count?:number
	},
	["AggregateIntegrationFeedConfig"]: {
		__typename?: "AggregateIntegrationFeedConfig";
			count?:number
	},
	["AggregateIntegrationFeedInstance"]: {
		__typename?: "AggregateIntegrationFeedInstance";
			count?:number
	},
	["AppleAccess"]: {
		__typename?: "AppleAccess";
			accountName?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			login?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			password?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["AppleAccessCreateInput"]: {
	accountName:string,
	descriptiveName:string,
	id?:string,
	login:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	password:string
},
	["AppleAccessOrderByInput"]:AppleAccessOrderByInput,
	["AppleAccessUpdateInput"]: {
	accountName?:string,
	descriptiveName?:string,
	login?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	password?:string
},
	["AppleAccessWhereInput"]: {
	accountName?:string,
	accountName_contains?:string,
	accountName_ends_with?:string,
	accountName_gt?:string,
	accountName_gte?:string,
	accountName_in?:string[],
	accountName_lt?:string,
	accountName_lte?:string,
	accountName_not?:string,
	accountName_not_contains?:string,
	accountName_not_ends_with?:string,
	accountName_not_in?:string[],
	accountName_not_starts_with?:string,
	accountName_starts_with?:string,
	AND?:PartialObjects["AppleAccessWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	login?:string,
	login_contains?:string,
	login_ends_with?:string,
	login_gt?:string,
	login_gte?:string,
	login_in?:string[],
	login_lt?:string,
	login_lte?:string,
	login_not?:string,
	login_not_contains?:string,
	login_not_ends_with?:string,
	login_not_in?:string[],
	login_not_starts_with?:string,
	login_starts_with?:string,
	NOT?:PartialObjects["AppleAccessWhereInput"][],
	OR?:PartialObjects["AppleAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	password?:string,
	password_contains?:string,
	password_ends_with?:string,
	password_gt?:string,
	password_gte?:string,
	password_in?:string[],
	password_lt?:string,
	password_lte?:string,
	password_not?:string,
	password_not_contains?:string,
	password_not_ends_with?:string,
	password_not_in?:string[],
	password_not_starts_with?:string,
	password_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["AppleAccessWhereUniqueInput"]: {
	id:string
},
	["AppleFeed"]: {
		__typename?: "AppleFeed";
			appleAccess?:PartialObjects["AppleAccess"],
			games?:PartialObjects["ScraperGame"][],
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			reporter?:boolean,
			scraper?:boolean,
			usePST?:boolean
	},
	["AppleFeedCreateWithoutParamsInput"]: {
	appleAccess:PartialObjects["AppleAccessWhereUniqueInput"],
	games?:PartialObjects["ScraperGameCreateInput"][],
	id?:string,
	reporter?:boolean,
	scraper?:boolean,
	usePST?:boolean
},
	["AppleFeedWhereInput"]: {
	AND?:PartialObjects["AppleFeedWhereInput"][],
	appleAccess?:PartialObjects["AppleAccessWhereInput"],
	games_every?:PartialObjects["ScraperGameWhereInput"],
	games_none?:PartialObjects["ScraperGameWhereInput"],
	games_some?:PartialObjects["ScraperGameWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppleFeedWhereInput"][],
	OR?:PartialObjects["AppleFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reporter?:boolean,
	reporter_not?:boolean,
	scraper?:boolean,
	scraper_not?:boolean,
	usePST?:boolean,
	usePST_not?:boolean
},
	["AppleSearchAdsAccess"]: {
		__typename?: "AppleSearchAdsAccess";
			certificate?:string,
			certificateKey?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["AppleSearchAdsAccessCreateInput"]: {
	certificate:string,
	certificateKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["AppleSearchAdsAccessOrderByInput"]:AppleSearchAdsAccessOrderByInput,
	["AppleSearchAdsAccessUpdateInput"]: {
	certificate?:string,
	certificateKey?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["AppleSearchAdsAccessWhereInput"]: {
	AND?:PartialObjects["AppleSearchAdsAccessWhereInput"][],
	certificate?:string,
	certificate_contains?:string,
	certificate_ends_with?:string,
	certificate_gt?:string,
	certificate_gte?:string,
	certificate_in?:string[],
	certificate_lt?:string,
	certificate_lte?:string,
	certificate_not?:string,
	certificate_not_contains?:string,
	certificate_not_ends_with?:string,
	certificate_not_in?:string[],
	certificate_not_starts_with?:string,
	certificate_starts_with?:string,
	certificateKey?:string,
	certificateKey_contains?:string,
	certificateKey_ends_with?:string,
	certificateKey_gt?:string,
	certificateKey_gte?:string,
	certificateKey_in?:string[],
	certificateKey_lt?:string,
	certificateKey_lte?:string,
	certificateKey_not?:string,
	certificateKey_not_contains?:string,
	certificateKey_not_ends_with?:string,
	certificateKey_not_in?:string[],
	certificateKey_not_starts_with?:string,
	certificateKey_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppleSearchAdsAccessWhereInput"][],
	OR?:PartialObjects["AppleSearchAdsAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["AppleSearchAdsAccessWhereUniqueInput"]: {
	id:string
},
	["AppleSearchAdsFeed"]: {
		__typename?: "AppleSearchAdsFeed";
			appleSearchAdsAccess?:PartialObjects["AppleSearchAdsAccess"],
			groupBy?:string[],
			id?:string,
			orgId?:string,
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["AppleSearchAdsFeedCreateWithoutParamsInput"]: {
	appleSearchAdsAccess:PartialObjects["AppleSearchAdsAccessWhereUniqueInput"],
	groupBy:string[],
	id?:string,
	orgId:string
},
	["AppleSearchAdsFeedWhereInput"]: {
	AND?:PartialObjects["AppleSearchAdsFeedWhereInput"][],
	appleSearchAdsAccess?:PartialObjects["AppleSearchAdsAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppleSearchAdsFeedWhereInput"][],
	OR?:PartialObjects["AppleSearchAdsFeedWhereInput"][],
	orgId?:string,
	orgId_contains?:string,
	orgId_ends_with?:string,
	orgId_gt?:string,
	orgId_gte?:string,
	orgId_in?:string[],
	orgId_lt?:string,
	orgId_lte?:string,
	orgId_not?:string,
	orgId_not_contains?:string,
	orgId_not_ends_with?:string,
	orgId_not_in?:string[],
	orgId_not_starts_with?:string,
	orgId_starts_with?:string,
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["ApplovinAccess"]: {
		__typename?: "ApplovinAccess";
			apiKey?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["ApplovinAccessCreateInput"]: {
	apiKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["ApplovinAccessOrderByInput"]:ApplovinAccessOrderByInput,
	["ApplovinAccessUpdateInput"]: {
	apiKey?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["ApplovinAccessWhereInput"]: {
	AND?:PartialObjects["ApplovinAccessWhereInput"][],
	apiKey?:string,
	apiKey_contains?:string,
	apiKey_ends_with?:string,
	apiKey_gt?:string,
	apiKey_gte?:string,
	apiKey_in?:string[],
	apiKey_lt?:string,
	apiKey_lte?:string,
	apiKey_not?:string,
	apiKey_not_contains?:string,
	apiKey_not_ends_with?:string,
	apiKey_not_in?:string[],
	apiKey_not_starts_with?:string,
	apiKey_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["ApplovinAccessWhereInput"][],
	OR?:PartialObjects["ApplovinAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["ApplovinAccessWhereUniqueInput"]: {
	id:string
},
	["ApplovinFeed"]: {
		__typename?: "ApplovinFeed";
			applovinAccess?:PartialObjects["ApplovinAccess"],
			gameIds?:string[],
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			reportType?:PartialObjects["ApplovinReportType"]
	},
	["ApplovinFeedCreateWithoutParamsInput"]: {
	applovinAccess:PartialObjects["ApplovinAccessWhereUniqueInput"],
	gameIds:string[],
	id?:string,
	reportType:PartialObjects["ApplovinReportType"]
},
	["ApplovinFeedWhereInput"]: {
	AND?:PartialObjects["ApplovinFeedWhereInput"][],
	applovinAccess?:PartialObjects["ApplovinAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["ApplovinFeedWhereInput"][],
	OR?:PartialObjects["ApplovinFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reportType?:PartialObjects["ApplovinReportType"],
	reportType_in?:PartialObjects["ApplovinReportType"][],
	reportType_not?:PartialObjects["ApplovinReportType"],
	reportType_not_in?:PartialObjects["ApplovinReportType"][]
},
	["ApplovinReportType"]:ApplovinReportType,
	["AppsFlyerAccess"]: {
		__typename?: "AppsFlyerAccess";
			apiToken?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["AppsFlyerAccessCreateInput"]: {
	apiToken:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["AppsFlyerAccessOrderByInput"]:AppsFlyerAccessOrderByInput,
	["AppsFlyerAccessUpdateInput"]: {
	apiToken?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["AppsFlyerAccessWhereInput"]: {
	AND?:PartialObjects["AppsFlyerAccessWhereInput"][],
	apiToken?:string,
	apiToken_contains?:string,
	apiToken_ends_with?:string,
	apiToken_gt?:string,
	apiToken_gte?:string,
	apiToken_in?:string[],
	apiToken_lt?:string,
	apiToken_lte?:string,
	apiToken_not?:string,
	apiToken_not_contains?:string,
	apiToken_not_ends_with?:string,
	apiToken_not_in?:string[],
	apiToken_not_starts_with?:string,
	apiToken_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppsFlyerAccessWhereInput"][],
	OR?:PartialObjects["AppsFlyerAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["AppsFlyerAccessWhereUniqueInput"]: {
	id:string
},
	["AppsFlyerCampaignFeed"]: {
		__typename?: "AppsFlyerCampaignFeed";
			appsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			attributedTouchType?:string,
			currency?:string,
			eventName?:string,
			gameId?:string,
			id?:string,
			mediaSource?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			reattr?:boolean,
			reportType?:PartialObjects["ReportType"],
			timezone?:string
	},
	["AppsFlyerCampaignFeedCreateWithoutParamsInput"]: {
	appsFlyerAccess:PartialObjects["AppsFlyerAccessWhereUniqueInput"],
	attributedTouchType?:string,
	currency?:string,
	eventName?:string,
	gameId:string,
	id?:string,
	mediaSource?:string,
	reattr?:boolean,
	reportType?:PartialObjects["ReportType"],
	timezone?:string
},
	["AppsFlyerCampaignFeedWhereInput"]: {
	AND?:PartialObjects["AppsFlyerCampaignFeedWhereInput"][],
	appsFlyerAccess?:PartialObjects["AppsFlyerAccessWhereInput"],
	attributedTouchType?:string,
	attributedTouchType_contains?:string,
	attributedTouchType_ends_with?:string,
	attributedTouchType_gt?:string,
	attributedTouchType_gte?:string,
	attributedTouchType_in?:string[],
	attributedTouchType_lt?:string,
	attributedTouchType_lte?:string,
	attributedTouchType_not?:string,
	attributedTouchType_not_contains?:string,
	attributedTouchType_not_ends_with?:string,
	attributedTouchType_not_in?:string[],
	attributedTouchType_not_starts_with?:string,
	attributedTouchType_starts_with?:string,
	currency?:string,
	currency_contains?:string,
	currency_ends_with?:string,
	currency_gt?:string,
	currency_gte?:string,
	currency_in?:string[],
	currency_lt?:string,
	currency_lte?:string,
	currency_not?:string,
	currency_not_contains?:string,
	currency_not_ends_with?:string,
	currency_not_in?:string[],
	currency_not_starts_with?:string,
	currency_starts_with?:string,
	eventName?:string,
	eventName_contains?:string,
	eventName_ends_with?:string,
	eventName_gt?:string,
	eventName_gte?:string,
	eventName_in?:string[],
	eventName_lt?:string,
	eventName_lte?:string,
	eventName_not?:string,
	eventName_not_contains?:string,
	eventName_not_ends_with?:string,
	eventName_not_in?:string[],
	eventName_not_starts_with?:string,
	eventName_starts_with?:string,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	mediaSource?:string,
	mediaSource_contains?:string,
	mediaSource_ends_with?:string,
	mediaSource_gt?:string,
	mediaSource_gte?:string,
	mediaSource_in?:string[],
	mediaSource_lt?:string,
	mediaSource_lte?:string,
	mediaSource_not?:string,
	mediaSource_not_contains?:string,
	mediaSource_not_ends_with?:string,
	mediaSource_not_in?:string[],
	mediaSource_not_starts_with?:string,
	mediaSource_starts_with?:string,
	NOT?:PartialObjects["AppsFlyerCampaignFeedWhereInput"][],
	OR?:PartialObjects["AppsFlyerCampaignFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reattr?:boolean,
	reattr_not?:boolean,
	reportType?:PartialObjects["ReportType"],
	reportType_in?:PartialObjects["ReportType"][],
	reportType_not?:PartialObjects["ReportType"],
	reportType_not_in?:PartialObjects["ReportType"][],
	timezone?:string,
	timezone_contains?:string,
	timezone_ends_with?:string,
	timezone_gt?:string,
	timezone_gte?:string,
	timezone_in?:string[],
	timezone_lt?:string,
	timezone_lte?:string,
	timezone_not?:string,
	timezone_not_contains?:string,
	timezone_not_ends_with?:string,
	timezone_not_in?:string[],
	timezone_not_starts_with?:string,
	timezone_starts_with?:string
},
	["AppsFlyerDatalockerFeed"]: {
		__typename?: "AppsFlyerDatalockerFeed";
			awsAccessKey?:string,
			bucketSecret?:string,
			eventNames?:string[],
			homeFolder?:string,
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["AppsFlyerDatalockerFeedCreateWithoutParamsInput"]: {
	awsAccessKey:string,
	bucketSecret:string,
	eventNames:string[],
	homeFolder:string,
	id?:string
},
	["AppsFlyerDatalockerFeedWhereInput"]: {
	AND?:PartialObjects["AppsFlyerDatalockerFeedWhereInput"][],
	awsAccessKey?:string,
	awsAccessKey_contains?:string,
	awsAccessKey_ends_with?:string,
	awsAccessKey_gt?:string,
	awsAccessKey_gte?:string,
	awsAccessKey_in?:string[],
	awsAccessKey_lt?:string,
	awsAccessKey_lte?:string,
	awsAccessKey_not?:string,
	awsAccessKey_not_contains?:string,
	awsAccessKey_not_ends_with?:string,
	awsAccessKey_not_in?:string[],
	awsAccessKey_not_starts_with?:string,
	awsAccessKey_starts_with?:string,
	bucketSecret?:string,
	bucketSecret_contains?:string,
	bucketSecret_ends_with?:string,
	bucketSecret_gt?:string,
	bucketSecret_gte?:string,
	bucketSecret_in?:string[],
	bucketSecret_lt?:string,
	bucketSecret_lte?:string,
	bucketSecret_not?:string,
	bucketSecret_not_contains?:string,
	bucketSecret_not_ends_with?:string,
	bucketSecret_not_in?:string[],
	bucketSecret_not_starts_with?:string,
	bucketSecret_starts_with?:string,
	homeFolder?:string,
	homeFolder_contains?:string,
	homeFolder_ends_with?:string,
	homeFolder_gt?:string,
	homeFolder_gte?:string,
	homeFolder_in?:string[],
	homeFolder_lt?:string,
	homeFolder_lte?:string,
	homeFolder_not?:string,
	homeFolder_not_contains?:string,
	homeFolder_not_ends_with?:string,
	homeFolder_not_in?:string[],
	homeFolder_not_starts_with?:string,
	homeFolder_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppsFlyerDatalockerFeedWhereInput"][],
	OR?:PartialObjects["AppsFlyerDatalockerFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["AppsFlyerRawEventsFeed"]: {
		__typename?: "AppsFlyerRawEventsFeed";
			appsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			eventName?:string,
			gameId?:string,
			id?:string,
			omitFields?:string[],
			params?:PartialObjects["IntegrationFeedParams"],
			reportType?:string
	},
	["AppsFlyerRawEventsFeedCreateWithoutParamsInput"]: {
	appsFlyerAccess:PartialObjects["AppsFlyerAccessWhereUniqueInput"],
	eventName:string,
	gameId:string,
	id?:string,
	omitFields:string[],
	reportType:string
},
	["AppsFlyerRawEventsFeedWhereInput"]: {
	AND?:PartialObjects["AppsFlyerRawEventsFeedWhereInput"][],
	appsFlyerAccess?:PartialObjects["AppsFlyerAccessWhereInput"],
	eventName?:string,
	eventName_contains?:string,
	eventName_ends_with?:string,
	eventName_gt?:string,
	eventName_gte?:string,
	eventName_in?:string[],
	eventName_lt?:string,
	eventName_lte?:string,
	eventName_not?:string,
	eventName_not_contains?:string,
	eventName_not_ends_with?:string,
	eventName_not_in?:string[],
	eventName_not_starts_with?:string,
	eventName_starts_with?:string,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppsFlyerRawEventsFeedWhereInput"][],
	OR?:PartialObjects["AppsFlyerRawEventsFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reportType?:string,
	reportType_contains?:string,
	reportType_ends_with?:string,
	reportType_gt?:string,
	reportType_gte?:string,
	reportType_in?:string[],
	reportType_lt?:string,
	reportType_lte?:string,
	reportType_not?:string,
	reportType_not_contains?:string,
	reportType_not_ends_with?:string,
	reportType_not_in?:string[],
	reportType_not_starts_with?:string,
	reportType_starts_with?:string
},
	["AppsFlyerRawInstallsFeed"]: {
		__typename?: "AppsFlyerRawInstallsFeed";
			appsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			gameId?:string,
			id?:string,
			omitFields?:string[],
			params?:PartialObjects["IntegrationFeedParams"],
			reportType?:string
	},
	["AppsFlyerRawInstallsFeedCreateWithoutParamsInput"]: {
	appsFlyerAccess:PartialObjects["AppsFlyerAccessWhereUniqueInput"],
	gameId:string,
	id?:string,
	omitFields:string[],
	reportType:string
},
	["AppsFlyerRawInstallsFeedWhereInput"]: {
	AND?:PartialObjects["AppsFlyerRawInstallsFeedWhereInput"][],
	appsFlyerAccess?:PartialObjects["AppsFlyerAccessWhereInput"],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppsFlyerRawInstallsFeedWhereInput"][],
	OR?:PartialObjects["AppsFlyerRawInstallsFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reportType?:string,
	reportType_contains?:string,
	reportType_ends_with?:string,
	reportType_gt?:string,
	reportType_gte?:string,
	reportType_in?:string[],
	reportType_lt?:string,
	reportType_lte?:string,
	reportType_not?:string,
	reportType_not_contains?:string,
	reportType_not_ends_with?:string,
	reportType_not_in?:string[],
	reportType_not_starts_with?:string,
	reportType_starts_with?:string
},
	["AppsFlyerScraperFeed"]: {
		__typename?: "AppsFlyerScraperFeed";
			email?:string,
			games?:PartialObjects["ScraperGame"][],
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			password?:string
	},
	["AppsFlyerScraperFeedCreateWithoutParamsInput"]: {
	email:string,
	games?:PartialObjects["ScraperGameCreateInput"][],
	id?:string,
	password:string
},
	["AppsFlyerScraperFeedWhereInput"]: {
	AND?:PartialObjects["AppsFlyerScraperFeedWhereInput"][],
	email?:string,
	email_contains?:string,
	email_ends_with?:string,
	email_gt?:string,
	email_gte?:string,
	email_in?:string[],
	email_lt?:string,
	email_lte?:string,
	email_not?:string,
	email_not_contains?:string,
	email_not_ends_with?:string,
	email_not_in?:string[],
	email_not_starts_with?:string,
	email_starts_with?:string,
	games_every?:PartialObjects["ScraperGameWhereInput"],
	games_none?:PartialObjects["ScraperGameWhereInput"],
	games_some?:PartialObjects["ScraperGameWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AppsFlyerScraperFeedWhereInput"][],
	OR?:PartialObjects["AppsFlyerScraperFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	password?:string,
	password_contains?:string,
	password_ends_with?:string,
	password_gt?:string,
	password_gte?:string,
	password_in?:string[],
	password_lt?:string,
	password_lte?:string,
	password_not?:string,
	password_not_contains?:string,
	password_not_ends_with?:string,
	password_not_in?:string[],
	password_not_starts_with?:string,
	password_starts_with?:string
},
	["AwsProject"]: {
		__typename?: "AwsProject";
			accessKeyId?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			s3Destinations?:PartialObjects["S3Destination"][],
			secretAccessKey?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["AwsProjectCreateInput"]: {
	accessKeyId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	secretAccessKey:string
},
	["AwsProjectOrderByInput"]:AwsProjectOrderByInput,
	["AwsProjectUpdateInput"]: {
	accessKeyId?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	secretAccessKey?:string
},
	["AwsProjectWhereInput"]: {
	accessKeyId?:string,
	accessKeyId_contains?:string,
	accessKeyId_ends_with?:string,
	accessKeyId_gt?:string,
	accessKeyId_gte?:string,
	accessKeyId_in?:string[],
	accessKeyId_lt?:string,
	accessKeyId_lte?:string,
	accessKeyId_not?:string,
	accessKeyId_not_contains?:string,
	accessKeyId_not_ends_with?:string,
	accessKeyId_not_in?:string[],
	accessKeyId_not_starts_with?:string,
	accessKeyId_starts_with?:string,
	AND?:PartialObjects["AwsProjectWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["AwsProjectWhereInput"][],
	OR?:PartialObjects["AwsProjectWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	s3Destinations_every?:PartialObjects["S3DestinationWhereInput"],
	s3Destinations_none?:PartialObjects["S3DestinationWhereInput"],
	s3Destinations_some?:PartialObjects["S3DestinationWhereInput"],
	secretAccessKey?:string,
	secretAccessKey_contains?:string,
	secretAccessKey_ends_with?:string,
	secretAccessKey_gt?:string,
	secretAccessKey_gte?:string,
	secretAccessKey_in?:string[],
	secretAccessKey_lt?:string,
	secretAccessKey_lte?:string,
	secretAccessKey_not?:string,
	secretAccessKey_not_contains?:string,
	secretAccessKey_not_ends_with?:string,
	secretAccessKey_not_in?:string[],
	secretAccessKey_not_starts_with?:string,
	secretAccessKey_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["AwsProjectWhereUniqueInput"]: {
	id:string
},
	["BigQueryCheck"]: {
		__typename?: "BigQueryCheck";
			createdAt?:PartialObjects["DateTime"],
			dataset?:string,
			id?:string,
			lastModified?:PartialObjects["DateTime"],
			maxDate?:PartialObjects["DateTime"],
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			projectId?:string,
			tableExists?:boolean,
			tableName?:string,
			tableReference?:string,
			tableSuffix?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["BigQueryCheckConnection"]: {
		__typename?: "BigQueryCheckConnection";
			aggregate?:PartialObjects["AggregateBigQueryCheck"],
			edges?:PartialObjects["BigQueryCheckEdge"][],
			pageInfo?:PartialObjects["PageInfo"]
	},
	["BigQueryCheckEdge"]: {
		__typename?: "BigQueryCheckEdge";
			cursor?:string,
			node?:PartialObjects["BigQueryCheck"]
	},
	["BigQueryCheckOrderByInput"]:BigQueryCheckOrderByInput,
	["BigQueryCheckWhereInput"]: {
	AND?:PartialObjects["BigQueryCheckWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	dataset?:string,
	dataset_contains?:string,
	dataset_ends_with?:string,
	dataset_gt?:string,
	dataset_gte?:string,
	dataset_in?:string[],
	dataset_lt?:string,
	dataset_lte?:string,
	dataset_not?:string,
	dataset_not_contains?:string,
	dataset_not_ends_with?:string,
	dataset_not_in?:string[],
	dataset_not_starts_with?:string,
	dataset_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	lastModified?:PartialObjects["DateTime"],
	lastModified_gt?:PartialObjects["DateTime"],
	lastModified_gte?:PartialObjects["DateTime"],
	lastModified_in?:PartialObjects["DateTime"][],
	lastModified_lt?:PartialObjects["DateTime"],
	lastModified_lte?:PartialObjects["DateTime"],
	lastModified_not?:PartialObjects["DateTime"],
	lastModified_not_in?:PartialObjects["DateTime"][],
	maxDate?:PartialObjects["DateTime"],
	maxDate_gt?:PartialObjects["DateTime"],
	maxDate_gte?:PartialObjects["DateTime"],
	maxDate_in?:PartialObjects["DateTime"][],
	maxDate_lt?:PartialObjects["DateTime"],
	maxDate_lte?:PartialObjects["DateTime"],
	maxDate_not?:PartialObjects["DateTime"],
	maxDate_not_in?:PartialObjects["DateTime"][],
	NOT?:PartialObjects["BigQueryCheckWhereInput"][],
	OR?:PartialObjects["BigQueryCheckWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	projectId?:string,
	projectId_contains?:string,
	projectId_ends_with?:string,
	projectId_gt?:string,
	projectId_gte?:string,
	projectId_in?:string[],
	projectId_lt?:string,
	projectId_lte?:string,
	projectId_not?:string,
	projectId_not_contains?:string,
	projectId_not_ends_with?:string,
	projectId_not_in?:string[],
	projectId_not_starts_with?:string,
	projectId_starts_with?:string,
	tableExists?:boolean,
	tableExists_not?:boolean,
	tableName?:string,
	tableName_contains?:string,
	tableName_ends_with?:string,
	tableName_gt?:string,
	tableName_gte?:string,
	tableName_in?:string[],
	tableName_lt?:string,
	tableName_lte?:string,
	tableName_not?:string,
	tableName_not_contains?:string,
	tableName_not_ends_with?:string,
	tableName_not_in?:string[],
	tableName_not_starts_with?:string,
	tableName_starts_with?:string,
	tableReference?:string,
	tableReference_contains?:string,
	tableReference_ends_with?:string,
	tableReference_gt?:string,
	tableReference_gte?:string,
	tableReference_in?:string[],
	tableReference_lt?:string,
	tableReference_lte?:string,
	tableReference_not?:string,
	tableReference_not_contains?:string,
	tableReference_not_ends_with?:string,
	tableReference_not_in?:string[],
	tableReference_not_starts_with?:string,
	tableReference_starts_with?:string,
	tableSuffix?:string,
	tableSuffix_contains?:string,
	tableSuffix_ends_with?:string,
	tableSuffix_gt?:string,
	tableSuffix_gte?:string,
	tableSuffix_in?:string[],
	tableSuffix_lt?:string,
	tableSuffix_lte?:string,
	tableSuffix_not?:string,
	tableSuffix_not_contains?:string,
	tableSuffix_not_ends_with?:string,
	tableSuffix_not_in?:string[],
	tableSuffix_not_starts_with?:string,
	tableSuffix_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["BigQueryCheckWhereUniqueInput"]: {
	id?:string,
	tableReference?:string
},
	["BigQueryDestination"]: {
		__typename?: "BigQueryDestination";
			bqProject?:PartialObjects["BigQueryProject"],
			createdAt?:PartialObjects["DateTime"],
			dataset?:string,
			descriptiveName?:string,
			enabled?:boolean,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			tablePrefix?:string,
			tableSuffix?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["BigQueryDestinationCreateInput"]: {
	bqProject:PartialObjects["BigQueryProjectWhereUniqueInput"],
	dataset:string,
	descriptiveName:string,
	enabled:boolean,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	tablePrefix?:string,
	tableSuffix?:string
},
	["BigQueryDestinationOrderByInput"]:BigQueryDestinationOrderByInput,
	["BigQueryDestinationUpdateInput"]: {
	bqProject?:PartialObjects["BigQueryProjectWhereUniqueInput"],
	dataset?:string,
	descriptiveName?:string,
	enabled?:boolean,
	id:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	tablePrefix?:string,
	tableSuffix?:string
},
	["BigQueryDestinationUpdateManyInput"]: {
	create?:PartialObjects["BigQueryDestinationCreateInput"][],
	delete?:PartialObjects["BigQueryDestinationWhereUniqueInput"][],
	update?:PartialObjects["BigQueryDestinationUpdateInput"][]
},
	["BigQueryDestinationWhereInput"]: {
	AND?:PartialObjects["BigQueryDestinationWhereInput"][],
	bqProject?:PartialObjects["BigQueryProjectWhereInput"],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	dataset?:string,
	dataset_contains?:string,
	dataset_ends_with?:string,
	dataset_gt?:string,
	dataset_gte?:string,
	dataset_in?:string[],
	dataset_lt?:string,
	dataset_lte?:string,
	dataset_not?:string,
	dataset_not_contains?:string,
	dataset_not_ends_with?:string,
	dataset_not_in?:string[],
	dataset_not_starts_with?:string,
	dataset_starts_with?:string,
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["BigQueryDestinationWhereInput"][],
	OR?:PartialObjects["BigQueryDestinationWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	tablePrefix?:string,
	tablePrefix_contains?:string,
	tablePrefix_ends_with?:string,
	tablePrefix_gt?:string,
	tablePrefix_gte?:string,
	tablePrefix_in?:string[],
	tablePrefix_lt?:string,
	tablePrefix_lte?:string,
	tablePrefix_not?:string,
	tablePrefix_not_contains?:string,
	tablePrefix_not_ends_with?:string,
	tablePrefix_not_in?:string[],
	tablePrefix_not_starts_with?:string,
	tablePrefix_starts_with?:string,
	tableSuffix?:string,
	tableSuffix_contains?:string,
	tableSuffix_ends_with?:string,
	tableSuffix_gt?:string,
	tableSuffix_gte?:string,
	tableSuffix_in?:string[],
	tableSuffix_lt?:string,
	tableSuffix_lte?:string,
	tableSuffix_not?:string,
	tableSuffix_not_contains?:string,
	tableSuffix_not_ends_with?:string,
	tableSuffix_not_in?:string[],
	tableSuffix_not_starts_with?:string,
	tableSuffix_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["BigQueryDestinationWhereUniqueInput"]: {
	id:string
},
	["BigQueryProject"]: {
		__typename?: "BigQueryProject";
			bqDestinations?:PartialObjects["BigQueryDestination"][],
			clientEmail?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			privateKey?:string,
			projectId?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["BigQueryProjectCreateInput"]: {
	clientEmail:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	privateKey:string,
	projectId:string
},
	["BigQueryProjectOrderByInput"]:BigQueryProjectOrderByInput,
	["BigQueryProjectUpdateInput"]: {
	clientEmail?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	privateKey?:string,
	projectId?:string
},
	["BigQueryProjectWhereInput"]: {
	AND?:PartialObjects["BigQueryProjectWhereInput"][],
	bqDestinations_every?:PartialObjects["BigQueryDestinationWhereInput"],
	bqDestinations_none?:PartialObjects["BigQueryDestinationWhereInput"],
	bqDestinations_some?:PartialObjects["BigQueryDestinationWhereInput"],
	clientEmail?:string,
	clientEmail_contains?:string,
	clientEmail_ends_with?:string,
	clientEmail_gt?:string,
	clientEmail_gte?:string,
	clientEmail_in?:string[],
	clientEmail_lt?:string,
	clientEmail_lte?:string,
	clientEmail_not?:string,
	clientEmail_not_contains?:string,
	clientEmail_not_ends_with?:string,
	clientEmail_not_in?:string[],
	clientEmail_not_starts_with?:string,
	clientEmail_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["BigQueryProjectWhereInput"][],
	OR?:PartialObjects["BigQueryProjectWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	privateKey?:string,
	privateKey_contains?:string,
	privateKey_ends_with?:string,
	privateKey_gt?:string,
	privateKey_gte?:string,
	privateKey_in?:string[],
	privateKey_lt?:string,
	privateKey_lte?:string,
	privateKey_not?:string,
	privateKey_not_contains?:string,
	privateKey_not_ends_with?:string,
	privateKey_not_in?:string[],
	privateKey_not_starts_with?:string,
	privateKey_starts_with?:string,
	projectId?:string,
	projectId_contains?:string,
	projectId_ends_with?:string,
	projectId_gt?:string,
	projectId_gte?:string,
	projectId_in?:string[],
	projectId_lt?:string,
	projectId_lte?:string,
	projectId_not?:string,
	projectId_not_contains?:string,
	projectId_not_ends_with?:string,
	projectId_not_in?:string[],
	projectId_not_starts_with?:string,
	projectId_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["BigQueryProjectWhereUniqueInput"]: {
	id:string
},
	["CampaignConsistencyCheck"]: {
		__typename?: "CampaignConsistencyCheck";
			campaignId?:string,
			createdAt?:PartialObjects["DateTime"],
			date?:PartialObjects["DateTime"],
			gameId?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			platform?:PartialObjects["Platform"],
			updatedAt?:PartialObjects["DateTime"],
			values?:PartialObjects["CampaignConsistencyValues"][]
	},
	["CampaignConsistencyCheckConnection"]: {
		__typename?: "CampaignConsistencyCheckConnection";
			aggregate?:PartialObjects["AggregateCampaignConsistencyCheck"],
			edges?:PartialObjects["CampaignConsistencyCheckEdge"][],
			pageInfo?:PartialObjects["PageInfo"]
	},
	["CampaignConsistencyCheckEdge"]: {
		__typename?: "CampaignConsistencyCheckEdge";
			cursor?:string,
			node?:PartialObjects["CampaignConsistencyCheck"]
	},
	["CampaignConsistencyCheckOrderByInput"]:CampaignConsistencyCheckOrderByInput,
	["CampaignConsistencyCheckWhereInput"]: {
	AND?:PartialObjects["CampaignConsistencyCheckWhereInput"][],
	campaignId?:string,
	campaignId_contains?:string,
	campaignId_ends_with?:string,
	campaignId_gt?:string,
	campaignId_gte?:string,
	campaignId_in?:string[],
	campaignId_lt?:string,
	campaignId_lte?:string,
	campaignId_not?:string,
	campaignId_not_contains?:string,
	campaignId_not_ends_with?:string,
	campaignId_not_in?:string[],
	campaignId_not_starts_with?:string,
	campaignId_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	date?:PartialObjects["DateTime"],
	date_gt?:PartialObjects["DateTime"],
	date_gte?:PartialObjects["DateTime"],
	date_in?:PartialObjects["DateTime"][],
	date_lt?:PartialObjects["DateTime"],
	date_lte?:PartialObjects["DateTime"],
	date_not?:PartialObjects["DateTime"],
	date_not_in?:PartialObjects["DateTime"][],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["CampaignConsistencyCheckWhereInput"][],
	OR?:PartialObjects["CampaignConsistencyCheckWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	platform?:PartialObjects["Platform"],
	platform_in?:PartialObjects["Platform"][],
	platform_not?:PartialObjects["Platform"],
	platform_not_in?:PartialObjects["Platform"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][],
	values_every?:PartialObjects["CampaignConsistencyValuesWhereInput"],
	values_none?:PartialObjects["CampaignConsistencyValuesWhereInput"],
	values_some?:PartialObjects["CampaignConsistencyValuesWhereInput"]
},
	["CampaignConsistencyCheckWhereUniqueInput"]: {
	id?:string
},
	["CampaignConsistencyValues"]: {
		__typename?: "CampaignConsistencyValues";
			clicks?:number,
			createdAt?:PartialObjects["DateTime"],
			iapGrossRevenue?:number,
			id?:string,
			impressions?:number,
			installs?:number,
			payers?:number,
			purchases?:number,
			source?:PartialObjects["ConsistencySource"],
			spend?:number,
			updatedAt?:PartialObjects["DateTime"]
	},
	["CampaignConsistencyValuesOrderByInput"]:CampaignConsistencyValuesOrderByInput,
	["CampaignConsistencyValuesWhereInput"]: {
	AND?:PartialObjects["CampaignConsistencyValuesWhereInput"][],
	clicks?:number,
	clicks_gt?:number,
	clicks_gte?:number,
	clicks_in?:number[],
	clicks_lt?:number,
	clicks_lte?:number,
	clicks_not?:number,
	clicks_not_in?:number[],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	iapGrossRevenue?:number,
	iapGrossRevenue_gt?:number,
	iapGrossRevenue_gte?:number,
	iapGrossRevenue_in?:number[],
	iapGrossRevenue_lt?:number,
	iapGrossRevenue_lte?:number,
	iapGrossRevenue_not?:number,
	iapGrossRevenue_not_in?:number[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	impressions?:number,
	impressions_gt?:number,
	impressions_gte?:number,
	impressions_in?:number[],
	impressions_lt?:number,
	impressions_lte?:number,
	impressions_not?:number,
	impressions_not_in?:number[],
	installs?:number,
	installs_gt?:number,
	installs_gte?:number,
	installs_in?:number[],
	installs_lt?:number,
	installs_lte?:number,
	installs_not?:number,
	installs_not_in?:number[],
	NOT?:PartialObjects["CampaignConsistencyValuesWhereInput"][],
	OR?:PartialObjects["CampaignConsistencyValuesWhereInput"][],
	payers?:number,
	payers_gt?:number,
	payers_gte?:number,
	payers_in?:number[],
	payers_lt?:number,
	payers_lte?:number,
	payers_not?:number,
	payers_not_in?:number[],
	purchases?:number,
	purchases_gt?:number,
	purchases_gte?:number,
	purchases_in?:number[],
	purchases_lt?:number,
	purchases_lte?:number,
	purchases_not?:number,
	purchases_not_in?:number[],
	source?:PartialObjects["ConsistencySource"],
	source_in?:PartialObjects["ConsistencySource"][],
	source_not?:PartialObjects["ConsistencySource"],
	source_not_in?:PartialObjects["ConsistencySource"][],
	spend?:number,
	spend_gt?:number,
	spend_gte?:number,
	spend_in?:number[],
	spend_lt?:number,
	spend_lte?:number,
	spend_not?:number,
	spend_not_in?:number[],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["ChartboostAccess"]: {
		__typename?: "ChartboostAccess";
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"],
			userId?:string,
			userSignature?:string
	},
	["ChartboostAccessCreateInput"]: {
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	userId:string,
	userSignature:string
},
	["ChartboostAccessOrderByInput"]:ChartboostAccessOrderByInput,
	["ChartboostAccessUpdateInput"]: {
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	userId?:string,
	userSignature?:string
},
	["ChartboostAccessWhereInput"]: {
	AND?:PartialObjects["ChartboostAccessWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["ChartboostAccessWhereInput"][],
	OR?:PartialObjects["ChartboostAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][],
	userId?:string,
	userId_contains?:string,
	userId_ends_with?:string,
	userId_gt?:string,
	userId_gte?:string,
	userId_in?:string[],
	userId_lt?:string,
	userId_lte?:string,
	userId_not?:string,
	userId_not_contains?:string,
	userId_not_ends_with?:string,
	userId_not_in?:string[],
	userId_not_starts_with?:string,
	userId_starts_with?:string,
	userSignature?:string,
	userSignature_contains?:string,
	userSignature_ends_with?:string,
	userSignature_gt?:string,
	userSignature_gte?:string,
	userSignature_in?:string[],
	userSignature_lt?:string,
	userSignature_lte?:string,
	userSignature_not?:string,
	userSignature_not_contains?:string,
	userSignature_not_ends_with?:string,
	userSignature_not_in?:string[],
	userSignature_not_starts_with?:string,
	userSignature_starts_with?:string
},
	["ChartboostAccessWhereUniqueInput"]: {
	id:string
},
	["ChartboostFeed"]: {
		__typename?: "ChartboostFeed";
			chartboostAccess?:PartialObjects["ChartboostAccess"],
			gameId?:string,
			groupBy?:string,
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["ChartboostFeedCreateWithoutParamsInput"]: {
	chartboostAccess:PartialObjects["ChartboostAccessWhereUniqueInput"],
	gameId:string,
	groupBy?:string,
	id?:string
},
	["ChartboostFeedWhereInput"]: {
	AND?:PartialObjects["ChartboostFeedWhereInput"][],
	chartboostAccess?:PartialObjects["ChartboostAccessWhereInput"],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	groupBy?:string,
	groupBy_contains?:string,
	groupBy_ends_with?:string,
	groupBy_gt?:string,
	groupBy_gte?:string,
	groupBy_in?:string[],
	groupBy_lt?:string,
	groupBy_lte?:string,
	groupBy_not?:string,
	groupBy_not_contains?:string,
	groupBy_not_ends_with?:string,
	groupBy_not_in?:string[],
	groupBy_not_starts_with?:string,
	groupBy_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["ChartboostFeedWhereInput"][],
	OR?:PartialObjects["ChartboostFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["ConsistencySource"]:ConsistencySource,
	["DailyConsistencyCheck"]: {
		__typename?: "DailyConsistencyCheck";
			createdAt?:PartialObjects["DateTime"],
			date?:PartialObjects["DateTime"],
			gameId?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			platform?:PartialObjects["Platform"],
			updatedAt?:PartialObjects["DateTime"],
			values?:PartialObjects["DailyConsistencyValues"][]
	},
	["DailyConsistencyCheckConnection"]: {
		__typename?: "DailyConsistencyCheckConnection";
			aggregate?:PartialObjects["AggregateDailyConsistencyCheck"],
			edges?:PartialObjects["DailyConsistencyCheckEdge"][],
			pageInfo?:PartialObjects["PageInfo"]
	},
	["DailyConsistencyCheckEdge"]: {
		__typename?: "DailyConsistencyCheckEdge";
			cursor?:string,
			node?:PartialObjects["DailyConsistencyCheck"]
	},
	["DailyConsistencyCheckOrderByInput"]:DailyConsistencyCheckOrderByInput,
	["DailyConsistencyCheckWhereInput"]: {
	AND?:PartialObjects["DailyConsistencyCheckWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	date?:PartialObjects["DateTime"],
	date_gt?:PartialObjects["DateTime"],
	date_gte?:PartialObjects["DateTime"],
	date_in?:PartialObjects["DateTime"][],
	date_lt?:PartialObjects["DateTime"],
	date_lte?:PartialObjects["DateTime"],
	date_not?:PartialObjects["DateTime"],
	date_not_in?:PartialObjects["DateTime"][],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["DailyConsistencyCheckWhereInput"][],
	OR?:PartialObjects["DailyConsistencyCheckWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	platform?:PartialObjects["Platform"],
	platform_in?:PartialObjects["Platform"][],
	platform_not?:PartialObjects["Platform"],
	platform_not_in?:PartialObjects["Platform"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][],
	values_every?:PartialObjects["DailyConsistencyValuesWhereInput"],
	values_none?:PartialObjects["DailyConsistencyValuesWhereInput"],
	values_some?:PartialObjects["DailyConsistencyValuesWhereInput"]
},
	["DailyConsistencyCheckWhereUniqueInput"]: {
	id?:string
},
	["DailyConsistencyValues"]: {
		__typename?: "DailyConsistencyValues";
			createdAt?:PartialObjects["DateTime"],
			id?:string,
			installs?:number,
			purchases?:number,
			revenue?:number,
			source?:PartialObjects["ConsistencySource"],
			updatedAt?:PartialObjects["DateTime"]
	},
	["DailyConsistencyValuesOrderByInput"]:DailyConsistencyValuesOrderByInput,
	["DailyConsistencyValuesWhereInput"]: {
	AND?:PartialObjects["DailyConsistencyValuesWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	installs?:number,
	installs_gt?:number,
	installs_gte?:number,
	installs_in?:number[],
	installs_lt?:number,
	installs_lte?:number,
	installs_not?:number,
	installs_not_in?:number[],
	NOT?:PartialObjects["DailyConsistencyValuesWhereInput"][],
	OR?:PartialObjects["DailyConsistencyValuesWhereInput"][],
	purchases?:number,
	purchases_gt?:number,
	purchases_gte?:number,
	purchases_in?:number[],
	purchases_lt?:number,
	purchases_lte?:number,
	purchases_not?:number,
	purchases_not_in?:number[],
	revenue?:number,
	revenue_gt?:number,
	revenue_gte?:number,
	revenue_in?:number[],
	revenue_lt?:number,
	revenue_lte?:number,
	revenue_not?:number,
	revenue_not_in?:number[],
	source?:PartialObjects["ConsistencySource"],
	source_in?:PartialObjects["ConsistencySource"][],
	source_not?:PartialObjects["ConsistencySource"],
	source_not_in?:PartialObjects["ConsistencySource"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["DateTime"]:any,
	["Error"]: {
		__typename?: "Error";
			createdAt?:PartialObjects["DateTime"],
			id?:string,
			message?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["ErrorOrderByInput"]:ErrorOrderByInput,
	["ErrorWhereInput"]: {
	AND?:PartialObjects["ErrorWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	message?:string,
	message_contains?:string,
	message_ends_with?:string,
	message_gt?:string,
	message_gte?:string,
	message_in?:string[],
	message_lt?:string,
	message_lte?:string,
	message_not?:string,
	message_not_contains?:string,
	message_not_ends_with?:string,
	message_not_in?:string[],
	message_not_starts_with?:string,
	message_starts_with?:string,
	NOT?:PartialObjects["ErrorWhereInput"][],
	OR?:PartialObjects["ErrorWhereInput"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["FacebookAdsAccess"]: {
		__typename?: "FacebookAdsAccess";
			accessToken?:string,
			accountId?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["FacebookAdsAccessCreateInput"]: {
	accessToken:string,
	accountId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["FacebookAdsAccessOrderByInput"]:FacebookAdsAccessOrderByInput,
	["FacebookAdsAccessUpdateInput"]: {
	accessToken?:string,
	accountId?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["FacebookAdsAccessWhereInput"]: {
	accessToken?:string,
	accessToken_contains?:string,
	accessToken_ends_with?:string,
	accessToken_gt?:string,
	accessToken_gte?:string,
	accessToken_in?:string[],
	accessToken_lt?:string,
	accessToken_lte?:string,
	accessToken_not?:string,
	accessToken_not_contains?:string,
	accessToken_not_ends_with?:string,
	accessToken_not_in?:string[],
	accessToken_not_starts_with?:string,
	accessToken_starts_with?:string,
	accountId?:string,
	accountId_contains?:string,
	accountId_ends_with?:string,
	accountId_gt?:string,
	accountId_gte?:string,
	accountId_in?:string[],
	accountId_lt?:string,
	accountId_lte?:string,
	accountId_not?:string,
	accountId_not_contains?:string,
	accountId_not_ends_with?:string,
	accountId_not_in?:string[],
	accountId_not_starts_with?:string,
	accountId_starts_with?:string,
	AND?:PartialObjects["FacebookAdsAccessWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FacebookAdsAccessWhereInput"][],
	OR?:PartialObjects["FacebookAdsAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["FacebookAdsAccessWhereUniqueInput"]: {
	id:string
},
	["FacebookAdsFeed"]: {
		__typename?: "FacebookAdsFeed";
			additionalFields?:string[],
			breakdowns?:PartialObjects["FacebookBreakdown"][],
			facebookAdsAccess?:PartialObjects["FacebookAdsAccess"],
			id?:string,
			levels?:string[],
			omitFields?:string[],
			params?:PartialObjects["IntegrationFeedParams"],
			snapshot?:boolean
	},
	["FacebookAdsFeedCreateWithoutParamsInput"]: {
	additionalFields:string[],
	breakdowns:PartialObjects["FacebookBreakdownCreateInput"][],
	facebookAdsAccess:PartialObjects["FacebookAdsAccessWhereUniqueInput"],
	id?:string,
	levels:string[],
	omitFields:string[],
	snapshot?:boolean
},
	["FacebookAdsFeedWhereInput"]: {
	AND?:PartialObjects["FacebookAdsFeedWhereInput"][],
	breakdowns_every?:PartialObjects["FacebookBreakdownWhereInput"],
	breakdowns_none?:PartialObjects["FacebookBreakdownWhereInput"],
	breakdowns_some?:PartialObjects["FacebookBreakdownWhereInput"],
	facebookAdsAccess?:PartialObjects["FacebookAdsAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FacebookAdsFeedWhereInput"][],
	OR?:PartialObjects["FacebookAdsFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	snapshot?:boolean,
	snapshot_not?:boolean
},
	["FacebookBreakdown"]: {
		__typename?: "FacebookBreakdown";
			id?:string,
			values?:string[]
	},
	["FacebookBreakdownCreateInput"]: {
	values:string[]
},
	["FacebookBreakdownOrderByInput"]:FacebookBreakdownOrderByInput,
	["FacebookBreakdownWhereInput"]: {
	AND?:PartialObjects["FacebookBreakdownWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FacebookBreakdownWhereInput"][],
	OR?:PartialObjects["FacebookBreakdownWhereInput"][]
},
	["FairbidAccess"]: {
		__typename?: "FairbidAccess";
			clientId?:string,
			clientSecret?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["FairbidAccessCreateInput"]: {
	clientId:string,
	clientSecret:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["FairbidAccessOrderByInput"]:FairbidAccessOrderByInput,
	["FairbidAccessUpdateInput"]: {
	clientId?:string,
	clientSecret?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["FairbidAccessWhereInput"]: {
	AND?:PartialObjects["FairbidAccessWhereInput"][],
	clientId?:string,
	clientId_contains?:string,
	clientId_ends_with?:string,
	clientId_gt?:string,
	clientId_gte?:string,
	clientId_in?:string[],
	clientId_lt?:string,
	clientId_lte?:string,
	clientId_not?:string,
	clientId_not_contains?:string,
	clientId_not_ends_with?:string,
	clientId_not_in?:string[],
	clientId_not_starts_with?:string,
	clientId_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FairbidAccessWhereInput"][],
	OR?:PartialObjects["FairbidAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["FairbidAccessWhereUniqueInput"]: {
	id:string
},
	["FairbidUserReportFeed"]: {
		__typename?: "FairbidUserReportFeed";
			appId?:string,
			fairbidAccess?:PartialObjects["FairbidAccess"],
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["FairbidUserReportFeedCreateWithoutParamsInput"]: {
	appId:string,
	fairbidAccess:PartialObjects["FairbidAccessWhereUniqueInput"],
	id?:string
},
	["FairbidUserReportFeedWhereInput"]: {
	AND?:PartialObjects["FairbidUserReportFeedWhereInput"][],
	appId?:string,
	appId_contains?:string,
	appId_ends_with?:string,
	appId_gt?:string,
	appId_gte?:string,
	appId_in?:string[],
	appId_lt?:string,
	appId_lte?:string,
	appId_not?:string,
	appId_not_contains?:string,
	appId_not_ends_with?:string,
	appId_not_in?:string[],
	appId_not_starts_with?:string,
	appId_starts_with?:string,
	fairbidAccess?:PartialObjects["FairbidAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FairbidUserReportFeedWhereInput"][],
	OR?:PartialObjects["FairbidUserReportFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["Feed"]:Feed,
	["FeedDateInput"]: {
		__typename?: "FeedDateInput";
			dateDashFrom?:string,
			dateDashTo?:string,
			daysInPast?:number,
			id?:string,
			offset?:number
	},
	["FeedDateInputCreateInput"]: {
	dateDashFrom?:string,
	dateDashTo?:string,
	daysInPast?:number,
	id?:string,
	offset?:number
},
	["FeedDateInputWhereInput"]: {
	AND?:PartialObjects["FeedDateInputWhereInput"][],
	dateDashFrom?:string,
	dateDashFrom_contains?:string,
	dateDashFrom_ends_with?:string,
	dateDashFrom_gt?:string,
	dateDashFrom_gte?:string,
	dateDashFrom_in?:string[],
	dateDashFrom_lt?:string,
	dateDashFrom_lte?:string,
	dateDashFrom_not?:string,
	dateDashFrom_not_contains?:string,
	dateDashFrom_not_ends_with?:string,
	dateDashFrom_not_in?:string[],
	dateDashFrom_not_starts_with?:string,
	dateDashFrom_starts_with?:string,
	dateDashTo?:string,
	dateDashTo_contains?:string,
	dateDashTo_ends_with?:string,
	dateDashTo_gt?:string,
	dateDashTo_gte?:string,
	dateDashTo_in?:string[],
	dateDashTo_lt?:string,
	dateDashTo_lte?:string,
	dateDashTo_not?:string,
	dateDashTo_not_contains?:string,
	dateDashTo_not_ends_with?:string,
	dateDashTo_not_in?:string[],
	dateDashTo_not_starts_with?:string,
	dateDashTo_starts_with?:string,
	daysInPast?:number,
	daysInPast_gt?:number,
	daysInPast_gte?:number,
	daysInPast_in?:number[],
	daysInPast_lt?:number,
	daysInPast_lte?:number,
	daysInPast_not?:number,
	daysInPast_not_in?:number[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FeedDateInputWhereInput"][],
	offset?:number,
	offset_gt?:number,
	offset_gte?:number,
	offset_in?:number[],
	offset_lt?:number,
	offset_lte?:number,
	offset_not?:number,
	offset_not_in?:number[],
	OR?:PartialObjects["FeedDateInputWhereInput"][]
},
	["FixerFeed"]: {
		__typename?: "FixerFeed";
			currency?:string,
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["FixerFeedCreateWithoutParamsInput"]: {
	currency:string,
	id?:string
},
	["FixerFeedWhereInput"]: {
	AND?:PartialObjects["FixerFeedWhereInput"][],
	currency?:string,
	currency_contains?:string,
	currency_ends_with?:string,
	currency_gt?:string,
	currency_gte?:string,
	currency_in?:string[],
	currency_lt?:string,
	currency_lte?:string,
	currency_not?:string,
	currency_not_contains?:string,
	currency_not_ends_with?:string,
	currency_not_in?:string[],
	currency_not_starts_with?:string,
	currency_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["FixerFeedWhereInput"][],
	OR?:PartialObjects["FixerFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["GoogleAdsAccess"]: {
		__typename?: "GoogleAdsAccess";
			clientId?:string,
			clientSecret?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			developerToken?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["GoogleAdsAccessCreateInput"]: {
	clientId:string,
	clientSecret:string,
	descriptiveName:string,
	developerToken:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["GoogleAdsAccessOrderByInput"]:GoogleAdsAccessOrderByInput,
	["GoogleAdsAccessUpdateInput"]: {
	clientId?:string,
	clientSecret?:string,
	descriptiveName?:string,
	developerToken?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["GoogleAdsAccessWhereInput"]: {
	AND?:PartialObjects["GoogleAdsAccessWhereInput"][],
	clientId?:string,
	clientId_contains?:string,
	clientId_ends_with?:string,
	clientId_gt?:string,
	clientId_gte?:string,
	clientId_in?:string[],
	clientId_lt?:string,
	clientId_lte?:string,
	clientId_not?:string,
	clientId_not_contains?:string,
	clientId_not_ends_with?:string,
	clientId_not_in?:string[],
	clientId_not_starts_with?:string,
	clientId_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	developerToken?:string,
	developerToken_contains?:string,
	developerToken_ends_with?:string,
	developerToken_gt?:string,
	developerToken_gte?:string,
	developerToken_in?:string[],
	developerToken_lt?:string,
	developerToken_lte?:string,
	developerToken_not?:string,
	developerToken_not_contains?:string,
	developerToken_not_ends_with?:string,
	developerToken_not_in?:string[],
	developerToken_not_starts_with?:string,
	developerToken_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["GoogleAdsAccessWhereInput"][],
	OR?:PartialObjects["GoogleAdsAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["GoogleAdsAccessWhereUniqueInput"]: {
	id:string
},
	["GoogleAdwordsFeed"]: {
		__typename?: "GoogleAdwordsFeed";
			customerAccountId?:string,
			geographicView?:boolean,
			googleAdsAccess?:PartialObjects["GoogleAdsAccess"],
			id?:string,
			loginCustomerId?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			refreshToken?:string
	},
	["GoogleAdwordsFeedCreateWithoutParamsInput"]: {
	customerAccountId:string,
	geographicView?:boolean,
	googleAdsAccess:PartialObjects["GoogleAdsAccessWhereUniqueInput"],
	id?:string,
	loginCustomerId:string,
	refreshToken:string
},
	["GoogleAdwordsFeedWhereInput"]: {
	AND?:PartialObjects["GoogleAdwordsFeedWhereInput"][],
	customerAccountId?:string,
	customerAccountId_contains?:string,
	customerAccountId_ends_with?:string,
	customerAccountId_gt?:string,
	customerAccountId_gte?:string,
	customerAccountId_in?:string[],
	customerAccountId_lt?:string,
	customerAccountId_lte?:string,
	customerAccountId_not?:string,
	customerAccountId_not_contains?:string,
	customerAccountId_not_ends_with?:string,
	customerAccountId_not_in?:string[],
	customerAccountId_not_starts_with?:string,
	customerAccountId_starts_with?:string,
	geographicView?:boolean,
	geographicView_not?:boolean,
	googleAdsAccess?:PartialObjects["GoogleAdsAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	loginCustomerId?:string,
	loginCustomerId_contains?:string,
	loginCustomerId_ends_with?:string,
	loginCustomerId_gt?:string,
	loginCustomerId_gte?:string,
	loginCustomerId_in?:string[],
	loginCustomerId_lt?:string,
	loginCustomerId_lte?:string,
	loginCustomerId_not?:string,
	loginCustomerId_not_contains?:string,
	loginCustomerId_not_ends_with?:string,
	loginCustomerId_not_in?:string[],
	loginCustomerId_not_starts_with?:string,
	loginCustomerId_starts_with?:string,
	NOT?:PartialObjects["GoogleAdwordsFeedWhereInput"][],
	OR?:PartialObjects["GoogleAdwordsFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string
},
	["GooglePlayAccess"]: {
		__typename?: "GooglePlayAccess";
			bucketId?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			packageIdentifier?:string,
			sourceClientEmail?:string,
			sourcePrivateKey?:string,
			sourceProjectId?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["GooglePlayAccessCreateInput"]: {
	bucketId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	packageIdentifier?:string,
	sourceClientEmail:string,
	sourcePrivateKey:string,
	sourceProjectId:string
},
	["GooglePlayAccessOrderByInput"]:GooglePlayAccessOrderByInput,
	["GooglePlayAccessUpdateInput"]: {
	bucketId?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	packageIdentifier?:string,
	sourceClientEmail?:string,
	sourcePrivateKey?:string,
	sourceProjectId?:string
},
	["GooglePlayAccessWhereInput"]: {
	AND?:PartialObjects["GooglePlayAccessWhereInput"][],
	bucketId?:string,
	bucketId_contains?:string,
	bucketId_ends_with?:string,
	bucketId_gt?:string,
	bucketId_gte?:string,
	bucketId_in?:string[],
	bucketId_lt?:string,
	bucketId_lte?:string,
	bucketId_not?:string,
	bucketId_not_contains?:string,
	bucketId_not_ends_with?:string,
	bucketId_not_in?:string[],
	bucketId_not_starts_with?:string,
	bucketId_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["GooglePlayAccessWhereInput"][],
	OR?:PartialObjects["GooglePlayAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	packageIdentifier?:string,
	packageIdentifier_contains?:string,
	packageIdentifier_ends_with?:string,
	packageIdentifier_gt?:string,
	packageIdentifier_gte?:string,
	packageIdentifier_in?:string[],
	packageIdentifier_lt?:string,
	packageIdentifier_lte?:string,
	packageIdentifier_not?:string,
	packageIdentifier_not_contains?:string,
	packageIdentifier_not_ends_with?:string,
	packageIdentifier_not_in?:string[],
	packageIdentifier_not_starts_with?:string,
	packageIdentifier_starts_with?:string,
	sourceClientEmail?:string,
	sourceClientEmail_contains?:string,
	sourceClientEmail_ends_with?:string,
	sourceClientEmail_gt?:string,
	sourceClientEmail_gte?:string,
	sourceClientEmail_in?:string[],
	sourceClientEmail_lt?:string,
	sourceClientEmail_lte?:string,
	sourceClientEmail_not?:string,
	sourceClientEmail_not_contains?:string,
	sourceClientEmail_not_ends_with?:string,
	sourceClientEmail_not_in?:string[],
	sourceClientEmail_not_starts_with?:string,
	sourceClientEmail_starts_with?:string,
	sourcePrivateKey?:string,
	sourcePrivateKey_contains?:string,
	sourcePrivateKey_ends_with?:string,
	sourcePrivateKey_gt?:string,
	sourcePrivateKey_gte?:string,
	sourcePrivateKey_in?:string[],
	sourcePrivateKey_lt?:string,
	sourcePrivateKey_lte?:string,
	sourcePrivateKey_not?:string,
	sourcePrivateKey_not_contains?:string,
	sourcePrivateKey_not_ends_with?:string,
	sourcePrivateKey_not_in?:string[],
	sourcePrivateKey_not_starts_with?:string,
	sourcePrivateKey_starts_with?:string,
	sourceProjectId?:string,
	sourceProjectId_contains?:string,
	sourceProjectId_ends_with?:string,
	sourceProjectId_gt?:string,
	sourceProjectId_gte?:string,
	sourceProjectId_in?:string[],
	sourceProjectId_lt?:string,
	sourceProjectId_lte?:string,
	sourceProjectId_not?:string,
	sourceProjectId_not_contains?:string,
	sourceProjectId_not_ends_with?:string,
	sourceProjectId_not_in?:string[],
	sourceProjectId_not_starts_with?:string,
	sourceProjectId_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["GooglePlayAccessWhereUniqueInput"]: {
	id:string
},
	["GooglePlayFeed"]: {
		__typename?: "GooglePlayFeed";
			googlePlayAccess?:PartialObjects["GooglePlayAccess"],
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["GooglePlayFeedCreateWithoutParamsInput"]: {
	googlePlayAccess:PartialObjects["GooglePlayAccessWhereUniqueInput"],
	id?:string
},
	["GooglePlayFeedWhereInput"]: {
	AND?:PartialObjects["GooglePlayFeedWhereInput"][],
	googlePlayAccess?:PartialObjects["GooglePlayAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["GooglePlayFeedWhereInput"][],
	OR?:PartialObjects["GooglePlayFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["HarvestAccess"]: {
		__typename?: "HarvestAccess";
			accountId?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			token?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["HarvestAccessCreateInput"]: {
	accountId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	token:string
},
	["HarvestAccessOrderByInput"]:HarvestAccessOrderByInput,
	["HarvestAccessUpdateInput"]: {
	accountId?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	token?:string
},
	["HarvestAccessWhereInput"]: {
	accountId?:string,
	accountId_contains?:string,
	accountId_ends_with?:string,
	accountId_gt?:string,
	accountId_gte?:string,
	accountId_in?:string[],
	accountId_lt?:string,
	accountId_lte?:string,
	accountId_not?:string,
	accountId_not_contains?:string,
	accountId_not_ends_with?:string,
	accountId_not_in?:string[],
	accountId_not_starts_with?:string,
	accountId_starts_with?:string,
	AND?:PartialObjects["HarvestAccessWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["HarvestAccessWhereInput"][],
	OR?:PartialObjects["HarvestAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	token?:string,
	token_contains?:string,
	token_ends_with?:string,
	token_gt?:string,
	token_gte?:string,
	token_in?:string[],
	token_lt?:string,
	token_lte?:string,
	token_not?:string,
	token_not_contains?:string,
	token_not_ends_with?:string,
	token_not_in?:string[],
	token_not_starts_with?:string,
	token_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["HarvestAccessWhereUniqueInput"]: {
	id:string
},
	["HarvestFeed"]: {
		__typename?: "HarvestFeed";
			harvestAccess?:PartialObjects["HarvestAccess"],
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			updatedSince?:string,
			updatedSinceDaysInPast?:number,
			updatedSinceOffset?:number
	},
	["HarvestFeedCreateWithoutParamsInput"]: {
	harvestAccess:PartialObjects["HarvestAccessWhereUniqueInput"],
	id?:string,
	updatedSince?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceOffset?:number
},
	["HarvestFeedWhereInput"]: {
	AND?:PartialObjects["HarvestFeedWhereInput"][],
	harvestAccess?:PartialObjects["HarvestAccessWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["HarvestFeedWhereInput"][],
	OR?:PartialObjects["HarvestFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	updatedSince?:string,
	updatedSince_contains?:string,
	updatedSince_ends_with?:string,
	updatedSince_gt?:string,
	updatedSince_gte?:string,
	updatedSince_in?:string[],
	updatedSince_lt?:string,
	updatedSince_lte?:string,
	updatedSince_not?:string,
	updatedSince_not_contains?:string,
	updatedSince_not_ends_with?:string,
	updatedSince_not_in?:string[],
	updatedSince_not_starts_with?:string,
	updatedSince_starts_with?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceDaysInPast_gt?:number,
	updatedSinceDaysInPast_gte?:number,
	updatedSinceDaysInPast_in?:number[],
	updatedSinceDaysInPast_lt?:number,
	updatedSinceDaysInPast_lte?:number,
	updatedSinceDaysInPast_not?:number,
	updatedSinceDaysInPast_not_in?:number[],
	updatedSinceOffset?:number,
	updatedSinceOffset_gt?:number,
	updatedSinceOffset_gte?:number,
	updatedSinceOffset_in?:number[],
	updatedSinceOffset_lt?:number,
	updatedSinceOffset_lte?:number,
	updatedSinceOffset_not?:number,
	updatedSinceOffset_not_in?:number[]
},
	["InstanceState"]:InstanceState,
	["IntegrationFeedConfig"]: {
		__typename?: "IntegrationFeedConfig";
			createdAt?:PartialObjects["DateTime"],
			eligibleAt?:string,
			enabled?:boolean,
			id?:string,
			instances?:PartialObjects["IntegrationFeedInstance"][],
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			params?:PartialObjects["IntegrationFeedParams"],
			updatedAt?:PartialObjects["DateTime"]
	},
	["IntegrationFeedConfigConnection"]: {
		__typename?: "IntegrationFeedConfigConnection";
			aggregate?:PartialObjects["AggregateIntegrationFeedConfig"],
			edges?:PartialObjects["IntegrationFeedConfigEdge"][],
			pageInfo?:PartialObjects["PageInfo"]
	},
	["IntegrationFeedConfigCreateInput"]: {
	eligibleAt:string,
	enabled?:boolean,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	params:PartialObjects["IntegrationFeedParamsCreateInput"]
},
	["IntegrationFeedConfigEdge"]: {
		__typename?: "IntegrationFeedConfigEdge";
			cursor?:string,
			node?:PartialObjects["IntegrationFeedConfig"]
	},
	["IntegrationFeedConfigOrderByInput"]:IntegrationFeedConfigOrderByInput,
	["IntegrationFeedConfigUpdateInput"]: {
	eligibleAt?:string,
	enabled?:boolean,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	params?:PartialObjects["IntegrationFeedParamsUpdateInput"]
},
	["IntegrationFeedConfigWhereInput"]: {
	AND?:PartialObjects["IntegrationFeedConfigWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	eligibleAt?:string,
	eligibleAt_contains?:string,
	eligibleAt_ends_with?:string,
	eligibleAt_gt?:string,
	eligibleAt_gte?:string,
	eligibleAt_in?:string[],
	eligibleAt_lt?:string,
	eligibleAt_lte?:string,
	eligibleAt_not?:string,
	eligibleAt_not_contains?:string,
	eligibleAt_not_ends_with?:string,
	eligibleAt_not_in?:string[],
	eligibleAt_not_starts_with?:string,
	eligibleAt_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	instances_every?:PartialObjects["IntegrationFeedInstanceWhereInput"],
	instances_none?:PartialObjects["IntegrationFeedInstanceWhereInput"],
	instances_some?:PartialObjects["IntegrationFeedInstanceWhereInput"],
	NOT?:PartialObjects["IntegrationFeedConfigWhereInput"][],
	OR?:PartialObjects["IntegrationFeedConfigWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["IntegrationFeedConfigWhereUniqueInput"]: {
	id:string
},
	["IntegrationFeedInstance"]: {
		__typename?: "IntegrationFeedInstance";
			config?:PartialObjects["IntegrationFeedConfig"],
			createdAt?:PartialObjects["DateTime"],
			failureError?:PartialObjects["Error"],
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			params?:PartialObjects["IntegrationFeedParams"],
			retryErrors?:PartialObjects["Error"][],
			state?:PartialObjects["InstanceState"],
			stateMachineExecutionArn?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["IntegrationFeedInstanceConnection"]: {
		__typename?: "IntegrationFeedInstanceConnection";
			aggregate?:PartialObjects["AggregateIntegrationFeedInstance"],
			edges?:PartialObjects["IntegrationFeedInstanceEdge"][],
			pageInfo?:PartialObjects["PageInfo"]
	},
	["IntegrationFeedInstanceCreateInput"]: {
	config?:PartialObjects["IntegrationFeedConfigWhereUniqueInput"],
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	params:PartialObjects["IntegrationFeedParamsCreateInput"]
},
	["IntegrationFeedInstanceEdge"]: {
		__typename?: "IntegrationFeedInstanceEdge";
			cursor?:string,
			node?:PartialObjects["IntegrationFeedInstance"]
	},
	["IntegrationFeedInstanceOrderByInput"]:IntegrationFeedInstanceOrderByInput,
	["IntegrationFeedInstanceWhereInput"]: {
	AND?:PartialObjects["IntegrationFeedInstanceWhereInput"][],
	config?:PartialObjects["IntegrationFeedConfigWhereInput"],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	failureError?:PartialObjects["ErrorWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["IntegrationFeedInstanceWhereInput"][],
	OR?:PartialObjects["IntegrationFeedInstanceWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	retryErrors_every?:PartialObjects["ErrorWhereInput"],
	retryErrors_none?:PartialObjects["ErrorWhereInput"],
	retryErrors_some?:PartialObjects["ErrorWhereInput"],
	state?:PartialObjects["InstanceState"],
	state_in?:PartialObjects["InstanceState"][],
	state_not?:PartialObjects["InstanceState"],
	state_not_in?:PartialObjects["InstanceState"][],
	stateMachineExecutionArn?:string,
	stateMachineExecutionArn_contains?:string,
	stateMachineExecutionArn_ends_with?:string,
	stateMachineExecutionArn_gt?:string,
	stateMachineExecutionArn_gte?:string,
	stateMachineExecutionArn_in?:string[],
	stateMachineExecutionArn_lt?:string,
	stateMachineExecutionArn_lte?:string,
	stateMachineExecutionArn_not?:string,
	stateMachineExecutionArn_not_contains?:string,
	stateMachineExecutionArn_not_ends_with?:string,
	stateMachineExecutionArn_not_in?:string[],
	stateMachineExecutionArn_not_starts_with?:string,
	stateMachineExecutionArn_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["IntegrationFeedInstanceWhereUniqueInput"]: {
	id?:string,
	stateMachineExecutionArn?:string
},
	["IntegrationFeedParams"]: {
		__typename?: "IntegrationFeedParams";
			apple?:PartialObjects["AppleFeed"],
			appleSearchAds?:PartialObjects["AppleSearchAdsFeed"],
			applovin?:PartialObjects["ApplovinFeed"],
			appsFlyerCampaign?:PartialObjects["AppsFlyerCampaignFeed"],
			appsFlyerDatalocker?:PartialObjects["AppsFlyerDatalockerFeed"],
			appsFlyerRawEvents?:PartialObjects["AppsFlyerRawEventsFeed"],
			appsFlyerRawInstalls?:PartialObjects["AppsFlyerRawInstallsFeed"],
			appsFlyerScraper?:PartialObjects["AppsFlyerScraperFeed"],
			bqDestinations?:PartialObjects["BigQueryDestination"][],
			chartboost?:PartialObjects["ChartboostFeed"],
			dateInput?:PartialObjects["FeedDateInput"],
			descriptiveName?:string,
			facebookAds?:PartialObjects["FacebookAdsFeed"],
			fairbidUserReport?:PartialObjects["FairbidUserReportFeed"],
			feedType?:PartialObjects["Feed"],
			fixer?:PartialObjects["FixerFeed"],
			googleAdwords?:PartialObjects["GoogleAdwordsFeed"],
			googlePlay?:PartialObjects["GooglePlayFeed"],
			harvest?:PartialObjects["HarvestFeed"],
			id?:string,
			ironSource?:PartialObjects["IronSourceFeed"],
			ironSourcePromotion?:PartialObjects["IronSourcePromotionFeed"],
			libring?:PartialObjects["LibringFeed"],
			s3Destinations?:PartialObjects["S3Destination"][],
			snapchat?:PartialObjects["SnapchatFeed"],
			tiktokAdsAudience?:PartialObjects["TiktokAdsAudienceFeed"],
			unityAds?:PartialObjects["UnityAdsFeed"],
			unityAdvertiser?:PartialObjects["UnityAdvertiserFeed"]
	},
	["IntegrationFeedParamsCreateInput"]: {
	apple?:PartialObjects["AppleFeedCreateWithoutParamsInput"],
	appleSearchAds?:PartialObjects["AppleSearchAdsFeedCreateWithoutParamsInput"],
	applovin?:PartialObjects["ApplovinFeedCreateWithoutParamsInput"],
	appsFlyerCampaign?:PartialObjects["AppsFlyerCampaignFeedCreateWithoutParamsInput"],
	appsFlyerDatalocker?:PartialObjects["AppsFlyerDatalockerFeedCreateWithoutParamsInput"],
	appsFlyerRawEvents?:PartialObjects["AppsFlyerRawEventsFeedCreateWithoutParamsInput"],
	appsFlyerRawInstalls?:PartialObjects["AppsFlyerRawInstallsFeedCreateWithoutParamsInput"],
	appsFlyerScraper?:PartialObjects["AppsFlyerScraperFeedCreateWithoutParamsInput"],
	bqDestinations:PartialObjects["BigQueryDestinationCreateInput"][],
	chartboost?:PartialObjects["ChartboostFeedCreateWithoutParamsInput"],
	dateInput?:PartialObjects["FeedDateInputCreateInput"],
	descriptiveName:string,
	facebookAds?:PartialObjects["FacebookAdsFeedCreateWithoutParamsInput"],
	fairbidUserReport?:PartialObjects["FairbidUserReportFeedCreateWithoutParamsInput"],
	feedType:PartialObjects["Feed"],
	fixer?:PartialObjects["FixerFeedCreateWithoutParamsInput"],
	googleAdwords?:PartialObjects["GoogleAdwordsFeedCreateWithoutParamsInput"],
	googlePlay?:PartialObjects["GooglePlayFeedCreateWithoutParamsInput"],
	harvest?:PartialObjects["HarvestFeedCreateWithoutParamsInput"],
	ironSource?:PartialObjects["IronSourceFeedCreateWithoutParamsInput"],
	ironSourcePromotion?:PartialObjects["IronSourcePromotionFeedCreateWithoutParamsInput"],
	libring?:PartialObjects["LibringFeedCreateWithoutParamsInput"],
	s3Destinations:PartialObjects["S3DestinationCreateInput"][],
	snapchat?:PartialObjects["SnapchatFeedCreateWithoutParamsInput"],
	tiktokAdsAudience?:PartialObjects["TiktokAdsAudienceFeedCreateWithoutParamsInput"],
	unityAds?:PartialObjects["UnityAdsFeedCreateWithoutParamsInput"],
	unityAdvertiser?:PartialObjects["UnityAdvertiserFeedCreateWithoutParamsInput"]
},
	["IntegrationFeedParamsUpdateInput"]: {
	apple?:PartialObjects["AppleFeedCreateWithoutParamsInput"],
	appleSearchAds?:PartialObjects["AppleSearchAdsFeedCreateWithoutParamsInput"],
	applovin?:PartialObjects["ApplovinFeedCreateWithoutParamsInput"],
	appsFlyerCampaign?:PartialObjects["AppsFlyerCampaignFeedCreateWithoutParamsInput"],
	appsFlyerDatalocker?:PartialObjects["AppsFlyerDatalockerFeedCreateWithoutParamsInput"],
	appsFlyerRawEvents?:PartialObjects["AppsFlyerRawEventsFeedCreateWithoutParamsInput"],
	appsFlyerRawInstalls?:PartialObjects["AppsFlyerRawInstallsFeedCreateWithoutParamsInput"],
	appsFlyerScraper?:PartialObjects["AppsFlyerScraperFeedCreateWithoutParamsInput"],
	bqDestinations?:PartialObjects["BigQueryDestinationUpdateManyInput"],
	chartboost?:PartialObjects["ChartboostFeedCreateWithoutParamsInput"],
	dateInput?:PartialObjects["FeedDateInputCreateInput"],
	descriptiveName?:string,
	facebookAds?:PartialObjects["FacebookAdsFeedCreateWithoutParamsInput"],
	fairbidUserReport?:PartialObjects["FairbidUserReportFeedCreateWithoutParamsInput"],
	feedType?:PartialObjects["Feed"],
	fixer?:PartialObjects["FixerFeedCreateWithoutParamsInput"],
	googleAdwords?:PartialObjects["GoogleAdwordsFeedCreateWithoutParamsInput"],
	googlePlay?:PartialObjects["GooglePlayFeedCreateWithoutParamsInput"],
	harvest?:PartialObjects["HarvestFeedCreateWithoutParamsInput"],
	ironSource?:PartialObjects["IronSourceFeedCreateWithoutParamsInput"],
	ironSourcePromotion?:PartialObjects["IronSourcePromotionFeedCreateWithoutParamsInput"],
	libring?:PartialObjects["LibringFeedCreateWithoutParamsInput"],
	s3Destinations?:PartialObjects["S3DestinationUpdateManyInput"],
	snapchat?:PartialObjects["SnapchatFeedCreateWithoutParamsInput"],
	tiktokAdsAudience?:PartialObjects["TiktokAdsAudienceFeedCreateWithoutParamsInput"],
	unityAds?:PartialObjects["UnityAdsFeedCreateWithoutParamsInput"],
	unityAdvertiser?:PartialObjects["UnityAdvertiserFeedCreateWithoutParamsInput"]
},
	["IntegrationFeedParamsWhereInput"]: {
	AND?:PartialObjects["IntegrationFeedParamsWhereInput"][],
	apple?:PartialObjects["AppleFeedWhereInput"],
	appleSearchAds?:PartialObjects["AppleSearchAdsFeedWhereInput"],
	applovin?:PartialObjects["ApplovinFeedWhereInput"],
	appsFlyerCampaign?:PartialObjects["AppsFlyerCampaignFeedWhereInput"],
	appsFlyerDatalocker?:PartialObjects["AppsFlyerDatalockerFeedWhereInput"],
	appsFlyerRawEvents?:PartialObjects["AppsFlyerRawEventsFeedWhereInput"],
	appsFlyerRawInstalls?:PartialObjects["AppsFlyerRawInstallsFeedWhereInput"],
	appsFlyerScraper?:PartialObjects["AppsFlyerScraperFeedWhereInput"],
	bqDestinations_every?:PartialObjects["BigQueryDestinationWhereInput"],
	bqDestinations_none?:PartialObjects["BigQueryDestinationWhereInput"],
	bqDestinations_some?:PartialObjects["BigQueryDestinationWhereInput"],
	chartboost?:PartialObjects["ChartboostFeedWhereInput"],
	dateInput?:PartialObjects["FeedDateInputWhereInput"],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	facebookAds?:PartialObjects["FacebookAdsFeedWhereInput"],
	fairbidUserReport?:PartialObjects["FairbidUserReportFeedWhereInput"],
	feedType?:PartialObjects["Feed"],
	feedType_in?:PartialObjects["Feed"][],
	feedType_not?:PartialObjects["Feed"],
	feedType_not_in?:PartialObjects["Feed"][],
	fixer?:PartialObjects["FixerFeedWhereInput"],
	googleAdwords?:PartialObjects["GoogleAdwordsFeedWhereInput"],
	googlePlay?:PartialObjects["GooglePlayFeedWhereInput"],
	harvest?:PartialObjects["HarvestFeedWhereInput"],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSource?:PartialObjects["IronSourceFeedWhereInput"],
	ironSourcePromotion?:PartialObjects["IronSourcePromotionFeedWhereInput"],
	libring?:PartialObjects["LibringFeedWhereInput"],
	NOT?:PartialObjects["IntegrationFeedParamsWhereInput"][],
	OR?:PartialObjects["IntegrationFeedParamsWhereInput"][],
	s3Destinations_every?:PartialObjects["S3DestinationWhereInput"],
	s3Destinations_none?:PartialObjects["S3DestinationWhereInput"],
	s3Destinations_some?:PartialObjects["S3DestinationWhereInput"],
	snapchat?:PartialObjects["SnapchatFeedWhereInput"],
	tiktokAdsAudience?:PartialObjects["TiktokAdsAudienceFeedWhereInput"],
	unityAds?:PartialObjects["UnityAdsFeedWhereInput"],
	unityAdvertiser?:PartialObjects["UnityAdvertiserFeedWhereInput"]
},
	["IronSourceAccess"]: {
		__typename?: "IronSourceAccess";
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			refreshToken?:string,
			secretKey?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["IronSourceAccessCreateInput"]: {
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	refreshToken:string,
	secretKey:string
},
	["IronSourceAccessOrderByInput"]:IronSourceAccessOrderByInput,
	["IronSourceAccessUpdateInput"]: {
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	refreshToken?:string,
	secretKey?:string
},
	["IronSourceAccessWhereInput"]: {
	AND?:PartialObjects["IronSourceAccessWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["IronSourceAccessWhereInput"][],
	OR?:PartialObjects["IronSourceAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string,
	secretKey?:string,
	secretKey_contains?:string,
	secretKey_ends_with?:string,
	secretKey_gt?:string,
	secretKey_gte?:string,
	secretKey_in?:string[],
	secretKey_lt?:string,
	secretKey_lte?:string,
	secretKey_not?:string,
	secretKey_not_contains?:string,
	secretKey_not_ends_with?:string,
	secretKey_not_in?:string[],
	secretKey_not_starts_with?:string,
	secretKey_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["IronSourceAccessWhereUniqueInput"]: {
	id:string
},
	["IronSourceFeed"]: {
		__typename?: "IronSourceFeed";
			adRevenue?:boolean,
			appKey?:string,
			id?:string,
			ironSourceAccess?:PartialObjects["IronSourceAccess"],
			params?:PartialObjects["IntegrationFeedParams"],
			reportBreakdowns?:string[],
			reportMetrics?:string[],
			reports?:boolean
	},
	["IronSourceFeedCreateWithoutParamsInput"]: {
	adRevenue?:boolean,
	appKey:string,
	id?:string,
	ironSourceAccess:PartialObjects["IronSourceAccessWhereUniqueInput"],
	reportBreakdowns:string[],
	reportMetrics:string[],
	reports?:boolean
},
	["IronSourceFeedWhereInput"]: {
	adRevenue?:boolean,
	adRevenue_not?:boolean,
	AND?:PartialObjects["IronSourceFeedWhereInput"][],
	appKey?:string,
	appKey_contains?:string,
	appKey_ends_with?:string,
	appKey_gt?:string,
	appKey_gte?:string,
	appKey_in?:string[],
	appKey_lt?:string,
	appKey_lte?:string,
	appKey_not?:string,
	appKey_not_contains?:string,
	appKey_not_ends_with?:string,
	appKey_not_in?:string[],
	appKey_not_starts_with?:string,
	appKey_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSourceAccess?:PartialObjects["IronSourceAccessWhereInput"],
	NOT?:PartialObjects["IronSourceFeedWhereInput"][],
	OR?:PartialObjects["IronSourceFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reports?:boolean,
	reports_not?:boolean
},
	["IronSourcePromotionDeviceType"]:IronSourcePromotionDeviceType,
	["IronSourcePromotionFeed"]: {
		__typename?: "IronSourcePromotionFeed";
			adUnit?:string,
			breakdowns?:string[],
			bundleIds?:string[],
			campaignIds?:number[],
			countries?:string[],
			creativeIds?:number[],
			deviceType?:PartialObjects["IronSourcePromotionDeviceType"],
			id?:string,
			ironSourceAccess?:PartialObjects["IronSourceAccess"],
			metrics?:string[],
			os?:PartialObjects["IronSourcePromotionOsType"],
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["IronSourcePromotionFeedCreateWithoutParamsInput"]: {
	adUnit?:string,
	breakdowns:string[],
	bundleIds:string[],
	campaignIds:number[],
	countries:string[],
	creativeIds:number[],
	deviceType?:PartialObjects["IronSourcePromotionDeviceType"],
	id?:string,
	ironSourceAccess:PartialObjects["IronSourceAccessWhereUniqueInput"],
	metrics:string[],
	os?:PartialObjects["IronSourcePromotionOsType"]
},
	["IronSourcePromotionFeedWhereInput"]: {
	adUnit?:string,
	adUnit_contains?:string,
	adUnit_ends_with?:string,
	adUnit_gt?:string,
	adUnit_gte?:string,
	adUnit_in?:string[],
	adUnit_lt?:string,
	adUnit_lte?:string,
	adUnit_not?:string,
	adUnit_not_contains?:string,
	adUnit_not_ends_with?:string,
	adUnit_not_in?:string[],
	adUnit_not_starts_with?:string,
	adUnit_starts_with?:string,
	AND?:PartialObjects["IronSourcePromotionFeedWhereInput"][],
	deviceType?:PartialObjects["IronSourcePromotionDeviceType"],
	deviceType_in?:PartialObjects["IronSourcePromotionDeviceType"][],
	deviceType_not?:PartialObjects["IronSourcePromotionDeviceType"],
	deviceType_not_in?:PartialObjects["IronSourcePromotionDeviceType"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSourceAccess?:PartialObjects["IronSourceAccessWhereInput"],
	NOT?:PartialObjects["IronSourcePromotionFeedWhereInput"][],
	OR?:PartialObjects["IronSourcePromotionFeedWhereInput"][],
	os?:PartialObjects["IronSourcePromotionOsType"],
	os_in?:PartialObjects["IronSourcePromotionOsType"][],
	os_not?:PartialObjects["IronSourcePromotionOsType"],
	os_not_in?:PartialObjects["IronSourcePromotionOsType"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["IronSourcePromotionOsType"]:IronSourcePromotionOsType,
	["LibringAccess"]: {
		__typename?: "LibringAccess";
			apiToken?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["LibringAccessCreateInput"]: {
	apiToken:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["LibringAccessOrderByInput"]:LibringAccessOrderByInput,
	["LibringAccessUpdateInput"]: {
	apiToken?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["LibringAccessWhereInput"]: {
	AND?:PartialObjects["LibringAccessWhereInput"][],
	apiToken?:string,
	apiToken_contains?:string,
	apiToken_ends_with?:string,
	apiToken_gt?:string,
	apiToken_gte?:string,
	apiToken_in?:string[],
	apiToken_lt?:string,
	apiToken_lte?:string,
	apiToken_not?:string,
	apiToken_not_contains?:string,
	apiToken_not_ends_with?:string,
	apiToken_not_in?:string[],
	apiToken_not_starts_with?:string,
	apiToken_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["LibringAccessWhereInput"][],
	OR?:PartialObjects["LibringAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["LibringAccessWhereUniqueInput"]: {
	id:string
},
	["LibringFeed"]: {
		__typename?: "LibringFeed";
			appName?:string,
			dataType?:string,
			id?:string,
			libringAccess?:PartialObjects["LibringAccess"],
			params?:PartialObjects["IntegrationFeedParams"]
	},
	["LibringFeedCreateWithoutParamsInput"]: {
	appName:string,
	dataType:string,
	id?:string,
	libringAccess:PartialObjects["LibringAccessWhereUniqueInput"]
},
	["LibringFeedWhereInput"]: {
	AND?:PartialObjects["LibringFeedWhereInput"][],
	appName?:string,
	appName_contains?:string,
	appName_ends_with?:string,
	appName_gt?:string,
	appName_gte?:string,
	appName_in?:string[],
	appName_lt?:string,
	appName_lte?:string,
	appName_not?:string,
	appName_not_contains?:string,
	appName_not_ends_with?:string,
	appName_not_in?:string[],
	appName_not_starts_with?:string,
	appName_starts_with?:string,
	dataType?:string,
	dataType_contains?:string,
	dataType_ends_with?:string,
	dataType_gt?:string,
	dataType_gte?:string,
	dataType_in?:string[],
	dataType_lt?:string,
	dataType_lte?:string,
	dataType_not?:string,
	dataType_not_contains?:string,
	dataType_not_ends_with?:string,
	dataType_not_in?:string[],
	dataType_not_starts_with?:string,
	dataType_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	libringAccess?:PartialObjects["LibringAccessWhereInput"],
	NOT?:PartialObjects["LibringFeedWhereInput"][],
	OR?:PartialObjects["LibringFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"]
},
	["Mutation"]: {
		__typename?: "Mutation";
			abortEtl?:PartialObjects["Action"],
			archiveView?:PartialObjects["View"],
			copyView?:PartialObjects["View"],
			createAppleAccess?:PartialObjects["AppleAccess"],
			createAppleSearchAdsAccess?:PartialObjects["AppleSearchAdsAccess"],
			createApplovinAccess?:PartialObjects["ApplovinAccess"],
			createAppsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			createAwsProject?:PartialObjects["AwsProject"],
			createBigQueryProject?:PartialObjects["BigQueryProject"],
			createChartboostAccess?:PartialObjects["ChartboostAccess"],
			createFacebookAdsAccess?:PartialObjects["FacebookAdsAccess"],
			createFairbidAccess?:PartialObjects["FairbidAccess"],
			createGoogleAdsAccess?:PartialObjects["GoogleAdsAccess"],
			createGooglePlayAccess?:PartialObjects["GooglePlayAccess"],
			createHarvestAccess?:PartialObjects["HarvestAccess"],
			createIntegrationFeedConfig?:PartialObjects["IntegrationFeedConfig"],
			createIronSourceAccess?:PartialObjects["IronSourceAccess"],
			createLibringAccess?:PartialObjects["LibringAccess"],
			createOrganization?:PartialObjects["Organization"],
			createSnapchatAccess?:PartialObjects["SnapchatAccess"],
			createTiktokAccess?:PartialObjects["TiktokAccess"],
			createUnityAccess?:PartialObjects["UnityAccess"],
			createView?:PartialObjects["View"],
			deleteAppleAccess?:PartialObjects["AppleAccess"],
			deleteAppleSearchAdsAccess?:PartialObjects["AppleSearchAdsAccess"],
			deleteApplovinAccess?:PartialObjects["ApplovinAccess"],
			deleteAppsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			deleteAwsProject?:PartialObjects["AwsProject"],
			deleteBigQueryProject?:PartialObjects["BigQueryProject"],
			deleteChartboostAccess?:PartialObjects["ChartboostAccess"],
			deleteFacebookAdsAccess?:PartialObjects["FacebookAdsAccess"],
			deleteFairbidAccess?:PartialObjects["FairbidAccess"],
			deleteGoogleAdsAccess?:PartialObjects["GoogleAdsAccess"],
			deleteGooglePlayAccess?:PartialObjects["GooglePlayAccess"],
			deleteHarvestAccess?:PartialObjects["HarvestAccess"],
			deleteIntegrationFeedConfig?:PartialObjects["IntegrationFeedConfig"],
			deleteIronSourceAccess?:PartialObjects["IronSourceAccess"],
			deleteLibringAccess?:PartialObjects["LibringAccess"],
			deleteOrganization?:PartialObjects["Organization"],
			deleteSnapchatAccess?:PartialObjects["SnapchatAccess"],
			deleteTiktokAccess?:PartialObjects["TiktokAccess"],
			deleteUnityAccess?:PartialObjects["UnityAccess"],
			deleteView?:PartialObjects["View"],
			inviteUser?:PartialObjects["OrganizationMembership"],
			publishView?:PartialObjects["View"],
			restoreView?:PartialObjects["View"],
			runEtl?:PartialObjects["Result"],
			setUserRoles?:PartialObjects["User"],
			updateAppleAccess?:PartialObjects["AppleAccess"],
			updateAppleSearchAdsAccess?:PartialObjects["AppleSearchAdsAccess"],
			updateApplovinAccess?:PartialObjects["ApplovinAccess"],
			updateAppsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			updateAwsProject?:PartialObjects["AwsProject"],
			updateBigQueryProject?:PartialObjects["BigQueryProject"],
			updateChartboostAccess?:PartialObjects["ChartboostAccess"],
			updateFacebookAdsAccess?:PartialObjects["FacebookAdsAccess"],
			updateFairbidAccess?:PartialObjects["FairbidAccess"],
			updateGoogleAdsAccess?:PartialObjects["GoogleAdsAccess"],
			updateGooglePlayAccess?:PartialObjects["GooglePlayAccess"],
			updateHarvestAccess?:PartialObjects["HarvestAccess"],
			updateIntegrationFeedConfig?:PartialObjects["IntegrationFeedConfig"],
			updateIronSourceAccess?:PartialObjects["IronSourceAccess"],
			updateLibringAccess?:PartialObjects["LibringAccess"],
			updateSnapchatAccess?:PartialObjects["SnapchatAccess"],
			updateTiktokAccess?:PartialObjects["TiktokAccess"],
			updateUnityAccess?:PartialObjects["UnityAccess"],
			updateView?:PartialObjects["View"]
	},
	["Organization"]: {
		__typename?: "Organization";
			awsProjects?:PartialObjects["AwsProject"][],
			bqProjects?:PartialObjects["BigQueryProject"][],
			createdAt?:PartialObjects["DateTime"],
			id?:string,
			name?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			organizationMemberships?:PartialObjects["OrganizationMembership"][],
			updatedAt?:PartialObjects["DateTime"],
			views?:PartialObjects["View"][]
	},
	["OrganizationAttribute"]: {
		__typename?: "OrganizationAttribute";
			createdAt?:PartialObjects["DateTime"],
			id?:string,
			organization?:PartialObjects["Organization"],
			role?:PartialObjects["OrganizationRole"],
			updatedAt?:PartialObjects["DateTime"]
	},
	["OrganizationAttributeCreateInput"]: {
	organization:PartialObjects["OrganizationWhereUniqueInput"],
	role:PartialObjects["OrganizationRole"]
},
	["OrganizationAttributeOrderByInput"]:OrganizationAttributeOrderByInput,
	["OrganizationAttributeUpdateInput"]: {
	id:string,
	organization:PartialObjects["OrganizationWhereUniqueInput"],
	role:PartialObjects["OrganizationRole"]
},
	["OrganizationAttributeUpdateManyInput"]: {
	create?:PartialObjects["OrganizationAttributeCreateInput"][],
	delete?:PartialObjects["OrganizationAttributeWhereUniqueInput"][],
	update?:PartialObjects["OrganizationAttributeUpdateInput"][]
},
	["OrganizationAttributeWhereInput"]: {
	AND?:PartialObjects["OrganizationAttributeWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["OrganizationAttributeWhereInput"][],
	OR?:PartialObjects["OrganizationAttributeWhereInput"][],
	organization?:PartialObjects["OrganizationWhereInput"],
	role?:PartialObjects["OrganizationRole"],
	role_in?:PartialObjects["OrganizationRole"][],
	role_not?:PartialObjects["OrganizationRole"],
	role_not_in?:PartialObjects["OrganizationRole"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["OrganizationAttributeWhereUniqueInput"]: {
	id:string
},
	["OrganizationMembership"]: {
		__typename?: "OrganizationMembership";
			createdAt?:PartialObjects["DateTime"],
			id?:string,
			organization?:PartialObjects["Organization"],
			role?:PartialObjects["UserRole"],
			updatedAt?:PartialObjects["DateTime"],
			user?:PartialObjects["User"]
	},
	["OrganizationMembershipOrderByInput"]:OrganizationMembershipOrderByInput,
	["OrganizationMembershipWhereInput"]: {
	AND?:PartialObjects["OrganizationMembershipWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["OrganizationMembershipWhereInput"][],
	OR?:PartialObjects["OrganizationMembershipWhereInput"][],
	organization?:PartialObjects["OrganizationWhereInput"],
	role?:PartialObjects["UserRole"],
	role_in?:PartialObjects["UserRole"][],
	role_not?:PartialObjects["UserRole"],
	role_not_in?:PartialObjects["UserRole"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][],
	user?:PartialObjects["UserWhereInput"]
},
	["OrganizationOrderByInput"]:OrganizationOrderByInput,
	["OrganizationRole"]:OrganizationRole,
	["OrganizationWhereInput"]: {
	AND?:PartialObjects["OrganizationWhereInput"][],
	awsProjects_every?:PartialObjects["AwsProjectWhereInput"],
	awsProjects_none?:PartialObjects["AwsProjectWhereInput"],
	awsProjects_some?:PartialObjects["AwsProjectWhereInput"],
	bqProjects_every?:PartialObjects["BigQueryProjectWhereInput"],
	bqProjects_none?:PartialObjects["BigQueryProjectWhereInput"],
	bqProjects_some?:PartialObjects["BigQueryProjectWhereInput"],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:PartialObjects["OrganizationWhereInput"][],
	OR?:PartialObjects["OrganizationWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationMemberships_every?:PartialObjects["OrganizationMembershipWhereInput"],
	organizationMemberships_none?:PartialObjects["OrganizationMembershipWhereInput"],
	organizationMemberships_some?:PartialObjects["OrganizationMembershipWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][],
	views_every?:PartialObjects["ViewWhereInput"],
	views_none?:PartialObjects["ViewWhereInput"],
	views_some?:PartialObjects["ViewWhereInput"]
},
	["OrganizationWhereUniqueInput"]: {
	id?:string,
	name?:string
},
	["PageInfo"]: {
		__typename?: "PageInfo";
			endCursor?:string,
			hasNextPage?:boolean,
			hasPreviousPage?:boolean,
			startCursor?:string
	},
	["Platform"]:Platform,
	["Query"]: {
		__typename?: "Query";
			appleAccess?:PartialObjects["AppleAccess"],
			appleAccesses?:PartialObjects["AppleAccess"][],
			appleSearchAdsAccess?:PartialObjects["AppleSearchAdsAccess"],
			appleSearchAdsAccesses?:PartialObjects["AppleSearchAdsAccess"][],
			applovinAccess?:PartialObjects["ApplovinAccess"],
			applovinAccesses?:PartialObjects["ApplovinAccess"][],
			appsFlyerAccess?:PartialObjects["AppsFlyerAccess"],
			appsFlyerAccesses?:PartialObjects["AppsFlyerAccess"][],
			awsProject?:PartialObjects["AwsProject"],
			awsProjects?:PartialObjects["AwsProject"][],
			bigQueryCheck?:PartialObjects["BigQueryCheck"],
			bigQueryChecks?:PartialObjects["BigQueryCheck"][],
			bigQueryChecksConnection?:PartialObjects["BigQueryCheckConnection"],
			bigQueryDestination?:PartialObjects["BigQueryDestination"],
			bigQueryDestinations?:PartialObjects["BigQueryDestination"][],
			bigQueryProject?:PartialObjects["BigQueryProject"],
			bigQueryProjects?:PartialObjects["BigQueryProject"][],
			campaignConsistencyCheck?:PartialObjects["CampaignConsistencyCheck"],
			campaignConsistencyChecks?:PartialObjects["CampaignConsistencyCheck"][],
			campaignConsistencyChecksConnection?:PartialObjects["CampaignConsistencyCheckConnection"],
			chartboostAccess?:PartialObjects["ChartboostAccess"],
			chartboostAccesses?:PartialObjects["ChartboostAccess"][],
			dailyConsistencyCheck?:PartialObjects["DailyConsistencyCheck"],
			dailyConsistencyChecks?:PartialObjects["DailyConsistencyCheck"][],
			dailyConsistencyChecksConnection?:PartialObjects["DailyConsistencyCheckConnection"],
			facebookAdsAccess?:PartialObjects["FacebookAdsAccess"],
			facebookAdsAccesses?:PartialObjects["FacebookAdsAccess"][],
			fairbidAccess?:PartialObjects["FairbidAccess"],
			fairbidAccesses?:PartialObjects["FairbidAccess"][],
			googleAdsAccess?:PartialObjects["GoogleAdsAccess"],
			googleAdsAccesses?:PartialObjects["GoogleAdsAccess"][],
			googlePlayAccess?:PartialObjects["GooglePlayAccess"],
			googlePlayAccesses?:PartialObjects["GooglePlayAccess"][],
			harvestAccess?:PartialObjects["HarvestAccess"],
			harvestAccesses?:PartialObjects["HarvestAccess"][],
			integrationFeedConfig?:PartialObjects["IntegrationFeedConfig"],
			integrationFeedConfigs?:PartialObjects["IntegrationFeedConfig"][],
			integrationFeedConfigsConnection?:PartialObjects["IntegrationFeedConfigConnection"],
			integrationFeedInstance?:PartialObjects["IntegrationFeedInstance"],
			integrationFeedInstances?:PartialObjects["IntegrationFeedInstance"][],
			integrationFeedInstancesConnection?:PartialObjects["IntegrationFeedInstanceConnection"],
			ironSourceAccess?:PartialObjects["IronSourceAccess"],
			ironSourceAccesses?:PartialObjects["IronSourceAccess"][],
			libringAccess?:PartialObjects["LibringAccess"],
			libringAccesses?:PartialObjects["LibringAccess"][],
			me?:PartialObjects["User"],
			organization?:PartialObjects["Organization"],
			organizations?:PartialObjects["Organization"][],
			s3Destination?:PartialObjects["S3Destination"],
			s3Destinations?:PartialObjects["S3Destination"][],
			snapchatAccess?:PartialObjects["SnapchatAccess"],
			snapchatAccesses?:PartialObjects["SnapchatAccess"][],
			tiktokAccess?:PartialObjects["TiktokAccess"],
			tiktokAccesses?:PartialObjects["TiktokAccess"][],
			unityAccess?:PartialObjects["UnityAccess"],
			unityAccesses?:PartialObjects["UnityAccess"][],
			users?:PartialObjects["User"][],
			view?:PartialObjects["View"],
			views?:PartialObjects["View"][]
	},
	["ReportType"]:ReportType,
	["Result"]: {
		__typename?: "Result";
			actions?:PartialObjects["Action"][]
	},
	["S3Destination"]: {
		__typename?: "S3Destination";
			awsProject?:PartialObjects["AwsProject"],
			bucketName?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			enabled?:boolean,
			id?:string,
			objectSuffix?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			pathPrefix?:string,
			region?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["S3DestinationCreateInput"]: {
	awsProject:PartialObjects["AwsProjectWhereUniqueInput"],
	bucketName:string,
	descriptiveName:string,
	enabled:boolean,
	id?:string,
	objectSuffix?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	pathPrefix?:string,
	region:string
},
	["S3DestinationOrderByInput"]:S3DestinationOrderByInput,
	["S3DestinationUpdateInput"]: {
	awsProject?:PartialObjects["AwsProjectWhereUniqueInput"],
	bucketName?:string,
	descriptiveName?:string,
	enabled?:boolean,
	id:string,
	objectSuffix?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	pathPrefix?:string,
	region?:string
},
	["S3DestinationUpdateManyInput"]: {
	create?:PartialObjects["S3DestinationCreateInput"][],
	delete?:PartialObjects["S3DestinationWhereUniqueInput"][],
	update?:PartialObjects["S3DestinationUpdateInput"][]
},
	["S3DestinationWhereInput"]: {
	AND?:PartialObjects["S3DestinationWhereInput"][],
	awsProject?:PartialObjects["AwsProjectWhereInput"],
	bucketName?:string,
	bucketName_contains?:string,
	bucketName_ends_with?:string,
	bucketName_gt?:string,
	bucketName_gte?:string,
	bucketName_in?:string[],
	bucketName_lt?:string,
	bucketName_lte?:string,
	bucketName_not?:string,
	bucketName_not_contains?:string,
	bucketName_not_ends_with?:string,
	bucketName_not_in?:string[],
	bucketName_not_starts_with?:string,
	bucketName_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["S3DestinationWhereInput"][],
	objectSuffix?:string,
	objectSuffix_contains?:string,
	objectSuffix_ends_with?:string,
	objectSuffix_gt?:string,
	objectSuffix_gte?:string,
	objectSuffix_in?:string[],
	objectSuffix_lt?:string,
	objectSuffix_lte?:string,
	objectSuffix_not?:string,
	objectSuffix_not_contains?:string,
	objectSuffix_not_ends_with?:string,
	objectSuffix_not_in?:string[],
	objectSuffix_not_starts_with?:string,
	objectSuffix_starts_with?:string,
	OR?:PartialObjects["S3DestinationWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	pathPrefix?:string,
	pathPrefix_contains?:string,
	pathPrefix_ends_with?:string,
	pathPrefix_gt?:string,
	pathPrefix_gte?:string,
	pathPrefix_in?:string[],
	pathPrefix_lt?:string,
	pathPrefix_lte?:string,
	pathPrefix_not?:string,
	pathPrefix_not_contains?:string,
	pathPrefix_not_ends_with?:string,
	pathPrefix_not_in?:string[],
	pathPrefix_not_starts_with?:string,
	pathPrefix_starts_with?:string,
	region?:string,
	region_contains?:string,
	region_ends_with?:string,
	region_gt?:string,
	region_gte?:string,
	region_in?:string[],
	region_lt?:string,
	region_lte?:string,
	region_not?:string,
	region_not_contains?:string,
	region_not_ends_with?:string,
	region_not_in?:string[],
	region_not_starts_with?:string,
	region_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["S3DestinationWhereUniqueInput"]: {
	id:string
},
	["ScraperGame"]: {
		__typename?: "ScraperGame";
			createdAt?:PartialObjects["DateTime"],
			gameId?:string,
			id?:string,
			name?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["ScraperGameCreateInput"]: {
	gameId:string,
	id?:string,
	name:string
},
	["ScraperGameOrderByInput"]:ScraperGameOrderByInput,
	["ScraperGameWhereInput"]: {
	AND?:PartialObjects["ScraperGameWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:PartialObjects["ScraperGameWhereInput"][],
	OR?:PartialObjects["ScraperGameWhereInput"][],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["SnapchatAccess"]: {
		__typename?: "SnapchatAccess";
			adAccountID?:string,
			clientID?:string,
			clientSecret?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			refreshToken?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["SnapchatAccessCreateInput"]: {
	adAccountID:string,
	clientID:string,
	clientSecret:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	refreshToken:string
},
	["SnapchatAccessOrderByInput"]:SnapchatAccessOrderByInput,
	["SnapchatAccessUpdateInput"]: {
	adAccountID?:string,
	clientID?:string,
	clientSecret?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	refreshToken?:string
},
	["SnapchatAccessWhereInput"]: {
	adAccountID?:string,
	adAccountID_contains?:string,
	adAccountID_ends_with?:string,
	adAccountID_gt?:string,
	adAccountID_gte?:string,
	adAccountID_in?:string[],
	adAccountID_lt?:string,
	adAccountID_lte?:string,
	adAccountID_not?:string,
	adAccountID_not_contains?:string,
	adAccountID_not_ends_with?:string,
	adAccountID_not_in?:string[],
	adAccountID_not_starts_with?:string,
	adAccountID_starts_with?:string,
	AND?:PartialObjects["SnapchatAccessWhereInput"][],
	clientID?:string,
	clientID_contains?:string,
	clientID_ends_with?:string,
	clientID_gt?:string,
	clientID_gte?:string,
	clientID_in?:string[],
	clientID_lt?:string,
	clientID_lte?:string,
	clientID_not?:string,
	clientID_not_contains?:string,
	clientID_not_ends_with?:string,
	clientID_not_in?:string[],
	clientID_not_starts_with?:string,
	clientID_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["SnapchatAccessWhereInput"][],
	OR?:PartialObjects["SnapchatAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["SnapchatAccessWhereUniqueInput"]: {
	id:string
},
	["SnapchatFeed"]: {
		__typename?: "SnapchatFeed";
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			reportDimension?:string,
			snapchatAccess?:PartialObjects["SnapchatAccess"],
			timezoneOffset?:number
	},
	["SnapchatFeedCreateWithoutParamsInput"]: {
	id?:string,
	reportDimension?:string,
	snapchatAccess:PartialObjects["SnapchatAccessWhereUniqueInput"],
	timezoneOffset?:number
},
	["SnapchatFeedWhereInput"]: {
	AND?:PartialObjects["SnapchatFeedWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["SnapchatFeedWhereInput"][],
	OR?:PartialObjects["SnapchatFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	reportDimension?:string,
	reportDimension_contains?:string,
	reportDimension_ends_with?:string,
	reportDimension_gt?:string,
	reportDimension_gte?:string,
	reportDimension_in?:string[],
	reportDimension_lt?:string,
	reportDimension_lte?:string,
	reportDimension_not?:string,
	reportDimension_not_contains?:string,
	reportDimension_not_ends_with?:string,
	reportDimension_not_in?:string[],
	reportDimension_not_starts_with?:string,
	reportDimension_starts_with?:string,
	snapchatAccess?:PartialObjects["SnapchatAccessWhereInput"],
	timezoneOffset?:number,
	timezoneOffset_gt?:number,
	timezoneOffset_gte?:number,
	timezoneOffset_in?:number[],
	timezoneOffset_lt?:number,
	timezoneOffset_lte?:number,
	timezoneOffset_not?:number,
	timezoneOffset_not_in?:number[]
},
	["TiktokAccess"]: {
		__typename?: "TiktokAccess";
			accessToken?:string,
			advertiserId?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			updatedAt?:PartialObjects["DateTime"]
	},
	["TiktokAccessCreateInput"]: {
	accessToken:string,
	advertiserId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][]
},
	["TiktokAccessOrderByInput"]:TiktokAccessOrderByInput,
	["TiktokAccessUpdateInput"]: {
	accessToken?:string,
	advertiserId?:string,
	descriptiveName?:string,
	id:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"]
},
	["TiktokAccessWhereInput"]: {
	accessToken?:string,
	accessToken_contains?:string,
	accessToken_ends_with?:string,
	accessToken_gt?:string,
	accessToken_gte?:string,
	accessToken_in?:string[],
	accessToken_lt?:string,
	accessToken_lte?:string,
	accessToken_not?:string,
	accessToken_not_contains?:string,
	accessToken_not_ends_with?:string,
	accessToken_not_in?:string[],
	accessToken_not_starts_with?:string,
	accessToken_starts_with?:string,
	advertiserId?:string,
	advertiserId_contains?:string,
	advertiserId_ends_with?:string,
	advertiserId_gt?:string,
	advertiserId_gte?:string,
	advertiserId_in?:string[],
	advertiserId_lt?:string,
	advertiserId_lte?:string,
	advertiserId_not?:string,
	advertiserId_not_contains?:string,
	advertiserId_not_ends_with?:string,
	advertiserId_not_in?:string[],
	advertiserId_not_starts_with?:string,
	advertiserId_starts_with?:string,
	AND?:PartialObjects["TiktokAccessWhereInput"][],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["TiktokAccessWhereInput"][],
	OR?:PartialObjects["TiktokAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["TiktokAccessWhereUniqueInput"]: {
	id:string
},
	["TiktokAdsAudienceFeed"]: {
		__typename?: "TiktokAdsAudienceFeed";
			dimensions?:string[],
			filterInactive?:boolean,
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			tiktokAccess?:PartialObjects["TiktokAccess"]
	},
	["TiktokAdsAudienceFeedCreateWithoutParamsInput"]: {
	dimensions:string[],
	filterInactive?:boolean,
	id?:string,
	tiktokAccess:PartialObjects["TiktokAccessWhereUniqueInput"]
},
	["TiktokAdsAudienceFeedWhereInput"]: {
	AND?:PartialObjects["TiktokAdsAudienceFeedWhereInput"][],
	filterInactive?:boolean,
	filterInactive_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["TiktokAdsAudienceFeedWhereInput"][],
	OR?:PartialObjects["TiktokAdsAudienceFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	tiktokAccess?:PartialObjects["TiktokAccessWhereInput"]
},
	["UnityAccess"]: {
		__typename?: "UnityAccess";
			apiKey?:string,
			createdAt?:PartialObjects["DateTime"],
			descriptiveName?:string,
			id?:string,
			organizationAttributes?:PartialObjects["OrganizationAttribute"][],
			organizationId?:string,
			updatedAt?:PartialObjects["DateTime"]
	},
	["UnityAccessCreateInput"]: {
	apiKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:PartialObjects["OrganizationAttributeCreateInput"][],
	organizationId:string
},
	["UnityAccessOrderByInput"]:UnityAccessOrderByInput,
	["UnityAccessUpdateInput"]: {
	apiKey?:string,
	descriptiveName?:string,
	organizationAttributes?:PartialObjects["OrganizationAttributeUpdateManyInput"],
	organizationId?:string
},
	["UnityAccessWhereInput"]: {
	AND?:PartialObjects["UnityAccessWhereInput"][],
	apiKey?:string,
	apiKey_contains?:string,
	apiKey_ends_with?:string,
	apiKey_gt?:string,
	apiKey_gte?:string,
	apiKey_in?:string[],
	apiKey_lt?:string,
	apiKey_lte?:string,
	apiKey_not?:string,
	apiKey_not_contains?:string,
	apiKey_not_ends_with?:string,
	apiKey_not_in?:string[],
	apiKey_not_starts_with?:string,
	apiKey_starts_with?:string,
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["UnityAccessWhereInput"][],
	OR?:PartialObjects["UnityAccessWhereInput"][],
	organizationAttributes_every?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_none?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationAttributes_some?:PartialObjects["OrganizationAttributeWhereInput"],
	organizationId?:string,
	organizationId_contains?:string,
	organizationId_ends_with?:string,
	organizationId_gt?:string,
	organizationId_gte?:string,
	organizationId_in?:string[],
	organizationId_lt?:string,
	organizationId_lte?:string,
	organizationId_not?:string,
	organizationId_not_contains?:string,
	organizationId_not_ends_with?:string,
	organizationId_not_in?:string[],
	organizationId_not_starts_with?:string,
	organizationId_starts_with?:string,
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][]
},
	["UnityAccessWhereUniqueInput"]: {
	id:string
},
	["UnityAdsFeed"]: {
		__typename?: "UnityAdsFeed";
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			unityAccess?:PartialObjects["UnityAccess"]
	},
	["UnityAdsFeedCreateWithoutParamsInput"]: {
	id?:string,
	unityAccess:PartialObjects["UnityAccessWhereUniqueInput"]
},
	["UnityAdsFeedWhereInput"]: {
	AND?:PartialObjects["UnityAdsFeedWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["UnityAdsFeedWhereInput"][],
	OR?:PartialObjects["UnityAdsFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	unityAccess?:PartialObjects["UnityAccessWhereInput"]
},
	["UnityAdvertiserFeed"]: {
		__typename?: "UnityAdvertiserFeed";
			id?:string,
			params?:PartialObjects["IntegrationFeedParams"],
			targetIds?:string[],
			unityAccess?:PartialObjects["UnityAccess"]
	},
	["UnityAdvertiserFeedCreateWithoutParamsInput"]: {
	id?:string,
	targetIds:string[],
	unityAccess:PartialObjects["UnityAccessWhereUniqueInput"]
},
	["UnityAdvertiserFeedWhereInput"]: {
	AND?:PartialObjects["UnityAdvertiserFeedWhereInput"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["UnityAdvertiserFeedWhereInput"][],
	OR?:PartialObjects["UnityAdvertiserFeedWhereInput"][],
	params?:PartialObjects["IntegrationFeedParamsWhereInput"],
	unityAccess?:PartialObjects["UnityAccessWhereInput"]
},
	["User"]: {
		__typename?: "User";
			authProvider?:string,
			email?:string,
			id?:string,
			isSuperAdmin?:boolean,
			name?:string,
			organizationMemberships?:PartialObjects["OrganizationMembership"][],
			surname?:string,
			views?:PartialObjects["View"][]
	},
	["UserOrderByInput"]:UserOrderByInput,
	["UserRole"]:UserRole,
	["UserWhereInput"]: {
	AND?:PartialObjects["UserWhereInput"][],
	email?:string,
	email_contains?:string,
	email_ends_with?:string,
	email_gt?:string,
	email_gte?:string,
	email_in?:string[],
	email_lt?:string,
	email_lte?:string,
	email_not?:string,
	email_not_contains?:string,
	email_not_ends_with?:string,
	email_not_in?:string[],
	email_not_starts_with?:string,
	email_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:PartialObjects["UserWhereInput"][],
	OR?:PartialObjects["UserWhereInput"][],
	organizationMemberships_every?:PartialObjects["OrganizationMembershipWhereInput"],
	organizationMemberships_none?:PartialObjects["OrganizationMembershipWhereInput"],
	organizationMemberships_some?:PartialObjects["OrganizationMembershipWhereInput"],
	views_every?:PartialObjects["ViewWhereInput"],
	views_none?:PartialObjects["ViewWhereInput"],
	views_some?:PartialObjects["ViewWhereInput"]
},
	["View"]: {
		__typename?: "View";
			archivedAt?:PartialObjects["DateTime"],
			author?:PartialObjects["User"],
			createdAt?:PartialObjects["DateTime"],
			id?:string,
			name?:string,
			organization?:PartialObjects["Organization"],
			updatedAt?:PartialObjects["DateTime"],
			urlQueryParams?:string
	},
	["ViewOrderByInput"]:ViewOrderByInput,
	["ViewWhereInput"]: {
	AND?:PartialObjects["ViewWhereInput"][],
	archivedAt?:PartialObjects["DateTime"],
	archivedAt_gt?:PartialObjects["DateTime"],
	archivedAt_gte?:PartialObjects["DateTime"],
	archivedAt_in?:PartialObjects["DateTime"][],
	archivedAt_lt?:PartialObjects["DateTime"],
	archivedAt_lte?:PartialObjects["DateTime"],
	archivedAt_not?:PartialObjects["DateTime"],
	archivedAt_not_in?:PartialObjects["DateTime"][],
	author?:PartialObjects["UserWhereInput"],
	createdAt?:PartialObjects["DateTime"],
	createdAt_gt?:PartialObjects["DateTime"],
	createdAt_gte?:PartialObjects["DateTime"],
	createdAt_in?:PartialObjects["DateTime"][],
	createdAt_lt?:PartialObjects["DateTime"],
	createdAt_lte?:PartialObjects["DateTime"],
	createdAt_not?:PartialObjects["DateTime"],
	createdAt_not_in?:PartialObjects["DateTime"][],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:PartialObjects["ViewWhereInput"][],
	OR?:PartialObjects["ViewWhereInput"][],
	organization?:PartialObjects["OrganizationWhereInput"],
	updatedAt?:PartialObjects["DateTime"],
	updatedAt_gt?:PartialObjects["DateTime"],
	updatedAt_gte?:PartialObjects["DateTime"],
	updatedAt_in?:PartialObjects["DateTime"][],
	updatedAt_lt?:PartialObjects["DateTime"],
	updatedAt_lte?:PartialObjects["DateTime"],
	updatedAt_not?:PartialObjects["DateTime"],
	updatedAt_not_in?:PartialObjects["DateTime"][],
	urlQueryParams?:string,
	urlQueryParams_contains?:string,
	urlQueryParams_ends_with?:string,
	urlQueryParams_gt?:string,
	urlQueryParams_gte?:string,
	urlQueryParams_in?:string[],
	urlQueryParams_lt?:string,
	urlQueryParams_lte?:string,
	urlQueryParams_not?:string,
	urlQueryParams_not_contains?:string,
	urlQueryParams_not_ends_with?:string,
	urlQueryParams_not_in?:string[],
	urlQueryParams_not_starts_with?:string,
	urlQueryParams_starts_with?:string
},
	["ViewWhereUniqueInput"]: {
	id?:string
}
  }

// ## This file was autogenerated by GraphQL Nexus

// ## Do not make changes to this file directly

export type Action = {
	__typename?: "Action",
	error?:string,
	message:string,
	startDate?:string,
	stopDate?:string
}

export type AggregateBigQueryCheck = {
	__typename?: "AggregateBigQueryCheck",
	count:number
}

export type AggregateCampaignConsistencyCheck = {
	__typename?: "AggregateCampaignConsistencyCheck",
	count:number
}

export type AggregateDailyConsistencyCheck = {
	__typename?: "AggregateDailyConsistencyCheck",
	count:number
}

export type AggregateIntegrationFeedConfig = {
	__typename?: "AggregateIntegrationFeedConfig",
	count:number
}

export type AggregateIntegrationFeedInstance = {
	__typename?: "AggregateIntegrationFeedInstance",
	count:number
}

export type AppleAccess = {
	__typename?: "AppleAccess",
	accountName:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	login:string,
	organizationAttributes?:OrganizationAttribute[],
	password:string,
	updatedAt:DateTime
}

export type AppleAccessCreateInput = {
		accountName:string,
	descriptiveName:string,
	id?:string,
	login:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	password:string
}

export enum AppleAccessOrderByInput {
	accountName_ASC = "accountName_ASC",
	accountName_DESC = "accountName_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	login_ASC = "login_ASC",
	login_DESC = "login_DESC",
	password_ASC = "password_ASC",
	password_DESC = "password_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type AppleAccessUpdateInput = {
		accountName?:string,
	descriptiveName?:string,
	login?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	password?:string
}

export type AppleAccessWhereInput = {
		accountName?:string,
	accountName_contains?:string,
	accountName_ends_with?:string,
	accountName_gt?:string,
	accountName_gte?:string,
	accountName_in?:string[],
	accountName_lt?:string,
	accountName_lte?:string,
	accountName_not?:string,
	accountName_not_contains?:string,
	accountName_not_ends_with?:string,
	accountName_not_in?:string[],
	accountName_not_starts_with?:string,
	accountName_starts_with?:string,
	AND?:AppleAccessWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	login?:string,
	login_contains?:string,
	login_ends_with?:string,
	login_gt?:string,
	login_gte?:string,
	login_in?:string[],
	login_lt?:string,
	login_lte?:string,
	login_not?:string,
	login_not_contains?:string,
	login_not_ends_with?:string,
	login_not_in?:string[],
	login_not_starts_with?:string,
	login_starts_with?:string,
	NOT?:AppleAccessWhereInput[],
	OR?:AppleAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	password?:string,
	password_contains?:string,
	password_ends_with?:string,
	password_gt?:string,
	password_gte?:string,
	password_in?:string[],
	password_lt?:string,
	password_lte?:string,
	password_not?:string,
	password_not_contains?:string,
	password_not_ends_with?:string,
	password_not_in?:string[],
	password_not_starts_with?:string,
	password_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type AppleAccessWhereUniqueInput = {
		id:string
}

export type AppleFeed = {
	__typename?: "AppleFeed",
	appleAccess:AppleAccess,
	games?:ScraperGame[],
	id:string,
	params:IntegrationFeedParams,
	reporter:boolean,
	scraper:boolean,
	usePST:boolean
}

export type AppleFeedCreateWithoutParamsInput = {
		appleAccess:AppleAccessWhereUniqueInput,
	games?:ScraperGameCreateInput[],
	id?:string,
	reporter?:boolean,
	scraper?:boolean,
	usePST?:boolean
}

export type AppleFeedWhereInput = {
		AND?:AppleFeedWhereInput[],
	appleAccess?:AppleAccessWhereInput,
	games_every?:ScraperGameWhereInput,
	games_none?:ScraperGameWhereInput,
	games_some?:ScraperGameWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppleFeedWhereInput[],
	OR?:AppleFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reporter?:boolean,
	reporter_not?:boolean,
	scraper?:boolean,
	scraper_not?:boolean,
	usePST?:boolean,
	usePST_not?:boolean
}

export type AppleSearchAdsAccess = {
	__typename?: "AppleSearchAdsAccess",
	certificate:string,
	certificateKey:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type AppleSearchAdsAccessCreateInput = {
		certificate:string,
	certificateKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum AppleSearchAdsAccessOrderByInput {
	certificate_ASC = "certificate_ASC",
	certificate_DESC = "certificate_DESC",
	certificateKey_ASC = "certificateKey_ASC",
	certificateKey_DESC = "certificateKey_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type AppleSearchAdsAccessUpdateInput = {
		certificate?:string,
	certificateKey?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type AppleSearchAdsAccessWhereInput = {
		AND?:AppleSearchAdsAccessWhereInput[],
	certificate?:string,
	certificate_contains?:string,
	certificate_ends_with?:string,
	certificate_gt?:string,
	certificate_gte?:string,
	certificate_in?:string[],
	certificate_lt?:string,
	certificate_lte?:string,
	certificate_not?:string,
	certificate_not_contains?:string,
	certificate_not_ends_with?:string,
	certificate_not_in?:string[],
	certificate_not_starts_with?:string,
	certificate_starts_with?:string,
	certificateKey?:string,
	certificateKey_contains?:string,
	certificateKey_ends_with?:string,
	certificateKey_gt?:string,
	certificateKey_gte?:string,
	certificateKey_in?:string[],
	certificateKey_lt?:string,
	certificateKey_lte?:string,
	certificateKey_not?:string,
	certificateKey_not_contains?:string,
	certificateKey_not_ends_with?:string,
	certificateKey_not_in?:string[],
	certificateKey_not_starts_with?:string,
	certificateKey_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppleSearchAdsAccessWhereInput[],
	OR?:AppleSearchAdsAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type AppleSearchAdsAccessWhereUniqueInput = {
		id:string
}

export type AppleSearchAdsFeed = {
	__typename?: "AppleSearchAdsFeed",
	appleSearchAdsAccess:AppleSearchAdsAccess,
	groupBy:string[],
	id:string,
	orgId:string,
	params:IntegrationFeedParams
}

export type AppleSearchAdsFeedCreateWithoutParamsInput = {
		appleSearchAdsAccess:AppleSearchAdsAccessWhereUniqueInput,
	groupBy:string[],
	id?:string,
	orgId:string
}

export type AppleSearchAdsFeedWhereInput = {
		AND?:AppleSearchAdsFeedWhereInput[],
	appleSearchAdsAccess?:AppleSearchAdsAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppleSearchAdsFeedWhereInput[],
	OR?:AppleSearchAdsFeedWhereInput[],
	orgId?:string,
	orgId_contains?:string,
	orgId_ends_with?:string,
	orgId_gt?:string,
	orgId_gte?:string,
	orgId_in?:string[],
	orgId_lt?:string,
	orgId_lte?:string,
	orgId_not?:string,
	orgId_not_contains?:string,
	orgId_not_ends_with?:string,
	orgId_not_in?:string[],
	orgId_not_starts_with?:string,
	orgId_starts_with?:string,
	params?:IntegrationFeedParamsWhereInput
}

export type ApplovinAccess = {
	__typename?: "ApplovinAccess",
	apiKey:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type ApplovinAccessCreateInput = {
		apiKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum ApplovinAccessOrderByInput {
	apiKey_ASC = "apiKey_ASC",
	apiKey_DESC = "apiKey_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type ApplovinAccessUpdateInput = {
		apiKey?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type ApplovinAccessWhereInput = {
		AND?:ApplovinAccessWhereInput[],
	apiKey?:string,
	apiKey_contains?:string,
	apiKey_ends_with?:string,
	apiKey_gt?:string,
	apiKey_gte?:string,
	apiKey_in?:string[],
	apiKey_lt?:string,
	apiKey_lte?:string,
	apiKey_not?:string,
	apiKey_not_contains?:string,
	apiKey_not_ends_with?:string,
	apiKey_not_in?:string[],
	apiKey_not_starts_with?:string,
	apiKey_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ApplovinAccessWhereInput[],
	OR?:ApplovinAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type ApplovinAccessWhereUniqueInput = {
		id:string
}

export type ApplovinFeed = {
	__typename?: "ApplovinFeed",
	applovinAccess:ApplovinAccess,
	gameIds:string[],
	id:string,
	params:IntegrationFeedParams,
	reportType:ApplovinReportType
}

export type ApplovinFeedCreateWithoutParamsInput = {
		applovinAccess:ApplovinAccessWhereUniqueInput,
	gameIds:string[],
	id?:string,
	reportType:ApplovinReportType
}

export type ApplovinFeedWhereInput = {
		AND?:ApplovinFeedWhereInput[],
	applovinAccess?:ApplovinAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ApplovinFeedWhereInput[],
	OR?:ApplovinFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reportType?:ApplovinReportType,
	reportType_in?:ApplovinReportType[],
	reportType_not?:ApplovinReportType,
	reportType_not_in?:ApplovinReportType[]
}

export enum ApplovinReportType {
	ADVERTISER = "ADVERTISER",
	PUBLISHER = "PUBLISHER"
}

export type AppsFlyerAccess = {
	__typename?: "AppsFlyerAccess",
	apiToken:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type AppsFlyerAccessCreateInput = {
		apiToken:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum AppsFlyerAccessOrderByInput {
	apiToken_ASC = "apiToken_ASC",
	apiToken_DESC = "apiToken_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type AppsFlyerAccessUpdateInput = {
		apiToken?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type AppsFlyerAccessWhereInput = {
		AND?:AppsFlyerAccessWhereInput[],
	apiToken?:string,
	apiToken_contains?:string,
	apiToken_ends_with?:string,
	apiToken_gt?:string,
	apiToken_gte?:string,
	apiToken_in?:string[],
	apiToken_lt?:string,
	apiToken_lte?:string,
	apiToken_not?:string,
	apiToken_not_contains?:string,
	apiToken_not_ends_with?:string,
	apiToken_not_in?:string[],
	apiToken_not_starts_with?:string,
	apiToken_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppsFlyerAccessWhereInput[],
	OR?:AppsFlyerAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type AppsFlyerAccessWhereUniqueInput = {
		id:string
}

export type AppsFlyerCampaignFeed = {
	__typename?: "AppsFlyerCampaignFeed",
	appsFlyerAccess:AppsFlyerAccess,
	attributedTouchType?:string,
	currency?:string,
	eventName?:string,
	gameId:string,
	id:string,
	mediaSource?:string,
	params:IntegrationFeedParams,
	reattr:boolean,
	reportType:ReportType,
	timezone?:string
}

export type AppsFlyerCampaignFeedCreateWithoutParamsInput = {
		appsFlyerAccess:AppsFlyerAccessWhereUniqueInput,
	attributedTouchType?:string,
	currency?:string,
	eventName?:string,
	gameId:string,
	id?:string,
	mediaSource?:string,
	reattr?:boolean,
	reportType?:ReportType,
	timezone?:string
}

export type AppsFlyerCampaignFeedWhereInput = {
		AND?:AppsFlyerCampaignFeedWhereInput[],
	appsFlyerAccess?:AppsFlyerAccessWhereInput,
	attributedTouchType?:string,
	attributedTouchType_contains?:string,
	attributedTouchType_ends_with?:string,
	attributedTouchType_gt?:string,
	attributedTouchType_gte?:string,
	attributedTouchType_in?:string[],
	attributedTouchType_lt?:string,
	attributedTouchType_lte?:string,
	attributedTouchType_not?:string,
	attributedTouchType_not_contains?:string,
	attributedTouchType_not_ends_with?:string,
	attributedTouchType_not_in?:string[],
	attributedTouchType_not_starts_with?:string,
	attributedTouchType_starts_with?:string,
	currency?:string,
	currency_contains?:string,
	currency_ends_with?:string,
	currency_gt?:string,
	currency_gte?:string,
	currency_in?:string[],
	currency_lt?:string,
	currency_lte?:string,
	currency_not?:string,
	currency_not_contains?:string,
	currency_not_ends_with?:string,
	currency_not_in?:string[],
	currency_not_starts_with?:string,
	currency_starts_with?:string,
	eventName?:string,
	eventName_contains?:string,
	eventName_ends_with?:string,
	eventName_gt?:string,
	eventName_gte?:string,
	eventName_in?:string[],
	eventName_lt?:string,
	eventName_lte?:string,
	eventName_not?:string,
	eventName_not_contains?:string,
	eventName_not_ends_with?:string,
	eventName_not_in?:string[],
	eventName_not_starts_with?:string,
	eventName_starts_with?:string,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	mediaSource?:string,
	mediaSource_contains?:string,
	mediaSource_ends_with?:string,
	mediaSource_gt?:string,
	mediaSource_gte?:string,
	mediaSource_in?:string[],
	mediaSource_lt?:string,
	mediaSource_lte?:string,
	mediaSource_not?:string,
	mediaSource_not_contains?:string,
	mediaSource_not_ends_with?:string,
	mediaSource_not_in?:string[],
	mediaSource_not_starts_with?:string,
	mediaSource_starts_with?:string,
	NOT?:AppsFlyerCampaignFeedWhereInput[],
	OR?:AppsFlyerCampaignFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reattr?:boolean,
	reattr_not?:boolean,
	reportType?:ReportType,
	reportType_in?:ReportType[],
	reportType_not?:ReportType,
	reportType_not_in?:ReportType[],
	timezone?:string,
	timezone_contains?:string,
	timezone_ends_with?:string,
	timezone_gt?:string,
	timezone_gte?:string,
	timezone_in?:string[],
	timezone_lt?:string,
	timezone_lte?:string,
	timezone_not?:string,
	timezone_not_contains?:string,
	timezone_not_ends_with?:string,
	timezone_not_in?:string[],
	timezone_not_starts_with?:string,
	timezone_starts_with?:string
}

export type AppsFlyerDatalockerFeed = {
	__typename?: "AppsFlyerDatalockerFeed",
	awsAccessKey:string,
	bucketSecret:string,
	eventNames:string[],
	homeFolder:string,
	id:string,
	params:IntegrationFeedParams
}

export type AppsFlyerDatalockerFeedCreateWithoutParamsInput = {
		awsAccessKey:string,
	bucketSecret:string,
	eventNames:string[],
	homeFolder:string,
	id?:string
}

export type AppsFlyerDatalockerFeedWhereInput = {
		AND?:AppsFlyerDatalockerFeedWhereInput[],
	awsAccessKey?:string,
	awsAccessKey_contains?:string,
	awsAccessKey_ends_with?:string,
	awsAccessKey_gt?:string,
	awsAccessKey_gte?:string,
	awsAccessKey_in?:string[],
	awsAccessKey_lt?:string,
	awsAccessKey_lte?:string,
	awsAccessKey_not?:string,
	awsAccessKey_not_contains?:string,
	awsAccessKey_not_ends_with?:string,
	awsAccessKey_not_in?:string[],
	awsAccessKey_not_starts_with?:string,
	awsAccessKey_starts_with?:string,
	bucketSecret?:string,
	bucketSecret_contains?:string,
	bucketSecret_ends_with?:string,
	bucketSecret_gt?:string,
	bucketSecret_gte?:string,
	bucketSecret_in?:string[],
	bucketSecret_lt?:string,
	bucketSecret_lte?:string,
	bucketSecret_not?:string,
	bucketSecret_not_contains?:string,
	bucketSecret_not_ends_with?:string,
	bucketSecret_not_in?:string[],
	bucketSecret_not_starts_with?:string,
	bucketSecret_starts_with?:string,
	homeFolder?:string,
	homeFolder_contains?:string,
	homeFolder_ends_with?:string,
	homeFolder_gt?:string,
	homeFolder_gte?:string,
	homeFolder_in?:string[],
	homeFolder_lt?:string,
	homeFolder_lte?:string,
	homeFolder_not?:string,
	homeFolder_not_contains?:string,
	homeFolder_not_ends_with?:string,
	homeFolder_not_in?:string[],
	homeFolder_not_starts_with?:string,
	homeFolder_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppsFlyerDatalockerFeedWhereInput[],
	OR?:AppsFlyerDatalockerFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput
}

export type AppsFlyerRawEventsFeed = {
	__typename?: "AppsFlyerRawEventsFeed",
	appsFlyerAccess:AppsFlyerAccess,
	eventName:string,
	gameId:string,
	id:string,
	omitFields:string[],
	params:IntegrationFeedParams,
	reportType:string
}

export type AppsFlyerRawEventsFeedCreateWithoutParamsInput = {
		appsFlyerAccess:AppsFlyerAccessWhereUniqueInput,
	eventName:string,
	gameId:string,
	id?:string,
	omitFields:string[],
	reportType:string
}

export type AppsFlyerRawEventsFeedWhereInput = {
		AND?:AppsFlyerRawEventsFeedWhereInput[],
	appsFlyerAccess?:AppsFlyerAccessWhereInput,
	eventName?:string,
	eventName_contains?:string,
	eventName_ends_with?:string,
	eventName_gt?:string,
	eventName_gte?:string,
	eventName_in?:string[],
	eventName_lt?:string,
	eventName_lte?:string,
	eventName_not?:string,
	eventName_not_contains?:string,
	eventName_not_ends_with?:string,
	eventName_not_in?:string[],
	eventName_not_starts_with?:string,
	eventName_starts_with?:string,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppsFlyerRawEventsFeedWhereInput[],
	OR?:AppsFlyerRawEventsFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reportType?:string,
	reportType_contains?:string,
	reportType_ends_with?:string,
	reportType_gt?:string,
	reportType_gte?:string,
	reportType_in?:string[],
	reportType_lt?:string,
	reportType_lte?:string,
	reportType_not?:string,
	reportType_not_contains?:string,
	reportType_not_ends_with?:string,
	reportType_not_in?:string[],
	reportType_not_starts_with?:string,
	reportType_starts_with?:string
}

export type AppsFlyerRawInstallsFeed = {
	__typename?: "AppsFlyerRawInstallsFeed",
	appsFlyerAccess:AppsFlyerAccess,
	gameId:string,
	id:string,
	omitFields:string[],
	params:IntegrationFeedParams,
	reportType:string
}

export type AppsFlyerRawInstallsFeedCreateWithoutParamsInput = {
		appsFlyerAccess:AppsFlyerAccessWhereUniqueInput,
	gameId:string,
	id?:string,
	omitFields:string[],
	reportType:string
}

export type AppsFlyerRawInstallsFeedWhereInput = {
		AND?:AppsFlyerRawInstallsFeedWhereInput[],
	appsFlyerAccess?:AppsFlyerAccessWhereInput,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppsFlyerRawInstallsFeedWhereInput[],
	OR?:AppsFlyerRawInstallsFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reportType?:string,
	reportType_contains?:string,
	reportType_ends_with?:string,
	reportType_gt?:string,
	reportType_gte?:string,
	reportType_in?:string[],
	reportType_lt?:string,
	reportType_lte?:string,
	reportType_not?:string,
	reportType_not_contains?:string,
	reportType_not_ends_with?:string,
	reportType_not_in?:string[],
	reportType_not_starts_with?:string,
	reportType_starts_with?:string
}

export type AppsFlyerScraperFeed = {
	__typename?: "AppsFlyerScraperFeed",
	email:string,
	games?:ScraperGame[],
	id:string,
	params:IntegrationFeedParams,
	password:string
}

export type AppsFlyerScraperFeedCreateWithoutParamsInput = {
		email:string,
	games?:ScraperGameCreateInput[],
	id?:string,
	password:string
}

export type AppsFlyerScraperFeedWhereInput = {
		AND?:AppsFlyerScraperFeedWhereInput[],
	email?:string,
	email_contains?:string,
	email_ends_with?:string,
	email_gt?:string,
	email_gte?:string,
	email_in?:string[],
	email_lt?:string,
	email_lte?:string,
	email_not?:string,
	email_not_contains?:string,
	email_not_ends_with?:string,
	email_not_in?:string[],
	email_not_starts_with?:string,
	email_starts_with?:string,
	games_every?:ScraperGameWhereInput,
	games_none?:ScraperGameWhereInput,
	games_some?:ScraperGameWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AppsFlyerScraperFeedWhereInput[],
	OR?:AppsFlyerScraperFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	password?:string,
	password_contains?:string,
	password_ends_with?:string,
	password_gt?:string,
	password_gte?:string,
	password_in?:string[],
	password_lt?:string,
	password_lte?:string,
	password_not?:string,
	password_not_contains?:string,
	password_not_ends_with?:string,
	password_not_in?:string[],
	password_not_starts_with?:string,
	password_starts_with?:string
}

export type AwsProject = {
	__typename?: "AwsProject",
	accessKeyId:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	s3Destinations?:S3Destination[],
	secretAccessKey:string,
	updatedAt:DateTime
}

export type AwsProjectCreateInput = {
		accessKeyId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	secretAccessKey:string
}

export enum AwsProjectOrderByInput {
	accessKeyId_ASC = "accessKeyId_ASC",
	accessKeyId_DESC = "accessKeyId_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	secretAccessKey_ASC = "secretAccessKey_ASC",
	secretAccessKey_DESC = "secretAccessKey_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type AwsProjectUpdateInput = {
		accessKeyId?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	secretAccessKey?:string
}

export type AwsProjectWhereInput = {
		accessKeyId?:string,
	accessKeyId_contains?:string,
	accessKeyId_ends_with?:string,
	accessKeyId_gt?:string,
	accessKeyId_gte?:string,
	accessKeyId_in?:string[],
	accessKeyId_lt?:string,
	accessKeyId_lte?:string,
	accessKeyId_not?:string,
	accessKeyId_not_contains?:string,
	accessKeyId_not_ends_with?:string,
	accessKeyId_not_in?:string[],
	accessKeyId_not_starts_with?:string,
	accessKeyId_starts_with?:string,
	AND?:AwsProjectWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:AwsProjectWhereInput[],
	OR?:AwsProjectWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	s3Destinations_every?:S3DestinationWhereInput,
	s3Destinations_none?:S3DestinationWhereInput,
	s3Destinations_some?:S3DestinationWhereInput,
	secretAccessKey?:string,
	secretAccessKey_contains?:string,
	secretAccessKey_ends_with?:string,
	secretAccessKey_gt?:string,
	secretAccessKey_gte?:string,
	secretAccessKey_in?:string[],
	secretAccessKey_lt?:string,
	secretAccessKey_lte?:string,
	secretAccessKey_not?:string,
	secretAccessKey_not_contains?:string,
	secretAccessKey_not_ends_with?:string,
	secretAccessKey_not_in?:string[],
	secretAccessKey_not_starts_with?:string,
	secretAccessKey_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type AwsProjectWhereUniqueInput = {
		id:string
}

export type BigQueryCheck = {
	__typename?: "BigQueryCheck",
	createdAt:DateTime,
	dataset:string,
	id:string,
	lastModified?:DateTime,
	maxDate?:DateTime,
	organizationAttributes?:OrganizationAttribute[],
	projectId:string,
	tableExists:boolean,
	tableName:string,
	tableReference:string,
	tableSuffix?:string,
	updatedAt:DateTime
}

export type BigQueryCheckConnection = {
	__typename?: "BigQueryCheckConnection",
	aggregate:AggregateBigQueryCheck,
	edges:BigQueryCheckEdge[],
	pageInfo:PageInfo
}

export type BigQueryCheckEdge = {
	__typename?: "BigQueryCheckEdge",
	cursor:string,
	node:BigQueryCheck
}

export enum BigQueryCheckOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	dataset_ASC = "dataset_ASC",
	dataset_DESC = "dataset_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	lastModified_ASC = "lastModified_ASC",
	lastModified_DESC = "lastModified_DESC",
	maxDate_ASC = "maxDate_ASC",
	maxDate_DESC = "maxDate_DESC",
	projectId_ASC = "projectId_ASC",
	projectId_DESC = "projectId_DESC",
	tableExists_ASC = "tableExists_ASC",
	tableExists_DESC = "tableExists_DESC",
	tableName_ASC = "tableName_ASC",
	tableName_DESC = "tableName_DESC",
	tableReference_ASC = "tableReference_ASC",
	tableReference_DESC = "tableReference_DESC",
	tableSuffix_ASC = "tableSuffix_ASC",
	tableSuffix_DESC = "tableSuffix_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type BigQueryCheckWhereInput = {
		AND?:BigQueryCheckWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	dataset?:string,
	dataset_contains?:string,
	dataset_ends_with?:string,
	dataset_gt?:string,
	dataset_gte?:string,
	dataset_in?:string[],
	dataset_lt?:string,
	dataset_lte?:string,
	dataset_not?:string,
	dataset_not_contains?:string,
	dataset_not_ends_with?:string,
	dataset_not_in?:string[],
	dataset_not_starts_with?:string,
	dataset_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	lastModified?:DateTime,
	lastModified_gt?:DateTime,
	lastModified_gte?:DateTime,
	lastModified_in?:DateTime[],
	lastModified_lt?:DateTime,
	lastModified_lte?:DateTime,
	lastModified_not?:DateTime,
	lastModified_not_in?:DateTime[],
	maxDate?:DateTime,
	maxDate_gt?:DateTime,
	maxDate_gte?:DateTime,
	maxDate_in?:DateTime[],
	maxDate_lt?:DateTime,
	maxDate_lte?:DateTime,
	maxDate_not?:DateTime,
	maxDate_not_in?:DateTime[],
	NOT?:BigQueryCheckWhereInput[],
	OR?:BigQueryCheckWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	projectId?:string,
	projectId_contains?:string,
	projectId_ends_with?:string,
	projectId_gt?:string,
	projectId_gte?:string,
	projectId_in?:string[],
	projectId_lt?:string,
	projectId_lte?:string,
	projectId_not?:string,
	projectId_not_contains?:string,
	projectId_not_ends_with?:string,
	projectId_not_in?:string[],
	projectId_not_starts_with?:string,
	projectId_starts_with?:string,
	tableExists?:boolean,
	tableExists_not?:boolean,
	tableName?:string,
	tableName_contains?:string,
	tableName_ends_with?:string,
	tableName_gt?:string,
	tableName_gte?:string,
	tableName_in?:string[],
	tableName_lt?:string,
	tableName_lte?:string,
	tableName_not?:string,
	tableName_not_contains?:string,
	tableName_not_ends_with?:string,
	tableName_not_in?:string[],
	tableName_not_starts_with?:string,
	tableName_starts_with?:string,
	tableReference?:string,
	tableReference_contains?:string,
	tableReference_ends_with?:string,
	tableReference_gt?:string,
	tableReference_gte?:string,
	tableReference_in?:string[],
	tableReference_lt?:string,
	tableReference_lte?:string,
	tableReference_not?:string,
	tableReference_not_contains?:string,
	tableReference_not_ends_with?:string,
	tableReference_not_in?:string[],
	tableReference_not_starts_with?:string,
	tableReference_starts_with?:string,
	tableSuffix?:string,
	tableSuffix_contains?:string,
	tableSuffix_ends_with?:string,
	tableSuffix_gt?:string,
	tableSuffix_gte?:string,
	tableSuffix_in?:string[],
	tableSuffix_lt?:string,
	tableSuffix_lte?:string,
	tableSuffix_not?:string,
	tableSuffix_not_contains?:string,
	tableSuffix_not_ends_with?:string,
	tableSuffix_not_in?:string[],
	tableSuffix_not_starts_with?:string,
	tableSuffix_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type BigQueryCheckWhereUniqueInput = {
		id?:string,
	tableReference?:string
}

export type BigQueryDestination = {
	__typename?: "BigQueryDestination",
	bqProject:BigQueryProject,
	createdAt:DateTime,
	dataset:string,
	descriptiveName:string,
	enabled:boolean,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	tablePrefix?:string,
	tableSuffix?:string,
	updatedAt:DateTime
}

export type BigQueryDestinationCreateInput = {
		bqProject:BigQueryProjectWhereUniqueInput,
	dataset:string,
	descriptiveName:string,
	enabled:boolean,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	tablePrefix?:string,
	tableSuffix?:string
}

export enum BigQueryDestinationOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	dataset_ASC = "dataset_ASC",
	dataset_DESC = "dataset_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	enabled_ASC = "enabled_ASC",
	enabled_DESC = "enabled_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	tablePrefix_ASC = "tablePrefix_ASC",
	tablePrefix_DESC = "tablePrefix_DESC",
	tableSuffix_ASC = "tableSuffix_ASC",
	tableSuffix_DESC = "tableSuffix_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type BigQueryDestinationUpdateInput = {
		bqProject?:BigQueryProjectWhereUniqueInput,
	dataset?:string,
	descriptiveName?:string,
	enabled?:boolean,
	id:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	tablePrefix?:string,
	tableSuffix?:string
}

export type BigQueryDestinationUpdateManyInput = {
		create?:BigQueryDestinationCreateInput[],
	delete?:BigQueryDestinationWhereUniqueInput[],
	update?:BigQueryDestinationUpdateInput[]
}

export type BigQueryDestinationWhereInput = {
		AND?:BigQueryDestinationWhereInput[],
	bqProject?:BigQueryProjectWhereInput,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	dataset?:string,
	dataset_contains?:string,
	dataset_ends_with?:string,
	dataset_gt?:string,
	dataset_gte?:string,
	dataset_in?:string[],
	dataset_lt?:string,
	dataset_lte?:string,
	dataset_not?:string,
	dataset_not_contains?:string,
	dataset_not_ends_with?:string,
	dataset_not_in?:string[],
	dataset_not_starts_with?:string,
	dataset_starts_with?:string,
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:BigQueryDestinationWhereInput[],
	OR?:BigQueryDestinationWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	tablePrefix?:string,
	tablePrefix_contains?:string,
	tablePrefix_ends_with?:string,
	tablePrefix_gt?:string,
	tablePrefix_gte?:string,
	tablePrefix_in?:string[],
	tablePrefix_lt?:string,
	tablePrefix_lte?:string,
	tablePrefix_not?:string,
	tablePrefix_not_contains?:string,
	tablePrefix_not_ends_with?:string,
	tablePrefix_not_in?:string[],
	tablePrefix_not_starts_with?:string,
	tablePrefix_starts_with?:string,
	tableSuffix?:string,
	tableSuffix_contains?:string,
	tableSuffix_ends_with?:string,
	tableSuffix_gt?:string,
	tableSuffix_gte?:string,
	tableSuffix_in?:string[],
	tableSuffix_lt?:string,
	tableSuffix_lte?:string,
	tableSuffix_not?:string,
	tableSuffix_not_contains?:string,
	tableSuffix_not_ends_with?:string,
	tableSuffix_not_in?:string[],
	tableSuffix_not_starts_with?:string,
	tableSuffix_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type BigQueryDestinationWhereUniqueInput = {
		id:string
}

export type BigQueryProject = {
	__typename?: "BigQueryProject",
	bqDestinations?:BigQueryDestination[],
	clientEmail:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	privateKey:string,
	projectId:string,
	updatedAt:DateTime
}

export type BigQueryProjectCreateInput = {
		clientEmail:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	privateKey:string,
	projectId:string
}

export enum BigQueryProjectOrderByInput {
	clientEmail_ASC = "clientEmail_ASC",
	clientEmail_DESC = "clientEmail_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	privateKey_ASC = "privateKey_ASC",
	privateKey_DESC = "privateKey_DESC",
	projectId_ASC = "projectId_ASC",
	projectId_DESC = "projectId_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type BigQueryProjectUpdateInput = {
		clientEmail?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	privateKey?:string,
	projectId?:string
}

export type BigQueryProjectWhereInput = {
		AND?:BigQueryProjectWhereInput[],
	bqDestinations_every?:BigQueryDestinationWhereInput,
	bqDestinations_none?:BigQueryDestinationWhereInput,
	bqDestinations_some?:BigQueryDestinationWhereInput,
	clientEmail?:string,
	clientEmail_contains?:string,
	clientEmail_ends_with?:string,
	clientEmail_gt?:string,
	clientEmail_gte?:string,
	clientEmail_in?:string[],
	clientEmail_lt?:string,
	clientEmail_lte?:string,
	clientEmail_not?:string,
	clientEmail_not_contains?:string,
	clientEmail_not_ends_with?:string,
	clientEmail_not_in?:string[],
	clientEmail_not_starts_with?:string,
	clientEmail_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:BigQueryProjectWhereInput[],
	OR?:BigQueryProjectWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	privateKey?:string,
	privateKey_contains?:string,
	privateKey_ends_with?:string,
	privateKey_gt?:string,
	privateKey_gte?:string,
	privateKey_in?:string[],
	privateKey_lt?:string,
	privateKey_lte?:string,
	privateKey_not?:string,
	privateKey_not_contains?:string,
	privateKey_not_ends_with?:string,
	privateKey_not_in?:string[],
	privateKey_not_starts_with?:string,
	privateKey_starts_with?:string,
	projectId?:string,
	projectId_contains?:string,
	projectId_ends_with?:string,
	projectId_gt?:string,
	projectId_gte?:string,
	projectId_in?:string[],
	projectId_lt?:string,
	projectId_lte?:string,
	projectId_not?:string,
	projectId_not_contains?:string,
	projectId_not_ends_with?:string,
	projectId_not_in?:string[],
	projectId_not_starts_with?:string,
	projectId_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type BigQueryProjectWhereUniqueInput = {
		id:string
}

export type CampaignConsistencyCheck = {
	__typename?: "CampaignConsistencyCheck",
	campaignId:string,
	createdAt:DateTime,
	date:DateTime,
	gameId:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	platform:Platform,
	updatedAt:DateTime,
	values?:CampaignConsistencyValues[]
}

export type CampaignConsistencyCheckConnection = {
	__typename?: "CampaignConsistencyCheckConnection",
	aggregate:AggregateCampaignConsistencyCheck,
	edges:CampaignConsistencyCheckEdge[],
	pageInfo:PageInfo
}

export type CampaignConsistencyCheckEdge = {
	__typename?: "CampaignConsistencyCheckEdge",
	cursor:string,
	node:CampaignConsistencyCheck
}

export enum CampaignConsistencyCheckOrderByInput {
	campaignId_ASC = "campaignId_ASC",
	campaignId_DESC = "campaignId_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	date_ASC = "date_ASC",
	date_DESC = "date_DESC",
	gameId_ASC = "gameId_ASC",
	gameId_DESC = "gameId_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	platform_ASC = "platform_ASC",
	platform_DESC = "platform_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type CampaignConsistencyCheckWhereInput = {
		AND?:CampaignConsistencyCheckWhereInput[],
	campaignId?:string,
	campaignId_contains?:string,
	campaignId_ends_with?:string,
	campaignId_gt?:string,
	campaignId_gte?:string,
	campaignId_in?:string[],
	campaignId_lt?:string,
	campaignId_lte?:string,
	campaignId_not?:string,
	campaignId_not_contains?:string,
	campaignId_not_ends_with?:string,
	campaignId_not_in?:string[],
	campaignId_not_starts_with?:string,
	campaignId_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	date?:DateTime,
	date_gt?:DateTime,
	date_gte?:DateTime,
	date_in?:DateTime[],
	date_lt?:DateTime,
	date_lte?:DateTime,
	date_not?:DateTime,
	date_not_in?:DateTime[],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:CampaignConsistencyCheckWhereInput[],
	OR?:CampaignConsistencyCheckWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	platform?:Platform,
	platform_in?:Platform[],
	platform_not?:Platform,
	platform_not_in?:Platform[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[],
	values_every?:CampaignConsistencyValuesWhereInput,
	values_none?:CampaignConsistencyValuesWhereInput,
	values_some?:CampaignConsistencyValuesWhereInput
}

export type CampaignConsistencyCheckWhereUniqueInput = {
		id?:string
}

export type CampaignConsistencyValues = {
	__typename?: "CampaignConsistencyValues",
	clicks?:number,
	createdAt:DateTime,
	iapGrossRevenue?:number,
	id:string,
	impressions?:number,
	installs?:number,
	payers?:number,
	purchases?:number,
	source:ConsistencySource,
	spend?:number,
	updatedAt:DateTime
}

export enum CampaignConsistencyValuesOrderByInput {
	clicks_ASC = "clicks_ASC",
	clicks_DESC = "clicks_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	iapGrossRevenue_ASC = "iapGrossRevenue_ASC",
	iapGrossRevenue_DESC = "iapGrossRevenue_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	impressions_ASC = "impressions_ASC",
	impressions_DESC = "impressions_DESC",
	installs_ASC = "installs_ASC",
	installs_DESC = "installs_DESC",
	payers_ASC = "payers_ASC",
	payers_DESC = "payers_DESC",
	purchases_ASC = "purchases_ASC",
	purchases_DESC = "purchases_DESC",
	source_ASC = "source_ASC",
	source_DESC = "source_DESC",
	spend_ASC = "spend_ASC",
	spend_DESC = "spend_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type CampaignConsistencyValuesWhereInput = {
		AND?:CampaignConsistencyValuesWhereInput[],
	clicks?:number,
	clicks_gt?:number,
	clicks_gte?:number,
	clicks_in?:number[],
	clicks_lt?:number,
	clicks_lte?:number,
	clicks_not?:number,
	clicks_not_in?:number[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	iapGrossRevenue?:number,
	iapGrossRevenue_gt?:number,
	iapGrossRevenue_gte?:number,
	iapGrossRevenue_in?:number[],
	iapGrossRevenue_lt?:number,
	iapGrossRevenue_lte?:number,
	iapGrossRevenue_not?:number,
	iapGrossRevenue_not_in?:number[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	impressions?:number,
	impressions_gt?:number,
	impressions_gte?:number,
	impressions_in?:number[],
	impressions_lt?:number,
	impressions_lte?:number,
	impressions_not?:number,
	impressions_not_in?:number[],
	installs?:number,
	installs_gt?:number,
	installs_gte?:number,
	installs_in?:number[],
	installs_lt?:number,
	installs_lte?:number,
	installs_not?:number,
	installs_not_in?:number[],
	NOT?:CampaignConsistencyValuesWhereInput[],
	OR?:CampaignConsistencyValuesWhereInput[],
	payers?:number,
	payers_gt?:number,
	payers_gte?:number,
	payers_in?:number[],
	payers_lt?:number,
	payers_lte?:number,
	payers_not?:number,
	payers_not_in?:number[],
	purchases?:number,
	purchases_gt?:number,
	purchases_gte?:number,
	purchases_in?:number[],
	purchases_lt?:number,
	purchases_lte?:number,
	purchases_not?:number,
	purchases_not_in?:number[],
	source?:ConsistencySource,
	source_in?:ConsistencySource[],
	source_not?:ConsistencySource,
	source_not_in?:ConsistencySource[],
	spend?:number,
	spend_gt?:number,
	spend_gte?:number,
	spend_in?:number[],
	spend_lt?:number,
	spend_lte?:number,
	spend_not?:number,
	spend_not_in?:number[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type ChartboostAccess = {
	__typename?: "ChartboostAccess",
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime,
	userId:string,
	userSignature:string
}

export type ChartboostAccessCreateInput = {
		descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	userId:string,
	userSignature:string
}

export enum ChartboostAccessOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC",
	userId_ASC = "userId_ASC",
	userId_DESC = "userId_DESC",
	userSignature_ASC = "userSignature_ASC",
	userSignature_DESC = "userSignature_DESC"
}

export type ChartboostAccessUpdateInput = {
		descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	userId?:string,
	userSignature?:string
}

export type ChartboostAccessWhereInput = {
		AND?:ChartboostAccessWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ChartboostAccessWhereInput[],
	OR?:ChartboostAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[],
	userId?:string,
	userId_contains?:string,
	userId_ends_with?:string,
	userId_gt?:string,
	userId_gte?:string,
	userId_in?:string[],
	userId_lt?:string,
	userId_lte?:string,
	userId_not?:string,
	userId_not_contains?:string,
	userId_not_ends_with?:string,
	userId_not_in?:string[],
	userId_not_starts_with?:string,
	userId_starts_with?:string,
	userSignature?:string,
	userSignature_contains?:string,
	userSignature_ends_with?:string,
	userSignature_gt?:string,
	userSignature_gte?:string,
	userSignature_in?:string[],
	userSignature_lt?:string,
	userSignature_lte?:string,
	userSignature_not?:string,
	userSignature_not_contains?:string,
	userSignature_not_ends_with?:string,
	userSignature_not_in?:string[],
	userSignature_not_starts_with?:string,
	userSignature_starts_with?:string
}

export type ChartboostAccessWhereUniqueInput = {
		id:string
}

export type ChartboostFeed = {
	__typename?: "ChartboostFeed",
	chartboostAccess:ChartboostAccess,
	gameId:string,
	groupBy?:string,
	id:string,
	params:IntegrationFeedParams
}

export type ChartboostFeedCreateWithoutParamsInput = {
		chartboostAccess:ChartboostAccessWhereUniqueInput,
	gameId:string,
	groupBy?:string,
	id?:string
}

export type ChartboostFeedWhereInput = {
		AND?:ChartboostFeedWhereInput[],
	chartboostAccess?:ChartboostAccessWhereInput,
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	groupBy?:string,
	groupBy_contains?:string,
	groupBy_ends_with?:string,
	groupBy_gt?:string,
	groupBy_gte?:string,
	groupBy_in?:string[],
	groupBy_lt?:string,
	groupBy_lte?:string,
	groupBy_not?:string,
	groupBy_not_contains?:string,
	groupBy_not_ends_with?:string,
	groupBy_not_in?:string[],
	groupBy_not_starts_with?:string,
	groupBy_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:ChartboostFeedWhereInput[],
	OR?:ChartboostFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput
}

export enum ConsistencySource {
	APPSFLYER = "APPSFLYER",
	FACEBOOK = "FACEBOOK",
	STORE = "STORE"
}

export type DailyConsistencyCheck = {
	__typename?: "DailyConsistencyCheck",
	createdAt:DateTime,
	date:DateTime,
	gameId:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	platform:Platform,
	updatedAt:DateTime,
	values?:DailyConsistencyValues[]
}

export type DailyConsistencyCheckConnection = {
	__typename?: "DailyConsistencyCheckConnection",
	aggregate:AggregateDailyConsistencyCheck,
	edges:DailyConsistencyCheckEdge[],
	pageInfo:PageInfo
}

export type DailyConsistencyCheckEdge = {
	__typename?: "DailyConsistencyCheckEdge",
	cursor:string,
	node:DailyConsistencyCheck
}

export enum DailyConsistencyCheckOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	date_ASC = "date_ASC",
	date_DESC = "date_DESC",
	gameId_ASC = "gameId_ASC",
	gameId_DESC = "gameId_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	platform_ASC = "platform_ASC",
	platform_DESC = "platform_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type DailyConsistencyCheckWhereInput = {
		AND?:DailyConsistencyCheckWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	date?:DateTime,
	date_gt?:DateTime,
	date_gte?:DateTime,
	date_in?:DateTime[],
	date_lt?:DateTime,
	date_lte?:DateTime,
	date_not?:DateTime,
	date_not_in?:DateTime[],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:DailyConsistencyCheckWhereInput[],
	OR?:DailyConsistencyCheckWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	platform?:Platform,
	platform_in?:Platform[],
	platform_not?:Platform,
	platform_not_in?:Platform[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[],
	values_every?:DailyConsistencyValuesWhereInput,
	values_none?:DailyConsistencyValuesWhereInput,
	values_some?:DailyConsistencyValuesWhereInput
}

export type DailyConsistencyCheckWhereUniqueInput = {
		id?:string
}

export type DailyConsistencyValues = {
	__typename?: "DailyConsistencyValues",
	createdAt:DateTime,
	id:string,
	installs?:number,
	purchases?:number,
	revenue?:number,
	source:ConsistencySource,
	updatedAt:DateTime
}

export enum DailyConsistencyValuesOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	installs_ASC = "installs_ASC",
	installs_DESC = "installs_DESC",
	purchases_ASC = "purchases_ASC",
	purchases_DESC = "purchases_DESC",
	revenue_ASC = "revenue_ASC",
	revenue_DESC = "revenue_DESC",
	source_ASC = "source_ASC",
	source_DESC = "source_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type DailyConsistencyValuesWhereInput = {
		AND?:DailyConsistencyValuesWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	installs?:number,
	installs_gt?:number,
	installs_gte?:number,
	installs_in?:number[],
	installs_lt?:number,
	installs_lte?:number,
	installs_not?:number,
	installs_not_in?:number[],
	NOT?:DailyConsistencyValuesWhereInput[],
	OR?:DailyConsistencyValuesWhereInput[],
	purchases?:number,
	purchases_gt?:number,
	purchases_gte?:number,
	purchases_in?:number[],
	purchases_lt?:number,
	purchases_lte?:number,
	purchases_not?:number,
	purchases_not_in?:number[],
	revenue?:number,
	revenue_gt?:number,
	revenue_gte?:number,
	revenue_in?:number[],
	revenue_lt?:number,
	revenue_lte?:number,
	revenue_not?:number,
	revenue_not_in?:number[],
	source?:ConsistencySource,
	source_in?:ConsistencySource[],
	source_not?:ConsistencySource,
	source_not_in?:ConsistencySource[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type DateTime = any

export type Error = {
	__typename?: "Error",
	createdAt:DateTime,
	id:string,
	message:string,
	updatedAt:DateTime
}

export enum ErrorOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	message_ASC = "message_ASC",
	message_DESC = "message_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type ErrorWhereInput = {
		AND?:ErrorWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	message?:string,
	message_contains?:string,
	message_ends_with?:string,
	message_gt?:string,
	message_gte?:string,
	message_in?:string[],
	message_lt?:string,
	message_lte?:string,
	message_not?:string,
	message_not_contains?:string,
	message_not_ends_with?:string,
	message_not_in?:string[],
	message_not_starts_with?:string,
	message_starts_with?:string,
	NOT?:ErrorWhereInput[],
	OR?:ErrorWhereInput[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type FacebookAdsAccess = {
	__typename?: "FacebookAdsAccess",
	accessToken:string,
	accountId:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type FacebookAdsAccessCreateInput = {
		accessToken:string,
	accountId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum FacebookAdsAccessOrderByInput {
	accessToken_ASC = "accessToken_ASC",
	accessToken_DESC = "accessToken_DESC",
	accountId_ASC = "accountId_ASC",
	accountId_DESC = "accountId_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type FacebookAdsAccessUpdateInput = {
		accessToken?:string,
	accountId?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type FacebookAdsAccessWhereInput = {
		accessToken?:string,
	accessToken_contains?:string,
	accessToken_ends_with?:string,
	accessToken_gt?:string,
	accessToken_gte?:string,
	accessToken_in?:string[],
	accessToken_lt?:string,
	accessToken_lte?:string,
	accessToken_not?:string,
	accessToken_not_contains?:string,
	accessToken_not_ends_with?:string,
	accessToken_not_in?:string[],
	accessToken_not_starts_with?:string,
	accessToken_starts_with?:string,
	accountId?:string,
	accountId_contains?:string,
	accountId_ends_with?:string,
	accountId_gt?:string,
	accountId_gte?:string,
	accountId_in?:string[],
	accountId_lt?:string,
	accountId_lte?:string,
	accountId_not?:string,
	accountId_not_contains?:string,
	accountId_not_ends_with?:string,
	accountId_not_in?:string[],
	accountId_not_starts_with?:string,
	accountId_starts_with?:string,
	AND?:FacebookAdsAccessWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FacebookAdsAccessWhereInput[],
	OR?:FacebookAdsAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type FacebookAdsAccessWhereUniqueInput = {
		id:string
}

export type FacebookAdsFeed = {
	__typename?: "FacebookAdsFeed",
	additionalFields:string[],
	breakdowns?:FacebookBreakdown[],
	facebookAdsAccess:FacebookAdsAccess,
	id:string,
	levels:string[],
	omitFields:string[],
	params:IntegrationFeedParams,
	snapshot?:boolean
}

export type FacebookAdsFeedCreateWithoutParamsInput = {
		additionalFields:string[],
	breakdowns:FacebookBreakdownCreateInput[],
	facebookAdsAccess:FacebookAdsAccessWhereUniqueInput,
	id?:string,
	levels:string[],
	omitFields:string[],
	snapshot?:boolean
}

export type FacebookAdsFeedWhereInput = {
		AND?:FacebookAdsFeedWhereInput[],
	breakdowns_every?:FacebookBreakdownWhereInput,
	breakdowns_none?:FacebookBreakdownWhereInput,
	breakdowns_some?:FacebookBreakdownWhereInput,
	facebookAdsAccess?:FacebookAdsAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FacebookAdsFeedWhereInput[],
	OR?:FacebookAdsFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	snapshot?:boolean,
	snapshot_not?:boolean
}

export type FacebookBreakdown = {
	__typename?: "FacebookBreakdown",
	id:string,
	values:string[]
}

export type FacebookBreakdownCreateInput = {
		values:string[]
}

export enum FacebookBreakdownOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type FacebookBreakdownWhereInput = {
		AND?:FacebookBreakdownWhereInput[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FacebookBreakdownWhereInput[],
	OR?:FacebookBreakdownWhereInput[]
}

export type FairbidAccess = {
	__typename?: "FairbidAccess",
	clientId:string,
	clientSecret:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type FairbidAccessCreateInput = {
		clientId:string,
	clientSecret:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum FairbidAccessOrderByInput {
	clientId_ASC = "clientId_ASC",
	clientId_DESC = "clientId_DESC",
	clientSecret_ASC = "clientSecret_ASC",
	clientSecret_DESC = "clientSecret_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type FairbidAccessUpdateInput = {
		clientId?:string,
	clientSecret?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type FairbidAccessWhereInput = {
		AND?:FairbidAccessWhereInput[],
	clientId?:string,
	clientId_contains?:string,
	clientId_ends_with?:string,
	clientId_gt?:string,
	clientId_gte?:string,
	clientId_in?:string[],
	clientId_lt?:string,
	clientId_lte?:string,
	clientId_not?:string,
	clientId_not_contains?:string,
	clientId_not_ends_with?:string,
	clientId_not_in?:string[],
	clientId_not_starts_with?:string,
	clientId_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FairbidAccessWhereInput[],
	OR?:FairbidAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type FairbidAccessWhereUniqueInput = {
		id:string
}

export type FairbidUserReportFeed = {
	__typename?: "FairbidUserReportFeed",
	appId:string,
	fairbidAccess:FairbidAccess,
	id:string,
	params:IntegrationFeedParams
}

export type FairbidUserReportFeedCreateWithoutParamsInput = {
		appId:string,
	fairbidAccess:FairbidAccessWhereUniqueInput,
	id?:string
}

export type FairbidUserReportFeedWhereInput = {
		AND?:FairbidUserReportFeedWhereInput[],
	appId?:string,
	appId_contains?:string,
	appId_ends_with?:string,
	appId_gt?:string,
	appId_gte?:string,
	appId_in?:string[],
	appId_lt?:string,
	appId_lte?:string,
	appId_not?:string,
	appId_not_contains?:string,
	appId_not_ends_with?:string,
	appId_not_in?:string[],
	appId_not_starts_with?:string,
	appId_starts_with?:string,
	fairbidAccess?:FairbidAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FairbidUserReportFeedWhereInput[],
	OR?:FairbidUserReportFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput
}

export enum Feed {
	APPLE = "APPLE",
	APPLE_SEARCH_ADS = "APPLE_SEARCH_ADS",
	APPLOVIN = "APPLOVIN",
	APPSFLYER_CAMPAIGN = "APPSFLYER_CAMPAIGN",
	APPSFLYER_DATALOCKER = "APPSFLYER_DATALOCKER",
	APPSFLYER_RAW_EVENTS = "APPSFLYER_RAW_EVENTS",
	APPSFLYER_RAW_INSTALLS = "APPSFLYER_RAW_INSTALLS",
	APPSFLYER_SCRAPER = "APPSFLYER_SCRAPER",
	CHARTBOOST = "CHARTBOOST",
	FACEBOOK_ADS = "FACEBOOK_ADS",
	FAIRBID_USER_REPORT = "FAIRBID_USER_REPORT",
	FIXER = "FIXER",
	GOOGLE_ADWORDS = "GOOGLE_ADWORDS",
	GOOGLE_PLAY = "GOOGLE_PLAY",
	HARVEST = "HARVEST",
	IRON_SOURCE = "IRON_SOURCE",
	IRON_SOURCE_PROMOTION = "IRON_SOURCE_PROMOTION",
	LIBRING = "LIBRING",
	SNAPCHAT = "SNAPCHAT",
	TIKTOK_ADS_AUDIENCE = "TIKTOK_ADS_AUDIENCE",
	UNITY_ADS = "UNITY_ADS",
	UNITY_ADVERTISER = "UNITY_ADVERTISER"
}

export type FeedDateInput = {
	__typename?: "FeedDateInput",
	dateDashFrom?:string,
	dateDashTo?:string,
	daysInPast?:number,
	id:string,
	offset?:number
}

export type FeedDateInputCreateInput = {
		dateDashFrom?:string,
	dateDashTo?:string,
	daysInPast?:number,
	id?:string,
	offset?:number
}

export type FeedDateInputWhereInput = {
		AND?:FeedDateInputWhereInput[],
	dateDashFrom?:string,
	dateDashFrom_contains?:string,
	dateDashFrom_ends_with?:string,
	dateDashFrom_gt?:string,
	dateDashFrom_gte?:string,
	dateDashFrom_in?:string[],
	dateDashFrom_lt?:string,
	dateDashFrom_lte?:string,
	dateDashFrom_not?:string,
	dateDashFrom_not_contains?:string,
	dateDashFrom_not_ends_with?:string,
	dateDashFrom_not_in?:string[],
	dateDashFrom_not_starts_with?:string,
	dateDashFrom_starts_with?:string,
	dateDashTo?:string,
	dateDashTo_contains?:string,
	dateDashTo_ends_with?:string,
	dateDashTo_gt?:string,
	dateDashTo_gte?:string,
	dateDashTo_in?:string[],
	dateDashTo_lt?:string,
	dateDashTo_lte?:string,
	dateDashTo_not?:string,
	dateDashTo_not_contains?:string,
	dateDashTo_not_ends_with?:string,
	dateDashTo_not_in?:string[],
	dateDashTo_not_starts_with?:string,
	dateDashTo_starts_with?:string,
	daysInPast?:number,
	daysInPast_gt?:number,
	daysInPast_gte?:number,
	daysInPast_in?:number[],
	daysInPast_lt?:number,
	daysInPast_lte?:number,
	daysInPast_not?:number,
	daysInPast_not_in?:number[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FeedDateInputWhereInput[],
	offset?:number,
	offset_gt?:number,
	offset_gte?:number,
	offset_in?:number[],
	offset_lt?:number,
	offset_lte?:number,
	offset_not?:number,
	offset_not_in?:number[],
	OR?:FeedDateInputWhereInput[]
}

export type FixerFeed = {
	__typename?: "FixerFeed",
	currency:string,
	id:string,
	params:IntegrationFeedParams
}

export type FixerFeedCreateWithoutParamsInput = {
		currency:string,
	id?:string
}

export type FixerFeedWhereInput = {
		AND?:FixerFeedWhereInput[],
	currency?:string,
	currency_contains?:string,
	currency_ends_with?:string,
	currency_gt?:string,
	currency_gte?:string,
	currency_in?:string[],
	currency_lt?:string,
	currency_lte?:string,
	currency_not?:string,
	currency_not_contains?:string,
	currency_not_ends_with?:string,
	currency_not_in?:string[],
	currency_not_starts_with?:string,
	currency_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:FixerFeedWhereInput[],
	OR?:FixerFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput
}

export type GoogleAdsAccess = {
	__typename?: "GoogleAdsAccess",
	clientId:string,
	clientSecret:string,
	createdAt:DateTime,
	descriptiveName:string,
	developerToken:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type GoogleAdsAccessCreateInput = {
		clientId:string,
	clientSecret:string,
	descriptiveName:string,
	developerToken:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum GoogleAdsAccessOrderByInput {
	clientId_ASC = "clientId_ASC",
	clientId_DESC = "clientId_DESC",
	clientSecret_ASC = "clientSecret_ASC",
	clientSecret_DESC = "clientSecret_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	developerToken_ASC = "developerToken_ASC",
	developerToken_DESC = "developerToken_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type GoogleAdsAccessUpdateInput = {
		clientId?:string,
	clientSecret?:string,
	descriptiveName?:string,
	developerToken?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type GoogleAdsAccessWhereInput = {
		AND?:GoogleAdsAccessWhereInput[],
	clientId?:string,
	clientId_contains?:string,
	clientId_ends_with?:string,
	clientId_gt?:string,
	clientId_gte?:string,
	clientId_in?:string[],
	clientId_lt?:string,
	clientId_lte?:string,
	clientId_not?:string,
	clientId_not_contains?:string,
	clientId_not_ends_with?:string,
	clientId_not_in?:string[],
	clientId_not_starts_with?:string,
	clientId_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	developerToken?:string,
	developerToken_contains?:string,
	developerToken_ends_with?:string,
	developerToken_gt?:string,
	developerToken_gte?:string,
	developerToken_in?:string[],
	developerToken_lt?:string,
	developerToken_lte?:string,
	developerToken_not?:string,
	developerToken_not_contains?:string,
	developerToken_not_ends_with?:string,
	developerToken_not_in?:string[],
	developerToken_not_starts_with?:string,
	developerToken_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:GoogleAdsAccessWhereInput[],
	OR?:GoogleAdsAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type GoogleAdsAccessWhereUniqueInput = {
		id:string
}

export type GoogleAdwordsFeed = {
	__typename?: "GoogleAdwordsFeed",
	customerAccountId:string,
	geographicView?:boolean,
	googleAdsAccess:GoogleAdsAccess,
	id:string,
	loginCustomerId:string,
	params:IntegrationFeedParams,
	refreshToken:string
}

export type GoogleAdwordsFeedCreateWithoutParamsInput = {
		customerAccountId:string,
	geographicView?:boolean,
	googleAdsAccess:GoogleAdsAccessWhereUniqueInput,
	id?:string,
	loginCustomerId:string,
	refreshToken:string
}

export type GoogleAdwordsFeedWhereInput = {
		AND?:GoogleAdwordsFeedWhereInput[],
	customerAccountId?:string,
	customerAccountId_contains?:string,
	customerAccountId_ends_with?:string,
	customerAccountId_gt?:string,
	customerAccountId_gte?:string,
	customerAccountId_in?:string[],
	customerAccountId_lt?:string,
	customerAccountId_lte?:string,
	customerAccountId_not?:string,
	customerAccountId_not_contains?:string,
	customerAccountId_not_ends_with?:string,
	customerAccountId_not_in?:string[],
	customerAccountId_not_starts_with?:string,
	customerAccountId_starts_with?:string,
	geographicView?:boolean,
	geographicView_not?:boolean,
	googleAdsAccess?:GoogleAdsAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	loginCustomerId?:string,
	loginCustomerId_contains?:string,
	loginCustomerId_ends_with?:string,
	loginCustomerId_gt?:string,
	loginCustomerId_gte?:string,
	loginCustomerId_in?:string[],
	loginCustomerId_lt?:string,
	loginCustomerId_lte?:string,
	loginCustomerId_not?:string,
	loginCustomerId_not_contains?:string,
	loginCustomerId_not_ends_with?:string,
	loginCustomerId_not_in?:string[],
	loginCustomerId_not_starts_with?:string,
	loginCustomerId_starts_with?:string,
	NOT?:GoogleAdwordsFeedWhereInput[],
	OR?:GoogleAdwordsFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string
}

export type GooglePlayAccess = {
	__typename?: "GooglePlayAccess",
	bucketId:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	packageIdentifier?:string,
	sourceClientEmail:string,
	sourcePrivateKey:string,
	sourceProjectId:string,
	updatedAt:DateTime
}

export type GooglePlayAccessCreateInput = {
		bucketId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	packageIdentifier?:string,
	sourceClientEmail:string,
	sourcePrivateKey:string,
	sourceProjectId:string
}

export enum GooglePlayAccessOrderByInput {
	bucketId_ASC = "bucketId_ASC",
	bucketId_DESC = "bucketId_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	packageIdentifier_ASC = "packageIdentifier_ASC",
	packageIdentifier_DESC = "packageIdentifier_DESC",
	sourceClientEmail_ASC = "sourceClientEmail_ASC",
	sourceClientEmail_DESC = "sourceClientEmail_DESC",
	sourcePrivateKey_ASC = "sourcePrivateKey_ASC",
	sourcePrivateKey_DESC = "sourcePrivateKey_DESC",
	sourceProjectId_ASC = "sourceProjectId_ASC",
	sourceProjectId_DESC = "sourceProjectId_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type GooglePlayAccessUpdateInput = {
		bucketId?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	packageIdentifier?:string,
	sourceClientEmail?:string,
	sourcePrivateKey?:string,
	sourceProjectId?:string
}

export type GooglePlayAccessWhereInput = {
		AND?:GooglePlayAccessWhereInput[],
	bucketId?:string,
	bucketId_contains?:string,
	bucketId_ends_with?:string,
	bucketId_gt?:string,
	bucketId_gte?:string,
	bucketId_in?:string[],
	bucketId_lt?:string,
	bucketId_lte?:string,
	bucketId_not?:string,
	bucketId_not_contains?:string,
	bucketId_not_ends_with?:string,
	bucketId_not_in?:string[],
	bucketId_not_starts_with?:string,
	bucketId_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:GooglePlayAccessWhereInput[],
	OR?:GooglePlayAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	packageIdentifier?:string,
	packageIdentifier_contains?:string,
	packageIdentifier_ends_with?:string,
	packageIdentifier_gt?:string,
	packageIdentifier_gte?:string,
	packageIdentifier_in?:string[],
	packageIdentifier_lt?:string,
	packageIdentifier_lte?:string,
	packageIdentifier_not?:string,
	packageIdentifier_not_contains?:string,
	packageIdentifier_not_ends_with?:string,
	packageIdentifier_not_in?:string[],
	packageIdentifier_not_starts_with?:string,
	packageIdentifier_starts_with?:string,
	sourceClientEmail?:string,
	sourceClientEmail_contains?:string,
	sourceClientEmail_ends_with?:string,
	sourceClientEmail_gt?:string,
	sourceClientEmail_gte?:string,
	sourceClientEmail_in?:string[],
	sourceClientEmail_lt?:string,
	sourceClientEmail_lte?:string,
	sourceClientEmail_not?:string,
	sourceClientEmail_not_contains?:string,
	sourceClientEmail_not_ends_with?:string,
	sourceClientEmail_not_in?:string[],
	sourceClientEmail_not_starts_with?:string,
	sourceClientEmail_starts_with?:string,
	sourcePrivateKey?:string,
	sourcePrivateKey_contains?:string,
	sourcePrivateKey_ends_with?:string,
	sourcePrivateKey_gt?:string,
	sourcePrivateKey_gte?:string,
	sourcePrivateKey_in?:string[],
	sourcePrivateKey_lt?:string,
	sourcePrivateKey_lte?:string,
	sourcePrivateKey_not?:string,
	sourcePrivateKey_not_contains?:string,
	sourcePrivateKey_not_ends_with?:string,
	sourcePrivateKey_not_in?:string[],
	sourcePrivateKey_not_starts_with?:string,
	sourcePrivateKey_starts_with?:string,
	sourceProjectId?:string,
	sourceProjectId_contains?:string,
	sourceProjectId_ends_with?:string,
	sourceProjectId_gt?:string,
	sourceProjectId_gte?:string,
	sourceProjectId_in?:string[],
	sourceProjectId_lt?:string,
	sourceProjectId_lte?:string,
	sourceProjectId_not?:string,
	sourceProjectId_not_contains?:string,
	sourceProjectId_not_ends_with?:string,
	sourceProjectId_not_in?:string[],
	sourceProjectId_not_starts_with?:string,
	sourceProjectId_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type GooglePlayAccessWhereUniqueInput = {
		id:string
}

export type GooglePlayFeed = {
	__typename?: "GooglePlayFeed",
	googlePlayAccess:GooglePlayAccess,
	id:string,
	params:IntegrationFeedParams
}

export type GooglePlayFeedCreateWithoutParamsInput = {
		googlePlayAccess:GooglePlayAccessWhereUniqueInput,
	id?:string
}

export type GooglePlayFeedWhereInput = {
		AND?:GooglePlayFeedWhereInput[],
	googlePlayAccess?:GooglePlayAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:GooglePlayFeedWhereInput[],
	OR?:GooglePlayFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput
}

export type HarvestAccess = {
	__typename?: "HarvestAccess",
	accountId:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	token:string,
	updatedAt:DateTime
}

export type HarvestAccessCreateInput = {
		accountId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	token:string
}

export enum HarvestAccessOrderByInput {
	accountId_ASC = "accountId_ASC",
	accountId_DESC = "accountId_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	token_ASC = "token_ASC",
	token_DESC = "token_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type HarvestAccessUpdateInput = {
		accountId?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	token?:string
}

export type HarvestAccessWhereInput = {
		accountId?:string,
	accountId_contains?:string,
	accountId_ends_with?:string,
	accountId_gt?:string,
	accountId_gte?:string,
	accountId_in?:string[],
	accountId_lt?:string,
	accountId_lte?:string,
	accountId_not?:string,
	accountId_not_contains?:string,
	accountId_not_ends_with?:string,
	accountId_not_in?:string[],
	accountId_not_starts_with?:string,
	accountId_starts_with?:string,
	AND?:HarvestAccessWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:HarvestAccessWhereInput[],
	OR?:HarvestAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	token?:string,
	token_contains?:string,
	token_ends_with?:string,
	token_gt?:string,
	token_gte?:string,
	token_in?:string[],
	token_lt?:string,
	token_lte?:string,
	token_not?:string,
	token_not_contains?:string,
	token_not_ends_with?:string,
	token_not_in?:string[],
	token_not_starts_with?:string,
	token_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type HarvestAccessWhereUniqueInput = {
		id:string
}

export type HarvestFeed = {
	__typename?: "HarvestFeed",
	harvestAccess:HarvestAccess,
	id:string,
	params:IntegrationFeedParams,
	updatedSince?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceOffset?:number
}

export type HarvestFeedCreateWithoutParamsInput = {
		harvestAccess:HarvestAccessWhereUniqueInput,
	id?:string,
	updatedSince?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceOffset?:number
}

export type HarvestFeedWhereInput = {
		AND?:HarvestFeedWhereInput[],
	harvestAccess?:HarvestAccessWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:HarvestFeedWhereInput[],
	OR?:HarvestFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	updatedSince?:string,
	updatedSince_contains?:string,
	updatedSince_ends_with?:string,
	updatedSince_gt?:string,
	updatedSince_gte?:string,
	updatedSince_in?:string[],
	updatedSince_lt?:string,
	updatedSince_lte?:string,
	updatedSince_not?:string,
	updatedSince_not_contains?:string,
	updatedSince_not_ends_with?:string,
	updatedSince_not_in?:string[],
	updatedSince_not_starts_with?:string,
	updatedSince_starts_with?:string,
	updatedSinceDaysInPast?:number,
	updatedSinceDaysInPast_gt?:number,
	updatedSinceDaysInPast_gte?:number,
	updatedSinceDaysInPast_in?:number[],
	updatedSinceDaysInPast_lt?:number,
	updatedSinceDaysInPast_lte?:number,
	updatedSinceDaysInPast_not?:number,
	updatedSinceDaysInPast_not_in?:number[],
	updatedSinceOffset?:number,
	updatedSinceOffset_gt?:number,
	updatedSinceOffset_gte?:number,
	updatedSinceOffset_in?:number[],
	updatedSinceOffset_lt?:number,
	updatedSinceOffset_lte?:number,
	updatedSinceOffset_not?:number,
	updatedSinceOffset_not_in?:number[]
}

export enum InstanceState {
	ABORTED = "ABORTED",
	FAILED = "FAILED",
	RETRYING = "RETRYING",
	RUNNING = "RUNNING",
	SUCCEEDED = "SUCCEEDED"
}

export type IntegrationFeedConfig = {
	__typename?: "IntegrationFeedConfig",
	createdAt:DateTime,
	eligibleAt:string,
	enabled:boolean,
	id:string,
	instances?:IntegrationFeedInstance[],
	organizationAttributes?:OrganizationAttribute[],
	params:IntegrationFeedParams,
	updatedAt:DateTime
}

export type IntegrationFeedConfigConnection = {
	__typename?: "IntegrationFeedConfigConnection",
	aggregate:AggregateIntegrationFeedConfig,
	edges:IntegrationFeedConfigEdge[],
	pageInfo:PageInfo
}

export type IntegrationFeedConfigCreateInput = {
		eligibleAt:string,
	enabled?:boolean,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	params:IntegrationFeedParamsCreateInput
}

export type IntegrationFeedConfigEdge = {
	__typename?: "IntegrationFeedConfigEdge",
	cursor:string,
	node:IntegrationFeedConfig
}

export enum IntegrationFeedConfigOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	eligibleAt_ASC = "eligibleAt_ASC",
	eligibleAt_DESC = "eligibleAt_DESC",
	enabled_ASC = "enabled_ASC",
	enabled_DESC = "enabled_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type IntegrationFeedConfigUpdateInput = {
		eligibleAt?:string,
	enabled?:boolean,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	params?:IntegrationFeedParamsUpdateInput
}

export type IntegrationFeedConfigWhereInput = {
		AND?:IntegrationFeedConfigWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	eligibleAt?:string,
	eligibleAt_contains?:string,
	eligibleAt_ends_with?:string,
	eligibleAt_gt?:string,
	eligibleAt_gte?:string,
	eligibleAt_in?:string[],
	eligibleAt_lt?:string,
	eligibleAt_lte?:string,
	eligibleAt_not?:string,
	eligibleAt_not_contains?:string,
	eligibleAt_not_ends_with?:string,
	eligibleAt_not_in?:string[],
	eligibleAt_not_starts_with?:string,
	eligibleAt_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	instances_every?:IntegrationFeedInstanceWhereInput,
	instances_none?:IntegrationFeedInstanceWhereInput,
	instances_some?:IntegrationFeedInstanceWhereInput,
	NOT?:IntegrationFeedConfigWhereInput[],
	OR?:IntegrationFeedConfigWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	params?:IntegrationFeedParamsWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type IntegrationFeedConfigWhereUniqueInput = {
		id:string
}

export type IntegrationFeedInstance = {
	__typename?: "IntegrationFeedInstance",
	config?:IntegrationFeedConfig,
	createdAt:DateTime,
	failureError?:Error,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	params:IntegrationFeedParams,
	retryErrors?:Error[],
	state:InstanceState,
	stateMachineExecutionArn:string,
	updatedAt:DateTime
}

export type IntegrationFeedInstanceConnection = {
	__typename?: "IntegrationFeedInstanceConnection",
	aggregate:AggregateIntegrationFeedInstance,
	edges:IntegrationFeedInstanceEdge[],
	pageInfo:PageInfo
}

export type IntegrationFeedInstanceCreateInput = {
		config?:IntegrationFeedConfigWhereUniqueInput,
	organizationAttributes:OrganizationAttributeCreateInput[],
	params:IntegrationFeedParamsCreateInput
}

export type IntegrationFeedInstanceEdge = {
	__typename?: "IntegrationFeedInstanceEdge",
	cursor:string,
	node:IntegrationFeedInstance
}

export enum IntegrationFeedInstanceOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	state_ASC = "state_ASC",
	state_DESC = "state_DESC",
	stateMachineExecutionArn_ASC = "stateMachineExecutionArn_ASC",
	stateMachineExecutionArn_DESC = "stateMachineExecutionArn_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type IntegrationFeedInstanceWhereInput = {
		AND?:IntegrationFeedInstanceWhereInput[],
	config?:IntegrationFeedConfigWhereInput,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	failureError?:ErrorWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:IntegrationFeedInstanceWhereInput[],
	OR?:IntegrationFeedInstanceWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	params?:IntegrationFeedParamsWhereInput,
	retryErrors_every?:ErrorWhereInput,
	retryErrors_none?:ErrorWhereInput,
	retryErrors_some?:ErrorWhereInput,
	state?:InstanceState,
	state_in?:InstanceState[],
	state_not?:InstanceState,
	state_not_in?:InstanceState[],
	stateMachineExecutionArn?:string,
	stateMachineExecutionArn_contains?:string,
	stateMachineExecutionArn_ends_with?:string,
	stateMachineExecutionArn_gt?:string,
	stateMachineExecutionArn_gte?:string,
	stateMachineExecutionArn_in?:string[],
	stateMachineExecutionArn_lt?:string,
	stateMachineExecutionArn_lte?:string,
	stateMachineExecutionArn_not?:string,
	stateMachineExecutionArn_not_contains?:string,
	stateMachineExecutionArn_not_ends_with?:string,
	stateMachineExecutionArn_not_in?:string[],
	stateMachineExecutionArn_not_starts_with?:string,
	stateMachineExecutionArn_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type IntegrationFeedInstanceWhereUniqueInput = {
		id?:string,
	stateMachineExecutionArn?:string
}

export type IntegrationFeedParams = {
	__typename?: "IntegrationFeedParams",
	apple?:AppleFeed,
	appleSearchAds?:AppleSearchAdsFeed,
	applovin?:ApplovinFeed,
	appsFlyerCampaign?:AppsFlyerCampaignFeed,
	appsFlyerDatalocker?:AppsFlyerDatalockerFeed,
	appsFlyerRawEvents?:AppsFlyerRawEventsFeed,
	appsFlyerRawInstalls?:AppsFlyerRawInstallsFeed,
	appsFlyerScraper?:AppsFlyerScraperFeed,
	bqDestinations?:BigQueryDestination[],
	chartboost?:ChartboostFeed,
	dateInput:FeedDateInput,
	descriptiveName:string,
	facebookAds?:FacebookAdsFeed,
	fairbidUserReport?:FairbidUserReportFeed,
	feedType:Feed,
	fixer?:FixerFeed,
	googleAdwords?:GoogleAdwordsFeed,
	googlePlay?:GooglePlayFeed,
	harvest?:HarvestFeed,
	id:string,
	ironSource?:IronSourceFeed,
	ironSourcePromotion?:IronSourcePromotionFeed,
	libring?:LibringFeed,
	s3Destinations?:S3Destination[],
	snapchat?:SnapchatFeed,
	tiktokAdsAudience?:TiktokAdsAudienceFeed,
	unityAds?:UnityAdsFeed,
	unityAdvertiser?:UnityAdvertiserFeed
}

export type IntegrationFeedParamsCreateInput = {
		apple?:AppleFeedCreateWithoutParamsInput,
	appleSearchAds?:AppleSearchAdsFeedCreateWithoutParamsInput,
	applovin?:ApplovinFeedCreateWithoutParamsInput,
	appsFlyerCampaign?:AppsFlyerCampaignFeedCreateWithoutParamsInput,
	appsFlyerDatalocker?:AppsFlyerDatalockerFeedCreateWithoutParamsInput,
	appsFlyerRawEvents?:AppsFlyerRawEventsFeedCreateWithoutParamsInput,
	appsFlyerRawInstalls?:AppsFlyerRawInstallsFeedCreateWithoutParamsInput,
	appsFlyerScraper?:AppsFlyerScraperFeedCreateWithoutParamsInput,
	bqDestinations:BigQueryDestinationCreateInput[],
	chartboost?:ChartboostFeedCreateWithoutParamsInput,
	dateInput?:FeedDateInputCreateInput,
	descriptiveName:string,
	facebookAds?:FacebookAdsFeedCreateWithoutParamsInput,
	fairbidUserReport?:FairbidUserReportFeedCreateWithoutParamsInput,
	feedType:Feed,
	fixer?:FixerFeedCreateWithoutParamsInput,
	googleAdwords?:GoogleAdwordsFeedCreateWithoutParamsInput,
	googlePlay?:GooglePlayFeedCreateWithoutParamsInput,
	harvest?:HarvestFeedCreateWithoutParamsInput,
	ironSource?:IronSourceFeedCreateWithoutParamsInput,
	ironSourcePromotion?:IronSourcePromotionFeedCreateWithoutParamsInput,
	libring?:LibringFeedCreateWithoutParamsInput,
	s3Destinations:S3DestinationCreateInput[],
	snapchat?:SnapchatFeedCreateWithoutParamsInput,
	tiktokAdsAudience?:TiktokAdsAudienceFeedCreateWithoutParamsInput,
	unityAds?:UnityAdsFeedCreateWithoutParamsInput,
	unityAdvertiser?:UnityAdvertiserFeedCreateWithoutParamsInput
}

export type IntegrationFeedParamsUpdateInput = {
		apple?:AppleFeedCreateWithoutParamsInput,
	appleSearchAds?:AppleSearchAdsFeedCreateWithoutParamsInput,
	applovin?:ApplovinFeedCreateWithoutParamsInput,
	appsFlyerCampaign?:AppsFlyerCampaignFeedCreateWithoutParamsInput,
	appsFlyerDatalocker?:AppsFlyerDatalockerFeedCreateWithoutParamsInput,
	appsFlyerRawEvents?:AppsFlyerRawEventsFeedCreateWithoutParamsInput,
	appsFlyerRawInstalls?:AppsFlyerRawInstallsFeedCreateWithoutParamsInput,
	appsFlyerScraper?:AppsFlyerScraperFeedCreateWithoutParamsInput,
	bqDestinations?:BigQueryDestinationUpdateManyInput,
	chartboost?:ChartboostFeedCreateWithoutParamsInput,
	dateInput?:FeedDateInputCreateInput,
	descriptiveName?:string,
	facebookAds?:FacebookAdsFeedCreateWithoutParamsInput,
	fairbidUserReport?:FairbidUserReportFeedCreateWithoutParamsInput,
	feedType?:Feed,
	fixer?:FixerFeedCreateWithoutParamsInput,
	googleAdwords?:GoogleAdwordsFeedCreateWithoutParamsInput,
	googlePlay?:GooglePlayFeedCreateWithoutParamsInput,
	harvest?:HarvestFeedCreateWithoutParamsInput,
	ironSource?:IronSourceFeedCreateWithoutParamsInput,
	ironSourcePromotion?:IronSourcePromotionFeedCreateWithoutParamsInput,
	libring?:LibringFeedCreateWithoutParamsInput,
	s3Destinations?:S3DestinationUpdateManyInput,
	snapchat?:SnapchatFeedCreateWithoutParamsInput,
	tiktokAdsAudience?:TiktokAdsAudienceFeedCreateWithoutParamsInput,
	unityAds?:UnityAdsFeedCreateWithoutParamsInput,
	unityAdvertiser?:UnityAdvertiserFeedCreateWithoutParamsInput
}

export type IntegrationFeedParamsWhereInput = {
		AND?:IntegrationFeedParamsWhereInput[],
	apple?:AppleFeedWhereInput,
	appleSearchAds?:AppleSearchAdsFeedWhereInput,
	applovin?:ApplovinFeedWhereInput,
	appsFlyerCampaign?:AppsFlyerCampaignFeedWhereInput,
	appsFlyerDatalocker?:AppsFlyerDatalockerFeedWhereInput,
	appsFlyerRawEvents?:AppsFlyerRawEventsFeedWhereInput,
	appsFlyerRawInstalls?:AppsFlyerRawInstallsFeedWhereInput,
	appsFlyerScraper?:AppsFlyerScraperFeedWhereInput,
	bqDestinations_every?:BigQueryDestinationWhereInput,
	bqDestinations_none?:BigQueryDestinationWhereInput,
	bqDestinations_some?:BigQueryDestinationWhereInput,
	chartboost?:ChartboostFeedWhereInput,
	dateInput?:FeedDateInputWhereInput,
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	facebookAds?:FacebookAdsFeedWhereInput,
	fairbidUserReport?:FairbidUserReportFeedWhereInput,
	feedType?:Feed,
	feedType_in?:Feed[],
	feedType_not?:Feed,
	feedType_not_in?:Feed[],
	fixer?:FixerFeedWhereInput,
	googleAdwords?:GoogleAdwordsFeedWhereInput,
	googlePlay?:GooglePlayFeedWhereInput,
	harvest?:HarvestFeedWhereInput,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSource?:IronSourceFeedWhereInput,
	ironSourcePromotion?:IronSourcePromotionFeedWhereInput,
	libring?:LibringFeedWhereInput,
	NOT?:IntegrationFeedParamsWhereInput[],
	OR?:IntegrationFeedParamsWhereInput[],
	s3Destinations_every?:S3DestinationWhereInput,
	s3Destinations_none?:S3DestinationWhereInput,
	s3Destinations_some?:S3DestinationWhereInput,
	snapchat?:SnapchatFeedWhereInput,
	tiktokAdsAudience?:TiktokAdsAudienceFeedWhereInput,
	unityAds?:UnityAdsFeedWhereInput,
	unityAdvertiser?:UnityAdvertiserFeedWhereInput
}

export type IronSourceAccess = {
	__typename?: "IronSourceAccess",
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	refreshToken:string,
	secretKey:string,
	updatedAt:DateTime
}

export type IronSourceAccessCreateInput = {
		descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	refreshToken:string,
	secretKey:string
}

export enum IronSourceAccessOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	refreshToken_ASC = "refreshToken_ASC",
	refreshToken_DESC = "refreshToken_DESC",
	secretKey_ASC = "secretKey_ASC",
	secretKey_DESC = "secretKey_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type IronSourceAccessUpdateInput = {
		descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	refreshToken?:string,
	secretKey?:string
}

export type IronSourceAccessWhereInput = {
		AND?:IronSourceAccessWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:IronSourceAccessWhereInput[],
	OR?:IronSourceAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string,
	secretKey?:string,
	secretKey_contains?:string,
	secretKey_ends_with?:string,
	secretKey_gt?:string,
	secretKey_gte?:string,
	secretKey_in?:string[],
	secretKey_lt?:string,
	secretKey_lte?:string,
	secretKey_not?:string,
	secretKey_not_contains?:string,
	secretKey_not_ends_with?:string,
	secretKey_not_in?:string[],
	secretKey_not_starts_with?:string,
	secretKey_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type IronSourceAccessWhereUniqueInput = {
		id:string
}

export type IronSourceFeed = {
	__typename?: "IronSourceFeed",
	adRevenue:boolean,
	appKey:string,
	id:string,
	ironSourceAccess:IronSourceAccess,
	params:IntegrationFeedParams,
	reportBreakdowns:string[],
	reportMetrics:string[],
	reports:boolean
}

export type IronSourceFeedCreateWithoutParamsInput = {
		adRevenue?:boolean,
	appKey:string,
	id?:string,
	ironSourceAccess:IronSourceAccessWhereUniqueInput,
	reportBreakdowns:string[],
	reportMetrics:string[],
	reports?:boolean
}

export type IronSourceFeedWhereInput = {
		adRevenue?:boolean,
	adRevenue_not?:boolean,
	AND?:IronSourceFeedWhereInput[],
	appKey?:string,
	appKey_contains?:string,
	appKey_ends_with?:string,
	appKey_gt?:string,
	appKey_gte?:string,
	appKey_in?:string[],
	appKey_lt?:string,
	appKey_lte?:string,
	appKey_not?:string,
	appKey_not_contains?:string,
	appKey_not_ends_with?:string,
	appKey_not_in?:string[],
	appKey_not_starts_with?:string,
	appKey_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSourceAccess?:IronSourceAccessWhereInput,
	NOT?:IronSourceFeedWhereInput[],
	OR?:IronSourceFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reports?:boolean,
	reports_not?:boolean
}

export enum IronSourcePromotionDeviceType {
	PHONE = "PHONE",
	TABLET = "TABLET"
}

export type IronSourcePromotionFeed = {
	__typename?: "IronSourcePromotionFeed",
	adUnit?:string,
	breakdowns:string[],
	bundleIds:string[],
	campaignIds:number[],
	countries:string[],
	creativeIds:number[],
	deviceType?:IronSourcePromotionDeviceType,
	id:string,
	ironSourceAccess:IronSourceAccess,
	metrics:string[],
	os?:IronSourcePromotionOsType,
	params:IntegrationFeedParams
}

export type IronSourcePromotionFeedCreateWithoutParamsInput = {
		adUnit?:string,
	breakdowns:string[],
	bundleIds:string[],
	campaignIds:number[],
	countries:string[],
	creativeIds:number[],
	deviceType?:IronSourcePromotionDeviceType,
	id?:string,
	ironSourceAccess:IronSourceAccessWhereUniqueInput,
	metrics:string[],
	os?:IronSourcePromotionOsType
}

export type IronSourcePromotionFeedWhereInput = {
		adUnit?:string,
	adUnit_contains?:string,
	adUnit_ends_with?:string,
	adUnit_gt?:string,
	adUnit_gte?:string,
	adUnit_in?:string[],
	adUnit_lt?:string,
	adUnit_lte?:string,
	adUnit_not?:string,
	adUnit_not_contains?:string,
	adUnit_not_ends_with?:string,
	adUnit_not_in?:string[],
	adUnit_not_starts_with?:string,
	adUnit_starts_with?:string,
	AND?:IronSourcePromotionFeedWhereInput[],
	deviceType?:IronSourcePromotionDeviceType,
	deviceType_in?:IronSourcePromotionDeviceType[],
	deviceType_not?:IronSourcePromotionDeviceType,
	deviceType_not_in?:IronSourcePromotionDeviceType[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	ironSourceAccess?:IronSourceAccessWhereInput,
	NOT?:IronSourcePromotionFeedWhereInput[],
	OR?:IronSourcePromotionFeedWhereInput[],
	os?:IronSourcePromotionOsType,
	os_in?:IronSourcePromotionOsType[],
	os_not?:IronSourcePromotionOsType,
	os_not_in?:IronSourcePromotionOsType[],
	params?:IntegrationFeedParamsWhereInput
}

export enum IronSourcePromotionOsType {
	ANDROID = "ANDROID",
	IOS = "IOS"
}

export type LibringAccess = {
	__typename?: "LibringAccess",
	apiToken:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type LibringAccessCreateInput = {
		apiToken:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum LibringAccessOrderByInput {
	apiToken_ASC = "apiToken_ASC",
	apiToken_DESC = "apiToken_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type LibringAccessUpdateInput = {
		apiToken?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type LibringAccessWhereInput = {
		AND?:LibringAccessWhereInput[],
	apiToken?:string,
	apiToken_contains?:string,
	apiToken_ends_with?:string,
	apiToken_gt?:string,
	apiToken_gte?:string,
	apiToken_in?:string[],
	apiToken_lt?:string,
	apiToken_lte?:string,
	apiToken_not?:string,
	apiToken_not_contains?:string,
	apiToken_not_ends_with?:string,
	apiToken_not_in?:string[],
	apiToken_not_starts_with?:string,
	apiToken_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:LibringAccessWhereInput[],
	OR?:LibringAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type LibringAccessWhereUniqueInput = {
		id:string
}

export type LibringFeed = {
	__typename?: "LibringFeed",
	appName:string,
	dataType:string,
	id:string,
	libringAccess:LibringAccess,
	params:IntegrationFeedParams
}

export type LibringFeedCreateWithoutParamsInput = {
		appName:string,
	dataType:string,
	id?:string,
	libringAccess:LibringAccessWhereUniqueInput
}

export type LibringFeedWhereInput = {
		AND?:LibringFeedWhereInput[],
	appName?:string,
	appName_contains?:string,
	appName_ends_with?:string,
	appName_gt?:string,
	appName_gte?:string,
	appName_in?:string[],
	appName_lt?:string,
	appName_lte?:string,
	appName_not?:string,
	appName_not_contains?:string,
	appName_not_ends_with?:string,
	appName_not_in?:string[],
	appName_not_starts_with?:string,
	appName_starts_with?:string,
	dataType?:string,
	dataType_contains?:string,
	dataType_ends_with?:string,
	dataType_gt?:string,
	dataType_gte?:string,
	dataType_in?:string[],
	dataType_lt?:string,
	dataType_lte?:string,
	dataType_not?:string,
	dataType_not_contains?:string,
	dataType_not_ends_with?:string,
	dataType_not_in?:string[],
	dataType_not_starts_with?:string,
	dataType_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	libringAccess?:LibringAccessWhereInput,
	NOT?:LibringFeedWhereInput[],
	OR?:LibringFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput
}

export type Mutation = {
	__typename?: "Mutation",
	abortEtl:Action,
	archiveView:View,
	copyView:View,
	createAppleAccess:AppleAccess,
	createAppleSearchAdsAccess:AppleSearchAdsAccess,
	createApplovinAccess:ApplovinAccess,
	createAppsFlyerAccess:AppsFlyerAccess,
	createAwsProject:AwsProject,
	createBigQueryProject:BigQueryProject,
	createChartboostAccess:ChartboostAccess,
	createFacebookAdsAccess:FacebookAdsAccess,
	createFairbidAccess:FairbidAccess,
	createGoogleAdsAccess:GoogleAdsAccess,
	createGooglePlayAccess:GooglePlayAccess,
	createHarvestAccess:HarvestAccess,
	createIntegrationFeedConfig:IntegrationFeedConfig,
	createIronSourceAccess:IronSourceAccess,
	createLibringAccess:LibringAccess,
	createOrganization:Organization,
	createSnapchatAccess:SnapchatAccess,
	createTiktokAccess:TiktokAccess,
	createUnityAccess:UnityAccess,
	createView:View,
	deleteAppleAccess:AppleAccess,
	deleteAppleSearchAdsAccess:AppleSearchAdsAccess,
	deleteApplovinAccess:ApplovinAccess,
	deleteAppsFlyerAccess:AppsFlyerAccess,
	deleteAwsProject:AwsProject,
	deleteBigQueryProject:BigQueryProject,
	deleteChartboostAccess:ChartboostAccess,
	deleteFacebookAdsAccess:FacebookAdsAccess,
	deleteFairbidAccess:FairbidAccess,
	deleteGoogleAdsAccess:GoogleAdsAccess,
	deleteGooglePlayAccess:GooglePlayAccess,
	deleteHarvestAccess:HarvestAccess,
	deleteIntegrationFeedConfig:IntegrationFeedConfig,
	deleteIronSourceAccess:IronSourceAccess,
	deleteLibringAccess:LibringAccess,
	deleteOrganization:Organization,
	deleteSnapchatAccess:SnapchatAccess,
	deleteTiktokAccess:TiktokAccess,
	deleteUnityAccess:UnityAccess,
	deleteView:View,
	inviteUser:OrganizationMembership,
	publishView:View,
	restoreView:View,
	runEtl:Result,
	setUserRoles:User,
	updateAppleAccess:AppleAccess,
	updateAppleSearchAdsAccess:AppleSearchAdsAccess,
	updateApplovinAccess:ApplovinAccess,
	updateAppsFlyerAccess:AppsFlyerAccess,
	updateAwsProject:AwsProject,
	updateBigQueryProject:BigQueryProject,
	updateChartboostAccess:ChartboostAccess,
	updateFacebookAdsAccess:FacebookAdsAccess,
	updateFairbidAccess:FairbidAccess,
	updateGoogleAdsAccess:GoogleAdsAccess,
	updateGooglePlayAccess:GooglePlayAccess,
	updateHarvestAccess:HarvestAccess,
	updateIntegrationFeedConfig:IntegrationFeedConfig,
	updateIronSourceAccess:IronSourceAccess,
	updateLibringAccess:LibringAccess,
	updateSnapchatAccess:SnapchatAccess,
	updateTiktokAccess:TiktokAccess,
	updateUnityAccess:UnityAccess,
	updateView:View
}

export type Organization = {
	__typename?: "Organization",
	awsProjects?:AwsProject[],
	bqProjects?:BigQueryProject[],
	createdAt:DateTime,
	id:string,
	name:string,
	organizationAttributes?:OrganizationAttribute[],
	organizationMemberships?:OrganizationMembership[],
	updatedAt:DateTime,
	views?:View[]
}

export type OrganizationAttribute = {
	__typename?: "OrganizationAttribute",
	createdAt:DateTime,
	id:string,
	organization:Organization,
	role:OrganizationRole,
	updatedAt:DateTime
}

export type OrganizationAttributeCreateInput = {
		organization:OrganizationWhereUniqueInput,
	role:OrganizationRole
}

export enum OrganizationAttributeOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	role_ASC = "role_ASC",
	role_DESC = "role_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type OrganizationAttributeUpdateInput = {
		id:string,
	organization:OrganizationWhereUniqueInput,
	role:OrganizationRole
}

export type OrganizationAttributeUpdateManyInput = {
		create?:OrganizationAttributeCreateInput[],
	delete?:OrganizationAttributeWhereUniqueInput[],
	update?:OrganizationAttributeUpdateInput[]
}

export type OrganizationAttributeWhereInput = {
		AND?:OrganizationAttributeWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:OrganizationAttributeWhereInput[],
	OR?:OrganizationAttributeWhereInput[],
	organization?:OrganizationWhereInput,
	role?:OrganizationRole,
	role_in?:OrganizationRole[],
	role_not?:OrganizationRole,
	role_not_in?:OrganizationRole[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type OrganizationAttributeWhereUniqueInput = {
		id:string
}

export type OrganizationMembership = {
	__typename?: "OrganizationMembership",
	createdAt:DateTime,
	id:string,
	organization:Organization,
	role:UserRole,
	updatedAt:DateTime,
	user:User
}

export enum OrganizationMembershipOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	role_ASC = "role_ASC",
	role_DESC = "role_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type OrganizationMembershipWhereInput = {
		AND?:OrganizationMembershipWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:OrganizationMembershipWhereInput[],
	OR?:OrganizationMembershipWhereInput[],
	organization?:OrganizationWhereInput,
	role?:UserRole,
	role_in?:UserRole[],
	role_not?:UserRole,
	role_not_in?:UserRole[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[],
	user?:UserWhereInput
}

export enum OrganizationOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	name_ASC = "name_ASC",
	name_DESC = "name_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export enum OrganizationRole {
	ADMIN = "ADMIN",
	OWNER = "OWNER",
	USER = "USER"
}

export type OrganizationWhereInput = {
		AND?:OrganizationWhereInput[],
	awsProjects_every?:AwsProjectWhereInput,
	awsProjects_none?:AwsProjectWhereInput,
	awsProjects_some?:AwsProjectWhereInput,
	bqProjects_every?:BigQueryProjectWhereInput,
	bqProjects_none?:BigQueryProjectWhereInput,
	bqProjects_some?:BigQueryProjectWhereInput,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:OrganizationWhereInput[],
	OR?:OrganizationWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	organizationMemberships_every?:OrganizationMembershipWhereInput,
	organizationMemberships_none?:OrganizationMembershipWhereInput,
	organizationMemberships_some?:OrganizationMembershipWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[],
	views_every?:ViewWhereInput,
	views_none?:ViewWhereInput,
	views_some?:ViewWhereInput
}

export type OrganizationWhereUniqueInput = {
		id?:string,
	name?:string
}

export type PageInfo = {
	__typename?: "PageInfo",
	endCursor?:string,
	hasNextPage:boolean,
	hasPreviousPage:boolean,
	startCursor?:string
}

export enum Platform {
	ANDROID = "ANDROID",
	IOS = "IOS",
	TOTAL = "TOTAL"
}

export type Query = {
	__typename?: "Query",
	appleAccess?:AppleAccess,
	appleAccesses:AppleAccess[],
	appleSearchAdsAccess?:AppleSearchAdsAccess,
	appleSearchAdsAccesses:AppleSearchAdsAccess[],
	applovinAccess?:ApplovinAccess,
	applovinAccesses:ApplovinAccess[],
	appsFlyerAccess?:AppsFlyerAccess,
	appsFlyerAccesses:AppsFlyerAccess[],
	awsProject?:AwsProject,
	awsProjects:AwsProject[],
	bigQueryCheck?:BigQueryCheck,
	bigQueryChecks:BigQueryCheck[],
	bigQueryChecksConnection:BigQueryCheckConnection,
	bigQueryDestination?:BigQueryDestination,
	bigQueryDestinations:BigQueryDestination[],
	bigQueryProject?:BigQueryProject,
	bigQueryProjects:BigQueryProject[],
	campaignConsistencyCheck?:CampaignConsistencyCheck,
	campaignConsistencyChecks:CampaignConsistencyCheck[],
	campaignConsistencyChecksConnection:CampaignConsistencyCheckConnection,
	chartboostAccess?:ChartboostAccess,
	chartboostAccesses:ChartboostAccess[],
	dailyConsistencyCheck?:DailyConsistencyCheck,
	dailyConsistencyChecks:DailyConsistencyCheck[],
	dailyConsistencyChecksConnection:DailyConsistencyCheckConnection,
	facebookAdsAccess?:FacebookAdsAccess,
	facebookAdsAccesses:FacebookAdsAccess[],
	fairbidAccess?:FairbidAccess,
	fairbidAccesses:FairbidAccess[],
	googleAdsAccess?:GoogleAdsAccess,
	googleAdsAccesses:GoogleAdsAccess[],
	googlePlayAccess?:GooglePlayAccess,
	googlePlayAccesses:GooglePlayAccess[],
	harvestAccess?:HarvestAccess,
	harvestAccesses:HarvestAccess[],
	integrationFeedConfig?:IntegrationFeedConfig,
	integrationFeedConfigs:IntegrationFeedConfig[],
	integrationFeedConfigsConnection:IntegrationFeedConfigConnection,
	integrationFeedInstance?:IntegrationFeedInstance,
	integrationFeedInstances:IntegrationFeedInstance[],
	integrationFeedInstancesConnection:IntegrationFeedInstanceConnection,
	ironSourceAccess?:IronSourceAccess,
	ironSourceAccesses:IronSourceAccess[],
	libringAccess?:LibringAccess,
	libringAccesses:LibringAccess[],
	me:User,
	organization?:Organization,
	organizations:Organization[],
	s3Destination?:S3Destination,
	s3Destinations:S3Destination[],
	snapchatAccess?:SnapchatAccess,
	snapchatAccesses:SnapchatAccess[],
	tiktokAccess?:TiktokAccess,
	tiktokAccesses:TiktokAccess[],
	unityAccess?:UnityAccess,
	unityAccesses:UnityAccess[],
	users:User[],
	view?:View,
	views:View[]
}

export enum ReportType {
	DAILY_REPORT = "DAILY_REPORT",
	GEO_BY_DATE_REPORT = "GEO_BY_DATE_REPORT",
	GEO_REPORT = "GEO_REPORT",
	PARTNERS_BY_DATE_REPORT = "PARTNERS_BY_DATE_REPORT",
	PARTNERS_REPORT = "PARTNERS_REPORT"
}

export type Result = {
	__typename?: "Result",
	actions:Action[]
}

export type S3Destination = {
	__typename?: "S3Destination",
	awsProject:AwsProject,
	bucketName:string,
	createdAt:DateTime,
	descriptiveName:string,
	enabled:boolean,
	id:string,
	objectSuffix?:string,
	organizationAttributes?:OrganizationAttribute[],
	pathPrefix?:string,
	region:string,
	updatedAt:DateTime
}

export type S3DestinationCreateInput = {
		awsProject:AwsProjectWhereUniqueInput,
	bucketName:string,
	descriptiveName:string,
	enabled:boolean,
	id?:string,
	objectSuffix?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	pathPrefix?:string,
	region:string
}

export enum S3DestinationOrderByInput {
	bucketName_ASC = "bucketName_ASC",
	bucketName_DESC = "bucketName_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	enabled_ASC = "enabled_ASC",
	enabled_DESC = "enabled_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	objectSuffix_ASC = "objectSuffix_ASC",
	objectSuffix_DESC = "objectSuffix_DESC",
	pathPrefix_ASC = "pathPrefix_ASC",
	pathPrefix_DESC = "pathPrefix_DESC",
	region_ASC = "region_ASC",
	region_DESC = "region_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type S3DestinationUpdateInput = {
		awsProject?:AwsProjectWhereUniqueInput,
	bucketName?:string,
	descriptiveName?:string,
	enabled?:boolean,
	id:string,
	objectSuffix?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	pathPrefix?:string,
	region?:string
}

export type S3DestinationUpdateManyInput = {
		create?:S3DestinationCreateInput[],
	delete?:S3DestinationWhereUniqueInput[],
	update?:S3DestinationUpdateInput[]
}

export type S3DestinationWhereInput = {
		AND?:S3DestinationWhereInput[],
	awsProject?:AwsProjectWhereInput,
	bucketName?:string,
	bucketName_contains?:string,
	bucketName_ends_with?:string,
	bucketName_gt?:string,
	bucketName_gte?:string,
	bucketName_in?:string[],
	bucketName_lt?:string,
	bucketName_lte?:string,
	bucketName_not?:string,
	bucketName_not_contains?:string,
	bucketName_not_ends_with?:string,
	bucketName_not_in?:string[],
	bucketName_not_starts_with?:string,
	bucketName_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	enabled?:boolean,
	enabled_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:S3DestinationWhereInput[],
	objectSuffix?:string,
	objectSuffix_contains?:string,
	objectSuffix_ends_with?:string,
	objectSuffix_gt?:string,
	objectSuffix_gte?:string,
	objectSuffix_in?:string[],
	objectSuffix_lt?:string,
	objectSuffix_lte?:string,
	objectSuffix_not?:string,
	objectSuffix_not_contains?:string,
	objectSuffix_not_ends_with?:string,
	objectSuffix_not_in?:string[],
	objectSuffix_not_starts_with?:string,
	objectSuffix_starts_with?:string,
	OR?:S3DestinationWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	pathPrefix?:string,
	pathPrefix_contains?:string,
	pathPrefix_ends_with?:string,
	pathPrefix_gt?:string,
	pathPrefix_gte?:string,
	pathPrefix_in?:string[],
	pathPrefix_lt?:string,
	pathPrefix_lte?:string,
	pathPrefix_not?:string,
	pathPrefix_not_contains?:string,
	pathPrefix_not_ends_with?:string,
	pathPrefix_not_in?:string[],
	pathPrefix_not_starts_with?:string,
	pathPrefix_starts_with?:string,
	region?:string,
	region_contains?:string,
	region_ends_with?:string,
	region_gt?:string,
	region_gte?:string,
	region_in?:string[],
	region_lt?:string,
	region_lte?:string,
	region_not?:string,
	region_not_contains?:string,
	region_not_ends_with?:string,
	region_not_in?:string[],
	region_not_starts_with?:string,
	region_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type S3DestinationWhereUniqueInput = {
		id:string
}

export type ScraperGame = {
	__typename?: "ScraperGame",
	createdAt:DateTime,
	gameId:string,
	id:string,
	name:string,
	updatedAt:DateTime
}

export type ScraperGameCreateInput = {
		gameId:string,
	id?:string,
	name:string
}

export enum ScraperGameOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	gameId_ASC = "gameId_ASC",
	gameId_DESC = "gameId_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	name_ASC = "name_ASC",
	name_DESC = "name_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type ScraperGameWhereInput = {
		AND?:ScraperGameWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	gameId?:string,
	gameId_contains?:string,
	gameId_ends_with?:string,
	gameId_gt?:string,
	gameId_gte?:string,
	gameId_in?:string[],
	gameId_lt?:string,
	gameId_lte?:string,
	gameId_not?:string,
	gameId_not_contains?:string,
	gameId_not_ends_with?:string,
	gameId_not_in?:string[],
	gameId_not_starts_with?:string,
	gameId_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:ScraperGameWhereInput[],
	OR?:ScraperGameWhereInput[],
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type SnapchatAccess = {
	__typename?: "SnapchatAccess",
	adAccountID:string,
	clientID:string,
	clientSecret:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	refreshToken:string,
	updatedAt:DateTime
}

export type SnapchatAccessCreateInput = {
		adAccountID:string,
	clientID:string,
	clientSecret:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	refreshToken:string
}

export enum SnapchatAccessOrderByInput {
	adAccountID_ASC = "adAccountID_ASC",
	adAccountID_DESC = "adAccountID_DESC",
	clientID_ASC = "clientID_ASC",
	clientID_DESC = "clientID_DESC",
	clientSecret_ASC = "clientSecret_ASC",
	clientSecret_DESC = "clientSecret_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	refreshToken_ASC = "refreshToken_ASC",
	refreshToken_DESC = "refreshToken_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type SnapchatAccessUpdateInput = {
		adAccountID?:string,
	clientID?:string,
	clientSecret?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	refreshToken?:string
}

export type SnapchatAccessWhereInput = {
		adAccountID?:string,
	adAccountID_contains?:string,
	adAccountID_ends_with?:string,
	adAccountID_gt?:string,
	adAccountID_gte?:string,
	adAccountID_in?:string[],
	adAccountID_lt?:string,
	adAccountID_lte?:string,
	adAccountID_not?:string,
	adAccountID_not_contains?:string,
	adAccountID_not_ends_with?:string,
	adAccountID_not_in?:string[],
	adAccountID_not_starts_with?:string,
	adAccountID_starts_with?:string,
	AND?:SnapchatAccessWhereInput[],
	clientID?:string,
	clientID_contains?:string,
	clientID_ends_with?:string,
	clientID_gt?:string,
	clientID_gte?:string,
	clientID_in?:string[],
	clientID_lt?:string,
	clientID_lte?:string,
	clientID_not?:string,
	clientID_not_contains?:string,
	clientID_not_ends_with?:string,
	clientID_not_in?:string[],
	clientID_not_starts_with?:string,
	clientID_starts_with?:string,
	clientSecret?:string,
	clientSecret_contains?:string,
	clientSecret_ends_with?:string,
	clientSecret_gt?:string,
	clientSecret_gte?:string,
	clientSecret_in?:string[],
	clientSecret_lt?:string,
	clientSecret_lte?:string,
	clientSecret_not?:string,
	clientSecret_not_contains?:string,
	clientSecret_not_ends_with?:string,
	clientSecret_not_in?:string[],
	clientSecret_not_starts_with?:string,
	clientSecret_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:SnapchatAccessWhereInput[],
	OR?:SnapchatAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	refreshToken?:string,
	refreshToken_contains?:string,
	refreshToken_ends_with?:string,
	refreshToken_gt?:string,
	refreshToken_gte?:string,
	refreshToken_in?:string[],
	refreshToken_lt?:string,
	refreshToken_lte?:string,
	refreshToken_not?:string,
	refreshToken_not_contains?:string,
	refreshToken_not_ends_with?:string,
	refreshToken_not_in?:string[],
	refreshToken_not_starts_with?:string,
	refreshToken_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type SnapchatAccessWhereUniqueInput = {
		id:string
}

export type SnapchatFeed = {
	__typename?: "SnapchatFeed",
	id:string,
	params:IntegrationFeedParams,
	reportDimension?:string,
	snapchatAccess:SnapchatAccess,
	timezoneOffset:number
}

export type SnapchatFeedCreateWithoutParamsInput = {
		id?:string,
	reportDimension?:string,
	snapchatAccess:SnapchatAccessWhereUniqueInput,
	timezoneOffset?:number
}

export type SnapchatFeedWhereInput = {
		AND?:SnapchatFeedWhereInput[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:SnapchatFeedWhereInput[],
	OR?:SnapchatFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	reportDimension?:string,
	reportDimension_contains?:string,
	reportDimension_ends_with?:string,
	reportDimension_gt?:string,
	reportDimension_gte?:string,
	reportDimension_in?:string[],
	reportDimension_lt?:string,
	reportDimension_lte?:string,
	reportDimension_not?:string,
	reportDimension_not_contains?:string,
	reportDimension_not_ends_with?:string,
	reportDimension_not_in?:string[],
	reportDimension_not_starts_with?:string,
	reportDimension_starts_with?:string,
	snapchatAccess?:SnapchatAccessWhereInput,
	timezoneOffset?:number,
	timezoneOffset_gt?:number,
	timezoneOffset_gte?:number,
	timezoneOffset_in?:number[],
	timezoneOffset_lt?:number,
	timezoneOffset_lte?:number,
	timezoneOffset_not?:number,
	timezoneOffset_not_in?:number[]
}

export type TiktokAccess = {
	__typename?: "TiktokAccess",
	accessToken:string,
	advertiserId:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	updatedAt:DateTime
}

export type TiktokAccessCreateInput = {
		accessToken:string,
	advertiserId:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[]
}

export enum TiktokAccessOrderByInput {
	accessToken_ASC = "accessToken_ASC",
	accessToken_DESC = "accessToken_DESC",
	advertiserId_ASC = "advertiserId_ASC",
	advertiserId_DESC = "advertiserId_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type TiktokAccessUpdateInput = {
		accessToken?:string,
	advertiserId?:string,
	descriptiveName?:string,
	id:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput
}

export type TiktokAccessWhereInput = {
		accessToken?:string,
	accessToken_contains?:string,
	accessToken_ends_with?:string,
	accessToken_gt?:string,
	accessToken_gte?:string,
	accessToken_in?:string[],
	accessToken_lt?:string,
	accessToken_lte?:string,
	accessToken_not?:string,
	accessToken_not_contains?:string,
	accessToken_not_ends_with?:string,
	accessToken_not_in?:string[],
	accessToken_not_starts_with?:string,
	accessToken_starts_with?:string,
	advertiserId?:string,
	advertiserId_contains?:string,
	advertiserId_ends_with?:string,
	advertiserId_gt?:string,
	advertiserId_gte?:string,
	advertiserId_in?:string[],
	advertiserId_lt?:string,
	advertiserId_lte?:string,
	advertiserId_not?:string,
	advertiserId_not_contains?:string,
	advertiserId_not_ends_with?:string,
	advertiserId_not_in?:string[],
	advertiserId_not_starts_with?:string,
	advertiserId_starts_with?:string,
	AND?:TiktokAccessWhereInput[],
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:TiktokAccessWhereInput[],
	OR?:TiktokAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type TiktokAccessWhereUniqueInput = {
		id:string
}

export type TiktokAdsAudienceFeed = {
	__typename?: "TiktokAdsAudienceFeed",
	dimensions:string[],
	filterInactive:boolean,
	id:string,
	params:IntegrationFeedParams,
	tiktokAccess:TiktokAccess
}

export type TiktokAdsAudienceFeedCreateWithoutParamsInput = {
		dimensions:string[],
	filterInactive?:boolean,
	id?:string,
	tiktokAccess:TiktokAccessWhereUniqueInput
}

export type TiktokAdsAudienceFeedWhereInput = {
		AND?:TiktokAdsAudienceFeedWhereInput[],
	filterInactive?:boolean,
	filterInactive_not?:boolean,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:TiktokAdsAudienceFeedWhereInput[],
	OR?:TiktokAdsAudienceFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	tiktokAccess?:TiktokAccessWhereInput
}

export type UnityAccess = {
	__typename?: "UnityAccess",
	apiKey:string,
	createdAt:DateTime,
	descriptiveName:string,
	id:string,
	organizationAttributes?:OrganizationAttribute[],
	organizationId:string,
	updatedAt:DateTime
}

export type UnityAccessCreateInput = {
		apiKey:string,
	descriptiveName:string,
	id?:string,
	organizationAttributes:OrganizationAttributeCreateInput[],
	organizationId:string
}

export enum UnityAccessOrderByInput {
	apiKey_ASC = "apiKey_ASC",
	apiKey_DESC = "apiKey_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	descriptiveName_ASC = "descriptiveName_ASC",
	descriptiveName_DESC = "descriptiveName_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	organizationId_ASC = "organizationId_ASC",
	organizationId_DESC = "organizationId_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export type UnityAccessUpdateInput = {
		apiKey?:string,
	descriptiveName?:string,
	organizationAttributes?:OrganizationAttributeUpdateManyInput,
	organizationId?:string
}

export type UnityAccessWhereInput = {
		AND?:UnityAccessWhereInput[],
	apiKey?:string,
	apiKey_contains?:string,
	apiKey_ends_with?:string,
	apiKey_gt?:string,
	apiKey_gte?:string,
	apiKey_in?:string[],
	apiKey_lt?:string,
	apiKey_lte?:string,
	apiKey_not?:string,
	apiKey_not_contains?:string,
	apiKey_not_ends_with?:string,
	apiKey_not_in?:string[],
	apiKey_not_starts_with?:string,
	apiKey_starts_with?:string,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	descriptiveName?:string,
	descriptiveName_contains?:string,
	descriptiveName_ends_with?:string,
	descriptiveName_gt?:string,
	descriptiveName_gte?:string,
	descriptiveName_in?:string[],
	descriptiveName_lt?:string,
	descriptiveName_lte?:string,
	descriptiveName_not?:string,
	descriptiveName_not_contains?:string,
	descriptiveName_not_ends_with?:string,
	descriptiveName_not_in?:string[],
	descriptiveName_not_starts_with?:string,
	descriptiveName_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:UnityAccessWhereInput[],
	OR?:UnityAccessWhereInput[],
	organizationAttributes_every?:OrganizationAttributeWhereInput,
	organizationAttributes_none?:OrganizationAttributeWhereInput,
	organizationAttributes_some?:OrganizationAttributeWhereInput,
	organizationId?:string,
	organizationId_contains?:string,
	organizationId_ends_with?:string,
	organizationId_gt?:string,
	organizationId_gte?:string,
	organizationId_in?:string[],
	organizationId_lt?:string,
	organizationId_lte?:string,
	organizationId_not?:string,
	organizationId_not_contains?:string,
	organizationId_not_ends_with?:string,
	organizationId_not_in?:string[],
	organizationId_not_starts_with?:string,
	organizationId_starts_with?:string,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[]
}

export type UnityAccessWhereUniqueInput = {
		id:string
}

export type UnityAdsFeed = {
	__typename?: "UnityAdsFeed",
	id:string,
	params:IntegrationFeedParams,
	unityAccess:UnityAccess
}

export type UnityAdsFeedCreateWithoutParamsInput = {
		id?:string,
	unityAccess:UnityAccessWhereUniqueInput
}

export type UnityAdsFeedWhereInput = {
		AND?:UnityAdsFeedWhereInput[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:UnityAdsFeedWhereInput[],
	OR?:UnityAdsFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	unityAccess?:UnityAccessWhereInput
}

export type UnityAdvertiserFeed = {
	__typename?: "UnityAdvertiserFeed",
	id:string,
	params:IntegrationFeedParams,
	targetIds:string[],
	unityAccess:UnityAccess
}

export type UnityAdvertiserFeedCreateWithoutParamsInput = {
		id?:string,
	targetIds:string[],
	unityAccess:UnityAccessWhereUniqueInput
}

export type UnityAdvertiserFeedWhereInput = {
		AND?:UnityAdvertiserFeedWhereInput[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:UnityAdvertiserFeedWhereInput[],
	OR?:UnityAdvertiserFeedWhereInput[],
	params?:IntegrationFeedParamsWhereInput,
	unityAccess?:UnityAccessWhereInput
}

export type User = {
	__typename?: "User",
	authProvider:string,
	email:string,
	id:string,
	isSuperAdmin:boolean,
	name:string,
	organizationMemberships?:OrganizationMembership[],
	surname:string,
	views?:View[]
}

export enum UserOrderByInput {
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	email_ASC = "email_ASC",
	email_DESC = "email_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC"
}

export enum UserRole {
	ADMIN = "ADMIN",
	GUEST = "GUEST",
	MEMBER = "MEMBER",
	SUPER_ADMIN = "SUPER_ADMIN"
}

export type UserWhereInput = {
		AND?:UserWhereInput[],
	email?:string,
	email_contains?:string,
	email_ends_with?:string,
	email_gt?:string,
	email_gte?:string,
	email_in?:string[],
	email_lt?:string,
	email_lte?:string,
	email_not?:string,
	email_not_contains?:string,
	email_not_ends_with?:string,
	email_not_in?:string[],
	email_not_starts_with?:string,
	email_starts_with?:string,
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	NOT?:UserWhereInput[],
	OR?:UserWhereInput[],
	organizationMemberships_every?:OrganizationMembershipWhereInput,
	organizationMemberships_none?:OrganizationMembershipWhereInput,
	organizationMemberships_some?:OrganizationMembershipWhereInput,
	views_every?:ViewWhereInput,
	views_none?:ViewWhereInput,
	views_some?:ViewWhereInput
}

export type View = {
	__typename?: "View",
	archivedAt?:DateTime,
	author:User,
	createdAt:DateTime,
	id:string,
	name:string,
	organization?:Organization,
	updatedAt:DateTime,
	urlQueryParams:string
}

export enum ViewOrderByInput {
	archivedAt_ASC = "archivedAt_ASC",
	archivedAt_DESC = "archivedAt_DESC",
	createdAt_ASC = "createdAt_ASC",
	createdAt_DESC = "createdAt_DESC",
	id_ASC = "id_ASC",
	id_DESC = "id_DESC",
	name_ASC = "name_ASC",
	name_DESC = "name_DESC",
	updatedAt_ASC = "updatedAt_ASC",
	updatedAt_DESC = "updatedAt_DESC",
	urlQueryParams_ASC = "urlQueryParams_ASC",
	urlQueryParams_DESC = "urlQueryParams_DESC"
}

export type ViewWhereInput = {
		AND?:ViewWhereInput[],
	archivedAt?:DateTime,
	archivedAt_gt?:DateTime,
	archivedAt_gte?:DateTime,
	archivedAt_in?:DateTime[],
	archivedAt_lt?:DateTime,
	archivedAt_lte?:DateTime,
	archivedAt_not?:DateTime,
	archivedAt_not_in?:DateTime[],
	author?:UserWhereInput,
	createdAt?:DateTime,
	createdAt_gt?:DateTime,
	createdAt_gte?:DateTime,
	createdAt_in?:DateTime[],
	createdAt_lt?:DateTime,
	createdAt_lte?:DateTime,
	createdAt_not?:DateTime,
	createdAt_not_in?:DateTime[],
	id?:string,
	id_contains?:string,
	id_ends_with?:string,
	id_gt?:string,
	id_gte?:string,
	id_in?:string[],
	id_lt?:string,
	id_lte?:string,
	id_not?:string,
	id_not_contains?:string,
	id_not_ends_with?:string,
	id_not_in?:string[],
	id_not_starts_with?:string,
	id_starts_with?:string,
	name?:string,
	name_contains?:string,
	name_ends_with?:string,
	name_gt?:string,
	name_gte?:string,
	name_in?:string[],
	name_lt?:string,
	name_lte?:string,
	name_not?:string,
	name_not_contains?:string,
	name_not_ends_with?:string,
	name_not_in?:string[],
	name_not_starts_with?:string,
	name_starts_with?:string,
	NOT?:ViewWhereInput[],
	OR?:ViewWhereInput[],
	organization?:OrganizationWhereInput,
	updatedAt?:DateTime,
	updatedAt_gt?:DateTime,
	updatedAt_gte?:DateTime,
	updatedAt_in?:DateTime[],
	updatedAt_lt?:DateTime,
	updatedAt_lte?:DateTime,
	updatedAt_not?:DateTime,
	updatedAt_not_in?:DateTime[],
	urlQueryParams?:string,
	urlQueryParams_contains?:string,
	urlQueryParams_ends_with?:string,
	urlQueryParams_gt?:string,
	urlQueryParams_gte?:string,
	urlQueryParams_in?:string[],
	urlQueryParams_lt?:string,
	urlQueryParams_lte?:string,
	urlQueryParams_not?:string,
	urlQueryParams_not_contains?:string,
	urlQueryParams_not_ends_with?:string,
	urlQueryParams_not_in?:string[],
	urlQueryParams_not_starts_with?:string,
	urlQueryParams_starts_with?:string
}

export type ViewWhereUniqueInput = {
		id?:string
}

export const AllTypesProps: Record<string,any> = {
	AppleAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppleAccessCreateInput:{
		accountName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		login:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppleAccessOrderByInput: "enum",
	AppleAccessUpdateInput:{
		accountName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppleAccessWhereInput:{
		accountName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accountName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accountName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"AppleAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		login:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		login_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		login_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppleAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppleAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		password_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		password_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AppleAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppleFeed:{
		games:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ScraperGameOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ScraperGameWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppleFeedCreateWithoutParamsInput:{
		appleAccess:{
			type:"AppleAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		games:{
			type:"ScraperGameCreateInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		reporter:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		scraper:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		usePST:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppleFeedWhereInput:{
		AND:{
			type:"AppleFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appleAccess:{
			type:"AppleAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		games_every:{
			type:"ScraperGameWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		games_none:{
			type:"ScraperGameWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		games_some:{
			type:"ScraperGameWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppleFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppleFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reporter:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		reporter_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		scraper:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		scraper_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		usePST:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		usePST_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppleSearchAdsAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppleSearchAdsAccessCreateInput:{
		certificate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		certificateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	AppleSearchAdsAccessOrderByInput: "enum",
	AppleSearchAdsAccessUpdateInput:{
		certificate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppleSearchAdsAccessWhereInput:{
		AND:{
			type:"AppleSearchAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		certificate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		certificate_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		certificate_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificate_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		certificateKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		certificateKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		certificateKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppleSearchAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppleSearchAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AppleSearchAdsAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppleSearchAdsFeedCreateWithoutParamsInput:{
		appleSearchAdsAccess:{
			type:"AppleSearchAdsAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		groupBy:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppleSearchAdsFeedWhereInput:{
		AND:{
			type:"AppleSearchAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appleSearchAdsAccess:{
			type:"AppleSearchAdsAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppleSearchAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppleSearchAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		orgId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		orgId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		orgId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orgId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ApplovinAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ApplovinAccessCreateInput:{
		apiKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	ApplovinAccessOrderByInput: "enum",
	ApplovinAccessUpdateInput:{
		apiKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ApplovinAccessWhereInput:{
		AND:{
			type:"ApplovinAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ApplovinAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ApplovinAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ApplovinAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ApplovinFeedCreateWithoutParamsInput:{
		applovinAccess:{
			type:"ApplovinAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		gameIds:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType:{
			type:"ApplovinReportType",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ApplovinFeedWhereInput:{
		AND:{
			type:"ApplovinFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		applovinAccess:{
			type:"ApplovinAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ApplovinFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ApplovinFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType:{
			type:"ApplovinReportType",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_in:{
			type:"ApplovinReportType",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportType_not:{
			type:"ApplovinReportType",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_in:{
			type:"ApplovinReportType",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ApplovinReportType: "enum",
	AppsFlyerAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppsFlyerAccessCreateInput:{
		apiToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	AppsFlyerAccessOrderByInput: "enum",
	AppsFlyerAccessUpdateInput:{
		apiToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerAccessWhereInput:{
		AND:{
			type:"AppsFlyerAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppsFlyerAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppsFlyerAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AppsFlyerAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppsFlyerCampaignFeedCreateWithoutParamsInput:{
		appsFlyerAccess:{
			type:"AppsFlyerAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		attributedTouchType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reattr:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType:{
			type:"ReportType",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerCampaignFeedWhereInput:{
		AND:{
			type:"AppsFlyerCampaignFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appsFlyerAccess:{
			type:"AppsFlyerAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		attributedTouchType_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		attributedTouchType_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attributedTouchType_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		currency_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		currency_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		eventName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		eventName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		mediaSource_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		mediaSource_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mediaSource_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppsFlyerCampaignFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppsFlyerCampaignFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reattr:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		reattr_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType:{
			type:"ReportType",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_in:{
			type:"ReportType",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportType_not:{
			type:"ReportType",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_in:{
			type:"ReportType",
			array:true,
			arrayRequired:false,
			required:true
		},
		timezone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		timezone_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		timezone_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerDatalockerFeedCreateWithoutParamsInput:{
		awsAccessKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		bucketSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		eventNames:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		homeFolder:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerDatalockerFeedWhereInput:{
		AND:{
			type:"AppsFlyerDatalockerFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		awsAccessKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		awsAccessKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		awsAccessKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsAccessKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketSecret_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketSecret_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketSecret_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		homeFolder_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		homeFolder_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeFolder_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppsFlyerDatalockerFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppsFlyerDatalockerFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerRawEventsFeedCreateWithoutParamsInput:{
		appsFlyerAccess:{
			type:"AppsFlyerAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		eventName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		omitFields:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		reportType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppsFlyerRawEventsFeedWhereInput:{
		AND:{
			type:"AppsFlyerRawEventsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appsFlyerAccess:{
			type:"AppsFlyerAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		eventName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		eventName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppsFlyerRawEventsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppsFlyerRawEventsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportType_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportType_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerRawInstallsFeedCreateWithoutParamsInput:{
		appsFlyerAccess:{
			type:"AppsFlyerAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		omitFields:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		reportType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppsFlyerRawInstallsFeedWhereInput:{
		AND:{
			type:"AppsFlyerRawInstallsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appsFlyerAccess:{
			type:"AppsFlyerAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppsFlyerRawInstallsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppsFlyerRawInstallsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportType_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportType_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportType_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppsFlyerScraperFeed:{
		games:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ScraperGameOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ScraperGameWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppsFlyerScraperFeedCreateWithoutParamsInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		games:{
			type:"ScraperGameCreateInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppsFlyerScraperFeedWhereInput:{
		AND:{
			type:"AppsFlyerScraperFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		email_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		email_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		games_every:{
			type:"ScraperGameWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		games_none:{
			type:"ScraperGameWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		games_some:{
			type:"ScraperGameWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AppsFlyerScraperFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AppsFlyerScraperFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		password_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		password_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AwsProject:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		s3Destinations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"S3DestinationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"S3DestinationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AwsProjectCreateInput:{
		accessKeyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		secretAccessKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AwsProjectOrderByInput: "enum",
	AwsProjectUpdateInput:{
		accessKeyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AwsProjectWhereInput:{
		accessKeyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accessKeyId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accessKeyId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessKeyId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"AwsProjectWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"AwsProjectWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"AwsProjectWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations_every:{
			type:"S3DestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations_none:{
			type:"S3DestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations_some:{
			type:"S3DestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		secretAccessKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		secretAccessKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretAccessKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AwsProjectWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryCheck:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	BigQueryCheckOrderByInput: "enum",
	BigQueryCheckWhereInput:{
		AND:{
			type:"BigQueryCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataset_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataset_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		lastModified_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastModified_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		maxDate:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxDate_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxDate_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxDate_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		maxDate_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxDate_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxDate_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxDate_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		NOT:{
			type:"BigQueryCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"BigQueryCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		projectId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		projectId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableExists:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableExists_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableReference_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableReference_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableSuffix_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableSuffix_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryCheckWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableReference:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BigQueryDestination:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	BigQueryDestinationCreateInput:{
		bqProject:{
			type:"BigQueryProjectWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		dataset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		tablePrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BigQueryDestinationOrderByInput: "enum",
	BigQueryDestinationUpdateInput:{
		bqProject:{
			type:"BigQueryProjectWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BigQueryDestinationUpdateManyInput:{
		create:{
			type:"BigQueryDestinationCreateInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		delete:{
			type:"BigQueryDestinationWhereUniqueInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		update:{
			type:"BigQueryDestinationUpdateInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryDestinationWhereInput:{
		AND:{
			type:"BigQueryDestinationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		bqProject:{
			type:"BigQueryProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataset_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataset_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataset_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"BigQueryDestinationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"BigQueryDestinationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tablePrefix_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tablePrefix_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablePrefix_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableSuffix_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		tableSuffix_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableSuffix_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryDestinationWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryProject:{
		bqDestinations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryDestinationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryDestinationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	BigQueryProjectCreateInput:{
		clientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		privateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryProjectOrderByInput: "enum",
	BigQueryProjectUpdateInput:{
		clientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BigQueryProjectWhereInput:{
		AND:{
			type:"BigQueryProjectWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		bqDestinations_every:{
			type:"BigQueryDestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations_none:{
			type:"BigQueryDestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations_some:{
			type:"BigQueryDestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientEmail_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientEmail_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientEmail_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"BigQueryProjectWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"BigQueryProjectWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		privateKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		privateKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		privateKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		projectId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		projectId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	BigQueryProjectWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	CampaignConsistencyCheck:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		values:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CampaignConsistencyValuesOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"CampaignConsistencyValuesWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CampaignConsistencyCheckOrderByInput: "enum",
	CampaignConsistencyCheckWhereInput:{
		AND:{
			type:"CampaignConsistencyCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		campaignId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		campaignId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		campaignId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		date:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"CampaignConsistencyCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"CampaignConsistencyCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		platform:{
			type:"Platform",
			array:false,
			arrayRequired:false,
			required:false
		},
		platform_in:{
			type:"Platform",
			array:true,
			arrayRequired:false,
			required:true
		},
		platform_not:{
			type:"Platform",
			array:false,
			arrayRequired:false,
			required:false
		},
		platform_not_in:{
			type:"Platform",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		values_every:{
			type:"CampaignConsistencyValuesWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values_none:{
			type:"CampaignConsistencyValuesWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values_some:{
			type:"CampaignConsistencyValuesWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CampaignConsistencyCheckWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CampaignConsistencyValuesOrderByInput: "enum",
	CampaignConsistencyValuesWhereInput:{
		AND:{
			type:"CampaignConsistencyValuesWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		clicks:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		clicks_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		clicks_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		clicks_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		clicks_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		clicks_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		clicks_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		clicks_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		iapGrossRevenue:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		iapGrossRevenue_gt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		iapGrossRevenue_gte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		iapGrossRevenue_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		iapGrossRevenue_lt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		iapGrossRevenue_lte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		iapGrossRevenue_not:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		iapGrossRevenue_not_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		impressions_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		impressions_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		installs:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		installs_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		NOT:{
			type:"CampaignConsistencyValuesWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"CampaignConsistencyValuesWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		payers:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		payers_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		payers_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		payers_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		payers_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		payers_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		payers_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		payers_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		purchases:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		purchases_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		source:{
			type:"ConsistencySource",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_in:{
			type:"ConsistencySource",
			array:true,
			arrayRequired:false,
			required:true
		},
		source_not:{
			type:"ConsistencySource",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_not_in:{
			type:"ConsistencySource",
			array:true,
			arrayRequired:false,
			required:true
		},
		spend:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		spend_gt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		spend_gte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		spend_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		spend_lt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		spend_lte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		spend_not:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		spend_not_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ChartboostAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ChartboostAccessCreateInput:{
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		userSignature:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ChartboostAccessOrderByInput: "enum",
	ChartboostAccessUpdateInput:{
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ChartboostAccessWhereInput:{
		AND:{
			type:"ChartboostAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ChartboostAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ChartboostAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		userId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		userId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		userSignature_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		userSignature_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userSignature_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ChartboostAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ChartboostFeedCreateWithoutParamsInput:{
		chartboostAccess:{
			type:"ChartboostAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		groupBy:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ChartboostFeedWhereInput:{
		AND:{
			type:"ChartboostFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		chartboostAccess:{
			type:"ChartboostAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		groupBy_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		groupBy_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupBy_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ChartboostFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ChartboostFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ConsistencySource: "enum",
	DailyConsistencyCheck:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		values:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"DailyConsistencyValuesOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"DailyConsistencyValuesWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	DailyConsistencyCheckOrderByInput: "enum",
	DailyConsistencyCheckWhereInput:{
		AND:{
			type:"DailyConsistencyCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		date:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"DailyConsistencyCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"DailyConsistencyCheckWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		platform:{
			type:"Platform",
			array:false,
			arrayRequired:false,
			required:false
		},
		platform_in:{
			type:"Platform",
			array:true,
			arrayRequired:false,
			required:true
		},
		platform_not:{
			type:"Platform",
			array:false,
			arrayRequired:false,
			required:false
		},
		platform_not_in:{
			type:"Platform",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		values_every:{
			type:"DailyConsistencyValuesWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values_none:{
			type:"DailyConsistencyValuesWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values_some:{
			type:"DailyConsistencyValuesWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DailyConsistencyCheckWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DailyConsistencyValuesOrderByInput: "enum",
	DailyConsistencyValuesWhereInput:{
		AND:{
			type:"DailyConsistencyValuesWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		installs_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		installs_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		NOT:{
			type:"DailyConsistencyValuesWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"DailyConsistencyValuesWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		purchases:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		purchases_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchases_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		revenue:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		revenue_gt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		revenue_gte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		revenue_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		revenue_lt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		revenue_lte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		revenue_not:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		revenue_not_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		source:{
			type:"ConsistencySource",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_in:{
			type:"ConsistencySource",
			array:true,
			arrayRequired:false,
			required:true
		},
		source_not:{
			type:"ConsistencySource",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_not_in:{
			type:"ConsistencySource",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	DateTime: "String",
	ErrorOrderByInput: "enum",
	ErrorWhereInput:{
		AND:{
			type:"ErrorWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		message_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		message_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ErrorWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ErrorWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	FacebookAdsAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FacebookAdsAccessCreateInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		accountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	FacebookAdsAccessOrderByInput: "enum",
	FacebookAdsAccessUpdateInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FacebookAdsAccessWhereInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accessToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accessToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accountId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accountId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"FacebookAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FacebookAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FacebookAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	FacebookAdsAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	FacebookAdsFeed:{
		breakdowns:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FacebookBreakdownOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"FacebookBreakdownWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FacebookAdsFeedCreateWithoutParamsInput:{
		additionalFields:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		breakdowns:{
			type:"FacebookBreakdownCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		facebookAdsAccess:{
			type:"FacebookAdsAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		levels:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		omitFields:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		snapshot:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FacebookAdsFeedWhereInput:{
		AND:{
			type:"FacebookAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		breakdowns_every:{
			type:"FacebookBreakdownWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		breakdowns_none:{
			type:"FacebookBreakdownWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		breakdowns_some:{
			type:"FacebookBreakdownWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebookAdsAccess:{
			type:"FacebookAdsAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FacebookAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FacebookAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		snapshot:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		snapshot_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FacebookBreakdownCreateInput:{
		values:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	FacebookBreakdownOrderByInput: "enum",
	FacebookBreakdownWhereInput:{
		AND:{
			type:"FacebookBreakdownWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FacebookBreakdownWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FacebookBreakdownWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	FairbidAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FairbidAccessCreateInput:{
		clientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	FairbidAccessOrderByInput: "enum",
	FairbidAccessUpdateInput:{
		clientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FairbidAccessWhereInput:{
		AND:{
			type:"FairbidAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientSecret_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientSecret_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FairbidAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FairbidAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	FairbidAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	FairbidUserReportFeedCreateWithoutParamsInput:{
		appId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		fairbidAccess:{
			type:"FairbidAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FairbidUserReportFeedWhereInput:{
		AND:{
			type:"FairbidUserReportFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		appId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		appId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fairbidAccess:{
			type:"FairbidAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FairbidUserReportFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FairbidUserReportFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Feed: "enum",
	FeedDateInputCreateInput:{
		dateDashFrom:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeedDateInputWhereInput:{
		AND:{
			type:"FeedDateInputWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		dateDashFrom:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dateDashFrom_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dateDashFrom_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashFrom_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dateDashTo_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dateDashTo_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateDashTo_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		daysInPast_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daysInPast_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FeedDateInputWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		offset_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FeedDateInputWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	FixerFeedCreateWithoutParamsInput:{
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FixerFeedWhereInput:{
		AND:{
			type:"FixerFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		currency_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		currency_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"FixerFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"FixerFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GoogleAdsAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GoogleAdsAccessCreateInput:{
		clientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		developerToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	GoogleAdsAccessOrderByInput: "enum",
	GoogleAdsAccessUpdateInput:{
		clientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GoogleAdsAccessWhereInput:{
		AND:{
			type:"GoogleAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientSecret_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientSecret_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		developerToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		developerToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		developerToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"GoogleAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"GoogleAdsAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	GoogleAdsAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	GoogleAdwordsFeedCreateWithoutParamsInput:{
		customerAccountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		geographicView:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		googleAdsAccess:{
			type:"GoogleAdsAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	GoogleAdwordsFeedWhereInput:{
		AND:{
			type:"GoogleAdwordsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		customerAccountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		customerAccountId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		customerAccountId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerAccountId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicView:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicView_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		googleAdsAccess:{
			type:"GoogleAdsAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		loginCustomerId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		loginCustomerId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		loginCustomerId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"GoogleAdwordsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"GoogleAdwordsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		refreshToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		refreshToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GooglePlayAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GooglePlayAccessCreateInput:{
		bucketId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		packageIdentifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		sourcePrivateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		sourceProjectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	GooglePlayAccessOrderByInput: "enum",
	GooglePlayAccessUpdateInput:{
		bucketId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GooglePlayAccessWhereInput:{
		AND:{
			type:"GooglePlayAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"GooglePlayAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"GooglePlayAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		packageIdentifier_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		packageIdentifier_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		packageIdentifier_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		sourceClientEmail_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		sourceClientEmail_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceClientEmail_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		sourcePrivateKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		sourcePrivateKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourcePrivateKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		sourceProjectId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		sourceProjectId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceProjectId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	GooglePlayAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	GooglePlayFeedCreateWithoutParamsInput:{
		googlePlayAccess:{
			type:"GooglePlayAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GooglePlayFeedWhereInput:{
		AND:{
			type:"GooglePlayFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		googlePlayAccess:{
			type:"GooglePlayAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"GooglePlayFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"GooglePlayFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HarvestAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	HarvestAccessCreateInput:{
		accountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	HarvestAccessOrderByInput: "enum",
	HarvestAccessUpdateInput:{
		accountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HarvestAccessWhereInput:{
		accountId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accountId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accountId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"HarvestAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"HarvestAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"HarvestAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		token_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		token_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	HarvestAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	HarvestFeedCreateWithoutParamsInput:{
		harvestAccess:{
			type:"HarvestAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HarvestFeedWhereInput:{
		AND:{
			type:"HarvestFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		harvestAccess:{
			type:"HarvestAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"HarvestFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"HarvestFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedSince_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedSince_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSince_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedSinceDaysInPast_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceDaysInPast_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedSinceOffset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedSinceOffset_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedSinceOffset_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	InstanceState: "enum",
	IntegrationFeedConfig:{
		instances:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IntegrationFeedInstanceOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"IntegrationFeedInstanceWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IntegrationFeedConfigCreateInput:{
		eligibleAt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsCreateInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	IntegrationFeedConfigOrderByInput: "enum",
	IntegrationFeedConfigUpdateInput:{
		eligibleAt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		params:{
			type:"IntegrationFeedParamsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IntegrationFeedConfigWhereInput:{
		AND:{
			type:"IntegrationFeedConfigWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		eligibleAt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		eligibleAt_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		eligibleAt_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eligibleAt_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		instances_every:{
			type:"IntegrationFeedInstanceWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		instances_none:{
			type:"IntegrationFeedInstanceWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		instances_some:{
			type:"IntegrationFeedInstanceWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"IntegrationFeedConfigWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"IntegrationFeedConfigWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	IntegrationFeedConfigWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	IntegrationFeedInstance:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		retryErrors:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ErrorOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ErrorWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IntegrationFeedInstanceCreateInput:{
		config:{
			type:"IntegrationFeedConfigWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsCreateInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	IntegrationFeedInstanceOrderByInput: "enum",
	IntegrationFeedInstanceWhereInput:{
		AND:{
			type:"IntegrationFeedInstanceWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		config:{
			type:"IntegrationFeedConfigWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		failureError:{
			type:"ErrorWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"IntegrationFeedInstanceWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"IntegrationFeedInstanceWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		retryErrors_every:{
			type:"ErrorWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		retryErrors_none:{
			type:"ErrorWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		retryErrors_some:{
			type:"ErrorWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"InstanceState",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_in:{
			type:"InstanceState",
			array:true,
			arrayRequired:false,
			required:true
		},
		state_not:{
			type:"InstanceState",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_not_in:{
			type:"InstanceState",
			array:true,
			arrayRequired:false,
			required:true
		},
		stateMachineExecutionArn:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		stateMachineExecutionArn_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		stateMachineExecutionArn_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	IntegrationFeedInstanceWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		stateMachineExecutionArn:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IntegrationFeedParams:{
		bqDestinations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryDestinationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryDestinationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		s3Destinations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"S3DestinationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"S3DestinationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IntegrationFeedParamsCreateInput:{
		apple:{
			type:"AppleFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appleSearchAds:{
			type:"AppleSearchAdsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		applovin:{
			type:"ApplovinFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerCampaign:{
			type:"AppsFlyerCampaignFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerDatalocker:{
			type:"AppsFlyerDatalockerFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerRawEvents:{
			type:"AppsFlyerRawEventsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerRawInstalls:{
			type:"AppsFlyerRawInstallsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerScraper:{
			type:"AppsFlyerScraperFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations:{
			type:"BigQueryDestinationCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		chartboost:{
			type:"ChartboostFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateInput:{
			type:"FeedDateInputCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		facebookAds:{
			type:"FacebookAdsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fairbidUserReport:{
			type:"FairbidUserReportFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		feedType:{
			type:"Feed",
			array:false,
			arrayRequired:false,
			required:true
		},
		fixer:{
			type:"FixerFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		googleAdwords:{
			type:"GoogleAdwordsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		googlePlay:{
			type:"GooglePlayFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		harvest:{
			type:"HarvestFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSource:{
			type:"IronSourceFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourcePromotion:{
			type:"IronSourcePromotionFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		libring:{
			type:"LibringFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations:{
			type:"S3DestinationCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		snapchat:{
			type:"SnapchatFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktokAdsAudience:{
			type:"TiktokAdsAudienceFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAds:{
			type:"UnityAdsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAdvertiser:{
			type:"UnityAdvertiserFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IntegrationFeedParamsUpdateInput:{
		apple:{
			type:"AppleFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appleSearchAds:{
			type:"AppleSearchAdsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		applovin:{
			type:"ApplovinFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerCampaign:{
			type:"AppsFlyerCampaignFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerDatalocker:{
			type:"AppsFlyerDatalockerFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerRawEvents:{
			type:"AppsFlyerRawEventsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerRawInstalls:{
			type:"AppsFlyerRawInstallsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerScraper:{
			type:"AppsFlyerScraperFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations:{
			type:"BigQueryDestinationUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		chartboost:{
			type:"ChartboostFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateInput:{
			type:"FeedDateInputCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebookAds:{
			type:"FacebookAdsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fairbidUserReport:{
			type:"FairbidUserReportFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		feedType:{
			type:"Feed",
			array:false,
			arrayRequired:false,
			required:false
		},
		fixer:{
			type:"FixerFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		googleAdwords:{
			type:"GoogleAdwordsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		googlePlay:{
			type:"GooglePlayFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		harvest:{
			type:"HarvestFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSource:{
			type:"IronSourceFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourcePromotion:{
			type:"IronSourcePromotionFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		libring:{
			type:"LibringFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations:{
			type:"S3DestinationUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		snapchat:{
			type:"SnapchatFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktokAdsAudience:{
			type:"TiktokAdsAudienceFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAds:{
			type:"UnityAdsFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAdvertiser:{
			type:"UnityAdvertiserFeedCreateWithoutParamsInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IntegrationFeedParamsWhereInput:{
		AND:{
			type:"IntegrationFeedParamsWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		apple:{
			type:"AppleFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appleSearchAds:{
			type:"AppleSearchAdsFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		applovin:{
			type:"ApplovinFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerCampaign:{
			type:"AppsFlyerCampaignFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerDatalocker:{
			type:"AppsFlyerDatalockerFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerRawEvents:{
			type:"AppsFlyerRawEventsFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerRawInstalls:{
			type:"AppsFlyerRawInstallsFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		appsFlyerScraper:{
			type:"AppsFlyerScraperFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations_every:{
			type:"BigQueryDestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations_none:{
			type:"BigQueryDestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqDestinations_some:{
			type:"BigQueryDestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		chartboost:{
			type:"ChartboostFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateInput:{
			type:"FeedDateInputWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebookAds:{
			type:"FacebookAdsFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fairbidUserReport:{
			type:"FairbidUserReportFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		feedType:{
			type:"Feed",
			array:false,
			arrayRequired:false,
			required:false
		},
		feedType_in:{
			type:"Feed",
			array:true,
			arrayRequired:false,
			required:true
		},
		feedType_not:{
			type:"Feed",
			array:false,
			arrayRequired:false,
			required:false
		},
		feedType_not_in:{
			type:"Feed",
			array:true,
			arrayRequired:false,
			required:true
		},
		fixer:{
			type:"FixerFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		googleAdwords:{
			type:"GoogleAdwordsFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		googlePlay:{
			type:"GooglePlayFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		harvest:{
			type:"HarvestFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSource:{
			type:"IronSourceFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourcePromotion:{
			type:"IronSourcePromotionFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		libring:{
			type:"LibringFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"IntegrationFeedParamsWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"IntegrationFeedParamsWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		s3Destinations_every:{
			type:"S3DestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations_none:{
			type:"S3DestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		s3Destinations_some:{
			type:"S3DestinationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		snapchat:{
			type:"SnapchatFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktokAdsAudience:{
			type:"TiktokAdsAudienceFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAds:{
			type:"UnityAdsFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAdvertiser:{
			type:"UnityAdvertiserFeedWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IronSourceAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IronSourceAccessCreateInput:{
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		secretKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	IronSourceAccessOrderByInput: "enum",
	IronSourceAccessUpdateInput:{
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IronSourceAccessWhereInput:{
		AND:{
			type:"IronSourceAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"IronSourceAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"IronSourceAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		refreshToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		refreshToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		secretKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		secretKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secretKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	IronSourceAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	IronSourceFeedCreateWithoutParamsInput:{
		adRevenue:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourceAccess:{
			type:"IronSourceAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		reportBreakdowns:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		reportMetrics:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		reports:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IronSourceFeedWhereInput:{
		adRevenue:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		adRevenue_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"IronSourceFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		appKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		appKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourceAccess:{
			type:"IronSourceAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"IronSourceFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"IronSourceFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reports:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		reports_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IronSourcePromotionDeviceType: "enum",
	IronSourcePromotionFeedCreateWithoutParamsInput:{
		adUnit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		breakdowns:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		bundleIds:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		campaignIds:{
			type:"Int",
			array:true,
			arrayRequired:true,
			required:true
		},
		countries:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		creativeIds:{
			type:"Int",
			array:true,
			arrayRequired:true,
			required:true
		},
		deviceType:{
			type:"IronSourcePromotionDeviceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourceAccess:{
			type:"IronSourceAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		metrics:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		os:{
			type:"IronSourcePromotionOsType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IronSourcePromotionFeedWhereInput:{
		adUnit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		adUnit_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		adUnit_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adUnit_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"IronSourcePromotionFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		deviceType:{
			type:"IronSourcePromotionDeviceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		deviceType_in:{
			type:"IronSourcePromotionDeviceType",
			array:true,
			arrayRequired:false,
			required:true
		},
		deviceType_not:{
			type:"IronSourcePromotionDeviceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		deviceType_not_in:{
			type:"IronSourcePromotionDeviceType",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ironSourceAccess:{
			type:"IronSourceAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"IronSourcePromotionFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"IronSourcePromotionFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		os:{
			type:"IronSourcePromotionOsType",
			array:false,
			arrayRequired:false,
			required:false
		},
		os_in:{
			type:"IronSourcePromotionOsType",
			array:true,
			arrayRequired:false,
			required:true
		},
		os_not:{
			type:"IronSourcePromotionOsType",
			array:false,
			arrayRequired:false,
			required:false
		},
		os_not_in:{
			type:"IronSourcePromotionOsType",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IronSourcePromotionOsType: "enum",
	LibringAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	LibringAccessCreateInput:{
		apiToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	LibringAccessOrderByInput: "enum",
	LibringAccessUpdateInput:{
		apiToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LibringAccessWhereInput:{
		AND:{
			type:"LibringAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"LibringAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"LibringAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	LibringAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	LibringFeedCreateWithoutParamsInput:{
		appName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		dataType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		libringAccess:{
			type:"LibringAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	LibringFeedWhereInput:{
		AND:{
			type:"LibringFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		appName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		appName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		appName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataType_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		dataType_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dataType_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		libringAccess:{
			type:"LibringAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"LibringFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"LibringFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Mutation:{
		abortEtl:{
			stateMachineExecutionArn:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		archiveView:{
			id:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		copyView:{
			id:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAppleAccess:{
			data:{
				type:"AppleAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAppleSearchAdsAccess:{
			data:{
				type:"AppleSearchAdsAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createApplovinAccess:{
			data:{
				type:"ApplovinAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAppsFlyerAccess:{
			data:{
				type:"AppsFlyerAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAwsProject:{
			data:{
				type:"AwsProjectCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createBigQueryProject:{
			data:{
				type:"BigQueryProjectCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createChartboostAccess:{
			data:{
				type:"ChartboostAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFacebookAdsAccess:{
			data:{
				type:"FacebookAdsAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFairbidAccess:{
			data:{
				type:"FairbidAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createGoogleAdsAccess:{
			data:{
				type:"GoogleAdsAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createGooglePlayAccess:{
			data:{
				type:"GooglePlayAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createHarvestAccess:{
			data:{
				type:"HarvestAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIntegrationFeedConfig:{
			data:{
				type:"IntegrationFeedConfigCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIronSourceAccess:{
			data:{
				type:"IronSourceAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLibringAccess:{
			data:{
				type:"LibringAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createOrganization:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSnapchatAccess:{
			data:{
				type:"SnapchatAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTiktokAccess:{
			data:{
				type:"TiktokAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createUnityAccess:{
			data:{
				type:"UnityAccessCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createView:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			urlQueryParams:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAppleAccess:{
			where:{
				type:"AppleAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAppleSearchAdsAccess:{
			where:{
				type:"AppleSearchAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteApplovinAccess:{
			where:{
				type:"ApplovinAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAppsFlyerAccess:{
			where:{
				type:"AppsFlyerAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAwsProject:{
			where:{
				type:"AwsProjectWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteBigQueryProject:{
			where:{
				type:"BigQueryProjectWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteChartboostAccess:{
			where:{
				type:"ChartboostAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFacebookAdsAccess:{
			where:{
				type:"FacebookAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFairbidAccess:{
			where:{
				type:"FairbidAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteGoogleAdsAccess:{
			where:{
				type:"GoogleAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteGooglePlayAccess:{
			where:{
				type:"GooglePlayAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteHarvestAccess:{
			where:{
				type:"HarvestAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIntegrationFeedConfig:{
			where:{
				type:"IntegrationFeedConfigWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIronSourceAccess:{
			where:{
				type:"IronSourceAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLibringAccess:{
			where:{
				type:"LibringAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteOrganization:{
			organizationId:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteSnapchatAccess:{
			where:{
				type:"SnapchatAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTiktokAccess:{
			where:{
				type:"TiktokAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteUnityAccess:{
			where:{
				type:"UnityAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteView:{
			id:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		inviteUser:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			organizationId:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		publishView:{
			id:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			},
			organizationName:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		restoreView:{
			id:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		runEtl:{
			data:{
				type:"IntegrationFeedInstanceCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setUserRoles:{
			organizationId:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			},
			roles:{
				type:"UserRole",
				array:true,
				arrayRequired:true,
				required:true
			},
			userId:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateAppleAccess:{
			data:{
				type:"AppleAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppleAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateAppleSearchAdsAccess:{
			data:{
				type:"AppleSearchAdsAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppleSearchAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateApplovinAccess:{
			data:{
				type:"ApplovinAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ApplovinAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateAppsFlyerAccess:{
			data:{
				type:"AppsFlyerAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppsFlyerAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateAwsProject:{
			data:{
				type:"AwsProjectUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AwsProjectWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateBigQueryProject:{
			data:{
				type:"BigQueryProjectUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"BigQueryProjectWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateChartboostAccess:{
			data:{
				type:"ChartboostAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ChartboostAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateFacebookAdsAccess:{
			data:{
				type:"FacebookAdsAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"FacebookAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateFairbidAccess:{
			data:{
				type:"FairbidAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"FairbidAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateGoogleAdsAccess:{
			data:{
				type:"GoogleAdsAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GoogleAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateGooglePlayAccess:{
			data:{
				type:"GooglePlayAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GooglePlayAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateHarvestAccess:{
			data:{
				type:"HarvestAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HarvestAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateIntegrationFeedConfig:{
			data:{
				type:"IntegrationFeedConfigUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"IntegrationFeedConfigWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateIronSourceAccess:{
			data:{
				type:"IronSourceAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"IronSourceAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateLibringAccess:{
			data:{
				type:"LibringAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"LibringAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateSnapchatAccess:{
			data:{
				type:"SnapchatAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"SnapchatAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateTiktokAccess:{
			data:{
				type:"TiktokAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TiktokAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateUnityAccess:{
			data:{
				type:"UnityAccessUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"UnityAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateView:{
			id:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			urlQueryParams:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Organization:{
		awsProjects:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AwsProjectOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"AwsProjectWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		bqProjects:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryProjectOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryProjectWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		organizationMemberships:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationMembershipOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationMembershipWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		views:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ViewOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ViewWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	OrganizationAttributeCreateInput:{
		organization:{
			type:"OrganizationWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		role:{
			type:"OrganizationRole",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	OrganizationAttributeOrderByInput: "enum",
	OrganizationAttributeUpdateInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		},
		organization:{
			type:"OrganizationWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		role:{
			type:"OrganizationRole",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	OrganizationAttributeUpdateManyInput:{
		create:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		delete:{
			type:"OrganizationAttributeWhereUniqueInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		update:{
			type:"OrganizationAttributeUpdateInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	OrganizationAttributeWhereInput:{
		AND:{
			type:"OrganizationAttributeWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"OrganizationAttributeWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"OrganizationAttributeWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organization:{
			type:"OrganizationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"OrganizationRole",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_in:{
			type:"OrganizationRole",
			array:true,
			arrayRequired:false,
			required:true
		},
		role_not:{
			type:"OrganizationRole",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_not_in:{
			type:"OrganizationRole",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	OrganizationAttributeWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	OrganizationMembershipOrderByInput: "enum",
	OrganizationMembershipWhereInput:{
		AND:{
			type:"OrganizationMembershipWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"OrganizationMembershipWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"OrganizationMembershipWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organization:{
			type:"OrganizationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"UserRole",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_in:{
			type:"UserRole",
			array:true,
			arrayRequired:false,
			required:true
		},
		role_not:{
			type:"UserRole",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_not_in:{
			type:"UserRole",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		user:{
			type:"UserWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	OrganizationOrderByInput: "enum",
	OrganizationRole: "enum",
	OrganizationWhereInput:{
		AND:{
			type:"OrganizationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		awsProjects_every:{
			type:"AwsProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsProjects_none:{
			type:"AwsProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		awsProjects_some:{
			type:"AwsProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqProjects_every:{
			type:"BigQueryProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqProjects_none:{
			type:"BigQueryProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bqProjects_some:{
			type:"BigQueryProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		name_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		name_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"OrganizationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"OrganizationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationMemberships_every:{
			type:"OrganizationMembershipWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationMemberships_none:{
			type:"OrganizationMembershipWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationMemberships_some:{
			type:"OrganizationMembershipWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		views_every:{
			type:"ViewWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_none:{
			type:"ViewWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_some:{
			type:"ViewWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	OrganizationWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Platform: "enum",
	Query:{
		appleAccess:{
			where:{
				type:"AppleAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		appleAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AppleAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"AppleAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		appleSearchAdsAccess:{
			where:{
				type:"AppleSearchAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		appleSearchAdsAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AppleSearchAdsAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"AppleSearchAdsAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		applovinAccess:{
			where:{
				type:"ApplovinAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		applovinAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ApplovinAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ApplovinAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		appsFlyerAccess:{
			where:{
				type:"AppsFlyerAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		appsFlyerAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AppsFlyerAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"AppsFlyerAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		awsProject:{
			where:{
				type:"AwsProjectWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		awsProjects:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AwsProjectOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"AwsProjectWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		bigQueryCheck:{
			where:{
				type:"BigQueryCheckWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		bigQueryChecks:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryCheckOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryCheckWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		bigQueryChecksConnection:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryCheckOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryCheckWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		bigQueryDestination:{
			where:{
				type:"BigQueryDestinationWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		bigQueryDestinations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryDestinationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryDestinationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		bigQueryProject:{
			where:{
				type:"BigQueryProjectWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		bigQueryProjects:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"BigQueryProjectOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"BigQueryProjectWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		campaignConsistencyCheck:{
			where:{
				type:"CampaignConsistencyCheckWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		campaignConsistencyChecks:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CampaignConsistencyCheckOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"CampaignConsistencyCheckWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		campaignConsistencyChecksConnection:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CampaignConsistencyCheckOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"CampaignConsistencyCheckWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chartboostAccess:{
			where:{
				type:"ChartboostAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		chartboostAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ChartboostAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ChartboostAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		dailyConsistencyCheck:{
			where:{
				type:"DailyConsistencyCheckWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		dailyConsistencyChecks:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"DailyConsistencyCheckOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"DailyConsistencyCheckWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		dailyConsistencyChecksConnection:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"DailyConsistencyCheckOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"DailyConsistencyCheckWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		facebookAdsAccess:{
			where:{
				type:"FacebookAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		facebookAdsAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FacebookAdsAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"FacebookAdsAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		fairbidAccess:{
			where:{
				type:"FairbidAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		fairbidAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FairbidAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"FairbidAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		googleAdsAccess:{
			where:{
				type:"GoogleAdsAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		googleAdsAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GoogleAdsAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"GoogleAdsAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		googlePlayAccess:{
			where:{
				type:"GooglePlayAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		googlePlayAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GooglePlayAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"GooglePlayAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		harvestAccess:{
			where:{
				type:"HarvestAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		harvestAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HarvestAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"HarvestAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		integrationFeedConfig:{
			where:{
				type:"IntegrationFeedConfigWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		integrationFeedConfigs:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IntegrationFeedConfigOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"IntegrationFeedConfigWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		integrationFeedConfigsConnection:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IntegrationFeedConfigOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"IntegrationFeedConfigWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		integrationFeedInstance:{
			where:{
				type:"IntegrationFeedInstanceWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		integrationFeedInstances:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IntegrationFeedInstanceOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"IntegrationFeedInstanceWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		integrationFeedInstancesConnection:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IntegrationFeedInstanceOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"IntegrationFeedInstanceWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ironSourceAccess:{
			where:{
				type:"IronSourceAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ironSourceAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IronSourceAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"IronSourceAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		libringAccess:{
			where:{
				type:"LibringAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		libringAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LibringAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"LibringAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		organization:{
			where:{
				type:"OrganizationWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		organizations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		s3Destination:{
			where:{
				type:"S3DestinationWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		s3Destinations:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"S3DestinationOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"S3DestinationWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		snapchatAccess:{
			where:{
				type:"SnapchatAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		snapchatAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SnapchatAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"SnapchatAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		tiktokAccess:{
			where:{
				type:"TiktokAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		tiktokAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TiktokAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"TiktokAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unityAccess:{
			where:{
				type:"UnityAccessWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unityAccesses:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"UnityAccessOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"UnityAccessWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"UserOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"UserWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		view:{
			where:{
				type:"ViewWhereUniqueInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		views:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ViewOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ViewWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ReportType: "enum",
	S3Destination:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	S3DestinationCreateInput:{
		awsProject:{
			type:"AwsProjectWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		bucketName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		pathPrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	S3DestinationOrderByInput: "enum",
	S3DestinationUpdateInput:{
		awsProject:{
			type:"AwsProjectWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		},
		objectSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	S3DestinationUpdateManyInput:{
		create:{
			type:"S3DestinationCreateInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		delete:{
			type:"S3DestinationWhereUniqueInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		update:{
			type:"S3DestinationUpdateInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	S3DestinationWhereInput:{
		AND:{
			type:"S3DestinationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		awsProject:{
			type:"AwsProjectWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		bucketName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bucketName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"S3DestinationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		objectSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		objectSuffix_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		objectSuffix_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		objectSuffix_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		OR:{
			type:"S3DestinationWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		pathPrefix_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		pathPrefix_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pathPrefix_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		region_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		region_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	S3DestinationWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ScraperGameCreateInput:{
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ScraperGameOrderByInput: "enum",
	ScraperGameWhereInput:{
		AND:{
			type:"ScraperGameWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		gameId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gameId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		name_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		name_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ScraperGameWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ScraperGameWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	SnapchatAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	SnapchatAccessCreateInput:{
		adAccountID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		clientID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	SnapchatAccessOrderByInput: "enum",
	SnapchatAccessUpdateInput:{
		adAccountID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SnapchatAccessWhereInput:{
		adAccountID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		adAccountID_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		adAccountID_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		adAccountID_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"SnapchatAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientID_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientID_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientID_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientSecret_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		clientSecret_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		clientSecret_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"SnapchatAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"SnapchatAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		refreshToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		refreshToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refreshToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	SnapchatAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	SnapchatFeedCreateWithoutParamsInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		snapchatAccess:{
			type:"SnapchatAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		timezoneOffset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SnapchatFeedWhereInput:{
		AND:{
			type:"SnapchatFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"SnapchatFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"SnapchatFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportDimension_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reportDimension_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reportDimension_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		snapchatAccess:{
			type:"SnapchatAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		timezoneOffset_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset_not:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezoneOffset_not_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TiktokAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TiktokAccessCreateInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		advertiserId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	TiktokAccessOrderByInput: "enum",
	TiktokAccessUpdateInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TiktokAccessWhereInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accessToken_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		accessToken_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accessToken_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		advertiserId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		advertiserId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advertiserId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		AND:{
			type:"TiktokAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"TiktokAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"TiktokAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TiktokAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TiktokAdsAudienceFeedCreateWithoutParamsInput:{
		dimensions:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		filterInactive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktokAccess:{
			type:"TiktokAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TiktokAdsAudienceFeedWhereInput:{
		AND:{
			type:"TiktokAdsAudienceFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		filterInactive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filterInactive_not:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"TiktokAdsAudienceFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"TiktokAdsAudienceFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktokAccess:{
			type:"TiktokAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UnityAccess:{
		organizationAttributes:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationAttributeOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationAttributeWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	UnityAccessCreateInput:{
		apiKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		},
		organizationId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	UnityAccessOrderByInput: "enum",
	UnityAccessUpdateInput:{
		apiKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes:{
			type:"OrganizationAttributeUpdateManyInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UnityAccessWhereInput:{
		AND:{
			type:"UnityAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiKey:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiKey_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		apiKey_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		apiKey_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		descriptiveName_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptiveName_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"UnityAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"UnityAccessWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationAttributes_every:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_none:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationAttributes_some:{
			type:"OrganizationAttributeWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationId_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationId_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationId_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	UnityAccessWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	UnityAdsFeedCreateWithoutParamsInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAccess:{
			type:"UnityAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	UnityAdsFeedWhereInput:{
		AND:{
			type:"UnityAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"UnityAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"UnityAdsFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAccess:{
			type:"UnityAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UnityAdvertiserFeedCreateWithoutParamsInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		targetIds:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		unityAccess:{
			type:"UnityAccessWhereUniqueInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	UnityAdvertiserFeedWhereInput:{
		AND:{
			type:"UnityAdvertiserFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"UnityAdvertiserFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"UnityAdvertiserFeedWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		params:{
			type:"IntegrationFeedParamsWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		unityAccess:{
			type:"UnityAccessWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	User:{
		organizationMemberships:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"OrganizationMembershipOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"OrganizationMembershipWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		views:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			before:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			last:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ViewOrderByInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ViewWhereInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	UserOrderByInput: "enum",
	UserRole: "enum",
	UserWhereInput:{
		AND:{
			type:"UserWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		email_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		email_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"UserWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"UserWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organizationMemberships_every:{
			type:"OrganizationMembershipWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationMemberships_none:{
			type:"OrganizationMembershipWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		organizationMemberships_some:{
			type:"OrganizationMembershipWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_every:{
			type:"ViewWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_none:{
			type:"ViewWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_some:{
			type:"ViewWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ViewOrderByInput: "enum",
	ViewWhereInput:{
		AND:{
			type:"ViewWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		archivedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		archivedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		archivedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		archivedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		archivedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		archivedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		archivedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		archivedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		author:{
			type:"UserWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_gte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_lt:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_lte:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_contains:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_ends_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_not_in:{
			type:"ID",
			array:true,
			arrayRequired:false,
			required:true
		},
		id_not_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		id_starts_with:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		name_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		name_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		NOT:{
			type:"ViewWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		OR:{
			type:"ViewWhereInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		organization:{
			type:"OrganizationWhereInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt_lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt_not_in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		urlQueryParams:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		urlQueryParams_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_not:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_not_contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_not_ends_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_not_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		urlQueryParams_not_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		urlQueryParams_starts_with:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ViewWhereUniqueInput:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	Action:{
		error:"String",
		message:"String",
		startDate:"String",
		stopDate:"String"
	},
	AggregateBigQueryCheck:{
		count:"Int"
	},
	AggregateCampaignConsistencyCheck:{
		count:"Int"
	},
	AggregateDailyConsistencyCheck:{
		count:"Int"
	},
	AggregateIntegrationFeedConfig:{
		count:"Int"
	},
	AggregateIntegrationFeedInstance:{
		count:"Int"
	},
	AppleAccess:{
		accountName:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		login:"String",
		organizationAttributes:"OrganizationAttribute",
		password:"String",
		updatedAt:"DateTime"
	},
	AppleFeed:{
		appleAccess:"AppleAccess",
		games:"ScraperGame",
		id:"ID",
		params:"IntegrationFeedParams",
		reporter:"Boolean",
		scraper:"Boolean",
		usePST:"Boolean"
	},
	AppleSearchAdsAccess:{
		certificate:"String",
		certificateKey:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	AppleSearchAdsFeed:{
		appleSearchAdsAccess:"AppleSearchAdsAccess",
		groupBy:"String",
		id:"ID",
		orgId:"String",
		params:"IntegrationFeedParams"
	},
	ApplovinAccess:{
		apiKey:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	ApplovinFeed:{
		applovinAccess:"ApplovinAccess",
		gameIds:"String",
		id:"ID",
		params:"IntegrationFeedParams",
		reportType:"ApplovinReportType"
	},
	AppsFlyerAccess:{
		apiToken:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	AppsFlyerCampaignFeed:{
		appsFlyerAccess:"AppsFlyerAccess",
		attributedTouchType:"String",
		currency:"String",
		eventName:"String",
		gameId:"String",
		id:"ID",
		mediaSource:"String",
		params:"IntegrationFeedParams",
		reattr:"Boolean",
		reportType:"ReportType",
		timezone:"String"
	},
	AppsFlyerDatalockerFeed:{
		awsAccessKey:"String",
		bucketSecret:"String",
		eventNames:"String",
		homeFolder:"String",
		id:"ID",
		params:"IntegrationFeedParams"
	},
	AppsFlyerRawEventsFeed:{
		appsFlyerAccess:"AppsFlyerAccess",
		eventName:"String",
		gameId:"String",
		id:"ID",
		omitFields:"String",
		params:"IntegrationFeedParams",
		reportType:"String"
	},
	AppsFlyerRawInstallsFeed:{
		appsFlyerAccess:"AppsFlyerAccess",
		gameId:"String",
		id:"ID",
		omitFields:"String",
		params:"IntegrationFeedParams",
		reportType:"String"
	},
	AppsFlyerScraperFeed:{
		email:"String",
		games:"ScraperGame",
		id:"ID",
		params:"IntegrationFeedParams",
		password:"String"
	},
	AwsProject:{
		accessKeyId:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		s3Destinations:"S3Destination",
		secretAccessKey:"String",
		updatedAt:"DateTime"
	},
	BigQueryCheck:{
		createdAt:"DateTime",
		dataset:"String",
		id:"ID",
		lastModified:"DateTime",
		maxDate:"DateTime",
		organizationAttributes:"OrganizationAttribute",
		projectId:"String",
		tableExists:"Boolean",
		tableName:"String",
		tableReference:"String",
		tableSuffix:"String",
		updatedAt:"DateTime"
	},
	BigQueryCheckConnection:{
		aggregate:"AggregateBigQueryCheck",
		edges:"BigQueryCheckEdge",
		pageInfo:"PageInfo"
	},
	BigQueryCheckEdge:{
		cursor:"String",
		node:"BigQueryCheck"
	},
	BigQueryDestination:{
		bqProject:"BigQueryProject",
		createdAt:"DateTime",
		dataset:"String",
		descriptiveName:"String",
		enabled:"Boolean",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		tablePrefix:"String",
		tableSuffix:"String",
		updatedAt:"DateTime"
	},
	BigQueryProject:{
		bqDestinations:"BigQueryDestination",
		clientEmail:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		privateKey:"String",
		projectId:"String",
		updatedAt:"DateTime"
	},
	CampaignConsistencyCheck:{
		campaignId:"String",
		createdAt:"DateTime",
		date:"DateTime",
		gameId:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		platform:"Platform",
		updatedAt:"DateTime",
		values:"CampaignConsistencyValues"
	},
	CampaignConsistencyCheckConnection:{
		aggregate:"AggregateCampaignConsistencyCheck",
		edges:"CampaignConsistencyCheckEdge",
		pageInfo:"PageInfo"
	},
	CampaignConsistencyCheckEdge:{
		cursor:"String",
		node:"CampaignConsistencyCheck"
	},
	CampaignConsistencyValues:{
		clicks:"Int",
		createdAt:"DateTime",
		iapGrossRevenue:"Float",
		id:"ID",
		impressions:"Int",
		installs:"Int",
		payers:"Int",
		purchases:"Int",
		source:"ConsistencySource",
		spend:"Float",
		updatedAt:"DateTime"
	},
	ChartboostAccess:{
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime",
		userId:"String",
		userSignature:"String"
	},
	ChartboostFeed:{
		chartboostAccess:"ChartboostAccess",
		gameId:"String",
		groupBy:"String",
		id:"ID",
		params:"IntegrationFeedParams"
	},
	DailyConsistencyCheck:{
		createdAt:"DateTime",
		date:"DateTime",
		gameId:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		platform:"Platform",
		updatedAt:"DateTime",
		values:"DailyConsistencyValues"
	},
	DailyConsistencyCheckConnection:{
		aggregate:"AggregateDailyConsistencyCheck",
		edges:"DailyConsistencyCheckEdge",
		pageInfo:"PageInfo"
	},
	DailyConsistencyCheckEdge:{
		cursor:"String",
		node:"DailyConsistencyCheck"
	},
	DailyConsistencyValues:{
		createdAt:"DateTime",
		id:"ID",
		installs:"Int",
		purchases:"Int",
		revenue:"Float",
		source:"ConsistencySource",
		updatedAt:"DateTime"
	},
	Error:{
		createdAt:"DateTime",
		id:"ID",
		message:"String",
		updatedAt:"DateTime"
	},
	FacebookAdsAccess:{
		accessToken:"String",
		accountId:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	FacebookAdsFeed:{
		additionalFields:"String",
		breakdowns:"FacebookBreakdown",
		facebookAdsAccess:"FacebookAdsAccess",
		id:"ID",
		levels:"String",
		omitFields:"String",
		params:"IntegrationFeedParams",
		snapshot:"Boolean"
	},
	FacebookBreakdown:{
		id:"ID",
		values:"String"
	},
	FairbidAccess:{
		clientId:"String",
		clientSecret:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	FairbidUserReportFeed:{
		appId:"String",
		fairbidAccess:"FairbidAccess",
		id:"ID",
		params:"IntegrationFeedParams"
	},
	FeedDateInput:{
		dateDashFrom:"String",
		dateDashTo:"String",
		daysInPast:"Int",
		id:"ID",
		offset:"Int"
	},
	FixerFeed:{
		currency:"String",
		id:"ID",
		params:"IntegrationFeedParams"
	},
	GoogleAdsAccess:{
		clientId:"String",
		clientSecret:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		developerToken:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	GoogleAdwordsFeed:{
		customerAccountId:"String",
		geographicView:"Boolean",
		googleAdsAccess:"GoogleAdsAccess",
		id:"ID",
		loginCustomerId:"String",
		params:"IntegrationFeedParams",
		refreshToken:"String"
	},
	GooglePlayAccess:{
		bucketId:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		packageIdentifier:"String",
		sourceClientEmail:"String",
		sourcePrivateKey:"String",
		sourceProjectId:"String",
		updatedAt:"DateTime"
	},
	GooglePlayFeed:{
		googlePlayAccess:"GooglePlayAccess",
		id:"ID",
		params:"IntegrationFeedParams"
	},
	HarvestAccess:{
		accountId:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		token:"String",
		updatedAt:"DateTime"
	},
	HarvestFeed:{
		harvestAccess:"HarvestAccess",
		id:"ID",
		params:"IntegrationFeedParams",
		updatedSince:"String",
		updatedSinceDaysInPast:"Int",
		updatedSinceOffset:"Int"
	},
	IntegrationFeedConfig:{
		createdAt:"DateTime",
		eligibleAt:"String",
		enabled:"Boolean",
		id:"ID",
		instances:"IntegrationFeedInstance",
		organizationAttributes:"OrganizationAttribute",
		params:"IntegrationFeedParams",
		updatedAt:"DateTime"
	},
	IntegrationFeedConfigConnection:{
		aggregate:"AggregateIntegrationFeedConfig",
		edges:"IntegrationFeedConfigEdge",
		pageInfo:"PageInfo"
	},
	IntegrationFeedConfigEdge:{
		cursor:"String",
		node:"IntegrationFeedConfig"
	},
	IntegrationFeedInstance:{
		config:"IntegrationFeedConfig",
		createdAt:"DateTime",
		failureError:"Error",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		params:"IntegrationFeedParams",
		retryErrors:"Error",
		state:"InstanceState",
		stateMachineExecutionArn:"String",
		updatedAt:"DateTime"
	},
	IntegrationFeedInstanceConnection:{
		aggregate:"AggregateIntegrationFeedInstance",
		edges:"IntegrationFeedInstanceEdge",
		pageInfo:"PageInfo"
	},
	IntegrationFeedInstanceEdge:{
		cursor:"String",
		node:"IntegrationFeedInstance"
	},
	IntegrationFeedParams:{
		apple:"AppleFeed",
		appleSearchAds:"AppleSearchAdsFeed",
		applovin:"ApplovinFeed",
		appsFlyerCampaign:"AppsFlyerCampaignFeed",
		appsFlyerDatalocker:"AppsFlyerDatalockerFeed",
		appsFlyerRawEvents:"AppsFlyerRawEventsFeed",
		appsFlyerRawInstalls:"AppsFlyerRawInstallsFeed",
		appsFlyerScraper:"AppsFlyerScraperFeed",
		bqDestinations:"BigQueryDestination",
		chartboost:"ChartboostFeed",
		dateInput:"FeedDateInput",
		descriptiveName:"String",
		facebookAds:"FacebookAdsFeed",
		fairbidUserReport:"FairbidUserReportFeed",
		feedType:"Feed",
		fixer:"FixerFeed",
		googleAdwords:"GoogleAdwordsFeed",
		googlePlay:"GooglePlayFeed",
		harvest:"HarvestFeed",
		id:"ID",
		ironSource:"IronSourceFeed",
		ironSourcePromotion:"IronSourcePromotionFeed",
		libring:"LibringFeed",
		s3Destinations:"S3Destination",
		snapchat:"SnapchatFeed",
		tiktokAdsAudience:"TiktokAdsAudienceFeed",
		unityAds:"UnityAdsFeed",
		unityAdvertiser:"UnityAdvertiserFeed"
	},
	IronSourceAccess:{
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		refreshToken:"String",
		secretKey:"String",
		updatedAt:"DateTime"
	},
	IronSourceFeed:{
		adRevenue:"Boolean",
		appKey:"String",
		id:"ID",
		ironSourceAccess:"IronSourceAccess",
		params:"IntegrationFeedParams",
		reportBreakdowns:"String",
		reportMetrics:"String",
		reports:"Boolean"
	},
	IronSourcePromotionFeed:{
		adUnit:"String",
		breakdowns:"String",
		bundleIds:"String",
		campaignIds:"Int",
		countries:"String",
		creativeIds:"Int",
		deviceType:"IronSourcePromotionDeviceType",
		id:"ID",
		ironSourceAccess:"IronSourceAccess",
		metrics:"String",
		os:"IronSourcePromotionOsType",
		params:"IntegrationFeedParams"
	},
	LibringAccess:{
		apiToken:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	LibringFeed:{
		appName:"String",
		dataType:"String",
		id:"ID",
		libringAccess:"LibringAccess",
		params:"IntegrationFeedParams"
	},
	Mutation:{
		abortEtl:"Action",
		archiveView:"View",
		copyView:"View",
		createAppleAccess:"AppleAccess",
		createAppleSearchAdsAccess:"AppleSearchAdsAccess",
		createApplovinAccess:"ApplovinAccess",
		createAppsFlyerAccess:"AppsFlyerAccess",
		createAwsProject:"AwsProject",
		createBigQueryProject:"BigQueryProject",
		createChartboostAccess:"ChartboostAccess",
		createFacebookAdsAccess:"FacebookAdsAccess",
		createFairbidAccess:"FairbidAccess",
		createGoogleAdsAccess:"GoogleAdsAccess",
		createGooglePlayAccess:"GooglePlayAccess",
		createHarvestAccess:"HarvestAccess",
		createIntegrationFeedConfig:"IntegrationFeedConfig",
		createIronSourceAccess:"IronSourceAccess",
		createLibringAccess:"LibringAccess",
		createOrganization:"Organization",
		createSnapchatAccess:"SnapchatAccess",
		createTiktokAccess:"TiktokAccess",
		createUnityAccess:"UnityAccess",
		createView:"View",
		deleteAppleAccess:"AppleAccess",
		deleteAppleSearchAdsAccess:"AppleSearchAdsAccess",
		deleteApplovinAccess:"ApplovinAccess",
		deleteAppsFlyerAccess:"AppsFlyerAccess",
		deleteAwsProject:"AwsProject",
		deleteBigQueryProject:"BigQueryProject",
		deleteChartboostAccess:"ChartboostAccess",
		deleteFacebookAdsAccess:"FacebookAdsAccess",
		deleteFairbidAccess:"FairbidAccess",
		deleteGoogleAdsAccess:"GoogleAdsAccess",
		deleteGooglePlayAccess:"GooglePlayAccess",
		deleteHarvestAccess:"HarvestAccess",
		deleteIntegrationFeedConfig:"IntegrationFeedConfig",
		deleteIronSourceAccess:"IronSourceAccess",
		deleteLibringAccess:"LibringAccess",
		deleteOrganization:"Organization",
		deleteSnapchatAccess:"SnapchatAccess",
		deleteTiktokAccess:"TiktokAccess",
		deleteUnityAccess:"UnityAccess",
		deleteView:"View",
		inviteUser:"OrganizationMembership",
		publishView:"View",
		restoreView:"View",
		runEtl:"Result",
		setUserRoles:"User",
		updateAppleAccess:"AppleAccess",
		updateAppleSearchAdsAccess:"AppleSearchAdsAccess",
		updateApplovinAccess:"ApplovinAccess",
		updateAppsFlyerAccess:"AppsFlyerAccess",
		updateAwsProject:"AwsProject",
		updateBigQueryProject:"BigQueryProject",
		updateChartboostAccess:"ChartboostAccess",
		updateFacebookAdsAccess:"FacebookAdsAccess",
		updateFairbidAccess:"FairbidAccess",
		updateGoogleAdsAccess:"GoogleAdsAccess",
		updateGooglePlayAccess:"GooglePlayAccess",
		updateHarvestAccess:"HarvestAccess",
		updateIntegrationFeedConfig:"IntegrationFeedConfig",
		updateIronSourceAccess:"IronSourceAccess",
		updateLibringAccess:"LibringAccess",
		updateSnapchatAccess:"SnapchatAccess",
		updateTiktokAccess:"TiktokAccess",
		updateUnityAccess:"UnityAccess",
		updateView:"View"
	},
	Organization:{
		awsProjects:"AwsProject",
		bqProjects:"BigQueryProject",
		createdAt:"DateTime",
		id:"ID",
		name:"String",
		organizationAttributes:"OrganizationAttribute",
		organizationMemberships:"OrganizationMembership",
		updatedAt:"DateTime",
		views:"View"
	},
	OrganizationAttribute:{
		createdAt:"DateTime",
		id:"ID",
		organization:"Organization",
		role:"OrganizationRole",
		updatedAt:"DateTime"
	},
	OrganizationMembership:{
		createdAt:"DateTime",
		id:"ID",
		organization:"Organization",
		role:"UserRole",
		updatedAt:"DateTime",
		user:"User"
	},
	PageInfo:{
		endCursor:"String",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		startCursor:"String"
	},
	Query:{
		appleAccess:"AppleAccess",
		appleAccesses:"AppleAccess",
		appleSearchAdsAccess:"AppleSearchAdsAccess",
		appleSearchAdsAccesses:"AppleSearchAdsAccess",
		applovinAccess:"ApplovinAccess",
		applovinAccesses:"ApplovinAccess",
		appsFlyerAccess:"AppsFlyerAccess",
		appsFlyerAccesses:"AppsFlyerAccess",
		awsProject:"AwsProject",
		awsProjects:"AwsProject",
		bigQueryCheck:"BigQueryCheck",
		bigQueryChecks:"BigQueryCheck",
		bigQueryChecksConnection:"BigQueryCheckConnection",
		bigQueryDestination:"BigQueryDestination",
		bigQueryDestinations:"BigQueryDestination",
		bigQueryProject:"BigQueryProject",
		bigQueryProjects:"BigQueryProject",
		campaignConsistencyCheck:"CampaignConsistencyCheck",
		campaignConsistencyChecks:"CampaignConsistencyCheck",
		campaignConsistencyChecksConnection:"CampaignConsistencyCheckConnection",
		chartboostAccess:"ChartboostAccess",
		chartboostAccesses:"ChartboostAccess",
		dailyConsistencyCheck:"DailyConsistencyCheck",
		dailyConsistencyChecks:"DailyConsistencyCheck",
		dailyConsistencyChecksConnection:"DailyConsistencyCheckConnection",
		facebookAdsAccess:"FacebookAdsAccess",
		facebookAdsAccesses:"FacebookAdsAccess",
		fairbidAccess:"FairbidAccess",
		fairbidAccesses:"FairbidAccess",
		googleAdsAccess:"GoogleAdsAccess",
		googleAdsAccesses:"GoogleAdsAccess",
		googlePlayAccess:"GooglePlayAccess",
		googlePlayAccesses:"GooglePlayAccess",
		harvestAccess:"HarvestAccess",
		harvestAccesses:"HarvestAccess",
		integrationFeedConfig:"IntegrationFeedConfig",
		integrationFeedConfigs:"IntegrationFeedConfig",
		integrationFeedConfigsConnection:"IntegrationFeedConfigConnection",
		integrationFeedInstance:"IntegrationFeedInstance",
		integrationFeedInstances:"IntegrationFeedInstance",
		integrationFeedInstancesConnection:"IntegrationFeedInstanceConnection",
		ironSourceAccess:"IronSourceAccess",
		ironSourceAccesses:"IronSourceAccess",
		libringAccess:"LibringAccess",
		libringAccesses:"LibringAccess",
		me:"User",
		organization:"Organization",
		organizations:"Organization",
		s3Destination:"S3Destination",
		s3Destinations:"S3Destination",
		snapchatAccess:"SnapchatAccess",
		snapchatAccesses:"SnapchatAccess",
		tiktokAccess:"TiktokAccess",
		tiktokAccesses:"TiktokAccess",
		unityAccess:"UnityAccess",
		unityAccesses:"UnityAccess",
		users:"User",
		view:"View",
		views:"View"
	},
	Result:{
		actions:"Action"
	},
	S3Destination:{
		awsProject:"AwsProject",
		bucketName:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		enabled:"Boolean",
		id:"ID",
		objectSuffix:"String",
		organizationAttributes:"OrganizationAttribute",
		pathPrefix:"String",
		region:"String",
		updatedAt:"DateTime"
	},
	ScraperGame:{
		createdAt:"DateTime",
		gameId:"String",
		id:"ID",
		name:"String",
		updatedAt:"DateTime"
	},
	SnapchatAccess:{
		adAccountID:"String",
		clientID:"String",
		clientSecret:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		refreshToken:"String",
		updatedAt:"DateTime"
	},
	SnapchatFeed:{
		id:"ID",
		params:"IntegrationFeedParams",
		reportDimension:"String",
		snapchatAccess:"SnapchatAccess",
		timezoneOffset:"Int"
	},
	TiktokAccess:{
		accessToken:"String",
		advertiserId:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		updatedAt:"DateTime"
	},
	TiktokAdsAudienceFeed:{
		dimensions:"String",
		filterInactive:"Boolean",
		id:"ID",
		params:"IntegrationFeedParams",
		tiktokAccess:"TiktokAccess"
	},
	UnityAccess:{
		apiKey:"String",
		createdAt:"DateTime",
		descriptiveName:"String",
		id:"ID",
		organizationAttributes:"OrganizationAttribute",
		organizationId:"String",
		updatedAt:"DateTime"
	},
	UnityAdsFeed:{
		id:"ID",
		params:"IntegrationFeedParams",
		unityAccess:"UnityAccess"
	},
	UnityAdvertiserFeed:{
		id:"ID",
		params:"IntegrationFeedParams",
		targetIds:"String",
		unityAccess:"UnityAccess"
	},
	User:{
		authProvider:"String",
		email:"String",
		id:"ID",
		isSuperAdmin:"Boolean",
		name:"String",
		organizationMemberships:"OrganizationMembership",
		surname:"String",
		views:"View"
	},
	View:{
		archivedAt:"DateTime",
		author:"User",
		createdAt:"DateTime",
		id:"ID",
		name:"String",
		organization:"Organization",
		updatedAt:"DateTime",
		urlQueryParams:"String"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});
export const Zeus = {
  query: (o:ValueTypes["Query"]) => queryConstruct('query', 'Query')(o),
mutation: (o:ValueTypes["Mutation"]) => queryConstruct('mutation', 'Mutation')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Query"],
  Query
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Mutation"],
  Mutation
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>()
};
  