import { createGuid } from "@/functions/createGuid";
import { parse, stringify } from "qs";

type Subscription = {
	id: string;
	path?: (path: string) => void;
	query?: (query: Record<string, any>) => void;
};

class Url {
	subscriptions: Subscription[] = [];
	constructor() {
		window.addEventListener("popstate", () => {
			this.subscriptions.forEach((subscription) => {
				if (subscription.path) {
					subscription.path(this.getPath());
				}
				if (subscription.query) {
					subscription.query(this.getQuery());
				}
			});
		});
	}

	subscribe = ({ path, query }: Omit<Subscription, "id">) => {
		const id = createGuid();
		this.subscriptions.push({ id, path, query });
		return () => {
			this.subscriptions = this.subscriptions.filter(
				(item) => item.id !== id
			);
		};
	};

	getPath = () => {
		return window.location.pathname.slice(1);
	};
	setPath = (path: string) => {
		const previousPath = this.getPath();
		if (previousPath === path) return;
		const query =
			Object.keys(this.getQuery()).length !== 0
				? `?${stringify(this.getQuery())}`
				: "";
		window.history.pushState(null, path, `/${path}${query}`);
	};

	getQuery = (): Record<string, any> => {
		return parseQuery(window.location.search.slice(1));
	};

	setQuery = (query: Record<string, any>): void => {
		const path = this.getPath();
		window.history.pushState(null, path, `/${path}?${stringify(query)}`);
	};
}

export const create = () => new Url();
export const parseQuery = <U>(queryString: string): U => {
	// @ts-ignore
	return parse(queryString, {
		ignoreQueryPrefix: true,
		// @ts-ignore
		arrayFormat: "indices",
		encodeValuesOnly: true,
		arrayLimit: 150,
		depth: 6,
		decoder(value: string) {
			if (
				/^-?(0|0\.\d+|[1-9]|[1-9](\.\d+)|[1-9]+\d+(\.\d+)?)$/.test(
					value
				)
			) {
				return parseFloat(value);
			}

			const keywords = {
				undefined,
				true: true,
				false: false,
				null: null,
			};
			if (value in keywords) {
				// @ts-ignore
				return keywords[value];
			}

			return decodeURIComponent(value);
		},
	});
};
