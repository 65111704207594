import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useFairbidAccessesQuery = () =>
	useTypedQuery(
		{
			fairbidAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				FairbidAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useFairbidAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			fairbidAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				FairbidAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useFairbidAccessQuery = () =>
	useTypedLazyQuery(
		{
			fairbidAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				FairbidAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateFairbidAccess = () => {
	return useTypedMutation({
		createFairbidAccess: [
			{
				data: $`data`,
			},
			FairbidAccessSelector,
		],
	});
};
export const useUpdateFairbidAccess = () => {
	return useTypedMutation({
		updateFairbidAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			FairbidAccessSelector,
		],
	});
};
export const useDeleteFairbidAccess = () => {
	return useTypedMutation({
		deleteFairbidAccess: [
			{
				where: $`where`,
			},
			FairbidAccessSelector,
		],
	});
};

export const FairbidAccessSelector = ZeusSelect<ValueTypes["FairbidAccess"]>()({
	id: true,
	descriptiveName: true,
	clientId: true,
	clientSecret: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
