import React from "react";
import "react-toastify/dist/ReactToastify.css";

import { toast, ToastContainer } from "react-toastify";
import { Icon } from "@/views";

export const NotificationContainer = ToastContainer;

export const notifyError = (children: JSX.Element | string) => {
	toast(
		<div className="px-2 center-v gap-4">
			<Icon name="exclamation" size="large" weight="solid" />
			<div>{children}</div>
		</div>,
		{
			className: "bg-danger text-white rounded-lg",
			type: "error",
		}
	);
};

export const notifySuccess = (children: JSX.Element | string) => {
	toast(
		<div className="px-2 center-v gap-4">
			<Icon name="check" size="large" weight="solid" />
			<div>{children}</div>
		</div>,
		{
			className: "bg-success text-white rounded-lg",
			type: "success",
		}
	);
};

export const notifyWarning = (children: JSX.Element | string) => {
	toast(
		<div className="px-2 center-v gap-4">
			<Icon name="exclamation" size="large" weight="solid" />
			<div className="overflow">{children}</div>
		</div>,
		{
			className: "bg-warn text-white rounded-lg",
			type: "warning",
		}
	);
};
