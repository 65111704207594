import { Detail } from "@/components/Page/Etl/Detail";
import {
	AccessType,
	getGraphqlTypeByAccess,
	options,
	useCreate
} from "@/modules/Etl/Accesses";
import { useForm } from "@/modules/Form/logic";
import { Form } from "@/ui/components/Form/components/Form";
import { UIKit } from "@/ui/components/Form/uiKit";
import { BooleanField, BooleanMatch } from "@/ui/components/Form/uiKit/Boolean";
import {
	FacebookBreakdownsField,
	FacebookBreakdownsMatch
} from "@/ui/components/Form/uiKit/Breakdowns";
import { IntField, IntMatch } from "@/ui/components/Form/uiKit/Int";
import { ObjectField, ObjectMatch } from "@/ui/components/Form/uiKit/Object";
import {
	OrganizationAttributesField,
	OrganizationAttributesMatch
} from "@/ui/components/Form/uiKit/OrganizationAttributes";
import {
	OrganizationRoleField,
	OrganizationRoleMatch
} from "@/ui/components/Form/uiKit/OrganizationRole";
import {
	ScraperGameField,
	ScraperGameMatch
} from "@/ui/components/Form/uiKit/ScraperGames";
import { StringField, StringMatch } from "@/ui/components/Form/uiKit/String";
import {
	UpdateManyInputField,
	UpdateManyInputMatch
} from "@/ui/components/Form/uiKit/UpdateManyInput";
import { ButtonWithLoading } from "@/views/ButtonWithLoading";
import { default as React, useMemo } from "react";

type Props = {
	accessType: AccessType;
	close: () => void;
};

export const AccessDetailNew = ({ accessType, close }: Props) => {
	const [create, createState] = useCreate(accessType);
	const form = useForm();
	const ui = useMemo(() => {
		return uiKit();
	}, []);

	return (
		<Detail
			title={
				options.find((option) => option.value === accessType)?.label ||
				""
			}
			close={close}
			loading={false}
			Content={
				<Form
					className="p-4 overflow-overlay h-full bg-white"
					ui={ui}
					input={getGraphqlTypeByAccess(accessType).concat(
						"CreateInput"
					)}
					form={form}
					data={form.getValues()}
					Buttons={() => {
						return (
							<div className="flex mt-4">
								<div className="mr-2">
									<ButtonWithLoading
										loading={createState.loading}
										onClick={() => {
											const {
												id,
												...data
											} = form.getValues();

											create({
												variables: {
													data,
													where: {
														id,
													},
												},
											});
										}}
									>
										Create
									</ButtonWithLoading>
								</div>
							</div>
						);
					}}
				/>
			}
		/>
	);
};

const uiKit = (): UIKit<any>[] => [
	[OrganizationRoleMatch(), OrganizationRoleField],
	[OrganizationAttributesMatch(), OrganizationAttributesField],
	[UpdateManyInputMatch(), UpdateManyInputField],
	[FacebookBreakdownsMatch(), FacebookBreakdownsField],
	[ScraperGameMatch(), ScraperGameField],
	// // ...basicKit
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[IntMatch(), IntField],
	[BooleanMatch(), BooleanField],
];
