import { useBigQueryChecksData } from "@/modules/Checks/BigQuery";
import { BigQueryCheck } from "@/services/graphApi/graphql-zeus";
import { Icon } from "@/views";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";

dayjs.extend(relativeTime);

export type BQChecksTableProps = Pick<
	ReturnType<typeof useBigQueryChecksData>,
	"state"
>;

export const Table = ({ state }: BQChecksTableProps) => {
	if (state.loading) {
		return <div className="center">Loading...</div>;
	}

	if (state.error) {
		return <div className="center">{state.error.toString()}</div>;
	}

	return (
		<div className="overflow-auto">
			<table className="table-auto">
				<thead>
					<tr>
						<th className="px-4 py-2">Table reference</th>
						<th className="px-4 py-2">Last updated</th>
						<th className="px-4 py-2">Exists</th>
						<th className="px-4 py-2">Last modified</th>
						<th className="px-4 py-2">Max date</th>
					</tr>
				</thead>
				<tbody>
					{state.data?.bigQueryChecksConnection.edges.map(
						(check, index) => (
							<BqCheckRow
								key={check.node.id}
								check={check.node as BigQueryCheck}
								index={index}
							/>
						)
					)}
				</tbody>
			</table>
		</div>
	);
};

const BqCheckRow = (props: { check: BigQueryCheck; index: number }) => {
	const { check, index } = props;
	return (
		<tr className={index % 2 === 0 ? undefined : "bg-gray-100"}>
			<td className="border px-4 py-2">{check.tableReference}</td>
			<td className="border px-4 py-2">
				<DateSpan value={check.updatedAt} />
			</td>
			<td className="border px-4 py-2">
				<BooleanIcon value={check.tableExists} />
			</td>
			<td className="border px-4 py-2">
				<DateSpan value={check.lastModified} />
			</td>
			<td className="border px-4 py-2">
				<DateSpan value={check.maxDate} />
			</td>
		</tr>
	);
};

const BooleanIcon = (props: { value: boolean }) => {
	const { value } = props;
	return (
		<div className="inline-block w-full text-center">
			{value ? (
				<Icon
					className="text-green-400"
					name="check"
					weight="solid"
					size="mili"
				/>
			) : (
				<Icon name="times" weight="solid" size="mili" />
			)}
		</div>
	);
};

const DateSpan = (props: { value: Date | null | undefined }) => {
	const { value } = props;
	return value === undefined || value === null ? (
		<span className="text-gray-500">N/A</span>
	) : (
		<span title={dayjs(value).toString()}>{dayjs(value).fromNow()}</span>
	);
};
