import { createClass } from "@/reexports";
import * as React from "react";

export interface IconProps {
	weight?: "solid" | "regular" | "light";
	name: string;
	size?:
		| "pico"
		| "nano"
		| "micro"
		| "mili"
		| "normal"
		| "big"
		| "large"
		| "huge"
		| "gigantic";
	onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => any;
	className?: string;
}

export const Icon = ({
	className,
	size = "normal",
	weight = "regular",
	name,
	onClick,
}: IconProps) => {
	const solidity = {
		solid: "fas",
		regular: "far",
		light: "fal",
	};

	const sizeClass =
		size === "pico"
			? "text-pico"
			: size === "nano"
			? "text-nano"
			: size === "micro"
			? "text-micro"
			: size === "mili"
			? "text-mili"
			: size === "normal"
			? "text-normal"
			: size === "big"
			? "text-big"
			: size === "large"
			? "text-large"
			: size === 'huge'
			? "text-6xl"
			: 'text-7xl'

	const classes = createClass(
		className,
		sizeClass,
		solidity[weight],
		`fa-${name}`,
		{
			"cursor-pointer": onClick,
		}
	);
	return <i className={classes} onClick={onClick} />;
};
