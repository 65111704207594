import { OrganizationRole } from "@/services/graphApi/graphql-zeus";
import React, { useEffect } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const OrganizationRoleMatch = () =>
	createUIKitMatcher(
		({ field }) =>
			field.type === "enum" && field.enumType === "OrganizationRole"
	);

export const OrganizationRoleField = ({
	field,
	path,
	form,
	data,
}: UIFieldProps<OrganizationRole>) => {
	const fieldPath = path.concat(field.name).join(".");

	useEffect(() => {
		if (data) {
			return;
		}
		form.setValue(fieldPath, OrganizationRole.OWNER);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, fieldPath, form.setValue]);

	return (
		<div>
			<select
				name={path.concat(field.name).join(".")}
				onChange={(e) => {
					e.preventDefault();
					form.setValue(fieldPath, e.target.value);
				}}
				value={data || OrganizationRole.OWNER}
			>
				{Object.keys(OrganizationRole).map((role) => (
					<option key={role} value={role}>
						{role}
					</option>
				))}
			</select>
		</div>
	);
};
