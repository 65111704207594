import { useEffect } from "react";
import { UIFieldProps } from "../../uiKit";

export const useInt = <R>({
	data,
	field,
	path,
	form,
}: Omit<UIFieldProps<R>, "uiKit" | "UIField">) => {
	const fieldPath = path.concat(field.name).join(".");

	useEffect(() => {
		if (data) {
			return;
		}
		if (field.required) {
			form.setValue(fieldPath, field.array ? [] : "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const setValue = (value: string) => {
		if (value === "") {
			form.setValue(fieldPath, null);
			return;
		}
		form.setValue(fieldPath, Number(value));
	};

	const setArrayValue = (index: number, value: string) => {
		if (value === "") {
			form.setValue(`${fieldPath}[${index}]`, null);
			return;
		}
		form.setValue(`${fieldPath}[${index}]`, Number(value));
	};

	const pushValue = (value?: string) => {
		if (value === "") {
			form.pushFieldValue(fieldPath, null);
			return;
		}
		form.pushFieldValue(fieldPath, Number(value));
	};

	const removeArrayValue = (index: number) =>
		form.removeFieldValue(fieldPath, index);

	return {
		field,
		path,
		form,
		data,
		setValue,
		setArrayValue,
		pushValue,
		removeArrayValue,
	};
};
