import React from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const FeedMatch = () =>
	createUIKitMatcher(
		({ field: { name } }) =>
			name === "apple" ||
			name === "appleSearchAds" ||
			name === "appsFlyerCampaign" ||
			name === "appsFlyerDatalocker" ||
			name === "appsFlyerRawInstalls" ||
			name === "appsFlyerRawEvents" ||
			name === "appsFlyerScraper" ||
			name === "facebookAds" ||
			name === "fairbidUserReport" ||
			name === "fixer" ||
			name === "googleAdwords" ||
			name === "googlePlay" ||
			name === "harvest" ||
			name === "ironSource" ||
			name === "ironSourcePromotion" ||
			name === "libring" ||
			name === "snapchat" ||
			name === "tiktokAdsAudience" ||
			name === "unityAds" ||
			name === "unityAdvertiser"
	);

export const FeedField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<any>) => {
	return (
		<>
			{field.fields?.map((fieldItem) => {
				return (
					<UIField
						key={fieldItem.name}
						field={fieldItem}
						form={form}
						data={data && data[fieldItem.name]}
						path={path.concat(field.name)}
						uiKit={uiKit}
						UIField={UIField}
					/>
				);
			})}
		</>
	);
};
