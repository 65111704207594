import React from "react";

type ViewProps = {
	signIn: () => void;
};

export const View = ({ signIn }: ViewProps) => {
	return (
		<div className="w-screen h-screen bg-gray-100 center">
			<div className="center-v flex-col rounded w-80 h-96 bg-sidebar-dd">
				<div className="text-xl center font-bold mt-6 text-white">
					Welcome
				</div>
				<div className="h-full center">
					<button
						className="outline-none bg-c-accent1 hover:bg-c-accent1-darkened rounded py-2 px-4"
						onClick={signIn}
					>
						<span className="text-white">Google sign in</span>
					</button>
				</div>
			</div>
		</div>
	);
};
