import React, { ReactChild } from "react";
import { Icon } from "./Icon";

type Props = {
	onClick: () => void;
	loading: boolean;
	children: ReactChild;
};

export const ButtonWithLoading = ({ loading, onClick, children }: Props) => {
	return (
		<button className="btn-primary" onClick={onClick}>
			{loading ? <Icon className="spin" name="spinner" /> : children}
		</button>
	);
};
