import {
	CampaignConsistencyCheckQueryArgument,
	useCampaignConsistencyChecksData,
} from "@/modules/Checks/Consistency/Campaign";
import {
	DailyConsistencyCheckQueryArgument,
	useDailyConsistencyChecksData,
} from "@/modules/Checks/Consistency/Daily";
import { useOpenable } from "@/modules/useOpenable";
import { Icon } from "@/views";
import React, { useState } from "react";
import { PageControls, PageSizeDropdown } from "../../../modules/Pagination";
import { FilterInput, FilterPanel, FilterState } from "./Filters/FilterForm";
import { CampaignTable } from "./Table/CampaignTable";
import { DailyTable } from "./Table/DailyTable";

export type ConsistencyCheckType = "DAILY" | "CAMPAIGN";

export const View = (props: { tableType: ConsistencyCheckType }) => {
	return (
		<div className="p-2 overflow-overlay">
			{props.tableType === "DAILY" ? <DailyView /> : <CampaignView />}
		</div>
	);
};

const dailyFilterInputs: FilterInput<
	DailyConsistencyCheckQueryArgument,
	never
>[] = [
	{ name: "Game ID", queryArgument: "gameId_contains", type: "string" },
	{
		name: "Platform",
		queryArgument: "platform",
		type: "dropdown",
		values: ["ANDROID", "IOS"],
	},
	{ name: "Start date", queryArgument: "date_gte", type: "date", end: false },
	{ name: "End date", queryArgument: "date_lte", type: "date", end: true },
];

const campaignFilterInputs: FilterInput<
	CampaignConsistencyCheckQueryArgument,
	never
>[] = [
	{ name: "Game ID", queryArgument: "gameId_contains", type: "string" },
	{
		name: "Campaign ID",
		queryArgument: "campaignId_contains",
		type: "string",
	},
	{
		name: "Platform",
		queryArgument: "platform",
		type: "dropdown",
		values: ["ANDROID", "IOS"],
	},
	{ name: "Start date", queryArgument: "date_gte", type: "date", end: false },
	{ name: "End date", queryArgument: "date_lte", type: "date", end: true },
];

const DailyView = () => {
	const {
		state,
		pagination,
		filters: savedFilters,
		setFilters: saveFilters,
	} = useDailyConsistencyChecksData();
	const {
		hasNextPage,
		hasPreviousPage,
		nextPage,
		pageSize,
		previousPage,
		setPageSize,
	} = pagination;

	const [filters, setFilters] = useState<
		FilterState<DailyConsistencyCheckQueryArgument, never>
	>(savedFilters);
	const { opened: filtersOpened, toggle: toggleFilters } = useOpenable(false);

	return (
		<div className="flex flex-col gap-2">
			<FilterPanel
				filterInputs={dailyFilterInputs}
				filters={filters}
				setFilters={setFilters}
				saveFilters={saveFilters}
				opened={filtersOpened}
				toggle={toggleFilters}
			/>
			<div className="center-v gap-2">
				<PageSizeDropdown
					pageSize={pageSize}
					setPageSize={setPageSize}
				/>
				<PageControls
					hasNextPage={hasNextPage}
					hasPreviousPage={hasPreviousPage}
					nextPage={nextPage}
					previousPage={previousPage}
				/>
			</div>
			<div className="center-v gap-2">
				{state.loading ? (
					<div className="btn-primary center-v gap-2">
						Refetch
						<Icon className="spin" name="spinner" weight="solid" />
					</div>
				) : (
					<div
						className="btn-primary center-v gap-2"
						onClick={() => state.refetch && state.refetch()}
					>
						Refetch
						<Icon name="redo-alt" />
					</div>
				)}
			</div>
			<DailyTable state={state} />

			<PageControls
				hasNextPage={hasNextPage}
				hasPreviousPage={hasPreviousPage}
				nextPage={nextPage}
				previousPage={previousPage}
			/>
		</div>
	);
};

const CampaignView = () => {
	const {
		state,
		pagination,
		filters: savedFilters,
		setFilters: saveFilters,
	} = useCampaignConsistencyChecksData();
	const {
		hasNextPage,
		hasPreviousPage,
		nextPage,
		pageSize,
		previousPage,
		setPageSize,
	} = pagination;

	const [filters, setFilters] = useState<
		FilterState<CampaignConsistencyCheckQueryArgument, never>
	>(savedFilters);
	const { opened: filtersOpened, toggle: toggleFilters } = useOpenable(false);

	return (
		<div className="flex flex-col gap-2">
			<FilterPanel
				filterInputs={campaignFilterInputs}
				filters={filters}
				setFilters={setFilters}
				saveFilters={saveFilters}
				opened={filtersOpened}
				toggle={toggleFilters}
			/>
			<div className="center-v gap-2">
				<PageSizeDropdown
					pageSize={pageSize}
					setPageSize={setPageSize}
				/>
				<PageControls
					hasNextPage={hasNextPage}
					hasPreviousPage={hasPreviousPage}
					nextPage={nextPage}
					previousPage={previousPage}
				/>
			</div>

			<div className="center-v gap-2">
				{state.loading ? (
					<div className="btn-primary center-v gap-2">
						Refetch
						<Icon className="spin" name="spinner" weight="solid" />
					</div>
				) : (
					<div
						className="btn-primary center-v gap-2"
						onClick={() => state.refetch && state.refetch()}
					>
						Refetch
						<Icon name="redo-alt" />
					</div>
				)}
			</div>
			<CampaignTable state={state} />

			<PageControls
				hasNextPage={hasNextPage}
				hasPreviousPage={hasPreviousPage}
				nextPage={nextPage}
				previousPage={previousPage}
			/>
		</div>
	);
};
