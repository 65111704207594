import * as Router from "@/modules/Router";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";
import { Icon } from "@/views";
import * as Dropdown from "@/views/Dropdown";
import { NavigationLink } from "@/views/NavigationLink";
import * as React from "react";

export const View = () => {
	const { route, navigate } = Router.useRouter();
	const { opened, close, toggle } = useOpenable(false);

	return (
		<div className="center w-full">
			{/* <NavigationLink
				active={route.route === "home"}
				onClick={() => navigate("home")}
			>
				<Icon className="mr-2" name="chart-pie" />
				<span>Explore</span>
			</NavigationLink> */}
			<NavigationLink
				active={route.route.startsWith("etl") || route.route === "home"}
				onClick={() => navigate({ route: "etl-configs" })}
			>
				<Icon className="mr-2" name="chart-network" />
				<span>Etl</span>
			</NavigationLink>
			<NavigationLink
				active={route.route === "workspaces" || route.route === 'workspaces-detail'}
				onClick={() => navigate({ route: "workspaces" })}
			>
				<Icon className="mr-2" name="briefcase" />
				<span>Workspaces</span>
			</NavigationLink>
			<Dropdown.View
				label={
					<div
						className={createClass("center-v cursor-pointer", {
							"text-c-accent1":
								route.route === "checks-bq" ||
								route.route === "checks-consistency-daily" ||
								route.route === "checks-consistency-campaign",
						})}
						onClick={toggle}
					>
						<Icon className="mr-2" name="tasks" />
						<div className="mr-1">Checks</div>
						<Icon name="chevron-down" size="nano" />
					</div>
				}
				onClickOutside={close}
				opened={opened}
			>
				<Dropdown.Menu>
					<Dropdown.Item
						onClick={() => {
							navigate({ route: "checks-bq" });
							close();
						}}
					>
						<NavigationLink active={route.route === "checks-bq"}>
							<span>BQ Checks</span>
						</NavigationLink>
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => {
							navigate({ route: "checks-consistency-daily" });
							close();
						}}
					>
						<NavigationLink
							active={route.route === "checks-consistency-daily"}
						>
							<span>Daily Consistency Checks</span>
						</NavigationLink>
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => {
							navigate({ route: "checks-consistency-campaign" });
							close();
						}}
					>
						<NavigationLink
							active={
								route.route === "checks-consistency-campaign"
							}
						>
							<span>Campaign Consistency Checks</span>
						</NavigationLink>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown.View>
		</div>
	);
};
