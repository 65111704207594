import { Feed } from "@/services/graphApi/graphql-zeus";
import { urlService } from "@/services/url";
import { equals } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createStore } from "reusable";
import { AccessType } from "./Etl/Accesses";

const { getPath, setPath, subscribe } = urlService;

export const useRouter = createStore(() => {
	const [route, _setRoute] = useState<Model>(pathToRoute(getPath()));

	useEffect(() => {
		if (getPath() !== stringFromRoute(route)) {
			setPath(stringFromRoute(route));
		}
	}, [route]);

	useEffect(() => {
		const unsubscribe = subscribe({
			path: (path) => {
				_setRoute(pathToRoute(path));
			},
		});
		return () => {
			unsubscribe();
		};
	}, []);

	const navigate = useCallback(
		(to: RouteType) => {
			if (to.route === "home") {
				return _setRoute({ ...to, path: "" });
			}
			if (to.route === "login") {
				return _setRoute({ ...to, path: "login" });
			}
			if (to.route === "settings") {
				return _setRoute({ ...to, path: "settings" });
			}
			if (to.route === "etl-accesses") {
				return _setRoute({ ...to, path: "etl/accesses" });
			}
			if (to.route === "etl-accesses-detail") {
				return _setRoute({
					...to,
					path: "etl/accesses/:accessType/:id",
				});
			}
			if (to.route === "etl-configs") {
				return _setRoute({ ...to, path: "etl/configs" });
			}
			if (to.route === "etl-configs-detail") {
				return _setRoute({ ...to, path: "etl/configs/:feedType/:id" });
			}
			if (to.route === "etl-instances") {
				return _setRoute({ ...to, path: "etl/instances" });
			}
			if (to.route === "etl-instances-detail") {
				return _setRoute({
					...to,
					path: "etl/instances/:feedType/:id",
				});
			}
			if (to.route === "workspaces") {
				return _setRoute({ ...to, path: "workspaces" });
			}
			if (to.route === "workspaces-detail") {
				return _setRoute({ ...to, path: "workspaces/:id" });
			}
			if (to.route === "checks-bq") {
				return _setRoute({ ...to, path: "checks/bq" });
			}
			if (to.route === "checks-consistency-daily") {
				return _setRoute({ ...to, path: "checks/consistency-daily" });
			}
			if (to.route === "checks-consistency-campaign") {
				return _setRoute({
					...to,
					path: "checks/consistency-campaign",
				});
			}
			if (to.route === "not-found") {
				return _setRoute({ ...to, path: "404" });
			}
		},
		[_setRoute]
	);

	return { route, navigate };
});

type Props = {
	children: (params: any) => JSX.Element;
	path: Model["path"];
};

export const Route = ({ children, path }: Props) => {
	const router = useRouter();

	const params = useMemo(() => {
		// @ts-ignore
		return router.route.params || {};
	}, [router.route]);

	const opened = useMemo(() => {
		return router.route.path === path;
	}, [path, router.route.path]);

	if (!opened) return null;

	return children(params);
};

type RouteHome = { route: "home" };
type RouteLogin = { route: "login" };
type RouteSettings = { route: "settings" };
type RouteEtlAccesses = { route: "etl-accesses" };
type RouteEtlAccessesDetail = {
	route: "etl-accesses-detail";
	params: { id: string; accessType: AccessType };
};
type RouteEtlConfigs = { route: "etl-configs" };
type RouteEtlConfigsDetail = {
	route: "etl-configs-detail";
	params: { id: string; feedType: Feed };
};
type RouteEtlInstances = { route: "etl-instances" };
type RouteEtlInstancesDetail = {
	route: "etl-instances-detail";
	params: { id: string; feedType: Feed };
};
type RouteWorkspaces = { route: "workspaces" };
type RouteWorkspacesDetail = {
	route: "workspaces-detail";
	params: { id: string };
};
type RouteChecksBq = { route: "checks-bq" };
type RouteChecksConsistencyDaily = { route: "checks-consistency-daily" };
type RouteChecksConsistencyCampaign = { route: "checks-consistency-campaign" };
type RouteNotFound = { route: "not-found" };

type RouteType =
	| RouteHome
	| RouteLogin
	| RouteSettings
	| RouteEtlAccesses
	| RouteEtlAccessesDetail
	| RouteEtlConfigs
	| RouteEtlConfigsDetail
	| RouteEtlInstances
	| RouteEtlInstancesDetail
	| RouteWorkspaces
	| RouteWorkspacesDetail
	| RouteChecksBq
	| RouteChecksConsistencyDaily
	| RouteChecksConsistencyCampaign
	| RouteNotFound;

export const routeEquals = (val1: RouteType["route"]) => (
	val2: RouteType["route"]
) => equals(val1)(val2);

export type Model =
	| (RouteHome & { path: "" })
	| (RouteLogin & { path: "login" })
	| (RouteSettings & { path: "settings" })
	| (RouteEtlAccesses & { path: "etl/accesses" })
	| (RouteEtlAccessesDetail & { path: "etl/accesses/:accessType/:id" })
	| (RouteEtlConfigs & { path: "etl/configs" })
	| (RouteEtlConfigsDetail & { path: "etl/configs/:feedType/:id" })
	| (RouteEtlInstances & { path: "etl/instances" })
	| (RouteEtlInstancesDetail & { path: "etl/instances/:feedType/:id" })
	| (RouteWorkspaces & { path: "workspaces" })
	| (RouteWorkspacesDetail & { path: "workspaces/:id" })
	| (RouteChecksBq & { path: "checks/bq" })
	| (RouteChecksConsistencyDaily & { path: "checks/consistency-daily" })
	| (RouteChecksConsistencyCampaign & { path: "checks/consistency-campaign" })
	| (RouteNotFound & { path: "404" });

export const pathToRoute = (path: string): Model => {
	if (path === "") return { route: "home", path: "" };
	if (path === "login") return { route: "login", path: "login" };
	if (path === "settings") return { route: "settings", path: "settings" };
	if (path === "etl/accesses")
		return { route: "etl-accesses", path: "etl/accesses" };

	const accessDetailMatcher = /etl\/accesses\/([a-zA-Z]+)\/([a-z0-9]+)(\??|\/)$/;
	if (accessDetailMatcher.test(path)) {
		const accessDetailMatch = path.match(accessDetailMatcher);
		return {
			route: "etl-accesses-detail",
			path: "etl/accesses/:accessType/:id",
			params: {
				id: accessDetailMatch ? accessDetailMatch[2] : "",
				accessType: accessDetailMatch
					? (accessDetailMatch[1] as AccessType)
					: AccessType.appleAccesses,
			},
		};
	}

	if (path === "etl/configs")
		return { route: "etl-configs", path: "etl/configs" };

	const configDetailMatcher = /etl\/configs\/([_a-zA-Z]+)\/([a-z0-9]+)(\??|\/)$/;
	if (configDetailMatcher.test(path)) {
		const configDetailMatch = path.match(configDetailMatcher);
		return {
			route: "etl-configs-detail",
			path: "etl/configs/:feedType/:id",
			params: {
				id: configDetailMatch ? configDetailMatch[2] : "",
				feedType: configDetailMatch
					? (configDetailMatch[1] as Feed)
					: Feed.APPLE,
			},
		};
	}

	if (path === "etl/instances")
		return { route: "etl-instances", path: "etl/instances" };

	const instanceDetailMatcher = /etl\/instances\/([_a-zA-Z]+)\/([a-z0-9]+)(\??|\/)$/;
	if (instanceDetailMatcher.test(path)) {
		const instanceDetailMatch = path.match(instanceDetailMatcher);
		return {
			route: "etl-instances-detail",
			path: "etl/instances/:feedType/:id",
			params: {
				id: instanceDetailMatch ? instanceDetailMatch[2] : "",
				feedType: instanceDetailMatch
					? (instanceDetailMatch[1] as Feed)
					: Feed.APPLE,
			},
		};
	}

	if (path === "workspaces")
		return { route: "workspaces", path: "workspaces" };

	const workspacesDetailMatcher = /workspaces\/([a-z0-9]+)(\??|\/)$/;
	if (workspacesDetailMatcher.test(path)) {
		const workspacesDetailMatch = path.match(workspacesDetailMatcher);
		return {
			route: "workspaces-detail",
			path: "workspaces/:id",
			params: {
				id: workspacesDetailMatch ? workspacesDetailMatch[1] : "",
			},
		};
	}

	if (path === "checks/bq") return { route: "checks-bq", path: "checks/bq" };
	if (path === "checks/consistency-daily")
		return {
			route: "checks-consistency-daily",
			path: "checks/consistency-daily",
		};

	if (path === "checks/consistency-campaign")
		return {
			route: "checks-consistency-campaign",
			path: "checks/consistency-campaign",
		};

	return { route: "not-found", path: "404" };
};

const stringFromRoute = (route: {
	path: string;
	params?: Record<string, string>;
}) => {
	const { path, params = {} } = route;
	const paramKeys = Object.keys(params)
		.map((s) => `:${s}`)
		.join("|");

	const regexp = new RegExp(paramKeys, "gi");
	return !paramKeys
		? path
		: path.replace(regexp, (matched) => params[matched.substring(1)]);
};
