import { AccessType, options } from "@/modules/Etl/Accesses";
import { Route } from "@/modules/Router";
import { useSerialization } from "@/modules/Serialization";
import { useOpenable } from "@/modules/useOpenable";
import { accessesService } from "@/services/Accesses";
import { Dropdown, Icon, Modal } from "@/views";
import { Multiselect } from "@/views/Multiselect";
import { Refetch } from "@/views/Refetch";
import React, { useEffect, useState } from "react";
import { createStore } from "reusable";
import { AccessDetailExisting } from "./Detail/Existing";
import { AccessDetailNew } from "./Detail/New";
import { AccessTile } from "./Tile";

const shownAccessesService = createStore(() => {
	const [state, setState] = useState<AccessType[]>(Object.values(AccessType));

	useSerialization({
		equals: (query) => {
			if (
				state.length === Object.values(AccessType).length &&
				query.showAccesses === undefined
			)
				return true;
			return (
				// W10= is base64 encoded empty array => []
				atob(query.showAccesses || "W10=") === JSON.stringify(state)
			);
		},
		deserialize: (query) => {
			if (query.showAccesses) {
				setState(JSON.parse(atob(query.showAccesses)));
				return;
			}
			setState(Object.values(AccessType));
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				showAccesses:
					state.length === Object.values(AccessType).length
						? undefined
						: btoa(JSON.stringify(state)),
			});
		},
	});
	return [state, setState] as const;
});

export const Accesses = () => {
	const [shownAccesses, setShownAccesses] = shownAccessesService();
	const { getState } = accessesService();

	return (
		<div className="children:px-4 flex flex-col gap-2">
			<Multiselect
				value={shownAccesses}
				select={setShownAccesses}
				options={options}
			/>
			<div className="flex gap-4">
				<CreateNewAccessButton />
				<Refetch
					refetch={() => {
						shownAccesses.forEach((shownAccess) => {
							const [, state] = getState(shownAccess);
							if (state.refetch) {
								state.refetch();
							}
						});
					}}
					loading={shownAccesses
						.map(getState)
						.some(([, state]) => state.loading)}
				/>
			</div>
			<div className="py-2 overflow-overlay max-h-200 flex flex-wrap gap-4">
				{shownAccesses.map((shownAccess) => {
					return <Tiles key={shownAccess} accessType={shownAccess} />;
				})}
			</div>
			<Route path="etl/accesses/:accessType/:id">
				{({ accessType, id }) => (
					<AccessDetailExisting accessType={accessType} id={id} />
				)}
			</Route>
		</div>
	);
};

type TilesProps = {
	accessType: AccessType;
};

const Tiles = ({ accessType }: TilesProps) => {
	const { getState } = accessesService();
	const [getQuery, state] = getState(accessType);

	useEffect(() => {
		getQuery();
	}, [getQuery]);

	if (state.loading) {
		return (
			<div className="center">
				<Icon
					className="spin"
					name="spinner"
					weight="solid"
					size="big"
				/>
			</div>
		);
	}
	if (!state.data) return null;

	const refech = () => {
		if (state.refetch) {
			state.refetch();
		}
	};

	return (state.data as Record<keyof typeof AccessType, any>)[accessType].map(
		(access: any) => {
			return (
				<AccessTile
					key={access.id}
					item={access}
					accessType={accessType}
					refetch={refech}
				/>
			);
		}
	);
};

const CreateNewAccessButton = () => {
	const dropdown = useOpenable(false);
	const newConfig = useOpenable(false);
	const [accessType, setAccessType] = useState<AccessType>();

	return (
		<>
			<Dropdown.View
				label={
					<div className="btn-primary" onClick={dropdown.toggle}>
						Create new
					</div>
				}
				onClickOutside={dropdown.close}
				opened={dropdown.opened}
			>
				<Dropdown.Menu>
					{options.map((option) => {
						return (
							<Dropdown.Item
								key={option.value}
								onClick={() => {
									dropdown.close();
									newConfig.open();
									setAccessType(option.value);
								}}
							>
								<span>{option.label}</span>
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown.View>
			{newConfig.opened && accessType && (
				<Modal
					className="w-modal h-modal"
					isOpened={newConfig.opened}
					onClose={newConfig.close}
				>
					<AccessDetailNew
						accessType={accessType}
						close={newConfig.close}
					/>
				</Modal>
			)}
		</>
	);
};
