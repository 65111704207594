import { createGuid } from "@/functions/createGuid";
import { Reducer } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Optional, Required } from "utility-types";
import { Metrics } from ".";

export type Model = Dimension[];

export type Dimension = {
    id: string;
    value: string;
    label: string;
    filters: Filter<number | string>[];
    ordering: Sort[];
};

export type Filter<R> = {
    id: Id;
    value: Metrics.Value;
    label: string;
    operator: string;
    day_of_activity?: number;
    input: R;
};

type Id = string;

export type Sort = {
    id: string;
    value: string;
    label: string;
    day_of_activity?: number;
    ordering: "ASC" | "DESC";
};

export type Msg =
    | {
          type: "CREATE";
          payload: Pick<Dimension, "value" | "label"> & {
              filters?: Optional<
                  Omit<Filter<number | string>, "id">,
                  "operator" | "input"
              >[];
              ordering?: Optional<Omit<Sort, "id">, "ordering">[];
          };
      }
    | {
          type: "CREATE_FILTER";
          payload: Pick<Dimension, "id"> & {
              filters: Optional<
                  Omit<Filter<number | string>, "id">,
                  "operator" | "input"
              >;
          };
      }
    | {
          type: "CREATE_SORT";
          payload: Pick<Dimension, "id"> & {
              ordering: Optional<Omit<Sort, "id">, "ordering">;
          };
      }
    | {
          type: "UPDATE";
          payload: Pick<Dimension, "id" | "value" | "label">;
      }
    | {
          type: "UPDATE_FILTER";
          payload: Pick<Dimension, "id"> & {
              filters: Required<Filter<number | string>, "id">;
          };
      }
    | {
          type: "UPDATE_SORT";
          payload: Pick<Dimension, "id"> & { ordering: Required<Sort, "id"> };
      }
    | { type: "DELETE"; payload: Pick<Dimension, "id"> }
    | {
          type: "DELETE_FILTER";
          payload: Pick<Dimension, "id"> & {
              filters: Pick<Filter<number | string>, "id">;
          };
      }
    | {
          type: "DELETE_SORT";
          payload: Pick<Dimension, "id"> & { ordering: Pick<Sort, "id"> };
      }
    | {
          type: "CHANGE_ORDER_OF_DIMENSIONS";
          payload: DropResult;
      };

export const update: Reducer<Model, Msg> = (model: Model, msg: Msg): Model => {
    switch (msg.type) {
        case "CREATE": {
            const isCohort =
                model[model.length - 1] &&
                model[model.length - 1].value === "cohort";

            if (isCohort) {
                return [
                    ...model.slice(0, -1),
                    {
                        id: createGuid(),
                        value: msg.payload.value,
                        label: msg.payload.label,
                        filters: [],
                        ordering: []
                    },
                    model[model.length - 1]
                ];
            }

            return [
                ...model,
                {
                    id: createGuid(),
                    value: msg.payload.value,
                    label: msg.payload.label,
                    filters: [],
                    ordering: []
                }
            ];
        }
        case "CREATE_FILTER": {
            return model.map(item => {
                if (item.id === msg.payload.id) {
                    return {
                        ...item,
                        filters: item.filters.concat({
                            id: createGuid(),
                            value: msg.payload.filters.value,
                            label: msg.payload.filters.label,
                            operator: msg.payload.filters.operator || "equals",
                            input: msg.payload.filters.input || "",
                            ...(msg.payload.filters.day_of_activity && {
                                day_of_activity:
                                    msg.payload.filters.day_of_activity
                            })
                        })
                    };
                }
                return item;
            });
        }
        case "CREATE_SORT": {
            return model.map(item => {
                if (item.id === msg.payload.id) {
                    return {
                        ...item,
                        ordering: item.ordering.concat({
                            id: createGuid(),
                            value: msg.payload.ordering.value,
                            label: msg.payload.ordering.label,
                            day_of_activity:
                                msg.payload.ordering.day_of_activity,
                            ordering: msg.payload.ordering.ordering || "ASC"
                        })
                    };
                }
                return item;
            });
        }
        case "UPDATE": {
            return model.map(item =>
                item.id === msg.payload.id
                    ? {
                          ...item,
                          value: msg.payload.value,
                          label: msg.payload.label
                      }
                    : item
            );
        }
        case "UPDATE_FILTER": {
            const dimension = model.find(item => item.id === msg.payload.id);
            if (dimension) {
                const filterIndex = dimension.filters.findIndex(
                    item => item.id === msg.payload.filters.id
                );
                if (filterIndex !== -1) {
                    const filter = dimension.filters[filterIndex];
                    dimension.filters[filterIndex] = {
                        ...filter,
                        ...msg.payload.filters
                    };
                }
            }
            return model.map(item => {
                if (item.id === msg.payload.id) {
                    return {
                        ...item,
                        filters: item.filters.map(item =>
                            item.id === msg.payload.filters.id
                                ? {
                                      ...item,
                                      ...msg.payload.filters
                                  }
                                : item
                        )
                    };
                }
                return item;
            });
        }
        case "UPDATE_SORT": {
            return model.map(item => {
                if (item.id === msg.payload.id) {
                    return {
                        ...item,
                        ordering: item.ordering.map(item =>
                            item.id === msg.payload.ordering.id
                                ? {
                                      ...item,
                                      ...msg.payload.ordering
                                  }
                                : item
                        )
                    };
                }
                return item;
            });
        }
        case "DELETE": {
            return model.filter(item => item.id !== msg.payload.id);
        }
        case "DELETE_FILTER": {
            return model.map(item =>
                item.id === msg.payload.id
                    ? {
                          ...item,
                          filters: item.filters.filter(
                              item => item.id !== msg.payload.filters.id
                          )
                      }
                    : item
            );
        }
        case "DELETE_SORT": {
            return model.map(item =>
                item.id === msg.payload.id
                    ? {
                          ...item,
                          ordering: item.ordering.filter(
                              item => item.id !== msg.payload.ordering.id
                          )
                      }
                    : item
            );
        }
        case "CHANGE_ORDER_OF_DIMENSIONS": {
            if (!msg.payload.destination) return model;
            if (msg.payload.source.index === msg.payload.destination.index)
                return model;

            const sourceIndex = msg.payload.source.index;
            const destinationIndex = msg.payload.destination.index;
            const item = model[sourceIndex];

            if (sourceIndex < destinationIndex) {
                return [
                    ...model.slice(0, sourceIndex),
                    ...model.slice(sourceIndex + 1, destinationIndex + 1),
                    item,
                    ...model.slice(destinationIndex + 1)
                ];
            }
            return [
                ...model.slice(0, destinationIndex),
                item,
                ...model.slice(destinationIndex, sourceIndex),
                ...model.slice(sourceIndex + 1)
            ];
        }
    }
};

export const getNotSelected = (dimensions: Model) => {
    return all.filter(
        item => !dimensions.some(dimension => dimension.value === item.value)
    );
};

export const all = [
    { value: "project", label: "Project" },
    { value: "platform", label: "Platform" },
    { value: "campaign_name", label: "Camp. name" },
    { value: "adset_name", label: "Adset name" },
    { value: "ad_name", label: "Ad name" },
    { value: "campaign", label: "Campaign" },
    { value: "adset", label: "Adset" },
    { value: "ad", label: "Ad" },
    { value: "month", label: "Cal. month" },
    { value: "week", label: "Cal. week" },
    { value: "day", label: "Cal. day" },
    { value: "year", label: "Cal. year" },
    { value: "quarter", label: "Cal. quarter" },
    { value: "cohort", label: "!Cohort" }
];
