import { createClass } from "@/reexports";
import React, { ReactChild } from "react";

type Props = {
	children: ReactChild;
	className?: string;
};

export const Tile = ({ className, children }: Props) => {
	const classes = createClass(
		className,
		"rounded-lg shadow-flat-down"
	);

	return <div className={classes}>{children}</div>;
};
