import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { Icon } from "@/views";
import React from "react";
import { createUIKitMatcher, UIFieldProps } from ".";
import { StringField } from "./String";

export const FacebookBreakdownsMatch = () =>
	createUIKitMatcher(
		({ field }) => field.type === "FacebookBreakdownCreateInput"
	);

export const FacebookBreakdownsField = <R extends FacebookBreakdown[]>(
	props: UIFieldProps<R>
) => {
	const { path, field, form, data, UIField, uiKit } = props;
	const fieldPath = path.concat(field.name).join(".");

	return (
		<div className="center-v">
			<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			<div className="center flex-col gap-2">
				{data?.map((item, index) => {
					return field.fields?.map((fieldItem) => {
						return (
							<div key={fieldItem.name} className="flex">
								<StringField
									label={null}
									field={fieldItem}
									form={form}
									data={item.values}
									path={path.concat(
										`${field.name}[${index}]`
									)}
									uiKit={uiKit}
									UIField={UIField}
								/>
								<Icon
									name="times"
									onClick={() => {
										form.removeFieldValue(fieldPath, index);
									}}
								/>
							</div>
						);
					});
				})}
				<div>
					<Icon
						name="plus"
						onClick={() => {
							form.pushFieldValue(fieldPath, { values: [] });
						}}
					/>
				</div>
			</div>
		</div>
	);
};

type FacebookBreakdown = {
	values: string[];
};
