import type { Predicate } from "@/data-types/logic";

// MATCH
type Fn<R, T> = (value: R) => T;
type PredicateFnTuple<R, T> = [Predicate<R>, Fn<R, T>];

export const match = <R, T>(
	value: R,
	fnArray: PredicateFnTuple<R, T>[],
	defaultFn: (value: R) => T
): T => {
	const index = fnArray.findIndex(([predicateFn]) => predicateFn(value));
	if (index === -1) {
		return defaultFn(value);
	}
	return fnArray[index][1](value);
};
