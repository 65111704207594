import { Detail } from "@/components/Page/Etl/Detail";
import {
	AccessType,
	getGraphqlTypeByAccess,
	useAccessesQuery,
} from "@/modules/Etl/Accesses";
import { useCreateIntegrationFeedConfig } from "@/modules/Etl/IntegrationFeedConfigs/logic";
import { getFeedOptions } from "@/modules/Etl/IntegrationFeedParams/ui/FeedMultiselect";
import { useForm } from "@/modules/Form/logic";
import { useOrganizationsQuery } from "@/modules/Organizations/logic";
import {
	ApplovinReportType,
	Feed,
	ReportType,
} from "@/services/graphApi/graphql-zeus";
import { Form } from "@/ui/components/Form/components/Form";
import { UIKit } from "@/ui/components/Form/uiKit";
import { BooleanField, BooleanMatch } from "@/ui/components/Form/uiKit/Boolean";
import {
	FacebookBreakdownsField,
	FacebookBreakdownsMatch,
} from "@/ui/components/Form/uiKit/Breakdowns";
import {
	DestinationsField,
	DestinationsMatch,
} from "@/ui/components/Form/uiKit/Destinations";
import { FeedField, FeedMatch } from "@/ui/components/Form/uiKit/Feed";
import {
	FeedTypeField,
	FeedTypeMatch,
} from "@/ui/components/Form/uiKit/FeedType";
import { IntField, IntMatch } from "@/ui/components/Form/uiKit/Int";
import {
	IntegrationFeedParamsCreateInputField,
	IntegrationFeedParamsCreateInputMatch,
} from "@/ui/components/Form/uiKit/IntegrationFeedParamsCreateInput";
import { ObjectField, ObjectMatch } from "@/ui/components/Form/uiKit/Object";
import {
	OrganizationAttributesField,
	OrganizationAttributesMatch,
} from "@/ui/components/Form/uiKit/OrganizationAttributes";
import {
	OrganizationRoleField,
	OrganizationRoleMatch,
} from "@/ui/components/Form/uiKit/OrganizationRole";
import {
	EnumTypeField,
	EnumTypeMatch,
} from "@/ui/components/Form/uiKit/EnumType";
import {
	ScraperGameField,
	ScraperGameMatch,
} from "@/ui/components/Form/uiKit/ScraperGames";
import { StringField, StringMatch } from "@/ui/components/Form/uiKit/String";
import {
	UpdateManyInputField,
	UpdateManyInputMatch,
} from "@/ui/components/Form/uiKit/UpdateManyInput";
import {
	WhereUniqueInputField,
	WhereUniqueInputMatch,
} from "@/ui/components/Form/uiKit/WhereUniqueInput";
import { ButtonWithLoading } from "@/views/ButtonWithLoading";
import React, { useMemo } from "react";

const options = getFeedOptions();

type Props = {
	item: { params: { feedType: Feed } };
	close: () => void;
};

export const IntegrationFeedConfigDetailNew = ({ item, close }: Props) => {
	const {
		params: { feedType },
	} = item;
	const [create, createState] = useCreateIntegrationFeedConfig(feedType);
	const form = useForm({
		params: {
			feedType,
			s3Destinations: [],
		},
	});

	const ui = useMemo(
		() => uiKit(form.getValues("params.ironSource.adRevenue")),
		[form]
	);

	return (
		<Detail
			title={
				options.find((option) => option.value === feedType)?.label || ""
			}
			close={close}
			loading={false}
			Content={
				<Form
					className="p-4 overflow-overlay h-full bg-white"
					ui={ui}
					input="IntegrationFeedConfigCreateInput"
					form={form}
					data={form.getValues()}
					Buttons={() => {
						return (
							<div className="flex mt-4">
								<div className="mr-2">
									<ButtonWithLoading
										loading={createState.loading}
										onClick={() => {
											const {
												id,
												...data
											} = form.getValues();

											create({
												variables: {
													data,
													where: {
														id,
													},
												},
											});
										}}
									>
										Create
									</ButtonWithLoading>
								</div>
							</div>
						);
					}}
				/>
			}
		/>
	);
};

const uiKit = (showS3: boolean): UIKit<any>[] => [
	[OrganizationRoleMatch(), OrganizationRoleField],
	[EnumTypeMatch("ApplovinReportType"), EnumTypeField(ApplovinReportType)],
	[
		EnumTypeMatch("ReportType"),
		EnumTypeField(ReportType, ReportType.PARTNERS_BY_DATE_REPORT),
	],
	[FeedTypeMatch(), FeedTypeField],
	[
		IntegrationFeedParamsCreateInputMatch(),
		IntegrationFeedParamsCreateInputField,
	],
	[FeedMatch(), FeedField],
	[
		WhereUniqueInputMatch("OrganizationWhereUniqueInput"),
		WhereUniqueInputField({
			useRemoteData: () => useOrganizationsQuery(),
			key: "organizations",
		}),
	],
	...Object.values(AccessType).map((accessType) => {
		return [
			WhereUniqueInputMatch(
				`${getGraphqlTypeByAccess(accessType)}WhereUniqueInput`
			),
			WhereUniqueInputField({
				// @ts-ignore
				useRemoteData: () => useAccessesQuery(accessType),
				key: accessType,
			}),
		] as UIKit<any>;
	}),
	[OrganizationAttributesMatch(), OrganizationAttributesField],
	[DestinationsMatch("bqDestinations"), DestinationsField],
	[
		DestinationsMatch("s3Destinations"),
		showS3 ? DestinationsField : () => null,
	],
	[UpdateManyInputMatch(), UpdateManyInputField],
	[FacebookBreakdownsMatch(), FacebookBreakdownsField],
	[ScraperGameMatch(), ScraperGameField],
	// // ...basicKit
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[IntMatch(), IntField],
	[BooleanMatch(), BooleanField],
];
