import { createClass } from "@/reexports";
import * as React from "react";

interface PopupProps {
    opened?: boolean;
    className?: string;
    arrowPosition?: "top" | "bottom" | "none";
    children: string | JSX.Element;
}

export const Popup = React.forwardRef<HTMLDivElement, PopupProps>(
    (
        { opened = false, className, arrowPosition = "bottom", children },
        ref
    ) => {
        if (!opened) return null;

        const classes = createClass(className, "popup", {
            "arrow-top": arrowPosition === "top",
            "arrow-bottom": arrowPosition === "bottom",
            "arrow-none": arrowPosition === "none",
        });

        return (
            <div ref={ref} className={classes}>
                {children}
            </div>
        );
    }
);
