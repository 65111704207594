import _DatePicker, { ReactDatePickerProps } from "react-datepicker";
import React from "react";
import dayjs from "dayjs";
import "./DatePicker.less";

type Props = Omit<ReactDatePickerProps, "onChange"> & {
	onChange: (e: string) => void;
};

export const DatePicker = (props: Props) => {
	const { onChange } = props;
	const _onChange = React.useCallback((date: Date | null) => {
		if (date !== null) {
			onChange(dayjs(date).format("YYYY-MM-DD"));
		} else {
			onChange("");
		}
	}, [onChange]);

	return (
		<div>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<_DatePicker {...props} onChange={_onChange} />
		</div>
	);
};
