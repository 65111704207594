import { AccessType, useAccessesQueries } from "@/modules/Etl/Accesses";
import { useCallback } from "react";
import { createStore } from "reusable";

export const accessesService = createStore(() => {
	const state = useAccessesQueries();

	const getState = useCallback(
		(accessType: AccessType) => state[accessType],
		[state]
	);

	return {
		getState,
		state
	};
});
