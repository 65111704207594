import { App } from "@/components/App";
import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { ReusableProvider } from "reusable";
import { client } from "./services/request";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.less";

ReactDOM.render(
	<ApolloProvider client={client}>
		<ReusableProvider>
			<App />
		</ReusableProvider>
	</ApolloProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
	(module.hot as any).accept();
}
