import { useCampaignConsistencyChecksData } from "@/modules/Checks/Consistency/Campaign";
import { useOpenable } from "@/modules/useOpenable";
import {
	CampaignConsistencyCheck,
	CampaignConsistencyValues,
} from "@/services/graphApi/graphql-zeus";
import { Dropdown } from "@/views";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import fp from "lodash/fp";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Cell, DifferenceCell, GroupCell } from "./Cell";

dayjs.extend(relativeTime);

export type CampaignTableProps = Pick<
	ReturnType<typeof useCampaignConsistencyChecksData>,
	"state"
>;

export type CampaignCheckGrouping = "date" | "campaignId";

const groupByFunction = {
	date: (check: CampaignConsistencyCheck) =>
		dayjs(check.date).format("YYYY-MM-DD"),
	campaignId: (check: CampaignConsistencyCheck) => check.campaignId,
};

const GroupByDropdown = (props: {
	groupBy: CampaignCheckGrouping;
	setGroupBy: Dispatch<SetStateAction<CampaignCheckGrouping>>;
}) => {
	const { groupBy, setGroupBy } = props;
	const { opened, toggle, close } = useOpenable(false);

	const onOptionClick = (option: CampaignCheckGrouping) => {
		setGroupBy(option);
		close();
	};

	return (
		<div className="center-v gap-2">
			<div className="font-bold">Group by</div>
			<Dropdown.View
				className="w-fit"
				onClickOutside={close}
				opened={opened}
				label={
					<button
						className={
							"font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 "
						}
						type="button"
						onClick={toggle}
					>
						{groupBy === "date" ? "Date" : "Campaign"}
					</button>
				}
			>
				<Dropdown.Menu className="absolute text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 bg-gray-100 border border-gray-300">
					<span
						className={
							"cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent hover:bg-white "
						}
						onClick={() => onOptionClick("date")}
					>
						Date
					</span>
					<span
						className={
							"cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent hover:bg-white "
						}
						onClick={() => onOptionClick("campaignId")}
					>
						Campaign
					</span>
				</Dropdown.Menu>
			</Dropdown.View>
		</div>
	);
};

export const CampaignTable = ({ state }: CampaignTableProps) => {
	const [groupBy, setGroupBy] = useState<CampaignCheckGrouping>("date");

	if (state.loading) {
		return <div className="center">Loading...</div>;
	}

	if (state.error) {
		return <div className="center">{state.error.toString()}</div>;
	}

	const data = state.data?.campaignConsistencyChecksConnection.edges.map(
		(e) => e.node as CampaignConsistencyCheck
	);
	const checksByGame = fp.groupBy("gameId", data);

	return (
		<>
			<GroupByDropdown groupBy={groupBy} setGroupBy={setGroupBy} />
			<div className="overflow-auto">
				<table className="table-auto border-b-2 border-gray-300">
					<thead>
						<tr>
							<th className="px-4 py-2">Game ID</th>
							{groupBy === "date" ? (
								<>
									<th className="px-4 py-2">Date</th>
									<th className="px-4 py-2">Campaign ID</th>
								</>
							) : (
								<>
									<th className="px-4 py-2">Campaign ID</th>
									<th className="px-4 py-2">Date</th>
								</>
							)}
							<th className="px-4 py-2">Source</th>
							<th className="px-4 py-2">Spend</th>
							<th className="px-4 py-2">Spend Difference</th>
							<th className="px-4 py-2">Spend Rel. Difference</th>
							<th className="px-4 py-2">IAP Gross Revenue</th>
							<th className="px-4 py-2">
								IAP Gross Revenue Difference
							</th>
							<th className="px-4 py-2">
								IAP Gross Revenue Rel. Difference
							</th>
							<th className="px-4 py-2">Purchases</th>
							<th className="px-4 py-2">Purchases Difference</th>
							<th className="px-4 py-2">
								Purchases Rel. Difference
							</th>
							<th className="px-4 py-2">Payers</th>
							<th className="px-4 py-2">Payers Difference</th>
							<th className="px-4 py-2">
								Payers Rel. Difference
							</th>
							<th className="px-4 py-2">Impressions</th>
							<th className="px-4 py-2">
								Impressions Difference
							</th>
							<th className="px-4 py-2">
								Impressions Rel. Difference
							</th>
							<th className="px-4 py-2">Clicks</th>
							<th className="px-4 py-2">Clicks Difference</th>
							<th className="px-4 py-2">
								Clicks Rel. Difference
							</th>
							<th className="px-4 py-2">Installs</th>
							<th className="px-4 py-2">Installs Difference</th>
							<th className="px-4 py-2">
								Installs Rel. Difference
							</th>
						</tr>
					</thead>
					<tbody>
						{Object.entries(checksByGame).map(
							([gameId, checks], index) => (
								<GameRow
									key={gameId}
									gameId={gameId}
									checks={checks}
									gameIndex={index}
									groupBy={groupBy}
								/>
							)
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

type RowProps = {
	index: number;
	span: number;
	id: string;
};

const GameRow = (props: {
	checks: CampaignConsistencyCheck[];
	gameId: string;
	gameIndex: number;
	groupBy?: CampaignCheckGrouping;
}) => {
	const { gameIndex, gameId, checks, groupBy = "date" } = props;
	const checksByGroup = fp.groupBy(groupByFunction[groupBy], checks);

	const [, nonFacebookChecks] = fp.partition(sourceIsFacebook, checks);

	const distinctGroups = fp.flow(
		fp.map((check: CampaignConsistencyCheck) => ({
			date: dayjs(check.date).format("YYYY-MM-DD"),
			campaignId: check.campaignId,
		})),
		fp.uniqWith(
			(c1, c2) => c1.date === c2.date && c1.campaignId === c2.campaignId
		)
	)(checks);

	// All distinct (platform, date, campaignId)-triples have a store row (blank if missing in data).
	const rowSpan = distinctGroups.length + nonFacebookChecks.length;

	return (
		<>
			{Object.entries(checksByGroup).map(
				([groupId, groupChecks], gameRowindex) => (
					<PrimaryGroupRow
						key={`${gameId}.${groupId}`}
						checks={groupChecks}
						gameIndex={gameIndex}
						groupId={groupId}
						gameRowProps={{
							index: gameRowindex,
							span: rowSpan,
							id: gameId,
						}}
						groupBy={groupBy === "date" ? "campaignId" : "date"}
					/>
				)
			)}
		</>
	);
};

const PrimaryGroupRow = (props: {
	checks: CampaignConsistencyCheck[];
	gameIndex: number;
	gameRowProps: RowProps;
	groupId: string;
	groupBy: CampaignCheckGrouping;
}) => {
	const {
		gameIndex,
		gameRowProps,
		checks,
		groupId,
		groupBy = "campaignId",
	} = props;
	const checksByGroup = fp.groupBy(groupByFunction[groupBy], checks);

	const [, nonFacebookChecks] = fp.partition(sourceIsFacebook, checks);

	// Each distinct campaignId has one store row (blank if missing in data).
	const distinctCampaignIds = Object.keys(checksByGroup).length;
	const rowSpan = distinctCampaignIds + nonFacebookChecks.length;

	return (
		<>
			{Object.entries(checksByGroup).map(
				([secondaryGroupId, groupChecks], dateRowIndex) => (
					<SecondaryGroupRow
						key={`${gameRowProps.id}.${groupId}.${secondaryGroupId}`}
						checks={groupChecks}
						gameIndex={gameIndex}
						gameRowProps={gameRowProps}
						primaryRowProps={{
							index: dateRowIndex,
							span: rowSpan,
							id: groupId,
						}}
						groupId={secondaryGroupId}
					/>
				)
			)}
		</>
	);
};

const SecondaryGroupRow = (props: {
	checks: CampaignConsistencyCheck[];
	gameIndex: number;
	gameRowProps: RowProps;
	primaryRowProps: RowProps;
	groupId: string;
}) => {
	const { checks, gameIndex, gameRowProps, primaryRowProps, groupId } = props;

	const checkValues = fp.flatMap((c) => c.values, checks);

	const [[facebookCheck], nonFacebookChecks] = (fp.partition(
		sourceIsFacebook,
		checkValues
	) as unknown) as [[CampaignConsistencyValues], CampaignConsistencyValues[]];
	const rowSpan = 1 + nonFacebookChecks.length;

	return (
		<>
			{
				<tr
					className={`border-t-2 border-gray-300 ${
						gameIndex % 2 === 0 ? undefined : "bg-gray-100"
					}`}
					key={`${gameRowProps.id}.${
						primaryRowProps.id
					}.${groupId}.${0}`}
				>
					{
						// Multi-line cell for game ID
						primaryRowProps.index === 0 &&
							gameRowProps.index === 0 && (
								<GroupCell
									rowSpan={gameRowProps.span}
									value={gameRowProps.id}
								/>
							)
					}
					{
						// Multi-line cell for platform
						primaryRowProps.index === 0 && (
							<GroupCell
								rowSpan={primaryRowProps.span}
								value={primaryRowProps.id}
							/>
						)
					}

					<GroupCell rowSpan={rowSpan} value={groupId} />

					<FacebookRowCells {...facebookCheck} />
				</tr>
			}
			{nonFacebookChecks.map((check, groupIndex) => {
				return (
					<tr
						className={
							gameIndex % 2 === 0 ? undefined : "bg-gray-100"
						}
						key={`${gameRowProps.id}.${primaryRowProps.id}.${groupId}.${groupIndex}`}
					>
						<NonFacebookCells
							check={check}
							sourceOfTruth={facebookCheck}
						/>
					</tr>
				);
			})}
		</>
	);
};

const FacebookRowCells = (props: {
	installs?: number;
	purchases?: number;
	spend?: number;
	iapGrossRevenue?: number;
	payers?: number;
	impressions?: number;
	clicks?: number;
}) => {
	const {
		installs,
		purchases,
		spend,
		iapGrossRevenue,
		payers,
		impressions,
		clicks,
	} = props;
	return (
		<>
			<Cell value="FACEBOOK" isSource />
			<Cell value={spend} isSource colSpan={3} center />
			<Cell value={iapGrossRevenue} isSource colSpan={3} center />
			<Cell value={purchases} isSource colSpan={3} center />
			<Cell value={payers} isSource colSpan={3} center />
			<Cell value={impressions} isSource colSpan={3} center />
			<Cell value={clicks} isSource colSpan={3} center />
			<Cell value={installs} isSource colSpan={3} center />
		</>
	);
};

const NonFacebookCells = (props: {
	check: CampaignConsistencyValues;
	sourceOfTruth?: CampaignConsistencyValues;
}) => {
	const {
		check: {
			source,
			installs,
			purchases,
			spend,
			iapGrossRevenue,
			payers,
			impressions,
			clicks,
		},
		sourceOfTruth,
	} = props;

	return (
		<>
			<Cell value={source} />

			<Cell value={spend} center />
			<DifferenceCell
				value={spend}
				sourceValue={sourceOfTruth?.spend}
				center
			/>
			<DifferenceCell
				relative
				value={spend}
				sourceValue={sourceOfTruth?.spend}
				center
			/>

			<Cell value={iapGrossRevenue} center />
			<DifferenceCell
				value={iapGrossRevenue}
				sourceValue={sourceOfTruth?.iapGrossRevenue}
				center
			/>
			<DifferenceCell
				relative
				value={iapGrossRevenue}
				sourceValue={sourceOfTruth?.iapGrossRevenue}
				center
			/>

			<Cell value={purchases} center />
			<DifferenceCell
				value={purchases}
				sourceValue={sourceOfTruth?.purchases}
				center
			/>
			<DifferenceCell
				relative
				value={purchases}
				sourceValue={sourceOfTruth?.purchases}
				center
			/>

			<Cell value={payers} center />
			<DifferenceCell
				value={payers}
				sourceValue={sourceOfTruth?.payers}
				center
			/>
			<DifferenceCell
				relative
				value={payers}
				sourceValue={sourceOfTruth?.payers}
				center
			/>

			<Cell value={impressions} center />
			<DifferenceCell
				value={impressions}
				sourceValue={sourceOfTruth?.impressions}
				center
			/>
			<DifferenceCell
				relative
				value={impressions}
				sourceValue={sourceOfTruth?.impressions}
				center
			/>

			<Cell value={clicks} center />
			<DifferenceCell
				value={clicks}
				sourceValue={sourceOfTruth?.clicks}
				center
			/>
			<DifferenceCell
				relative
				value={clicks}
				sourceValue={sourceOfTruth?.clicks}
				center
			/>

			<Cell value={installs} center />
			<DifferenceCell
				value={installs}
				sourceValue={sourceOfTruth?.installs}
				center
			/>
			<DifferenceCell
				relative
				value={installs}
				sourceValue={sourceOfTruth?.installs}
				center
			/>
		</>
	);
};

const sourceIsFacebook = (item: { source: string }) =>
	item.source === "FACEBOOK";
