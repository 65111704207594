import React from "react";
import { useOpenable } from "@/modules/useOpenable";
import { Dropdown } from ".";
import { Icon } from "./Icon";
import { createClass } from "@/reexports";

export type MultiselectProps<R> = {
	className?: string;
	value: R[];
	options: readonly Option<R>[];
	select: (value: R[]) => void;
	selectedItem?: SelectedItem<R>;
};

export type Option<R> = { readonly label?: string; readonly value: R };
export type SelectedItem<R> = (props: {
	item: Option<R>;
	value: MultiselectProps<R>["value"];
	select: MultiselectProps<R>["select"];
}) => JSX.Element;

export const Multiselect = <R extends string, uknown>({
	className,
	value,
	options,
	select,
	selectedItem,
}: MultiselectProps<R>) => {
	const { opened, toggle, close } = useOpenable(false);
	const selected = value.map((item) => ({
		value: item,
		label: options.find((val) => val.value === item)?.label,
	}));
	const menuItems = options.filter((item) => !value.includes(item.value));
	const classes = createClass(className);

	return (
		<Dropdown.View
			className={classes}
			opened={opened}
			onClickOutside={close}
			label={
				<div onClick={toggle}>
					<div className="center-v justify-between relative min-h-10 rounded-lg border-gray-500 border py-2 px-4">
						<div className="center-v flex-wrap gap-2">
							{selected.map((item) => {
								if (selectedItem) {
									return selectedItem({
										item,
										select,
										value,
									});
								}
								return (
									<div
										key={item.value}
										className="center-v px-2 py-1 text-xs rounded-lg bg-gray-200"
										onClick={(e) => e.stopPropagation()}
									>
										<div className="center-v">
											<div className="mr-2">
												{item.label}
											</div>
											<Icon
												className="cursor-pointer"
												name="times"
												size="mili"
												onClick={(e) => {
													e.stopPropagation();
													select(
														value.filter(
															(val) =>
																val !==
																item.value
														)
													);
												}}
											/>
										</div>
									</div>
								);
							})}
						</div>
						{value.length > 0 && (
							<Icon
								onClick={(e) => {
									e.stopPropagation();
									select([]);
								}}
								className="cursor-pointer absolute right-0 mr-2"
								name="times"
								size="mili"
							/>
						)}
					</div>
				</div>
			}
		>
			<Dropdown.Menu>
				{menuItems.map((item) => {
					return (
						<Dropdown.Item
							key={item.value}
							selected={false}
							onClick={() => {
								select([...value, item.value]);
							}}
						>
							{item.label}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown.View>
	);
};
