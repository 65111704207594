import { Dimensions } from "@/modules";
import { useAnalyticsData } from "@/modules/AnalyticsData";
import { useFilters } from "@/modules/Filters";
import { useMetrics } from "@/modules/Metrics";
import { useReducer } from "react";
import { createStore } from "reusable";

export const useDimensionsStore = createStore(() =>
	useReducer(Dimensions.update, [])
);
export const useMetricsStore = createStore(() => useMetrics());
export const useFiltersStore = createStore(() => useFilters());
export const useAnalyticsDataStore = createStore(() => useAnalyticsData());
