import React from "react";

export const Cell = (props: {value?: number | string, isSource?: boolean, colSpan?: number, center?: boolean}) => {
    const { value, isSource = false, colSpan = 1, center = true } = props;
    return (
        <td
            className={`border px-4 py-2 group ${isSource ? "font-bold" : ""} ${center ? "text-center" : ""}`}
            colSpan={colSpan}
        >
            {value}
        </td>
    );
};

export const DifferenceCell = (props: {
    value?: number,
    sourceValue?: number,
    relative?: boolean,
    center?: boolean
}) => {
    const { sourceValue, value, relative = false, center = false } = props;

    return <td className={`border px-4 py-2 group ${center ? "text-center" : ""}`}>
        { sourceValue !== undefined && value !== undefined &&
            (relative
                ? <>{value > sourceValue && '+'}{(((value / sourceValue) - 1) * 100).toFixed(2)}%</>
                : <>{value > sourceValue && '+'}{Number((value - sourceValue).toFixed(2))}</>
            )
        }
    </td>;
};

export const GroupCell = (props: {rowSpan: number, value: string}) => {
    const {rowSpan, value} = props;
    return <td
        className="border px-4 py-2 border-r-2 border-gray-300"
        rowSpan={rowSpan}
    >
        {value}
    </td>;
};