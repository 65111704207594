import { useState, useMemo } from "react";

export const useSelect = <R extends { value: string; label: string }>(
	_options: R[] = [],
	_selectedValue: string = ""
) => {
	const [opened, setOpened] = useState(false);
	const [options, setOptions] = useState(_options);
	const [selectedValue, setSelectedValue] = useState(_selectedValue);

	const toggle = () => setOpened(!opened);
	const open = () => setOpened(true);
	const close = () => setOpened(false);

	const select = (value: string) =>
		setSelectedValue(
			options.find(item => item.value === value)?.value || ""
		);

	const selected = useMemo(
		() => options.find(item => item.value === selectedValue),
		[selectedValue, options]
	);

	return {
		opened,
		toggle,
		open,
		close,
		options,
		setOptions,
		selectedValue,
		select,
		selected
	};
};
