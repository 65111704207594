import React from "react";
import { Icon } from "./Icon";

type RefetchProps = {
	loading: boolean;
	refetch: () => void;
};

export const Refetch = ({ loading, refetch }: RefetchProps) => {
	return loading ? (
		<div className="btn-primary center-v gap-2">
			Refetch
			<Icon className="spin" name="spinner" weight="solid" />
		</div>
	) : (
		<div className="btn-primary center-v gap-2" onClick={refetch}>
			Refetch
			<Icon name="redo-alt" />
		</div>
	);
};
