import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { Icon } from "@/views";
import React from "react";
import { createUIKitMatcher, UIFieldProps } from ".";
import { useInt } from "./logic/useInt";

export const IntMatch = () =>
	createUIKitMatcher(({ field }) => field.type === "Int");

export const IntField = (props: UIFieldProps<any>) => {
	const {
		data,
		field,
		setValue,
		pushValue,
		removeArrayValue,
		setArrayValue,
	} = useInt(props);

	if (!field.array) {
		return (
			<Int
				name={camelCaseToWords(field.name)}
				onChange={setValue}
				value={data ?? ""}
			/>
		);
	}

	return (
		<div className="center-v">
			<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			<div className="h-10 center-v rounded-lg border-gray-500 border py-2 px-4">
				{(data || []).map((item: string, index: number) => {
					return (
						<div
							key={index}
							className="center-v p-2 mr-2 bg-gray-200 rounded"
						>
							<input
								className="w-20 outline-none px-1 text-xs bg-transparent"
								type="number"
								onChange={(e) => {
									e.preventDefault();
									setArrayValue(index, e.target.value);
								}}
								value={item ?? ""}
							/>
							<Icon
								className="cursor-pointer hover:font-bold"
								name="times"
								size="micro"
								onClick={(e) => {
									e.preventDefault();
									removeArrayValue(index);
								}}
							/>
						</div>
					);
				})}
				<Icon
					className="ml-2 hover:font-bold"
					name="plus"
					onClick={(e) => {
						e.preventDefault();
						pushValue();
					}}
				/>
			</div>
		</div>
	);
};

const Int = ({
	name,
	onChange,
	value,
}: {
	name: string;
	onChange: (val: string) => any;
	value: number | "";
}) => (
	<div className="center-v">
		<label htmlFor={name} className="w-48 mr-6">{name}</label>
		<input
			id={name}
			type="number"
			className="input w-100"
			onChange={(e) => {
				e.preventDefault();
				onChange(e.target.value);
			}}
			value={value}
		/>
	</div>
);
