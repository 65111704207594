// import { getRequest } from ".";

// const ENDPOINT = "/analytics-api";

export const getAnalyticsData = (props: any) => {
	return new Promise<Entry[]>((res, rej) => {
		setTimeout(() => {
			res(mock)
		}, 1500)
	});
	// return getRequest<Entry>(ENDPOINT, { params: { query: JSON.stringify(props) } });
};

type Entry = {
	entries: Entry[];
	fields: Field[];
};

type Field = {
	key: {
		value: string;
	};
	value: string;
};

const mock: Entry[] = [
	{
		entries: [],
		fields: [
			{
				key: { value: "project" },
				value: "Fok"
			},
			{
				key: { value: "clicks" },
				value: "72"
			},
			{
				key: { value: "installs" },
				value: "114"
			}
		]
	}
];
