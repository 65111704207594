import { useState } from "react";

export const useOpenable = (_opened: boolean = false) => {
	const [opened, setOpened] = useState(_opened);

	const toggle = () => setOpened(!opened);
	const open = () => setOpened(true);
	const close = () => setOpened(false);

	return { opened, toggle, open, close };
};
