import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { Icon } from "@/views";
import { Select } from "@/views/Select";
import { QueryResult } from "@apollo/client";
import React, { useEffect } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const WhereUniqueInputMatch = (uniqueInputType: string) =>
	createUIKitMatcher(({ path, field }) =>
		field.type.endsWith(uniqueInputType)
	);

export const WhereUniqueInputField = <
	R extends { id: string; name?: string; descriptiveName?: string },
	T extends string
>({
	useRemoteData,
	key,
}: {
	useRemoteData: () => QueryResult<{ [key in T]: R[] }>;
	key: T;
}) => ({ field, path, form, data }: UIFieldProps<R>) => {
	const fieldPath = path.concat(field.name).concat("id").join(".");

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const state = useRemoteData()

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (data) {
			return;
		}
		if (!!state.data) {
			form.setValue(fieldPath, state.data[key][0]?.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, fieldPath, form.setValue]);

	return (
		<div className="center-v" key={field.name}>
			<label htmlFor={field.name} className="w-48 mr-6">
				{camelCaseToWords(field.name)}
			</label>
			<Select
				label={({ value, selected, toggle }) => {
					return (
						<div
							className="center-v justify-between rounded-lg w-100 border-gray-500 border focus:outline-input py-2 px-4"
							onClick={toggle}
						>
							{selected ? selected.label : "Nothing selected"}
							<Icon name="chevron-down" />
						</div>
					);
				}}
				options={
					state.data
						? state.data[key].map((data) => ({
								label:
									data.name ||
									data.descriptiveName ||
									data.id,
								value: data.id,
						  }))
						: []
				}
				select={(value) => form.setValue(fieldPath, value)}
				value={data && data.id}
				className="cursor-pointer"
			/>
		</div>
	);
};
