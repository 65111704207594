import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useChartboostAccessesQuery = () =>
	useTypedQuery(
		{
			chartboostAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				ChartboostAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useChartboostAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			chartboostAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				ChartboostAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useChartboostAccessQuery = () =>
	useTypedLazyQuery(
		{
			chartboostAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				ChartboostAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateChartboostAccess = () => {
	return useTypedMutation({
		createChartboostAccess: [
			{
				data: $`data`,
			},
			ChartboostAccessSelector,
		],
	});
};
export const useUpdateChartboostAccess = () => {
	return useTypedMutation({
		updateChartboostAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			ChartboostAccessSelector,
		],
	});
};
export const useDeleteChartboostAccess = () => {
	return useTypedMutation({
		deleteChartboostAccess: [
			{
				where: $`where`,
			},
			ChartboostAccessSelector,
		],
	});
};

export const ChartboostAccessSelector = ZeusSelect<
	ValueTypes["ChartboostAccess"]
>()({
	id: true,
	descriptiveName: true,
	userId: true,
	userSignature: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
