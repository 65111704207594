import { createClass } from "@/reexports";
import * as React from "react";
import { unit } from "@/functions/unit";

type NavigationLinkProps = {
	onClick?: () => void;
	active: boolean;
	children: JSX.Element | JSX.Element[];
};

export const NavigationLink = ({
	onClick = unit,
	active,
	children,
}: NavigationLinkProps) => {
	return (
		<div
			className={createClass("cursor-pointer mr-6 last:mr-0", {
				"text-c-accent1": active,
			})}
			onClick={onClick}
		>
			{children}
		</div>
	);
};
