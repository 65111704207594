import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { useOrganizations } from "@/modules/Organizations/logic";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";
import { OrganizationRole } from "@/services/graphApi/graphql-zeus";
import { Dropdown, Icon } from "@/views";
import { Select } from "@/views/Select";
import React, { useMemo, useState } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const OrganizationAttributesMatch = () =>
	createUIKitMatcher(({ field }) => field.name === "organizationAttributes");

export const OrganizationAttributesField = (
	props:
		| UIFieldProps<{
				create: OrganizationAttribute[];
				delete: { id: string }[];
				update: OrganizationAttribute[];
		  }>
		| UIFieldProps<OrganizationAttribute[]>
) => {
	if (props.field.type === "OrganizationAttributeUpdateManyInput") {
		return (
			<OrganizationAttributesUpdateManyInputField
				{...(props as UIFieldProps<{
					create: OrganizationAttribute[];
					delete: { id: string }[];
					update: OrganizationAttribute[];
				}>)}
			/>
		);
	}

	return (
		<OrganizationAttributesCreateInputField
			{...(props as UIFieldProps<OrganizationAttribute[]>)}
		/>
	);
};

export const OrganizationAttributesCreateInputField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<OrganizationAttribute[]>) => {
	const fieldPath = path.concat(field.name).join(".");
	const [activeOrganization, setActiveOrganization] = useState<string>();
	const rolesInput = useOpenable(false);
	const rolesInputDropdown = useOpenable(false);

	const { getById, state } = useOrganizations();
	const options = useMemo(() => {
		if (state.data) {
			return state.data.organizations.map((organization) => {
				return {
					value: organization.id,
					label: organization.name,
				};
			});
		}
		return [];
	}, [state.data]);

	const uniqueOrganizationAttributesByOrganization = useMemo(() => {
		if (!data || data.length === 0) {
			return [];
		}
		return data.reduce((acc, item) => {
			if (
				!acc.some(
					(attr) => attr.organization.id === item.organization.id
				)
			) {
				acc.push(item);
			}
			return acc;
		}, [] as OrganizationAttribute[]);
	}, [data]);

	const selectedOrganizationAttributes = useMemo(() => {
		if (!activeOrganization || !data) {
			return [];
		}
		return data.filter(
			(item) => item.organization.id === activeOrganization
		);
	}, [data, activeOrganization]);

	return (
		<div className="center-v">
			<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			<div>
				<div className="min-h-10 center-v flex-wrap w-fit max-w-154 gap-2 rounded-lg border-gray-500 border py-2 px-4">
					{uniqueOrganizationAttributesByOrganization &&
						uniqueOrganizationAttributesByOrganization.map(
							(item, index) => {
								return (
									<OrganizationItem
										key={index}
										selected={item.organization.id || ""}
										active={
											item.organization.id ===
											activeOrganization
										}
										options={[
											{
												value: item.organization.id,
												label: getById(
													item.organization.id
												).name,
											},
										].concat(
											options.filter(
												(option) =>
													!uniqueOrganizationAttributesByOrganization.find(
														(attr) =>
															attr.organization
																.id ===
															option.value
													)
											)
										)}
										onItem={() => {
											if (
												!activeOrganization ||
												activeOrganization !==
													item.organization.id
											) {
												setActiveOrganization(
													item.organization.id
												);
												rolesInput.open();
												return;
											}
											setActiveOrganization(undefined);
											rolesInput.close();
										}}
										onRemove={() => {
											form.setValue(
												fieldPath,
												data.filter(
													(val) =>
														val.organization.id !==
														item.organization.id
												)
											);
											setActiveOrganization(undefined);
											rolesInput.close();
										}}
										onSelect={(organizationId) => {
											form.setValue(
												fieldPath,
												data.map((val) =>
													val.organization.id ===
													item.organization.id
														? {
																...val,
																organization: {
																	id: organizationId,
																},
														  }
														: val
												)
											);
										}}
									/>
								);
							}
						)}
					<Select
						options={
							state.data?.organizations
								.filter(
									(org) =>
										!data ||
										!data.some(
											(item) =>
												org.id === item.organization.id
										)
								)
								.map((item) => ({
									label: item.name,
									value: item.id,
								})) || []
						}
						label={({ toggle, options }) => (
							<Icon
								className="ml-2 hover:font-bold"
								name="plus"
								onClick={() => {
									if (options.length === 0) {
										return;
									}
									toggle();
								}}
							/>
						)}
						select={(value) => {
							form.pushFieldValue(fieldPath, {
								organization: { id: value },
								role: OrganizationRole.ADMIN,
							});
						}}
					/>
				</div>
				{rolesInput.opened && (
					<Dropdown.View
						key={activeOrganization}
						opened={rolesInputDropdown.opened}
						onClickOutside={rolesInputDropdown.close}
						label={
							<div
								className="center-v justify-between relative min-h-10 rounded-lg border-gray-500 border py-2 px-4 mt-2"
								onClick={rolesInputDropdown.toggle}
							>
								<div className="center-v flex-wrap gap-2 mr-4">
									{selectedOrganizationAttributes.map(
										(item) => {
											return (
												<div
													key={item.role}
													className="center-v px-2 py-1 text-xs rounded-lg bg-gray-200"
													onClick={(e) =>
														e.stopPropagation()
													}
												>
													<div className="center-v">
														<div className="mr-2">
															{item.role}
														</div>
														<Icon
															className="cursor-pointer hover:font-bold"
															name="times"
															size="micro"
															onClick={(e) => {
																e.stopPropagation();
																form.setValue(
																	fieldPath,
																	data.filter(
																		(
																			value
																		) =>
																			value
																				.organization
																				.id !==
																				item
																					.organization
																					.id ||
																			value.role !==
																				item.role
																	)
																);
															}}
														/>
													</div>
												</div>
											);
										}
									)}
								</div>
								{selectedOrganizationAttributes.length > 0 && (
									<Icon
										className="cursor-pointer hover:font-bold absolute right-0 mr-2"
										name="times"
										onClick={(e) => {
											e.stopPropagation();
											form.setValue(
												fieldPath,
												data.filter(
													(value) =>
														value.organization
															.id !==
														activeOrganization
												)
											);
											setActiveOrganization(undefined);
											rolesInput.close();
										}}
									/>
								)}
							</div>
						}
					>
						<Dropdown.Menu>
							{Object.values(OrganizationRole)
								.filter(
									(role) =>
										!selectedOrganizationAttributes.some(
											(item) => item.role === role
										)
								)
								.map((role) => {
									return (
										<Dropdown.Item
											key={role}
											onClick={() => {
												form.pushFieldValue(fieldPath, {
													organization: {
														id: activeOrganization,
													},
													role,
												});
												rolesInputDropdown.close();
											}}
										>
											{role}
										</Dropdown.Item>
									);
								})}
						</Dropdown.Menu>
					</Dropdown.View>
				)}
			</div>
		</div>
	);
};

export const OrganizationAttributesUpdateManyInputField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<{
	create: OrganizationAttribute[];
	delete: { id: string }[];
	update: OrganizationAttribute[];
}>) => {
	const fieldPath = path.concat(field.name).join(".");
	const createPath = path.concat(field.name, "create").join(".");
	const deletePath = path.concat(field.name, "delete").join(".");
	const updatePath = path.concat(field.name, "update").join(".");

	const [activeOrganization, setActiveOrganization] = useState<string>();
	const rolesInput = useOpenable(false);
	const rolesInputDropdown = useOpenable(false);

	const { getById, state } = useOrganizations();
	const options = useMemo(() => {
		if (state.data) {
			return state.data.organizations.map((organization) => {
				return {
					value: organization.id,
					label: organization.name,
				};
			});
		}
		return [];
	}, [state.data]);

	const uniqueOrganizationAttributesByOrganization = useMemo(() => {
		if (!data) {
			return [];
		}
		if (data.update.length === 0 && data.create.length === 0) {
			return [];
		}
		return data.update.concat(data.create).reduce((acc, item) => {
			if (
				!acc.some(
					(attr) => attr.organization.id === item.organization.id
				)
			) {
				acc.push(item);
			}
			return acc;
		}, [] as OrganizationAttribute[]);
	}, [data]);

	const selectedOrganizationAttributes = useMemo(() => {
		if (!activeOrganization || !data) {
			return [];
		}
		return data.update
			.concat(data.create)
			.filter((item) => item.organization.id === activeOrganization);
	}, [data, activeOrganization]);

	return (
		<div className="center-v">
			<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			<div>
				<div className="min-h-10 center-v flex-wrap w-100 gap-2 rounded-lg border-gray-500 border py-2 px-4">
					{uniqueOrganizationAttributesByOrganization &&
						uniqueOrganizationAttributesByOrganization.map(
							(item, index) => {
								return (
									<OrganizationItem
										key={index}
										selected={item.organization.id || ""}
										active={
											item.organization.id ===
											activeOrganization
										}
										options={[
											{
												value: item.organization.id,
												label: getById(
													item.organization.id
												).name,
											},
										].concat(
											options.filter(
												(option) =>
													!uniqueOrganizationAttributesByOrganization.find(
														(attr) =>
															attr.organization
																.id ===
															option.value
													)
											)
										)}
										onItem={() => {
											if (
												!activeOrganization ||
												activeOrganization !==
													item.organization.id
											) {
												setActiveOrganization(
													item.organization.id
												);
												rolesInput.open();
												return;
											}
											setActiveOrganization(undefined);
											rolesInput.close();
										}}
										onRemove={() => {
											form.setValue(fieldPath, {
												delete: data.update
													.filter(
														(val) =>
															val.organization
																.id ===
															item.organization.id
													)
													.map(({ id }) => ({
														id,
													})),
												create: data.create.filter(
													(val) =>
														val.organization.id !==
														item.organization.id
												),
												update: data.update.filter(
													(val) =>
														val.organization.id !==
														item.organization.id
												),
											});
											setActiveOrganization(undefined);
											rolesInput.close();
										}}
										onSelect={(organizationId) => {
											form.setValue(
												updatePath,
												data.update.map((val) =>
													val.organization.id ===
													item.organization.id
														? {
																...val,
																organization: {
																	id: organizationId,
																},
														  }
														: val
												)
											);
											form.setValue(
												createPath,
												data.create.map((val) =>
													val.organization.id ===
													item.organization.id
														? {
																...val,
																organization: {
																	id: organizationId,
																},
														  }
														: val
												)
											);
										}}
									/>
								);
							}
						)}
					<Select
						options={
							state.data?.organizations
								.filter(
									(org) =>
										!data.update
											.concat(data.create)
											.some(
												(item) =>
													org.id ===
													item.organization.id
											)
								)
								.map((item) => ({
									label: item.name,
									value: item.id,
								})) || []
						}
						label={({ toggle, options }) => (
							<Icon
								className="ml-2 hover:font-bold"
								name="plus"
								onClick={() => {
									if (options.length === 0) {
										return;
									}
									toggle();
								}}
							/>
						)}
						select={(value) => {
							form.pushFieldValue(createPath, {
								organization: { id: value },
								role: OrganizationRole.ADMIN,
							});
						}}
					/>
				</div>
				{rolesInput.opened && (
					<Dropdown.View
						key={activeOrganization}
						opened={rolesInputDropdown.opened}
						onClickOutside={rolesInputDropdown.close}
						label={
							<div
								className="center-v justify-between relative min-h-10 rounded-lg border-gray-500 border py-2 px-4 mt-2"
								onClick={rolesInputDropdown.toggle}
							>
								<div className="center-v flex-wrap gap-2 mr-4">
									{selectedOrganizationAttributes.map(
										(item) => {
											return (
												<div
													key={item.role}
													className="center-v px-2 py-1 text-xs rounded-lg bg-gray-200"
													onClick={(e) =>
														e.stopPropagation()
													}
												>
													<div className="center-v">
														<div className="mr-2">
															{item.role}
														</div>
														<Icon
															className="cursor-pointer hover:font-bold"
															name="times"
															size="micro"
															onClick={(e) => {
																e.stopPropagation();
																if (item.id) {
																	form.pushFieldValue(
																		deletePath,
																		{
																			id:
																				item.id,
																		}
																	);
																}

																form.setValue(
																	updatePath,
																	data.update.filter(
																		(
																			value
																		) =>
																			value
																				.organization
																				.id !==
																				item
																					.organization
																					.id ||
																			value.role !==
																				item.role
																	)
																);
																form.setValue(
																	createPath,
																	data.create.filter(
																		(
																			value
																		) =>
																			value
																				.organization
																				.id !==
																				item
																					.organization
																					.id ||
																			value.role !==
																				item.role
																	)
																);
															}}
														/>
													</div>
												</div>
											);
										}
									)}
								</div>
								{selectedOrganizationAttributes.length > 0 && (
									<Icon
										className="cursor-pointer hover:font-bold absolute right-0 mr-2"
										name="times"
										onClick={(e) => {
											e.stopPropagation();
											form.setValue(fieldPath, {
												delete: data.delete.concat(
													selectedOrganizationAttributes
														.filter(
															(item) => !!item.id
														)
														.map((item) => ({
															id: item.id as string,
														}))
												),
												create: data.create.filter(
													(value) =>
														value.organization
															.id !==
														activeOrganization
												),
												update: data.update.filter(
													(value) =>
														value.organization
															.id !==
														activeOrganization
												),
											});
											setActiveOrganization(undefined);
											rolesInput.close();
										}}
									/>
								)}
							</div>
						}
					>
						<Dropdown.Menu>
							{Object.values(OrganizationRole)
								.filter(
									(role) =>
										!selectedOrganizationAttributes.some(
											(item) => item.role === role
										)
								)
								.map((role) => {
									return (
										<Dropdown.Item
											key={role}
											onClick={() => {
												form.pushFieldValue(
													createPath,
													{
														organization: {
															id: activeOrganization,
														},
														role,
													}
												);
												rolesInputDropdown.close();
											}}
										>
											{role}
										</Dropdown.Item>
									);
								})}
						</Dropdown.Menu>
					</Dropdown.View>
				)}
			</div>
		</div>
	);
};

const OrganizationItem = (props: {
	options: { label: string; value: string }[];
	selected: string;
	onItem: () => void;
	onSelect: (selected: string) => void;
	onRemove: () => void;
	active: boolean;
}) => {
	const { active, options, selected, onItem, onSelect, onRemove } = props;
	const classes = createClass("center-v p-2 bg-gray-200 rounded zoom-05", {
		"bg-blue-200": active,
	});
	return (
		<div className={classes}>
			<Select
				className="cursor-pointer"
				options={options}
				label={(props) => {
					return (
						<div className="center-v text-xs mr-2" onClick={onItem}>
							<Icon
								className="mr-2 hover:font-bold"
								name="pen"
								size="micro"
								onClick={(e) => {
									e.stopPropagation();
									props.toggle();
								}}
							/>
							<div className="">
								{props.selected ? props.selected.label : ""}
							</div>
						</div>
					);
				}}
				select={onSelect}
				value={selected}
			/>
			<Icon
				className="cursor-pointer hover:font-bold"
				name="times"
				size="micro"
				onClick={onRemove}
			/>
		</div>
	);
};

type OrganizationAttribute = {
	id?: string;
	organization: { id: string };
	role: OrganizationRole;
};
