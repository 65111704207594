import { OrganizationAttributeSelector } from "@/modules/Organizations/logic";
import { useCurrentOrganization } from "@/modules/Organizations/logic/Current";
import { useSerialization } from "@/modules/Serialization";
import { useDebouncedValue } from "@/modules/useDebouncedValue";
import { usePagination } from "@/modules/usePagination";
import { useTypedLazyQuery, useTypedMutation } from "@/services/graphApi";
import {
	$,
	Feed,
	IntegrationFeedConfigOrderByInput,
	ValueTypes,
	Zeus,
	ZeusSelect,
} from "@/services/graphApi/graphql-zeus";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import {
	IntegrationFeedParamsSelector,
	useFeedTypes,
} from "../../IntegrationFeedParams/logic";

const useConfigFilters = () => {
	const [feedTypes, setFeedTypes] = useFeedTypes({
		serializationKey: "showConfigs",
	});
	const [search, setSearch] = useState<string>("");
	const [enabled, setEnabled] = useState<boolean>(true);

	const toggleEnabled = useCallback(() => {
		setEnabled((e) => !e);
	}, []);

	useSerialization({
		equals: (query) => query.search_configs === search,
		deserialize: (query) => {
			setSearch(query.search_configs || "");
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				search_configs: search || undefined,
			});
		},
	});

	useSerialization({
		equals: (query) => query.enabled_configs === enabled,
		deserialize: (query) => {
			if (query.enabled_configs === false) {
				setEnabled(false);
				return;
			}
			setEnabled(true);
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				enabled_configs: enabled,
			});
		},
	});

	return {
		feedTypes,
		setFeedTypes,
		search,
		setSearch,
		enabled,
		toggleEnabled,
	};
};

export const useIntegrationFeedConfigs = () => {
	const { currentOrganization } = useCurrentOrganization();
	const [selected, select] = useState<any>();
	const pagination = usePagination();
	const filters = useConfigFilters();

	const [getConfigs, state] = useIntegrationFeedConfigsLazyQuery();

	const { enabled, search, feedTypes } = filters;
	const debouncedSearch = useDebouncedValue(search, 300);
	const debouncedFeedTypes = useDebouncedValue(feedTypes, 500);

	const { reset } = pagination;
	useEffect(() => {
		reset();
	}, [
		enabled,
		debouncedSearch,
		debouncedFeedTypes,
		currentOrganization,
		reset,
	]);

	useEffect(() => {
		getConfigs({
			variables: {
				...pagination.pagination,
				enabled,
				feedTypes: debouncedFeedTypes,
				search: debouncedSearch,
				organizationId: currentOrganization?.id || "",
			},
		});
	}, [
		currentOrganization,
		enabled,
		debouncedSearch,
		debouncedFeedTypes,
		getConfigs,
		pagination.pagination,
	]);

	useEffect(() => {
		pagination.setCount(
			state.data?.integrationFeedConfigsConnection.aggregate.count || 0
		);
		pagination.setLoading(state.loading);
		pagination.setError(state.error && JSON.stringify(state.error));
		pagination.setPageInfo(
			state.data?.integrationFeedConfigsConnection.pageInfo
		);
	}, [pagination, state.data, state.error, state.loading]);

	const getItem = (id: string) =>
		state.data?.integrationFeedConfigsConnection.edges.find(
			(item) => item.node.id === id
		);

	const deselect = () => {
		select(undefined);
	};

	return {
		state,
		getItem,
		selected,
		select,
		deselect,
		pagination,
		filters,
	};
};

const useIntegrationFeedConfigsLazyQuery = () =>
	useTypedLazyQuery(
		{
			integrationFeedConfigsConnection: [
				{
					first: $`first`,
					last: $`last`,
					before: $`before`,
					after: $`after`,
					skip: $`skip`,
					orderBy: IntegrationFeedConfigOrderByInput.createdAt_DESC,
					where: {
						params: {
							feedType_in: $`feedTypes`,
							descriptiveName_contains: $`search`,
						},
						enabled: $`enabled`,
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				IntegrationFeedConfigConnection(),
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

const IntegrationFeedConfigConnection = () =>
	ZeusSelect<ValueTypes["IntegrationFeedConfigConnection"]>()({
		aggregate: {
			count: true,
		},
		edges: {
			node: {
				id: true,
				createdAt: true,
				updatedAt: true,
				params: {
					descriptiveName: true,
					feedType: true,
				},
			},
			cursor: true,
		},
		pageInfo: {
			hasNextPage: true,
			hasPreviousPage: true,
			startCursor: true,
			endCursor: true,
		},
	});

export const useCreateIntegrationFeedConfig = (feedType: Feed) => {
	return useTypedMutation(
		{
			createIntegrationFeedConfig: [
				{
					data: $`data`,
				},
				IntegrationFeedConfigSelector(feedType),
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);
};

export const useUpdateIntegrationFeedConfig = (feedType: Feed) => {
	return useTypedMutation(
		{
			updateIntegrationFeedConfig: [
				{
					where: $`where`,
					data: $`data`,
				},
				IntegrationFeedConfigSelector(feedType),
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);
};

const deleteQuery = gql(
	Zeus.mutation({
		deleteIntegrationFeedConfig: [
			{
				where: $`where`,
			},
			{
				id: true,
			},
		],
	})
);
export const useDeleteIntegrationFeedConfig = () => {
	return useMutation(deleteQuery);
};

export const IntegrationFeedConfigSelector = (feedType: Feed) =>
	ZeusSelect<ValueTypes["IntegrationFeedConfig"]>()({
		__typename: undefined,
		id: true,
		createdAt: true,
		updatedAt: true,
		eligibleAt: true,
		enabled: true,
		params: IntegrationFeedParamsSelector(feedType),
		organizationAttributes: [{}, OrganizationAttributeSelector()],
	});
