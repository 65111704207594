import React, { useEffect } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";
import { Select } from "@/views/Select";
import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { Icon } from "@/views";

export const EnumTypeMatch = (enumType: string) =>
	createUIKitMatcher(
		({ field }) => field.type === "enum" && field.enumType === enumType
	);

export const EnumTypeField = (
	enumeration: { [s: string]: string },
	defaultChoice?: string
) => ({ field, path, form, data }: UIFieldProps<string>) => {
	const fieldPath = path.concat(field.name).join(".");

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (data) {
			return;
		}
		form.setValue(
			fieldPath,
			defaultChoice || Object.values(enumeration)[0]
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="center-v" key={field.name}>
			<label htmlFor={field.name} className="w-48 mr-6">
				{camelCaseToWords(field.name)}
			</label>
			<Select
				label={({ value, selected, toggle }) => {
					return (
						<div
							className="center-v justify-between rounded-lg w-100 border-gray-500 border focus:outline-input py-2 px-4"
							onClick={toggle}
						>
							{selected ? selected.label : "Nothing selected"}
							<Icon name="chevron-down" />
						</div>
					);
				}}
				options={Object.keys(enumeration).map((role) => ({
					label: role,
					value: role,
				}))}
				select={(value) => form.setValue(fieldPath, value)}
				value={data || defaultChoice || Object.values(enumeration)[0]}
				className="cursor-pointer"
			/>
		</div>
	);
};
