import { useSerialization } from "@/modules/Serialization";
import { useCallback, useState } from "react";
import { createStore } from "reusable";
import { useOrganizationsLazyQuery } from ".";

export const useCurrentOrganization = createStore(() => {
	const [, state] = useOrganizationsLazyQuery();
	const [currentOrganization, _setCurrentOrganization] = useState<
		Organization | undefined
	>(undefined);

	const setCurrentOrganization = useCallback(
		(organizationId: string) => {
			const organizationName = state.data
				? (state.data.organizations.find(
						(organization) => organization.id === organizationId
				  )?.name as string)
				: "Loading";

			_setCurrentOrganization({
				id: organizationId,
				name: organizationName,
			});
		},
		[state]
	);

	useSerialization({
		equals: (query) => query.organization === currentOrganization?.id,
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				organization: currentOrganization?.id,
			});
		},
		deserialize: (query) => {
			setCurrentOrganization(query?.organization);
		},
	});

	return {
		currentOrganization,
		setCurrentOrganization,
	};
});

type Organization = {
	id: string;
	name: string;
};
