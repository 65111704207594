import { enumToField } from "@/modules/Etl/IntegrationFeedParams/logic";
import { Field } from "@/services/graphApi";
import React from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const IntegrationFeedParamsCreateInputMatch = () =>
	createUIKitMatcher(
		({ field }) =>
			field.type === "IntegrationFeedParamsCreateInput" ||
			field.type === "IntegrationFeedParamsUpdateInput"
	);

export const IntegrationFeedParamsCreateInputField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<any>) => {
	return (
		<>
			{field.fields?.filter(generalParamsFields).map((fieldItem) => {
				return (
					<UIField
						key={fieldItem.name}
						field={fieldItem}
						form={form}
						data={data && data[fieldItem.name]}
						path={path.concat(field.name)}
						uiKit={uiKit}
						UIField={UIField}
					/>
				);
			})}
			{field.fields
				?.filter((item) => item.name === enumToField(data.feedType))
				.map((fieldItem) => {
					return (
						<UIField
							key={fieldItem.name}
							field={fieldItem}
							form={form}
							data={data && data[fieldItem.name]}
							path={path.concat(field.name)}
							uiKit={uiKit}
							UIField={UIField}
						/>
					);
				})}
		</>
	);
};

const generalParamsFields = (field: Field) =>
	field.name === "dateInput" ||
	field.name === "descriptiveName" ||
	field.name === "s3Destinations" ||
	field.name === "bqDestinations";
