import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { Icon } from "@/views";
import React from "react";
import { createUIKitMatcher, UIFieldProps } from ".";
import { useString } from "./logic/useString";

export const StringMatch = () =>
	createUIKitMatcher(({ field }) => field.type === "String");

export const StringField = <R extends string | string[]>(
	props: UIFieldProps<R> & { label?: JSX.Element | null }
) => {
	const {
		field,
		data,
		pushValue,
		removeArrayValue,
		setArrayValue,
		setValue,
	} = useString(props);

	if (!field.array) {
		return (
			<StringInput
				name={camelCaseToWords(field.name)}
				onChange={setValue}
				value={(data as string) || ""}
			/>
		);
	}

	return (
		<div className="center-v">
			{props.label !== undefined ? (
				props.label
			) : (
				<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			)}
			<div className="min-h-10 center-v flex-wrap gap-2 w-100 rounded-lg border-gray-500 border py-2 px-4">
				{((data as string[]) || []).map((item, index) => {
					return (
						<div
							key={index}
							className="center-v p-2 bg-gray-200 rounded"
						>
							<input
								className="w-20 outline-none px-1 text-xs bg-transparent"
								onChange={(e) => {
									e.preventDefault();
									setArrayValue(
										index,
										e.target.value.replace(/\\n/g, "\n")
									);
								}}
								value={item.replace(/\n/g, "\\n")}
							/>
							<Icon
								className="cursor-pointer hover:font-bold"
								name="times"
								size="micro"
								onClick={(e) => {
									e.preventDefault();
									removeArrayValue(index);
								}}
							/>
						</div>
					);
				})}
				<Icon
					className="ml-2 hover:font-bold"
					name="plus"
					onClick={(e) => {
						e.preventDefault();
						pushValue();
					}}
				/>
			</div>
		</div>
	);
};

const StringInput = ({
	name,
	onChange,
	value,
}: {
	name: string;
	onChange: (val: string) => any;
	value: string;
}) => (
	<div className="center-v">
		<label htmlFor={name} className="w-48 mr-6">
			{name}
		</label>
		<input
			id={name}
			className="input w-100"
			onChange={(e) => {
				e.preventDefault();
				onChange(e.target.value.replace(/\\n/g, "\n"));
			}}
			value={value.replace ? value.replace(/\n/g, "\\n") : ''}
		/>
	</div>
);
