import { useIntegrationFeedInstances } from "@/modules/Etl/IntegrationFeedInstances/logic";
import {
	FeedMultiselect,
	getFeedOptions,
} from "@/modules/Etl/IntegrationFeedParams/ui/FeedMultiselect";
import { PageControls, PageInfo, PageSizeDropdown } from "@/modules/Pagination";
import { Route } from "@/modules/Router";
import { useOpenable } from "@/modules/useOpenable";
import { useSelect } from "@/modules/useSelect";
import {
	Feed,
	InstanceState,
	IntegrationFeedInstance,
} from "@/services/graphApi/graphql-zeus";
import { Dropdown, Icon, Modal } from "@/views";
import { DatePicker } from "@/views/DatePicker/DatePicker";
import { Refetch } from "@/views/Refetch";
import { Searchbar } from "@/views/Searchbar";
import { Select } from "@/views/Select";
import React, { useMemo, useState } from "react";
import { IntegrationFeedInstanceDetailExisting } from "./Detail/Existing";
import { IntegrationFeedInstanceDetailNew } from "./Detail/New";
import { InstanceTile } from "./Tile";

export const View = () => {
	const { state, pagination, filters } = useIntegrationFeedInstances();

	const instanceStateSelect = useSelect(
		Object.keys(InstanceState)
			.filter((state) => state !== InstanceState.RETRYING)
			.map((state) => ({
				label: state,
				value: state,
			}))
	);

	return (
		<div className="children:px-4 flex flex-col gap-2">
			<FeedMultiselect
				feeds={filters.feedTypes}
				setFeeds={filters.setFeedTypes}
			/>
			<div className="center-v gap-2">
				<Searchbar
					className="w-100 bg-white"
					onChange={(e) => filters.setSearch(e.target.value)}
					value={filters.search}
					placeholder="Search by Name(case sensitive)"
				/>
				<DatePicker
					className="input"
					placeholderText="Created after"
					selected={
						filters.createdAfter
							? new Date(filters.createdAfter)
							: null
					}
					dateFormat="yyyy-MM-dd"
					isClearable
					onChange={(date) =>
						filters.setCreatedAfter(date || undefined)
					}
				/>
				<DatePicker
					className="input"
					placeholderText="Created before"
					selected={
						filters.createdBefore
							? new Date(filters.createdBefore)
							: null
					}
					dateFormat="yyyy-MM-dd"
					isClearable
					onChange={(date) =>
						filters.setCreatedBefore(date || undefined)
					}
				/>
				<Select
					{...instanceStateSelect}
					value={filters.instanceState}
					select={(value) =>
						filters.setInstanceState(value as InstanceState)
					}
					label={({ toggle, selected }) => {
						return (
							<div
								className="input cursor-pointer center-v gap-2"
								onClick={toggle}
							>
								{selected?.label}
								<Icon name="chevron-down" />
							</div>
						);
					}}
				/>
			</div>
			<div className="center-v gap-2">
				<PageSizeDropdown {...pagination} />
				<PageControls {...pagination} />
				<PageInfo {...pagination} />
			</div>
			<div className="flex gap-4">
				<CreateNewInstanceButton />
				<Refetch
					loading={state.loading}
					refetch={() => state.refetch && state.refetch()}
				/>
			</div>
			{state.error && (
				<div className="bg-danger opacity-25 text-white p-2 rounded">
					Error: {JSON.stringify(state.error.graphQLErrors)}
				</div>
			)}
			<div className="pb-2 overflow-overlay max-h-200 flex flex-wrap gap-4">
				{state.data &&
					state.data.integrationFeedInstancesConnection.edges
						.map((item) => item?.node as IntegrationFeedInstance)
						.map((item) => {
							return (
								<InstanceTile
									key={item.id}
									item={item}
									refetch={() =>
										state.refetch && state.refetch()
									}
								/>
							);
						})}
			</div>
			<div className="center">
				<div className="center-v gap-2">
					<PageControls {...pagination} />
				</div>
			</div>
			<Route path="etl/instances/:feedType/:id">
				{({ feedType, id }) => (
					<IntegrationFeedInstanceDetailExisting
						item={{ id, params: { feedType } }}
					/>
				)}
			</Route>
		</div>
	);
};

const CreateNewInstanceButton = () => {
	const dropdown = useOpenable(false);
	const newInstance = useOpenable(false);
	const [feedType, setFeedType] = useState<Feed>();

	const options = useMemo(() => getFeedOptions(), []);

	return (
		<>
			<Dropdown.View
				label={
					<div className="btn-primary" onClick={dropdown.toggle}>
						Create new
					</div>
				}
				onClickOutside={dropdown.close}
				opened={dropdown.opened}
			>
				<Dropdown.Menu>
					{options.map((option) => {
						return (
							<Dropdown.Item
								key={option.value}
								onClick={() => {
									dropdown.close();
									newInstance.open();
									setFeedType(option.value);
								}}
							>
								<span>{option.label}</span>
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown.View>
			{newInstance.opened && feedType && (
				<Modal
					className="w-modal h-modal"
					isOpened={newInstance.opened}
					onClose={newInstance.close}
				>
					<IntegrationFeedInstanceDetailNew
						item={{
							params: {
								feedType,
							},
						}}
						close={newInstance.close}
					/>
				</Modal>
			)}
		</>
	);
};
