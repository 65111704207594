import { createClass } from "@/reexports";
import React from "react";
import { Icon } from "./Icon";

type Props = {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	placeholder?: string;
	className?: string;
};

export const Searchbar = ({
	className,
	onChange,
	value,
	placeholder,
}: Props) => {
	const classes = createClass(className, "center-v gap-2 input");

	return (
		<div className={classes}>
			<Icon name="search" />
			<input
				className="w-full bg-transparent outline-none"
				onChange={onChange}
				value={value}
				placeholder={placeholder}
			/>
		</div>
	);
};
