import {
	useCreateOrganization,
	useOrganizationsQuery,
} from "@/modules/Organizations/logic";
import { notifySuccess } from "@/services/notification";
import { LoadingIcon } from "@/ui/views/LoadingIcon";
import React, { useState } from "react";
import { WorkspaceTile } from "./Tile";

export const Workspaces = () => {
	const state = useOrganizationsQuery();

	if (state.loading)
		return (
			<LoadingIcon
				className="center h-full text-blue-300"
				size="gigantic"
			/>
		);

	return (
		<div className="m-4">
			<div className="mb-4">
				<CreateWorkspace />
			</div>
			<div className="flex flex-wrap gap-4">
				{state.data?.organizations.map((item) => {
					return <WorkspaceTile key={item.id} id={item.id} />;
				})}
			</div>
		</div>
	);
};

const CreateWorkspace = () => {
	const workspaces = useOrganizationsQuery();
	const [create, createState] = useCreateOrganization();
	const [name, setName] = useState("");

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (!name) return;
				create({
					variables: { name },
					update: () => {
						notifySuccess(`${name} workspace was created`);
						setName("");
						workspaces.refetch();
					},
				});
			}}
			className="w-80 relative center-v gap-2 input"
		>
			<input
				className="w-full bg-transparent outline-none"
				onChange={(e) => setName(e.target.value)}
				value={name}
				type="text"
				placeholder="workspace name..."
			/>
			{createState.loading ? (
				<LoadingIcon />
			) : (
				<button
					type="submit"
					className="right-0 transform -translate-x-1 absolute text-sm btn-primary"
				>
					Create
				</button>
			)}
		</form>
	);
};
