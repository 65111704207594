import { unit } from "@/functions/unit";
import { createClass } from "@/reexports";
import { Icon } from "@/views";
import * as React from "react";

type ViewProps = {
	onIconClick: typeof unit;
	opened: boolean;
	children?: JSX.Element;
};

export const View = ({ onIconClick, opened, children }: ViewProps) => {
	const classes = createClass(
		"sidebar-layout",
		"h-full",
		"bg-sidebar",
		"transition-all duration-300",
		opened ? "min-w-64" : "min-w-6"
	);

	const toggleClasses = createClass(
		opened ? "mr-2" : "mr-1 pr-px"
	);

	return (
		<div className={classes}>
			<div className="center-v justify-end h-header-height">
				<div className={toggleClasses}>
				{/* <div className=""> */}
					<Icon
						className="cursor-pointer text-sidebar-font-light"
						name="bars"
						onClick={e => {
							e.stopPropagation();
							onIconClick();
						}}
					/>
				</div>
			</div>
			{opened ? children : null}
		</div>
	);
};

export const Section = ({ children }: { children: JSX.Element }) => {
	return (
		<div className="pb-4 pt-2 border-b-2 border-sidebar-font-dark last:border-b-0">
			{children}
		</div>
	);
};

type LabelProps = {
	className?: string;
	icon?: JSX.Element | null;
	label: string | JSX.Element;
	onChevron: typeof unit;
	opened: boolean;
	empty: boolean;
};

export const Label = ({
	className = "",
	onChevron = unit,
	icon,
	label,
	opened = false,
	empty = false
}: LabelProps) => {
	const classes = createClass(
		className,
		"pl-6 override:text-sidebar-font-dark"
	);
	const rotateClass = createClass("transition-03 transform", {
		"-rotate-180": opened
	});

	const chevronClick = () => {
		if (!empty) onChevron();
	};

	return (
		<Item
			className={classes}
			onClick={chevronClick}
			icon={
				empty ? (
					<Icon
						className="pointer-events-none"
						name="circle"
						weight="solid"
						size="pico"
					/>
				) : (
					<Icon
						className={rotateClass}
						name="chevron-down"
						size="nano"
					/>
				)
			}
			onIconClick={chevronClick}
			hasEdit={false}
		>
			<div className="center-v nowrap w-full">
				<div className="center-v mr-2">{icon}</div>
				{label}
			</div>
		</Item>
	);
};

type ItemProps = {
	className?: string;
	onClick?: typeof unit;
	children: React.ReactChild;
	icon?: JSX.Element | null;
	onIconClick?: typeof unit;
	hasEdit?: boolean;
};

export const Item = ({
	className = "",
	onClick = unit,
	children,
	icon = null,
	onIconClick = unit,
	hasEdit = true
}: ItemProps) => {
	const classes = createClass(
		className,
		"group hover:bg-sidebar-item-hover",
		"relative center-v py-2 pr-9 text-sidebar-font-light"
	);

	const editClass = createClass(
		"hidden ai-center absolute text-sidebar-font-light translate-x-2 transform",
		{
			"group-hover:flex": hasEdit
		}
	);

	return (
		<div className={classes} onClick={onClick}>
			<div className={editClass}>
				<Icon name="pen" size="nano" />
			</div>
			{children}
			<div
				className="absolute flex right-0 transform -translate-x-4 cursor-pointer text-sidebar-font-dark"
				onClick={e => {
					e.stopPropagation();
					return onIconClick();
				}}
			>
				{icon}
			</div>
		</div>
	);
};
