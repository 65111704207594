import { OrganizationAttributeSelector } from "@/modules/Organizations/logic";
import { useSerialization } from "@/modules/Serialization";
import { Feed, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";
import { useState } from "react";

export const IntegrationFeedParamsSelector = (feedType: Feed) => {
	return ZeusSelect<ValueTypes["IntegrationFeedParams"]>()({
		descriptiveName: true,
		bqDestinations: [{}, BigQueryDestinationSelector()],
		s3Destinations: [{}, S3DestinationSelector()],
		feedType: true,
		dateInput: FeedDateInputSelector(),
		[enumToField(feedType)]: feedTypeToSelector(feedType),
	});
};

const feedTypeToSelector = (feedType: Feed) => {
	return {
		apple: AppleFeedSelector(),
		applovin: ApplovinFeedSelector(),
		appleSearchAds: AppleSearchAdsFeedSelector(),
		appsFlyerCampaign: AppsFlyerCampaignFeedSelector(),
		appsFlyerDatalocker: AppsFlyerDatalockerFeedSelector(),
		appsFlyerRawInstalls: AppsFlyerRawInstallsFeedSelector(),
		appsFlyerRawEvents: AppsFlyerRawEventsFeedSelector(),
		appsFlyerScraper: AppsFlyerScraperFeedSelector(),
		chartboost: ChartboostFeedSelector(),
		facebookAds: FacebookAdsFeedSelector(),
		fairbidUserReport: FairbidUserReportFeedSelector(),
		fixer: FixerFeedSelector(),
		googleAdwords: GoogleAdwordsFeedSelector(),
		googlePlay: GooglePlayFeedSelector(),
		harvest: HarvestFeedSelector(),
		ironSource: IronSourceFeedSelector(),
		ironSourcePromotion: IronSourcePromotionFeedSelector(),
		libring: LibringFeedSelector(),
		snapchat: SnapchatFeedSelector(),
		tiktokAdsAudience: TiktokAdsAudienceFeedSelector(),
		unityAds: UnityAdsFeedSelector(),
		unityAdvertiser: UnityAdvertiserFeedSelector(),
	}[enumToField(feedType)];
};

const BigQueryDestinationSelector = () =>
	ZeusSelect<ValueTypes["BigQueryDestination"]>()({
		id: true,
		bqProject: {
			id: true,
		},
		dataset: true,
		descriptiveName: true,
		enabled: true,
		organizationAttributes: [{}, OrganizationAttributeSelector()],
		tablePrefix: true,
		tableSuffix: true,
	});

const S3DestinationSelector = () =>
	ZeusSelect<ValueTypes["S3Destination"]>()({
		id: true,
		awsProject: {
			id: true,
		},
		bucketName: true,
		descriptiveName: true,
		enabled: true,
		organizationAttributes: [{}, OrganizationAttributeSelector()],
		objectSuffix: true,
		pathPrefix: true,
		region: true,
	});

const FeedDateInputSelector = () =>
	ZeusSelect<ValueTypes["FeedDateInput"]>()({
		id: true,
		dateDashFrom: true,
		dateDashTo: true,
		daysInPast: true,
		offset: true,
	});

const AppleFeedSelector = () =>
	ZeusSelect<ValueTypes["AppleFeed"]>()({
		appleAccess: { id: true },
		games: [{}, { name: true, gameId: true }],
		reporter: true,
		scraper: true,
		usePST: true,
	});

const AppleSearchAdsFeedSelector = () =>
	ZeusSelect<ValueTypes["AppleSearchAdsFeed"]>()({
		appleSearchAdsAccess: { id: true },
		groupBy: true,
		orgId: true,
	});

const ApplovinFeedSelector = () =>
	ZeusSelect<ValueTypes["ApplovinFeed"]>()({
		applovinAccess: { id: true },
		reportType: true,
		gameIds: true,
	});

const AppsFlyerCampaignFeedSelector = () =>
	ZeusSelect<ValueTypes["AppsFlyerCampaignFeed"]>()({
		appsFlyerAccess: { id: true },
		attributedTouchType: true,
		currency: true,
		eventName: true,
		gameId: true,
		mediaSource: true,
		reattr: true,
		reportType: true,
		timezone: true,
	});

const AppsFlyerDatalockerFeedSelector = () =>
	ZeusSelect<ValueTypes["AppsFlyerDatalockerFeed"]>()({
		awsAccessKey: true,
		bucketSecret: true,
		eventNames: true,
		homeFolder: true,
	});

const AppsFlyerRawInstallsFeedSelector = () =>
	ZeusSelect<ValueTypes["AppsFlyerRawInstallsFeed"]>()({
		appsFlyerAccess: { id: true },
		gameId: true,
		omitFields: true,
		reportType: true,
	});

const AppsFlyerRawEventsFeedSelector = () =>
	ZeusSelect<ValueTypes["AppsFlyerRawEventsFeed"]>()({
		appsFlyerAccess: { id: true },
		eventName: true,
		gameId: true,
		omitFields: true,
		reportType: true,
	});

const AppsFlyerScraperFeedSelector = () =>
	ZeusSelect<ValueTypes["AppsFlyerScraperFeed"]>()({
		email: true,
		password: true,
		games: [{}, { name: true, gameId: true }],
	});

const ChartboostFeedSelector = () =>
	ZeusSelect<ValueTypes["ChartboostFeed"]>()({
		chartboostAccess: { id: true },
		groupBy: true,
		gameId: true,
	});

const FacebookAdsFeedSelector = () =>
	ZeusSelect<ValueTypes["FacebookAdsFeed"]>()({
		facebookAdsAccess: { id: true },
		additionalFields: true,
		breakdowns: [{}, { values: true }],
		levels: true,
		omitFields: true,
		snapshot: true,
	});

const FairbidUserReportFeedSelector = () =>
	ZeusSelect<ValueTypes["FairbidUserReportFeed"]>()({
		fairbidAccess: { id: true },
		appId: true,
	});

const FixerFeedSelector = () =>
	ZeusSelect<ValueTypes["FixerFeed"]>()({
		currency: true,
	});

const GoogleAdwordsFeedSelector = () =>
	ZeusSelect<ValueTypes["GoogleAdwordsFeed"]>()({
		googleAdsAccess: { id: true },
		customerAccountId: true,
		loginCustomerId: true,
		refreshToken: true,
		geographicView: true,
	});

const GooglePlayFeedSelector = () =>
	ZeusSelect<ValueTypes["GooglePlayFeed"]>()({
		googlePlayAccess: { id: true },
	});

const HarvestFeedSelector = () =>
	ZeusSelect<ValueTypes["HarvestFeed"]>()({
		harvestAccess: { id: true },
		updatedSince: true,
		updatedSinceDaysInPast: true,
		updatedSinceOffset: true,
	});

const IronSourceFeedSelector = () =>
	ZeusSelect<ValueTypes["IronSourceFeed"]>()({
		ironSourceAccess: { id: true },
		adRevenue: true,
		appKey: true,
		reportBreakdowns: true,
		reportMetrics: true,
		reports: true,
	});

const IronSourcePromotionFeedSelector = () =>
	ZeusSelect<ValueTypes["IronSourcePromotionFeed"]>()({
		ironSourceAccess: { id: true },
		adUnit: true,
		breakdowns: true,
		bundleIds: true,
		campaignIds: true,
		countries: true,
		creativeIds: true,
		deviceType: true,
		metrics: true,
		os: true,
	});

const LibringFeedSelector = () =>
	ZeusSelect<ValueTypes["LibringFeed"]>()({
		libringAccess: { id: true },
		appName: true,
		dataType: true,
	});

const SnapchatFeedSelector = () =>
	ZeusSelect<ValueTypes["SnapchatFeed"]>()({
		snapchatAccess: { id: true },
		timezoneOffset: true,
		reportDimension: true,
	});

const TiktokAdsAudienceFeedSelector = () =>
	ZeusSelect<ValueTypes["TiktokAdsAudienceFeed"]>()({
		tiktokAccess: { id: true },
		dimensions: true,
		filterInactive: true,
	});

const UnityAdsFeedSelector = () =>
	ZeusSelect<ValueTypes["UnityAdsFeed"]>()({
		unityAccess: { id: true },
	});

const UnityAdvertiserFeedSelector = () =>
	ZeusSelect<ValueTypes["UnityAdvertiserFeed"]>()({
		unityAccess: { id: true },
		targetIds: true,
	});

export const enumToField = (
	feedType: Feed
): Exclude<
	keyof ValueTypes["IntegrationFeedParams"],
	| "id"
	| "feedType"
	| "descriptiveName"
	| "bqDestinations"
	| "s3Destinations"
	| "dateInput"
	| "__typename"
	| "__alias"
> =>
	(({
		APPLE: "apple",
		APPLE_SEARCH_ADS: "appleSearchAds",
		APPLOVIN: "applovin",
		APPSFLYER_CAMPAIGN: "appsFlyerCampaign",
		APPSFLYER_DATALOCKER: "appsFlyerDatalocker",
		APPSFLYER_RAW_INSTALLS: "appsFlyerRawInstalls",
		APPSFLYER_RAW_EVENTS: "appsFlyerRawEvents",
		APPSFLYER_SCRAPER: "appsFlyerScraper",
		CHARTBOOST: "chartboost",
		FACEBOOK_ADS: "facebookAds",
		FAIRBID_USER_REPORT: "fairbidUserReport",
		FIXER: "fixer",
		GOOGLE_ADWORDS: "googleAdwords",
		GOOGLE_PLAY: "googlePlay",
		HARVEST: "harvest",
		IRON_SOURCE: "ironSource",
		IRON_SOURCE_PROMOTION: "ironSourcePromotion",
		LIBRING: "libring",
		SNAPCHAT: "snapchat",
		TIKTOK_ADS_AUDIENCE: "tiktokAdsAudience",
		UNITY_ADS: "unityAds",
		UNITY_ADVERTISER: "unityAdvertiser",
	} as const)[feedType]);

export const useFeedTypes = ({
	serializationKey,
}: {
	serializationKey: string;
}) => {
	const [feedTypes, setFeedTypes] = useState<Feed[]>(Object.values(Feed));

	useSerialization({
		equals: (query) => {
			if (
				feedTypes.length === Object.values(Feed).length &&
				query[serializationKey] === undefined
			)
				return true;
			return (
				// W10= is base64 encoded empty array => []
				atob(query[serializationKey] || "W10=") ===
				JSON.stringify(feedTypes)
			);
		},
		deserialize: (query) => {
			if (query[serializationKey]) {
				setFeedTypes(JSON.parse(atob(query[serializationKey])));
				return;
			}
			setFeedTypes(Object.values(Feed));
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				[serializationKey]:
					feedTypes.length === Object.values(Feed).length
						? undefined
						: btoa(JSON.stringify(feedTypes)),
			});
		},
	});

	return [feedTypes, setFeedTypes] as const;
};
