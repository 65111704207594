import { useTypedLazyQuery, useTypedMutation, useTypedQuery } from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useAppleAccessesQuery = () =>
	useTypedQuery({
		appleAccesses: [
			{
				where: {
					organizationAttributes_some: {
						organization: { id: $`organizationId` },
					},
				},
			},
			AppleAccessSelector,
		],
	}, {
		notifyOnNetworkStatusChange: true
	});

export const useAppleAccessesLazyQuery = () =>
	useTypedLazyQuery({
		appleAccesses: [
			{
				where: {
					organizationAttributes_some: {
						organization: { id: $`organizationId` },
					},
				},
			},
			AppleAccessSelector,
		],
	}, {
		notifyOnNetworkStatusChange: true
	});

export const useAppleAccessQuery = () =>
	useTypedLazyQuery({
		appleAccess: [
			{
				where: {
					id: $`id`
				},
			},
			AppleAccessSelector,
		],
	}, {
		notifyOnNetworkStatusChange: true
	});

export const useCreateAppleAccess = () => {
	return useTypedMutation({
		createAppleAccess: [
			{
				data: $`data`,
			},
			AppleAccessSelector,
		],
	});
};
export const useUpdateAppleAccess = () => {
	return useTypedMutation({
		updateAppleAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			AppleAccessSelector,
		],
	});
};
export const useDeleteAppleAccess = () => {
	return useTypedMutation({
		deleteAppleAccess: [
			{
				where: $`where`,
			},
			AppleAccessSelector,
		],
	});
};

export const AppleAccessSelector = ZeusSelect<ValueTypes["AppleAccess"]>()({
	id: true,
	descriptiveName: true,
	accountName: true,
	login: true,
	password: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
