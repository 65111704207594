import {
	AccessType,
	getGraphqlTypeByAccess,
	useAccessesQuery,
} from "@/modules/Etl/Accesses";
import { useRunEtl } from "@/modules/Etl/IntegrationFeedInstances/logic";
import { getFeedOptions } from "@/modules/Etl/IntegrationFeedParams/ui/FeedMultiselect";
import { useForm } from "@/modules/Form/logic";
import { useOrganizationsQuery } from "@/modules/Organizations/logic";
import {
	ApplovinReportType,
	Feed,
	ReportType,
} from "@/services/graphApi/graphql-zeus";
import { Form } from "@/ui/components/Form/components/Form";
import { UIKit } from "@/ui/components/Form/uiKit";
import {
	DestinationsField,
	DestinationsMatch,
} from "@/ui/components/Form/uiKit/Destinations";
import { BooleanField, BooleanMatch } from "@/ui/components/Form/uiKit/Boolean";
import {
	FacebookBreakdownsField,
	FacebookBreakdownsMatch,
} from "@/ui/components/Form/uiKit/Breakdowns";
import { FeedField, FeedMatch } from "@/ui/components/Form/uiKit/Feed";
import {
	FeedTypeField,
	FeedTypeMatch,
} from "@/ui/components/Form/uiKit/FeedType";
import { IntField, IntMatch } from "@/ui/components/Form/uiKit/Int";
import {
	IntegrationFeedParamsCreateInputField,
	IntegrationFeedParamsCreateInputMatch,
} from "@/ui/components/Form/uiKit/IntegrationFeedParamsCreateInput";
import { ObjectField, ObjectMatch } from "@/ui/components/Form/uiKit/Object";
import {
	OrganizationAttributesField,
	OrganizationAttributesMatch,
} from "@/ui/components/Form/uiKit/OrganizationAttributes";
import {
	OrganizationRoleField,
	OrganizationRoleMatch,
} from "@/ui/components/Form/uiKit/OrganizationRole";
import {
	EnumTypeField,
	EnumTypeMatch,
} from "@/ui/components/Form/uiKit/EnumType";
import {
	ScraperGameField,
	ScraperGameMatch,
} from "@/ui/components/Form/uiKit/ScraperGames";
import { StringField, StringMatch } from "@/ui/components/Form/uiKit/String";
import {
	UpdateManyInputField,
	UpdateManyInputMatch,
} from "@/ui/components/Form/uiKit/UpdateManyInput";
import {
	WhereUniqueInputField,
	WhereUniqueInputMatch,
} from "@/ui/components/Form/uiKit/WhereUniqueInput";
import { Icon } from "@/views";
import React, { useMemo } from "react";
import { Detail } from "../../Detail";

const options = getFeedOptions();

type Props = {
	item: { params: { feedType: Feed } };
	close: () => void;
};

export const IntegrationFeedInstanceDetailNew = ({ close, item }: Props) => {
	const {
		params: { feedType },
	} = item;

	const [runEtl, runEtlState] = useRunEtl();
	const form = useForm({
		params: {
			feedType,
			s3Destinations: [],
		},
	});
	const ui = useMemo(
		() => uiKit(form.getValues("params.ironSource.adRevenue")),
		[form]
	);

	return (
		<Detail
			title={
				options.find((option) => option.value === feedType)?.label || ""
			}
			close={close}
			loading={false}
			Content={
				<Form
					className="p-4 overflow-overlay h-full bg-white"
					ui={ui}
					input="IntegrationFeedInstanceCreateInput"
					form={form}
					data={form.getValues()}
					Buttons={() => {
						return (
							<div className="flex mt-4">
								<div className="mr-2">
									<button
										className="btn-primary"
										onClick={() => {
											const transformedItem = JSON.parse(
												JSON.stringify(
													form.getValues(),
													queriedInstanceToRunnableInstance
												)
											);

											runEtl({
												variables: {
													data: transformedItem,
												},
											});
										}}
									>
										{runEtlState.loading ? (
											<Icon
												className="spin"
												name="spinner"
											/>
										) : (
											"Run"
										)}
									</button>
								</div>
							</div>
						);
					}}
				/>
			}
		/>
	);
};

const uiKit = (showS3: boolean): UIKit<any>[] => [
	[OrganizationRoleMatch(), OrganizationRoleField],
	[EnumTypeMatch("ApplovinReportType"), EnumTypeField(ApplovinReportType)],
	[
		EnumTypeMatch("ReportType"),
		EnumTypeField(ReportType, ReportType.PARTNERS_BY_DATE_REPORT),
	],
	[FeedTypeMatch(), FeedTypeField],
	[
		IntegrationFeedParamsCreateInputMatch(),
		IntegrationFeedParamsCreateInputField,
	],
	[FeedMatch(), FeedField],
	[
		WhereUniqueInputMatch("OrganizationWhereUniqueInput"),
		WhereUniqueInputField({
			useRemoteData: () => useOrganizationsQuery(),
			key: "organizations",
		}),
	],
	...Object.values(AccessType).map((accessType) => {
		return [
			WhereUniqueInputMatch(
				`${getGraphqlTypeByAccess(accessType)}WhereUniqueInput`
			),
			WhereUniqueInputField({
				// @ts-ignore
				useRemoteData: () => useAccessesQuery(accessType),
				key: accessType,
			}),
		] as UIKit<any>;
	}),
	[OrganizationAttributesMatch(), OrganizationAttributesField],
	[DestinationsMatch("bqDestinations"), DestinationsField],
	[
		DestinationsMatch("s3Destinations"),
		showS3 ? DestinationsField : () => null,
	],
	[UpdateManyInputMatch(), UpdateManyInputField],
	[FacebookBreakdownsMatch(), FacebookBreakdownsField],
	[ScraperGameMatch(), ScraperGameField],
	// // ...basicKit
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[IntMatch(), IntField],
	[BooleanMatch(), BooleanField],
];

const queriedInstanceToRunnableInstance = (key: string, value: any) => {
	if (key === "") {
		const { organizationAttributes, params } = value;
		return {
			organizationAttributes,
			params,
		};
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (key === "organizationAttributes") {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}
	if (key === "bqDestinations" || key === "s3Destinations") {
		return value
			.filter((item: any) => item.enabled)
			.map((item: any) => {
				const { id, ...rest } = item;
				return rest;
			});
	}
	return value;
};
