import { FilterState } from "@/components/Page/ConsistencyChecks/Filters/FilterForm";
import { useCurrentOrganization } from "@/modules/Organizations/logic/Current";
import { usePagination } from "@/modules/usePagination";
import { useTypedLazyQuery } from "@/services/graphApi";
import {
	$,
	BigQueryCheckOrderByInput,
	ValueTypes,
	ZeusSelect,
} from "@/services/graphApi/graphql-zeus";
import { useEffect, useState } from "react";

export type BQCheckStringArgument =
	| "projectId_contains"
	| "dataset_contains"
	| "tableName_contains"
	| "tableSuffix_contains"
	| "lastModified_gte"
	| "lastModified_lte"
	| "updatedAt_gte"
	| "updatedAt_lte"
	| "maxDate_gte"
	| "maxDate_lte";
export type BQCheckBoolArgument = "tableExists";

export const useBigQueryChecksData = () => {
	const { currentOrganization } = useCurrentOrganization();
	const pagination = usePagination();
	const [filters, setFilters] = useState<
		FilterState<BQCheckStringArgument, BQCheckBoolArgument>
	>({});

	const [getBigQueryChecks, state] = useBigQueryChecksQuery();

	useEffect(() => {
		pagination.setCount(
			state.data?.bigQueryChecksConnection.aggregate.count || 0
		);
		pagination.setLoading(state.loading);
		pagination.setError(state.error && JSON.stringify(state.error));
		pagination.setPageInfo(state.data?.bigQueryChecksConnection.pageInfo);
	}, [pagination, state.data, state.error, state.loading]);

	const { reset } = pagination;
	useEffect(() => {
		reset();
	}, [filters, currentOrganization, reset]);

	useEffect(() => {
		getBigQueryChecks({
			variables: {
				...pagination.pagination,
				...filters,
				organizationId: currentOrganization?.id,
			},
		});
	}, [
		currentOrganization,
		pagination.pagination,
		getBigQueryChecks,
		filters,
	]);

	return {
		state,
		pagination,
		filters,
		setFilters,
	};
};

const useBigQueryChecksQuery = () =>
	useTypedLazyQuery(
		{
			bigQueryChecksConnection: [
				{
					first: $`first`,
					last: $`last`,
					before: $`before`,
					after: $`after`,
					skip: $`skip`,
					orderBy: BigQueryCheckOrderByInput.createdAt_DESC,
					where: {
						projectId_contains: $`projectId_contains`,
						dataset_contains: $`dataset_contains`,
						tableName_contains: $`tableName_contains`,
						tableSuffix_contains: $`tableSuffix_contains`,
						tableExists: $`tableExists`,
						lastModified_gte: $`lastModified_gte`,
						lastModified_lte: $`lastModified_lte`,
						updatedAt_gte: $`updatedAt_gte`,
						updatedAt_lte: $`updatedAt_lte`,
						maxDate_gte: $`maxDate_gte`,
						maxDate_lte: $`maxDate_lte`,
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				BigQueryCheckConnection(),
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

const BigQueryCheckConnection = () =>
	ZeusSelect<ValueTypes["BigQueryCheckConnection"]>()({
		aggregate: {
			count: true,
		},
		edges: {
			node: BigQueryCheckSelector(),
			cursor: true,
		},
		pageInfo: {
			hasNextPage: true,
			hasPreviousPage: true,
			startCursor: true,
			endCursor: true,
		},
	});

const BigQueryCheckSelector = () =>
	ZeusSelect<ValueTypes["BigQueryCheck"]>()({
		id: true,
		createdAt: true,
		updatedAt: true,
		tableReference: true,
		tableName: true,
		tableSuffix: true,
		projectId: true,
		dataset: true,
		tableExists: true,
		lastModified: true,
		maxDate: true,
	});
