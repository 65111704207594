import { OrganizationAttributeSelector } from "@/modules/Organizations/logic";
import { useCurrentOrganization } from "@/modules/Organizations/logic/Current";
import { useSerialization } from "@/modules/Serialization";
import { useDebouncedValue } from "@/modules/useDebouncedValue";
import { usePagination } from "@/modules/usePagination";
import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import {
	$,
	ErrorOrderByInput,
	Feed,
	InstanceState,
	IntegrationFeedInstanceOrderByInput,
	ValueTypes,
	ZeusSelect,
} from "@/services/graphApi/graphql-zeus";
import { useEffect, useState } from "react";
import {
	IntegrationFeedParamsSelector,
	useFeedTypes,
} from "../../IntegrationFeedParams/logic";

const useInstanceFilters = () => {
	const [feedTypes, setFeedTypes] = useFeedTypes({
		serializationKey: "showInstances",
	});

	const [search, setSearch] = useState<string>("");
	useSerialization({
		equals: (query) => query.search_instances === search,
		deserialize: (query) => {
			setSearch(query.search_instances || "");
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				search_instances: search || undefined,
			});
		},
	});

	const [createdAfter, setCreatedAfter] = useState<string>();
	useSerialization({
		equals: (query) => query.createdAfter_instances === createdAfter,
		deserialize: (query) => {
			setCreatedAfter(query.createdAfter_instances || "");
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				createdAfter_instances: createdAfter || undefined,
			});
		},
	});

	const [createdBefore, setCreatedBefore] = useState<string>();
	useSerialization({
		equals: (query) => query.createdBefore_instances === createdBefore,
		deserialize: (query) => {
			setCreatedBefore(query.createdBefore_instances || "");
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				createdBefore_instances: createdBefore || undefined,
			});
		},
	});
	const [instanceState, setInstanceState] = useState<InstanceState>(
		InstanceState.SUCCEEDED
	);

	useSerialization({
		equals: (query) =>
			query.instanceState_instances === instanceState ||
			(!query.instanceState_instances &&
				instanceState === InstanceState.SUCCEEDED),
		deserialize: (query) => {
			setInstanceState(
				query.instanceState_instances || InstanceState.SUCCEEDED
			);
		},
		serialize: (query, setQuery) => {
			setQuery({
				...query,
				instanceState_instances:
					instanceState !== InstanceState.SUCCEEDED
						? instanceState
						: undefined,
			});
		},
	});

	return {
		feedTypes,
		setFeedTypes,
		search,
		setSearch,
		createdAfter,
		setCreatedAfter,
		createdBefore,
		setCreatedBefore,
		instanceState,
		setInstanceState,
	};
};

export const useIntegrationFeedInstances = () => {
	const { currentOrganization } = useCurrentOrganization();
	const [selected, setSelected] = useState<any>();
	const pagination = usePagination();
	const filters = useInstanceFilters();

	const [getInstances, state] = useIntegrationFeedInstancesLazyQuery();

	useEffect(() => {
		pagination.setCount(
			state.data?.integrationFeedInstancesConnection.aggregate.count || 0
		);
		pagination.setLoading(state.loading);
		pagination.setError(state.error && JSON.stringify(state.error));
		pagination.setPageInfo(
			state.data?.integrationFeedInstancesConnection.pageInfo
		);
	}, [pagination, state.data, state.error, state.loading]);

	const {
		search,
		feedTypes,
		instanceState,
		createdAfter,
		createdBefore,
	} = filters;

	const debouncedSearch = useDebouncedValue(search, 300);
	const debouncedFeedTypes = useDebouncedValue(feedTypes, 500);

	const { reset } = pagination;
	useEffect(() => {
		reset();
	}, [
		debouncedSearch,
		debouncedFeedTypes,
		instanceState,
		currentOrganization,
		reset,
		createdAfter,
		createdBefore,
	]);

	useEffect(() => {
		const instanceStateVariables = {
			instanceState:
				instanceState !== InstanceState.RUNNING
					? instanceState
					: undefined,
			instanceStates:
				instanceState === InstanceState.RUNNING
					? [InstanceState.RUNNING, InstanceState.RETRYING]
					: undefined,
		};

		getInstances({
			variables: {
				...pagination.pagination,
				feedTypes: debouncedFeedTypes,
				search: debouncedSearch,
				createdAfter,
				createdBefore,
				...instanceStateVariables,
				organizationId: currentOrganization?.id || "",
			},
		});
	}, [
		currentOrganization,
		getInstances,
		pagination.pagination,
		debouncedSearch,
		instanceState,
		createdAfter,
		createdBefore,
		debouncedFeedTypes,
	]);

	const [runEtl] = useRunEtl();
	const [abortEtl] = useAbortEtl();

	const getItem = (id: string) =>
		state.data?.integrationFeedInstancesConnection.edges.find(
			(item) => item.node.id === id
		);

	const select = (id: string) => {
		const maybeItem = getItem(id);
		if (maybeItem) {
			setSelected(maybeItem);
		}
	};

	const deselect = () => {
		setSelected(undefined);
	};

	return {
		state,
		getItem,
		selected,
		select,
		deselect,
		runEtl,
		abortEtl,
		pagination,
		filters,
	};
};

const useIntegrationFeedInstancesLazyQuery = () =>
	useTypedLazyQuery(
		{
			integrationFeedInstancesConnection: [
				{
					first: $`first`,
					last: $`last`,
					before: $`before`,
					after: $`after`,
					skip: $`skip`,
					orderBy: IntegrationFeedInstanceOrderByInput.updatedAt_DESC,
					where: {
						state: $`instanceState`,
						state_in: $`instanceStates`,
						params: {
							feedType_in: $`feedTypes`,
							descriptiveName_contains: $`search`,
						},
						createdAt_gte: $`createdAfter`,
						createdAt_lte: $`createdBefore`,
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				IntegrationFeedInstanceConnection(),
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useInstancesCountQuery = ({
	variables,
}: {
	variables: {
		instanceState: InstanceState;
		createdAfter: string;
		organizationId: string;
	};
}) =>
	useTypedQuery(
		{
			integrationFeedInstancesConnection: [
				{
					where: {
						state: $`instanceState`,
						createdAt_gte: $`createdAfter`,
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				{
					aggregate: {
						count: true,
					},
				},
			],
		},
		{
			variables,
			notifyOnNetworkStatusChange: true,
		}
	);

export const useRunEtl = () =>
	useTypedMutation({
		runEtl: [
			{
				data: $`data`,
			},
			{
				actions: {
					error: true,
					message: true,
					startDate: true,
					stopDate: true,
				},
			},
		],
	});

export const useAbortEtl = () =>
	useTypedMutation({
		abortEtl: [
			{
				stateMachineExecutionArn: $`id`,
			},
			{
				error: true,
				message: true,
				startDate: true,
				stopDate: true,
			},
		],
	});

const IntegrationFeedInstanceConnection = () =>
	ZeusSelect<ValueTypes["IntegrationFeedInstanceConnection"]>()({
		aggregate: {
			count: true,
		},
		edges: {
			node: {
				id: true,
				config: {
					id: true,
				},
				state: true,
				stateMachineExecutionArn: true,
				createdAt: true,
				updatedAt: true,
				params: {
					descriptiveName: true,
					feedType: true,
				},
				retryErrors: [
					{
						orderBy: ErrorOrderByInput.createdAt_DESC,
					},
					{
						id: true,
						createdAt: true,
						message: true,
					},
				],
				failureError: {
					createdAt: true,
					message: true,
				},
			},
			cursor: true,
		},
		pageInfo: {
			hasNextPage: true,
			hasPreviousPage: true,
			startCursor: true,
			endCursor: true,
		},
	});

export const IntegrationFeedInstanceSelector = (feedType: Feed) =>
	ZeusSelect<ValueTypes["IntegrationFeedInstance"]>()({
		id: true,
		config: {
			id: true,
		},
		state: true,
		stateMachineExecutionArn: true,
		createdAt: true,
		updatedAt: true,
		retryErrors: [
			{
				orderBy: ErrorOrderByInput.createdAt_DESC,
			},
			{
				id: true,
				createdAt: true,
				message: true,
			},
		],
		failureError: {
			createdAt: true,
			message: true,
		},
		params: IntegrationFeedParamsSelector(feedType),
		organizationAttributes: [{}, OrganizationAttributeSelector()],
	});
