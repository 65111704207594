import { Detail } from "@/components/Page/Etl/Detail";
import {
	AccessType,
	getGraphqlDataFieldByAccess,
	getGraphqlTypeByAccess,
	getTitleByAccess,
	useAccessQuery,
	useUpdate,
} from "@/modules/Etl/Accesses";
import { useForm } from "@/modules/Form/logic";
import { useRouter } from "@/modules/Router";
import { Form } from "@/ui/components/Form/components/Form";
import { UIKit } from "@/ui/components/Form/uiKit";
import { BooleanField, BooleanMatch } from "@/ui/components/Form/uiKit/Boolean";
import { IntField, IntMatch } from "@/ui/components/Form/uiKit/Int";
import { ObjectField, ObjectMatch } from "@/ui/components/Form/uiKit/Object";
import {
	OrganizationAttributesField,
	OrganizationAttributesMatch,
} from "@/ui/components/Form/uiKit/OrganizationAttributes";
import {
	OrganizationRoleField,
	OrganizationRoleMatch,
} from "@/ui/components/Form/uiKit/OrganizationRole";
import { StringField, StringMatch } from "@/ui/components/Form/uiKit/String";
import {
	UpdateManyInputField,
	UpdateManyInputMatch,
} from "@/ui/components/Form/uiKit/UpdateManyInput";
import { Modal } from "@/views";
import { ButtonWithLoading } from "@/views/ButtonWithLoading";
import React, { useCallback, useEffect, useState } from "react";
import { createStore } from "reusable";

export const useAccessDetail = createStore(() => {
	const [_remove, setRemove] = useState<() => void>(() => () => {});
	const { navigate } = useRouter();

	const close = useCallback(() => {
		navigate({ route: "etl-accesses" });
	}, [navigate]);

	const open = useCallback(
		({ accessType, id }: { id: string; accessType: AccessType }) => {
			navigate({
				route: "etl-accesses-detail",
				params: { id, accessType },
			});
		},
		[navigate]
	);

	const remove = useCallback(() => {
		close();
		_remove();
	}, [_remove, close]);

	return {
		close,
		open,
		remove,
		setRemove,
	};
});

export const AccessDetailExisting = ({
	accessType,
	id,
}: {
	accessType: AccessType;
	id: string;
}) => {
	const detail = useAccessDetail();
	const { close, remove } = detail;
	const [getAccess, state] = useAccessQuery(accessType);
	const [update, updateState] = useUpdate(accessType);

	useEffect(() => {
		getAccess({ variables: { id } });
	}, [getAccess, id]);

	const form = useForm();

	useEffect(() => {
		if (state.data) {
			form.setValue(
				"",
				JSON.parse(
					JSON.stringify(
						// @ts-ignore
						state.data[getGraphqlDataFieldByAccess(accessType)],
						accessToEditableAccess
					)
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const ui = uiKit();

	return (
		<Modal className="w-modal h-modal" isOpened={true} onClose={detail.close}>
			<Detail
				title={getTitleByAccess(accessType)}
				close={close}
				loading={
					state.loading || Object.keys(form.getValues()).length === 0
				}
				Content={
					<Form
						className="p-4 overflow-overlay h-full bg-white"
						ui={ui}
						input={getGraphqlTypeByAccess(accessType).concat(
							"UpdateInput"
						)}
						form={form}
						data={form.getValues()}
						Buttons={() => {
							return (
								<div className="flex mt-4">
									<div className="mr-2">
										<ButtonWithLoading
											loading={updateState.loading}
											onClick={() => {
												const {
													id,
													...data
												} = form.getValues();

												update({
													variables: {
														data,
														where: {
															id,
														},
													},
												});
											}}
										>
											Update
										</ButtonWithLoading>
									</div>
									<div className="mr-2">
										<button
											className="btn-primary"
											onClick={remove}
										>
											Delete
										</button>
									</div>
								</div>
							);
						}}
					/>
				}
			/>
		</Modal>
	);
};

const uiKit = (): UIKit<any>[] => [
	[OrganizationRoleMatch(), OrganizationRoleField],
	[OrganizationAttributesMatch(), OrganizationAttributesField],
	[UpdateManyInputMatch(), UpdateManyInputField],
	// // ...basicKit
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[IntMatch(), IntField],
	[BooleanMatch(), BooleanField],
];

const accessToEditableAccess = (key: string, value: any) => {
	if (key === "") {
		return value;
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "createdAt" || key === "updatedAt") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "organizationAttributes" || key === "bqDestinations"|| key === "s3Destinations") {
		return {
			create: [],
			delete: [],
			update: value,
		};
	}
	return value;
};
