import { createClass } from "@/reexports";
import { Icon, IconProps } from "@/views";
import React from "react";

type LoadingIconProps = {
	className?: string;
	onClick?: () => void;
} & Pick<IconProps, "size" | "weight" | "onClick">;

export const LoadingIcon = ({
	className,
	onClick,
	...iconProps
}: LoadingIconProps) => {
	const classes = createClass(className, "spin");

	return <Icon name="spinner" className={classes} {...iconProps} />;
};
