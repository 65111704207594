import {
	AccessType,
	getAccessSelector,
	getGraphqlDataFieldByAccess,
	useCreate,
	useDelete,
} from "@/modules/Etl/Accesses";
import {
	accessTypeToBrand,
	FeedIcon,
} from "@/modules/Etl/IntegrationFeedParams/ui/FeedIcon";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";
import { useTypedFetchQuery } from "@/services/graphApi";
import { Icon, Tile } from "@/views";
import React from "react";
import { useAccessDetail } from "./Detail/Existing";

type Props = {
	item: { id: string; descriptiveName: string };
	refetch: () => void;
	accessType: AccessType;
};

const SIZE = "w-80 h-40";
export const AccessTile = ({ item, refetch, accessType }: Props) => {
	const { open, setRemove } = useAccessDetail();
	const deleted = useOpenable(false);
	const [remove, removeState] = useDelete(accessType);
	const [copy] = useCreate(accessType);
	const fetch = useTypedFetchQuery({
		[getGraphqlDataFieldByAccess(accessType)]: [
			{ where: { id: item.id } },
			getAccessSelector(accessType),
		],
	});

	if (removeState.loading) {
		const deleteClasses = createClass(SIZE, "center p-6 transition-03", {
			"opacity-0": deleted.opened,
		});
		return (
			<Tile className={deleteClasses}>
				<Icon name="spinner" className="spin" size="big" />
			</Tile>
		);
	}

	if (removeState.error) {
		return (
			<Tile className={createClass(SIZE, "center p-6")}>
				<Icon name="spinner" className="spin" size="big" />
			</Tile>
		);
	}

	const classes = createClass(
		SIZE,
		"p-6 flex flex-col justify-between transition-03",
		{ "opacity-0": deleted.opened }
	);

	return (
		<Tile className={classes}>
			<>
				<div className="flex flex-grow items-center mb-4">
					<div className="mr-6">
						<FeedIcon type={accessTypeToBrand(accessType)} />
					</div>
					<div className="">
						<div className="font-bold">{item.descriptiveName}</div>
					</div>
				</div>
				<div className="flex items-center justify-around">
					<Icon
						className="hover:text-blue-300 transition duration-100"
						name="edit"
						weight="solid"
						size="big"
						onClick={(e) => {
							e.stopPropagation();
							open({ id: item.id, accessType });
							setRemove(() => () =>
								remove({
									variables: { where: { id: item.id } },
									update: refetch,
								})
							);
							// detail.open();
							// navigate({
							// 	route: "etl-accesses-detail",
							// 	params: { id: item.id },
							// });
						}}
					/>
					<Icon
						className="hover:text-blue-300 transition duration-100"
						name="copy"
						weight="solid"
						size="big"
						onClick={(e) => {
							e.stopPropagation();
							fetch().then((res) => {
								if (!res.data) {
									return;
								}
								const access =
									res.data[
										getGraphqlDataFieldByAccess(accessType)
									];
								const data = JSON.parse(
									JSON.stringify(
										access,
										accessToAccessCreateInput
									)
								);

								copy({
									variables: {
										data,
									},
									update: refetch,
								});
							});
						}}
					/>
				</div>
			</>
		</Tile>
	);
};

const accessToAccessCreateInput = (key: string, value: any) => {
	if (key === "") {
		const { id, updatedAt, createdAt, ...rest } = value;
		return rest;
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "descriptiveName") {
		return `${value} Copy`;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "organizationAttributes") {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}

	return value;
};
