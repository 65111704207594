import { useCallback, useState } from "react";
import { cloneDeep, get, set } from 'lodash/fp';

export type Form = {
	getValues: (path?: string | undefined) => any,
	setValue: (path: string, value: any) => void,
	pushFieldValue: (path: string, value: any) => void,
	removeFieldValue: (path: string, index: number) => void,
	watch: (path?: string | undefined) => any,
}

export const useForm = (defaultState?: Record<string, any>): Form => {
	const [state, setState] = useState({ current: defaultState || {} });

	const setValue = useCallback((path: string, value: any) => {
		if (!path) {
			setState((state) => ({ current: value }));
			return;
		}
		setState((state) => {
			const copy = cloneDeep(state.current);
			return {
				current: set(path, value, copy),
			};
		});
	}, []);

	const getValues = (path?: string) => {
		if (!path) return state.current;
		const values = get(path, state.current);
		return values;
	};

	const pushFieldValue = (path: string, value: any) => {
		const length = getValues(path) && getValues(path).length;
		setValue(path.concat(`[${length || 0}]`), value);
	};
	const removeFieldValue = (path: string, index: number) => {
		const values = get(path, state.current);
		setValue(path, [...values.slice(0, index), ...values.slice(index + 1)]);
	};

	return {
		getValues,
		setValue,
		pushFieldValue,
		removeFieldValue,
		watch: getValues,
	};
};