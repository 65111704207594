import { Icon } from "@/views";
import React from "react";

type DetailProps = {
	title: string | JSX.Element;
	close: () => void;
	loading: boolean;
	Content: JSX.Element;
};

export const Detail = ({ Content, close, title, loading }: DetailProps) => {
	return (
		<div className="flex flex-col w-full h-full">
			<div className="center-v justify-between w-full h-detail-header py-4 pl-4 bg-gray-900 text-lg text-white font-semibold">
				{title}
				<Icon className="mr-4" name="times" onClick={close} />
			</div>
			{loading ? (
				<Icon
					className="spin center h-full text-c-accent1"
					size="huge"
					name="spinner"
				/>
			) : (
				<div style={{ height: 'calc(100% - 3rem)' }}>{Content}</div>
			)}
		</div>
	);
};
