import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useBigQueryProjectsQuery = () =>
	useTypedQuery(
		{
			bigQueryProjects: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				BigQueryProjectSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useBigQueryProjectsLazyQuery = () =>
	useTypedLazyQuery(
		{
			bigQueryProjects: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				BigQueryProjectSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useBigQueryProjectQuery = () =>
	useTypedLazyQuery(
		{
			bigQueryProject: [
				{
					where: {
						id: $`id`,
					},
				},
				BigQueryProjectSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateBigQueryProject = () => {
	return useTypedMutation({
		createBigQueryProject: [
			{
				data: $`data`,
			},
			BigQueryProjectSelector,
		],
	});
};
export const useUpdateBigQueryProject = () => {
	return useTypedMutation({
		updateBigQueryProject: [
			{
				where: $`where`,
				data: $`data`,
			},
			BigQueryProjectSelector,
		],
	});
};
export const useDeleteBigQueryProject = () => {
	return useTypedMutation({
		deleteBigQueryProject: [
			{
				where: $`where`,
			},
			BigQueryProjectSelector,
		],
	});
};

export const BigQueryProjectSelector = ZeusSelect<
	ValueTypes["BigQueryProject"]
>()({
	id: true,
	clientEmail: true,
	descriptiveName: true,
	privateKey: true,
	projectId: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
