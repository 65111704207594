import * as React from "react";

type AvatarProps = {
	onClick: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
	imgSrc: string;
};

export const Avatar = ({ onClick, imgSrc }: AvatarProps) => {
	return (
		<img
			width="48px"
			height="48px"
			onClick={onClick}
			className="cursor-pointer rounded-full"
			src={imgSrc}
			alt="avatar"
		></img>
	);
};
