import * as Session from "@/components/Session";
import { usePopup } from "@/modules/usePopup";
import { fromSession } from "@/modules/User";
import * as Views from "@/views";
import * as React from "react";

export const Viewer = () => {
	const popupRef = React.useRef(null);
	const { session, signOut } = Session.useSession();
	const { opened, open } = usePopup(popupRef);

	const user = fromSession(Session.as.LoggedIn(session));

	return (
		<div className="relative">
			<div className="center-v">
				<div className="center">
					<Views.Avatar
						onClick={(e) => {
							e.stopPropagation();
							open();
						}}
						imgSrc={user.profilePictureURL}
					/>
				</div>
			</div>
			<Views.Popup
				ref={popupRef}
				opened={opened}
				className="z-10 border shadow-lg py-2 bg-white right-0 absolute rounded"
				arrowPosition="none"
			>
				<>
					<div className="py-2 px-4">
						<div>
							{user.name} {user.surname}
						</div>
						<div className="text-xxs opacity-50">{user.email}</div>
					</div>
					<div
						className="hover:bg-gray-100 py-2 px-4 cursor-pointer"
						onClick={signOut}
					>
						Sign out
					</div>
				</>
			</Views.Popup>
		</div>
	);
};

export type OrganizationMembership = {
	organization: {
		id: string;
		name: string;
	};
	role: typeof userRoles[number];
};

export const userRoles = ["SUPER_ADMIN", "ADMIN", "MEMBER", "GUEST"] as const;
export type UserRole = typeof userRoles[number];
