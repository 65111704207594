import { useOnClickOutside } from "@/functions/hooks/useOnClickOutside";
import { useInstancesCountQuery } from "@/modules/Etl/IntegrationFeedInstances/logic";
import {
	useDeleteOrganization,
	useOrganizationQuery,
	useOrganizationsQuery,
} from "@/modules/Organizations/logic";
import { useRouter } from "@/modules/Router";
import { useOpenable } from "@/modules/useOpenable";
import { useUsersQuery } from "@/modules/UserManagement/logic";
import { createClass } from "@/reexports";
import { InstanceState } from "@/services/graphApi/graphql-zeus";
import { notifySuccess } from "@/services/notification";
import { LoadingIcon } from "@/ui/views/LoadingIcon";
import { Icon, Popup } from "@/views";
import { Tile } from "@/views/Tile";
import dayjs from "dayjs";
import React, { ReactChild, useMemo, useRef } from "react";
import { Logo } from "./Logo";

type Props = {
	id: string;
};

const SIZE = "w-64 h-80";
export const WorkspaceTile = ({ id }: Props) => {
	// if (true) return <WorkspaceTileLoading />;

	return (
		<Tile className={createClass(SIZE, "flex flex-col bg-white")}>
			<>
				<div className="center h-full">
					<Logo id={id} size="huge" />
				</div>
				<div className="center-v flex-col gap-1 h-full">
					<Name id={id} />
					<Users id={id} />
					<Status id={id} />
					<Actions>
						<>
							<Edit id={id} />
							<Delete id={id} />
						</>
					</Actions>
				</div>
			</>
		</Tile>
	);
};

// const WorkspaceTileLoading = () => (
// 	<Tile className={createClass(SIZE, "center bg-white")}>
// 		<LoadingIcon className="text-blue-500" size="large" />
// 	</Tile>
// );

const Name = ({ id }: Props) => {
	const organization = useOrganizationQuery(id);
	if (organization.loading) return <LoadingIcon className="text-blue-300" />;
	return (
		<span className="text-2xl font-bold">
			{organization.data?.organization!.name}
		</span>
	);
};
const Users = ({ id }: Props) => {
	const state = useUsersQuery({ organizationId: id });
	return (
		<div className="center-v gap-2">
			<Icon name="users" weight="solid" size="mili" />
			<span>{state.data?.users.length}</span>
		</div>
	);
};

const useStatus = ({
	id,
	instanceState,
}: {
	id: string;
	instanceState: InstanceState;
}) => {
	const state = useInstancesCountQuery({
		variables: {
			createdAfter: dayjs().subtract(1, "d").format("YYYY-MM-DDTHH"),
			organizationId: id,
			instanceState,
		},
	});

	return [
		state.loading,
		state.data?.integrationFeedInstancesConnection.aggregate.count,
	];
};
const Status = ({ id }: Props) => {
	const [failedLoading, failedCount] = useStatus({
		id,
		instanceState: InstanceState.FAILED,
	});
	const [retryingLoading, retryingCount] = useStatus({
		id,
		instanceState: InstanceState.RETRYING,
	});

	const allClear = useMemo(() => {
		return failedCount === 0 && retryingCount === 0;
	}, [failedCount, retryingCount]);

	return (
		<div className="center gap-1">
			{allClear && (
				<span className="center-v gap-1 text-xs text-success">
					<Icon name="check-circle" weight="solid" size="micro" />
					All clear
				</span>
			)}
			{!allClear && (
				<>
					{!failedLoading && failedCount !== 0 && (
						<span className="center-v gap-1 text-xs text-danger">
							<Icon
								name="exclamation-triangle"
								weight="solid"
								size="micro"
							/>
							{failedLoading ? <LoadingIcon /> : failedCount}{" "}
							failed
						</span>
					)}
					{!retryingLoading && retryingCount !== 0 && (
						<span className="center-v gap-1 text-xs text-warn">
							<Icon
								name="exclamation-triangle"
								weight="solid"
								size="micro"
							/>
							{retryingLoading ? <LoadingIcon /> : retryingCount}{" "}
							retrying
						</span>
					)}
				</>
			)}
		</div>
	);
};

const Actions = ({ children }: { children: ReactChild }) => (
	<div className="mt-7 center-v justify-around w-full">{children}</div>
);
const Edit = ({ id }: Props) => {
	const { navigate } = useRouter();

	return (
		<Icon
			className="cursor-pointer transition duration-100 hover:text-blue-300"
			name="edit"
			weight="solid"
			size="big"
			onClick={() =>
				navigate({ route: "workspaces-detail", params: { id } })
			}
		/>
	);
};
const Delete = ({ id }: Props) => {
	const workspace = useOrganizationQuery(id);
	const workspaces = useOrganizationsQuery();
	const [remove, removeState] = useDeleteOrganization();
	const popup = useOpenable(false);
	const popupRef = useRef(null);
	useOnClickOutside(popupRef, popup.close);

	return (
		<div className="relative">
			<Icon
				className="opacity-50" // transition duration-100 hover:text-blue-300"
				name="trash"
				weight="solid"
				size="big"
				onClick={popup.open}
			/>
			{popup.opened && (
				<Popup
					ref={popupRef}
					className="p-4 rounded top-0 absolute transform -translate-y-full bg-white shadow-flat-down"
					opened={popup.opened}
				>
					<div>
						<div className="mb-4 text-2xl font-semibold whitespace-no-wrap">
							Delete workspace ?
						</div>
						<div className="center gap-12">
							<button
								className="btn bg-gray-500 hover:bg-gray-600 text-white"
								onClick={popup.close}
							>
								Cancel
							</button>
							<button
								className="btn bg-danger hover:bg-fail text-white"
								onClick={() =>
									remove({
										variables: { id },
										update: () => {
											notifySuccess(
												`${workspace.data?.organization?.name} workspace was successfuly deleted`
											);
											workspaces.refetch();
										},
									})
								}
							>
								{removeState.loading ? (
									<LoadingIcon />
								) : (
									"Delete"
								)}
							</button>
						</div>
					</div>
				</Popup>
			)}
		</div>
	);
};
