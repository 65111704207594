import { useEffect } from "react";
import { UIFieldProps } from "../../uiKit";

export const useString = <R>({
	data,
	field,
	path,
	form,
}: Omit<UIFieldProps<R>, "uiKit" | "UIField">) => {
	const fieldPath = path.concat(field.name).join(".");

	useEffect(() => {
		if (data) {
			if (String(data) === '[object Object]') {
				form.setValue(fieldPath, null)
			}
			return;
		}
		if (field.required) {
			form.setValue(fieldPath, field.array ? [] : "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const setValue = (value: string) => form.setValue(fieldPath, value);

	const setArrayValue = (index: number, value: string) =>
		form.setValue(`${fieldPath}[${index}]`, value);

	const pushValue = (value?: string) => {
		form.pushFieldValue(fieldPath, value || "");
	}
	const removeArrayValue = (index: number) =>
		form.removeFieldValue(fieldPath, index);

	return {
		field,
		path,
		form,
		data,
		setValue,
		setArrayValue,
		pushValue,
		removeArrayValue,
	};
};
