import { createClass } from "@/reexports";
import { Icon } from "@/views";
import React, { useMemo, useState } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const ScraperGameMatch = () =>
	createUIKitMatcher(({ field }) => field.type === "ScraperGameCreateInput");

export const ScraperGameField = ({
	field,
	data,
	path,
	form,
	UIField,
	uiKit,
}: UIFieldProps<ScraperGame[]>) => {
	const fieldPath = path.concat(field.name).join(".");
	const [activeIndex, setActiveIndex] = useState<number>();

	const items = useMemo(() => {
		return (!data
			? []
			: data.map((item, index) => {
					return (
						<ScraperGameItem
							key={index}
							active={index === activeIndex}
							name={item.name}
							onItem={() =>
								index === activeIndex
									? setActiveIndex(undefined)
									: setActiveIndex(index)
							}
							onRemove={() => {
								form.setValue(
									fieldPath,
									data.filter((_value, i) => i !== index)
								);
								if (activeIndex !== undefined) {
									if (activeIndex === index) {
										setActiveIndex(undefined);
										return;
									}
									if (activeIndex >= index) {
										setActiveIndex(activeIndex - 1);
									}
								}
							}}
						/>
					);
			  })
		).concat(
			<Icon
				key="add"
				className="ml-2 hover:font-bold"
				name="plus"
				onClick={() => {
					form.pushFieldValue(fieldPath, {
						name: "New game",
					} as ScraperGame);
					// toggleActive(allItems.length);
				}}
			/>
		);
	}, [activeIndex, data, fieldPath, form]);

	return (
		<div className="center-v">
			<div className="w-48 mr-6">Games</div>
			<div>
				<InputContainer items={items} />
				{data &&
					activeIndex !== undefined &&
					activeIndex < data.length && (
						<>
							{field.fields?.map((innerField) => {
								return (
									<UIField
										key={`${innerField.name}`}
										data={
											// @ts-ignore
											data[activeIndex][innerField.name]
										}
										field={innerField}
										form={form}
										path={path.concat(
											`${field.name}[${activeIndex}]`
										)}
										UIField={UIField}
										uiKit={uiKit}
									/>
								);
							})}
						</>
					)}
			</div>
		</div>
	);
};

const InputContainer = (props: {
	items: JSX.Element[];
	endItem?: JSX.Element;
}) => {
	return (
		<div className="min-h-10 center-v flex-wrap gap-2 max-w-154 rounded-lg border-gray-500 border py-2 px-4">
			{props.items}
			{props.endItem && (
				<div className="absolute right-0 mr-2">{props.endItem}</div>
			)}
		</div>
	);
};

const ScraperGameItem = (props: {
	name: string;
	onItem: () => void;
	onRemove: () => void;
	active: boolean;
}) => {
	const { active, onItem, onRemove, name } = props;
	const classes = createClass("center-v p-2 bg-gray-200 rounded zoom-05", {
		"bg-blue-200": active,
	});
	return (
		<div className={classes}>
			<div
				className="center-v text-xs mr-2 cursor-pointer"
				onClick={onItem}
			>
				<div className="">{name || ""}</div>
			</div>
			<Icon
				className="cursor-pointer hover:font-bold"
				name="times"
				size="micro"
				onClick={onRemove}
			/>
		</div>
	);
};

type ScraperGame = {
	gameId: string;
	name: string;
};
