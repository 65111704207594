import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useGoogleAdsAccessesQuery = () =>
	useTypedQuery(
		{
			googleAdsAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				GoogleAdsAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useGoogleAdsAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			googleAdsAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				GoogleAdsAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useGoogleAdsAccessQuery = () =>
	useTypedLazyQuery(
		{
			googleAdsAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				GoogleAdsAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateGoogleAdsAccess = () => {
	return useTypedMutation({
		createGoogleAdsAccess: [
			{
				data: $`data`,
			},
			GoogleAdsAccessSelector,
		],
	});
};
export const useUpdateGoogleAdsAccess = () => {
	return useTypedMutation({
		updateGoogleAdsAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			GoogleAdsAccessSelector,
		],
	});
};
export const useDeleteGoogleAdsAccess = () => {
	return useTypedMutation({
		deleteGoogleAdsAccess: [
			{
				where: $`where`,
			},
			GoogleAdsAccessSelector,
		],
	});
};

export const GoogleAdsAccessSelector = ZeusSelect<ValueTypes["GoogleAdsAccess"]>()({
	id: true,
	descriptiveName: true,
	clientId: true,
	clientSecret: true,
	developerToken: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
