import {
	AccessType,
	getGraphqlTypeByAccess,
	useAccessesQuery,
} from "@/modules/Etl/Accesses";
import {
	IntegrationFeedInstanceSelector,
	useRunEtl,
} from "@/modules/Etl/IntegrationFeedInstances/logic";
import { getFeedOptions } from "@/modules/Etl/IntegrationFeedParams/ui/FeedMultiselect";
import { useForm } from "@/modules/Form/logic";
import { useOrganizationsQuery } from "@/modules/Organizations/logic";
import { useRouter } from "@/modules/Router";
import { useTypedQuery } from "@/services/graphApi";
import {
	ApplovinReportType,
	Feed,
	ReportType,
} from "@/services/graphApi/graphql-zeus";
import { Form } from "@/ui/components/Form/components/Form";
import { UIKit } from "@/ui/components/Form/uiKit";
import {
	DestinationsField,
	DestinationsMatch,
} from "@/ui/components/Form/uiKit/Destinations";
import { BooleanField, BooleanMatch } from "@/ui/components/Form/uiKit/Boolean";
import {
	FacebookBreakdownsField,
	FacebookBreakdownsMatch,
} from "@/ui/components/Form/uiKit/Breakdowns";
import { FeedField, FeedMatch } from "@/ui/components/Form/uiKit/Feed";
import {
	FeedTypeField,
	FeedTypeMatch,
} from "@/ui/components/Form/uiKit/FeedType";
import { IntField, IntMatch } from "@/ui/components/Form/uiKit/Int";
import {
	IntegrationFeedParamsCreateInputField,
	IntegrationFeedParamsCreateInputMatch,
} from "@/ui/components/Form/uiKit/IntegrationFeedParamsCreateInput";
import { ObjectField, ObjectMatch } from "@/ui/components/Form/uiKit/Object";
import {
	OrganizationAttributesField,
	OrganizationAttributesMatch,
} from "@/ui/components/Form/uiKit/OrganizationAttributes";
import {
	OrganizationRoleField,
	OrganizationRoleMatch,
} from "@/ui/components/Form/uiKit/OrganizationRole";
import {
	EnumTypeField,
	EnumTypeMatch,
} from "@/ui/components/Form/uiKit/EnumType";
import {
	ScraperGameField,
	ScraperGameMatch,
} from "@/ui/components/Form/uiKit/ScraperGames";
import { StringField, StringMatch } from "@/ui/components/Form/uiKit/String";
import {
	UpdateManyInputField,
	UpdateManyInputMatch,
} from "@/ui/components/Form/uiKit/UpdateManyInput";
import {
	WhereUniqueInputField,
	WhereUniqueInputMatch,
} from "@/ui/components/Form/uiKit/WhereUniqueInput";
import { Icon, Modal } from "@/views";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { createStore } from "reusable";
import { Detail } from "../../Detail";

const options = getFeedOptions();

export const useInstancesDetail = createStore(() => {
	const { navigate } = useRouter();

	const close = useCallback(() => {
		navigate({ route: "etl-instances" });
	}, [navigate]);

	const open = useCallback(
		({ feedType, id }: { id: string; feedType: Feed }) => {
			navigate({
				route: "etl-instances-detail",
				params: { id, feedType },
			});
		},
		[navigate]
	);

	return {
		close,
		open,
	};
});

type Props = {
	item: { id: string; params: { feedType: Feed } };
};

export const IntegrationFeedInstanceDetailExisting = ({ item }: Props) => {
	const {
		id,
		params: { feedType },
	} = item;
	const { close } = useInstancesDetail();
	const state = useTypedQuery({
		integrationFeedInstance: [
			{ where: { id } },
			IntegrationFeedInstanceSelector(feedType),
		],
	});
	const [runEtl, runEtlState] = useRunEtl();
	const form = useForm();

	useEffect(() => {
		if (state.data && !state.loading) {
			form.setValue(
				"",
				JSON.parse(
					JSON.stringify(
						state.data.integrationFeedInstance,
						queriedInstanceToEditableInstance
					)
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const ui = useMemo(
		() => uiKit(form.getValues("params.ironSource.adRevenue")),
		[form]
	);

	return (
		<Modal className="w-modal h-modal" isOpened={true} onClose={close}>
			<Detail
				title={
					<div className="w-full center-v gap-2 mr-2">
						<div>
							{options.find((option) => option.value === feedType)
								?.label || ""}
						</div>
					</div>
				}
				close={close}
				loading={
					state.loading || Object.keys(form.getValues()).length === 0
				}
				Content={
					<div className="flex h-full">
						<Form
							className="p-4 pr-8 overflow-overlay h-full bg-white flex-grow flex-shrink-0"
							ui={ui}
							input="IntegrationFeedInstanceCreateInput"
							form={form}
							data={form.getValues()}
							Buttons={() => {
								return (
									<div className="flex mt-4">
										<div className="mr-2">
											<button
												className="btn-primary"
												onClick={() => {
													const transformedItem = JSON.parse(
														JSON.stringify(
															form.getValues(),
															queriedInstanceToRunnableInstance
														)
													);
													const {
														config,
														...data
													} = transformedItem;

													runEtl({
														variables: {
															data: {
																...data,
																...(config.id
																	? {
																			config: {
																				id:
																					config.id,
																			},
																	  }
																	: {}),
															},
														},
													});
													state.refetch &&
														state.refetch();
												}}
											>
												{runEtlState.loading ? (
													<Icon
														className="spin"
														name="spinner"
													/>
												) : (
													"Run"
												)}
											</button>
										</div>
									</div>
								);
							}}
						/>
						{(state.data?.integrationFeedInstance?.failureError ||
							state.data?.integrationFeedInstance?.retryErrors
								?.length !== 0) && (
							<div className="flex flex-col w-1/2 center-v gap-6 flex-grow bg-grey-300 py-4 px-8 overflow-overlay">
								{state.data?.integrationFeedInstance
									?.failureError && (
									<div className="flex-col p-2 bg-white shadow-flat-down rounded-lg w-full text-2xl">
										<div className="text-sm text-gray-500 mb-2 float-right">
											{dayjs(
												state.data
													?.integrationFeedInstance
													?.failureError?.createdAt
											).format("YYYY-MM-DD HH:mm:ss")}
										</div>
										<div className="text-danger font-bold text-2xl">
											Error:{" "}
										</div>
										<div className="break-words">
											{
												state.data
													?.integrationFeedInstance
													?.failureError?.message
											}
										</div>
									</div>
								)}
								<div className="pl-4 w-full flex flex-col gap-6">
									{state.data?.integrationFeedInstance?.retryErrors?.map(
										(item, index) => {
											return (
												<div
													className="p-2 bg-white shadow-flat-down rounded-lg w-full"
													key={index}
												>
													<div className="text-sm text-gray-500 mb-2 float-right">
														{dayjs(
															item.createdAt
														).format(
															"YYYY-MM-DD HH:mm:ss"
														)}
													</div>
													<div className="font-bold mb-1">
														{(state.data
															?.integrationFeedInstance
															?.retryErrors
															?.length || 0) -
															index}
														{". "}
														attempt failed with:
													</div>
													<div className="break-words">
														{item.message}
													</div>
												</div>
											);
										}
									)}
								</div>
							</div>
						)}
					</div>
				}
			/>
		</Modal>
	);
};

const uiKit = (showS3: boolean): UIKit<any>[] => [
	[OrganizationRoleMatch(), OrganizationRoleField],
	[EnumTypeMatch("ApplovinReportType"), EnumTypeField(ApplovinReportType)],
	[
		EnumTypeMatch("ReportType"),
		EnumTypeField(ReportType, ReportType.PARTNERS_BY_DATE_REPORT),
	],
	[FeedTypeMatch(), FeedTypeField],
	[
		IntegrationFeedParamsCreateInputMatch(),
		IntegrationFeedParamsCreateInputField,
	],
	[FeedMatch(), FeedField],
	[
		WhereUniqueInputMatch("OrganizationWhereUniqueInput"),
		WhereUniqueInputField({
			useRemoteData: () => useOrganizationsQuery(),
			key: "organizations",
		}),
	],
	...Object.values(AccessType).map((accessType) => {
		return [
			WhereUniqueInputMatch(
				`${getGraphqlTypeByAccess(accessType)}WhereUniqueInput`
			),
			WhereUniqueInputField({
				// @ts-ignore
				useRemoteData: () => useAccessesQuery(accessType),
				key: accessType,
			}),
		] as UIKit<any>;
	}),
	[OrganizationAttributesMatch(), OrganizationAttributesField],
	[DestinationsMatch("bqDestinations"), DestinationsField],
	[
		DestinationsMatch("s3Destinations"),
		showS3 ? DestinationsField : () => null,
	],
	[UpdateManyInputMatch(), UpdateManyInputField],
	[FacebookBreakdownsMatch(), FacebookBreakdownsField],
	[ScraperGameMatch(), ScraperGameField],
	// // ...basicKit
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[IntMatch(), IntField],
	[BooleanMatch(), BooleanField],
];

const queriedInstanceToEditableInstance = (key: string, value: any) => {
	if (key === "") {
		return value;
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (
		key === "organizationAttributes" ||
		key === "bqDestinations" ||
		key === "s3Destinations"
	) {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}
	return value;
};

const queriedInstanceToRunnableInstance = (key: string, value: any) => {
	if (key === "") {
		const { config, organizationAttributes, params } = value;
		return {
			config,
			organizationAttributes,
			params,
		};
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (key === "organizationAttributes") {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}
	if (key === "bqDestinations" || key === "s3Destinations") {
		return value
			.filter((item: any) => item.enabled)
			.map((item: any) => {
				const { id, ...rest } = item;
				return rest;
			});
	}
	return value;
};
