import { useIntegrationFeedConfigs } from "@/modules/Etl/IntegrationFeedConfigs/logic";
import {
	FeedMultiselect,
	getFeedOptions,
} from "@/modules/Etl/IntegrationFeedParams/ui/FeedMultiselect";
import { PageControls, PageInfo, PageSizeDropdown } from "@/modules/Pagination";
import { Route } from "@/modules/Router";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";
import { Feed, IntegrationFeedConfig } from "@/services/graphApi/graphql-zeus";
import { Dropdown, Icon, Modal } from "@/views";
import { Refetch } from "@/views/Refetch";
import React, { useMemo, useState } from "react";
import { IntegrationFeedConfigDetailExisting } from "./Detail/Existing";
import { IntegrationFeedConfigDetailNew } from "./Detail/New";
import { ConfigTile } from "./Tile";

export const View = () => {
	const { state, pagination, filters } = useIntegrationFeedConfigs();

	return (
		<div className="children:px-4 flex flex-col gap-2">
			<FeedMultiselect
				feeds={filters.feedTypes}
				setFeeds={filters.setFeedTypes}
			/>
			<div className="center-v gap-2">
				<div className="center-v gap-2 input w-100 bg-white">
					<Icon name="search" />
					<input
						className="w-full bg-transparent outline-none"
						onChange={(e) => filters.setSearch(e.target.value)}
						value={filters.search}
						placeholder="Search by Name(case sensitive)"
					/>
				</div>
				<div
					className={createClass(
						"cursor-pointer center-v justify-between gap-2 rounded-full text-white py-2 px-4",
						{
							"bg-primary": filters.enabled,
							"bg-gray-400": !filters.enabled,
						}
					)}
					onClick={filters.toggleEnabled}
				>
					<span
						className={createClass("transition-03", {
							"transform translate-x-8": !filters.enabled,
						})}
					>
						{filters.enabled ? "Enabled" : "Disabled"}
					</span>
					<Icon
						className={createClass("transition-03", {
							"transform -translate-x-18": !filters.enabled,
						})}
						name="circle"
						weight="solid"
						size="big"
					/>
				</div>
			</div>
			<div>
				<div className="center-v gap-2">
					<PageSizeDropdown {...pagination} />
					<PageControls {...pagination} />
					<PageInfo {...pagination} />
				</div>
			</div>
			<div className="flex gap-4">
				<CreateNewConfigButton />
				<Refetch
					loading={state.loading}
					refetch={() => state.refetch && state.refetch()}
				/>
			</div>
			{state.error && (
				<div className="bg-danger opacity-25 text-white p-2 rounded">
					Error: {JSON.stringify(state.error)}
				</div>
			)}
			<div className="py-2 overflow-overlay max-h-200 flex flex-wrap gap-4">
				{state.data &&
					state.data.integrationFeedConfigsConnection.edges
						.map((item) => item?.node as IntegrationFeedConfig)
						.map((item) => {
							return (
								<ConfigTile
									key={item.id}
									item={item}
									refetch={() =>
										state.refetch && state.refetch()
									}
								/>
							);
						})}
			</div>
			<div className="center">
				<div className="center-v gap-2">
					<PageControls {...pagination} />
				</div>
			</div>
			<Route path="etl/configs/:feedType/:id">
				{({ feedType, id }) => (
					<IntegrationFeedConfigDetailExisting
						item={{ id, params: { feedType } }}
					/>
				)}
			</Route>
		</div>
	);
};

const CreateNewConfigButton = () => {
	const dropdown = useOpenable(false);
	const newConfig = useOpenable(false);
	const [feedType, setFeedType] = useState<Feed>();

	const options = useMemo(() => getFeedOptions(), []);

	return (
		<>
			<Dropdown.View
				label={
					<div className="btn-primary" onClick={dropdown.toggle}>
						Create new
					</div>
				}
				onClickOutside={dropdown.close}
				opened={dropdown.opened}
			>
				<Dropdown.Menu>
					{options.map((option) => {
						return (
							<Dropdown.Item
								key={option.value}
								onClick={() => {
									dropdown.close();
									newConfig.open();
									setFeedType(option.value);
								}}
							>
								<span>{option.label}</span>
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown.View>
			{newConfig.opened && feedType && (
				<Modal
					className="w-modal h-modal"
					isOpened={newConfig.opened}
					onClose={newConfig.close}
				>
					<IntegrationFeedConfigDetailNew
						item={{
							params: {
								feedType,
							},
						}}
						close={newConfig.close}
					/>
				</Modal>
			)}
		</>
	);
};
