import { useOnClickOutside } from "@/functions/hooks/useOnClickOutside";
import { unit } from "@/functions/unit";
import { createClass } from "@/reexports";
import * as React from "react";

type ViewProps = {
	className?: string;
	label: JSX.Element;
	children: React.ReactChild;
	opened: boolean;
	onClickOutside: typeof unit;
};

export const View = ({
	className,
	label,
	children,
	opened,
	onClickOutside,
}: ViewProps) => {
	const classes = createClass(className, "relative");
	const ref = React.useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => {
		if (opened) {
			onClickOutside();
		}
	});
	return (
		<div ref={ref} className={classes}>
			{label}
			{opened && children}
		</div>
	);
};

type MenuProps = {
	children: React.ReactNode;
	className?: string;
};

export const Menu = ({ children, className }: MenuProps) => {
	const classes = createClass(
		className,
		"text-main-font text-xs left-0 mt-2 min-w-max-content cursor-auto absolute top-full bg-white border border-blue-pale-dark rounded z-50"
	);

	return <div className={classes}>{children}</div>;
};

type ItemProps = {
	children: React.ReactNode;
	className?: string;
	selected?: boolean;
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Item = ({
	children,
	className,
	selected = false,
	onClick,
}: ItemProps) => {
	const classes = createClass(
		"cursor-pointer py-3 px-4 min-w-max-content hover:bg-dropdown font-bold",
		{ "bg-dropdown": selected },
		className
	);

	return (
		<div className={classes} onClick={onClick}>
			{children}
		</div>
	);
};
