export type Predicate<R> = (value: R) => boolean;

// EQUALS
export const equals = <T>(val1: T) => (val2: T): boolean => val1 === val2;

// NOT
export const not = <T>(fn: (val: T) => boolean) => (val: T): boolean => !fn(val);

// AND
export const and = <R>(...args: Predicate<R>[]) => (value: R): boolean => {
	return args.some(fn => !fn(value));
}

// OR
export const or = <R>(...args: Predicate<R>[]) => (value: R): boolean => {
	return args.some(fn => fn(value));
}