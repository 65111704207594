import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";
import { useCallback } from "react";

export const useOrganizations = () => {
	const state = useOrganizationsQuery();
	const getById = useCallback(
		(organizationId: string) => {
			const organizationName = state.data
				? (state.data.organizations.find(
						(organization) => organization.id === organizationId
				  )?.name as string)
				: "Loading";

			return {
				id: organizationId,
				name: organizationName,
			};
		},
		[state.data]
	);

	return {
		getById,
		state,
	};
};

export const useOrganizationsQuery = () =>
	useTypedQuery({
		organizations: [{}, OrganizationSelector()],
	});

export const useOrganizationsLazyQuery = () =>
	useTypedLazyQuery({
		organizations: [{}, OrganizationSelector()],
	});

export const useOrganizationQuery = (organizationId: string) =>
	useTypedQuery(
		{
			organization: [
				{
					where: { id: $`organizationId` },
				},
				OrganizationSelector(),
			],
		},
		{
			variables: {
				organizationId,
			},
		}
	);

export const useCreateOrganization = () =>
	useTypedMutation({
		createOrganization: [
			{
				name: $`name`,
			},
			{
				id: true,
				name: true,
			},
		],
	});

export const useDeleteOrganization = () =>
	useTypedMutation({
		deleteOrganization: [
			{
				organizationId: $`id`,
			},
			{
				id: true,
			},
		],
	});

const OrganizationSelector = () =>
	ZeusSelect<ValueTypes["Organization"]>()({
		id: true,
		name: true,
	});

export const OrganizationAttributeSelector = () =>
	ZeusSelect<ValueTypes["OrganizationAttribute"]>()({
		id: true,
		role: true,
		organization: {
			id: true,
			name: true,
		},
	});
