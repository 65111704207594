import React, { useEffect } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";
import { camelCaseToWords } from "@/functions/camelCaseToWords";

export const BooleanMatch = () =>
	createUIKitMatcher(({ field }) => field.type === "Boolean");

export const BooleanField = ({
	field,
	path,
	form,
	data
}: UIFieldProps<boolean>) => {
	const fieldPath = path.concat(field.name).join(".");
	useEffect(() => {
		if (data) {
			if (String(data) === '[object Object]') {
				form.setValue(fieldPath, null)
			}
			return
		}
		form.setValue(fieldPath, false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="center-v" key={field.name}>
			<label htmlFor={fieldPath} className="w-48 mr-6">{camelCaseToWords(field.name)}</label>
			<input
				id={fieldPath}
				type="checkbox"
				className="rounded-lg w-100 border-gray-500 border focus:outline-input py-2 px-4"
				name={path.concat(field.name).join(".")}
				onChange={(e) => {
					form.setValue(fieldPath, e.target.checked);
				}}
				checked={data || false}
			/>
		</div>
	);
};
