import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useApplovinAccessesQuery = () =>
	useTypedQuery(
		{
			applovinAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				ApplovinAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useApplovinAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			applovinAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				ApplovinAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useApplovinAccessQuery = () =>
	useTypedLazyQuery(
		{
			applovinAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				ApplovinAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateApplovinAccess = () => {
	return useTypedMutation({
		createApplovinAccess: [
			{
				data: $`data`,
			},
			ApplovinAccessSelector,
		],
	});
};
export const useUpdateApplovinAccess = () => {
	return useTypedMutation({
		updateApplovinAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			ApplovinAccessSelector,
		],
	});
};
export const useDeleteApplovinAccess = () => {
	return useTypedMutation({
		deleteApplovinAccess: [
			{
				where: $`where`,
			},
			ApplovinAccessSelector,
		],
	});
};

export const ApplovinAccessSelector = ZeusSelect<
	ValueTypes["ApplovinAccess"]
>()({
	id: true,
	descriptiveName: true,
	apiKey: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
