import { Session } from "@/components/Session";
import { getUser } from "@/services/auth";

export type Model = {
	email: string;
	name: string;
	surname: string;
	profilePictureURL: string;
};

export const fromSession = (session: Session): Model => {
	const { email, family_name, given_name, picture } = getUser(
		session
	);
	return {
		email,
		name: given_name,
		surname: family_name,
		profilePictureURL: picture
	};
};
