import { useDailyConsistencyChecksData } from "@/modules/Checks/Consistency/Daily";
import {
	DailyConsistencyCheck,
	DailyConsistencyValues,
} from "@/services/graphApi/graphql-zeus";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import fp from "lodash/fp";
import React from "react";
import { Cell, DifferenceCell, GroupCell } from "./Cell";

dayjs.extend(relativeTime);

export type DailyTableProps = Pick<
	ReturnType<typeof useDailyConsistencyChecksData>,
	"state"
>;

export const DailyTable = ({ state }: DailyTableProps) => {
	if (state.loading) {
		return <div className="center">Loading...</div>;
	}

	if (state.error) {
		return <div className="center">{state.error.toString()}</div>;
	}

	const data = state.data?.dailyConsistencyChecksConnection.edges.map(
		(e) => e.node as DailyConsistencyCheck
	);
	const checksByGame = fp.groupBy("gameId", data);

	return (
		<div className="overflow-auto">
			<table className="table-auto border-b-2 border-gray-300">
				<thead>
					<tr>
						<th className="px-4 py-2">Game ID</th>
						<th className="px-4 py-2">Platform</th>
						<th className="px-4 py-2">Date</th>
						<th className="px-4 py-2">Source</th>
						<th className="px-4 py-2">Installs</th>
						<th className="px-4 py-2">Installs Difference</th>
						<th className="px-4 py-2">Installs Rel. Difference</th>
						<th className="px-4 py-2">Revenue</th>
						<th className="px-4 py-2">Revenue Difference</th>
						<th className="px-4 py-2">Revenue Rel. Difference</th>
						<th className="px-4 py-2">Purchases</th>
						<th className="px-4 py-2">Purchases Difference</th>
						<th className="px-4 py-2">Purchases Rel. Difference</th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(checksByGame).map(
						([gameId, checks], index) => (
							<GameRow
								key={gameId}
								gameId={gameId}
								checks={checks}
								gameIndex={index}
							/>
						)
					)}
				</tbody>
			</table>
		</div>
	);
};

type RowProps = {
	index: number;
	span: number;
	id: string;
};

const GameRow = (props: {
	checks: DailyConsistencyCheck[];
	gameId: string;
	gameIndex: number;
}) => {
	const { gameIndex, gameId, checks } = props;
	const checksByPlatform = fp.groupBy("platform", checks);

	const checkValues = fp.flatMap((c) => c.values, checks);

	const [, nonStoreChecks] = fp.partition(sourceIsStore, checkValues);

	const distinctGroups = fp.flow(
		fp.map((check: DailyConsistencyCheck) => ({
			platform: check.platform,
			date: dayjs(check.date).format("YYYY-MM-DD"),
		})),
		fp.uniqWith(
			(c1, c2) => c1.platform === c2.platform && c1.date === c2.date
		)
	)(checks);

	// All distinct (platform, date)-pairs have a store row (blank if missing in data).
	const rowSpan = distinctGroups.length + nonStoreChecks.length;

	return (
		<>
			{Object.entries(checksByPlatform).map(
				([platform, platformChecks], gameRowindex) => (
					<PlatformRow
						key={`${gameId}.${platform}`}
						checks={platformChecks}
						gameIndex={gameIndex}
						platform={platform}
						gameRowProps={{
							index: gameRowindex,
							span: rowSpan,
							id: gameId,
						}}
					/>
				)
			)}
		</>
	);
};

const PlatformRow = (props: {
	checks: DailyConsistencyCheck[];
	gameIndex: number;
	platform: string;
	gameRowProps: RowProps;
}) => {
	const { gameIndex, gameRowProps, checks, platform } = props;
	const checksByDate = fp.groupBy(
		(check) => dayjs(check.date).format("YYYY-MM-DD"),
		checks
	);

	const checkValues = fp.flatMap((c) => c.values, checks);

	const [, nonStoreChecks] = fp.partition(sourceIsStore, checkValues);

	// Each distinct date has one store row (blank if missing in data).
	const distinctDates = Object.keys(checksByDate).length;
	const rowSpan = distinctDates + nonStoreChecks.length;

	return (
		<>
			{Object.entries(checksByDate).map(
				([date, dateChecks], platformRowIndex) => (
					<DateRow
						key={`${gameRowProps.id}.${platform}.${date}`}
						checks={dateChecks}
						gameIndex={gameIndex}
						gameRowProps={gameRowProps}
						platformRowProps={{
							index: platformRowIndex,
							span: rowSpan,
							id: platform,
						}}
						date={date}
					/>
				)
			)}
		</>
	);
};

const DateRow = (props: {
	checks: DailyConsistencyCheck[];
	gameIndex: number;
	gameRowProps: RowProps;
	platformRowProps: RowProps;
	date: string;
}) => {
	const { checks, gameIndex, gameRowProps, platformRowProps, date } = props;

	const checkValues = fp.flatMap((c) => c.values, checks);

	const [[storeCheck], nonStoreChecks] = (fp.partition(
		sourceIsStore,
		checkValues
	) as unknown) as [[DailyConsistencyValues], DailyConsistencyValues[]];
	const rowSpan = 1 + nonStoreChecks.length;

	return (
		<>
			{
				<tr
					className={`border-t-2 border-gray-300 ${
						gameIndex % 2 === 0 ? undefined : "bg-gray-100"
					}`}
					key={`${gameRowProps.id}.${
						platformRowProps.id
					}.${date}.${0}`}
				>
					{
						// Multi-line cell for game ID
						platformRowProps.index === 0 &&
							gameRowProps.index === 0 && (
								<GroupCell
									rowSpan={gameRowProps.span}
									value={gameRowProps.id}
								/>
							)
					}
					{
						// Multi-line cell for platform
						platformRowProps.index === 0 && (
							<GroupCell
								rowSpan={platformRowProps.span}
								value={platformRowProps.id}
							/>
						)
					}

					<GroupCell rowSpan={rowSpan} value={date} />

					<StoreRowCells {...storeCheck} />
				</tr>
			}
			{nonStoreChecks.map((check, dateIndex) => {
				return (
					<tr
						className={
							gameIndex % 2 === 0 ? undefined : "bg-gray-100"
						}
						key={`${gameRowProps.id}.${platformRowProps.id}.${date}.${dateIndex}`}
					>
						<NonStoreRowCells
							check={check}
							sourceOfTruth={storeCheck}
						/>
					</tr>
				);
			})}
		</>
	);
};

const StoreRowCells = (props: {
	installs?: number;
	revenue?: number;
	purchases?: number;
}) => {
	const { installs, revenue, purchases } = props;
	return (
		<>
			<Cell value="STORE" isSource />
			<Cell value={installs} isSource colSpan={3} center />
			<Cell value={revenue} isSource colSpan={3} center />
			<Cell value={purchases} isSource colSpan={3} center />
		</>
	);
};

const NonStoreRowCells = (props: {
	check: DailyConsistencyValues;
	sourceOfTruth?: DailyConsistencyValues;
}) => {
	const {
		check: { source, installs, revenue, purchases },
		sourceOfTruth,
	} = props;

	return (
		<>
			<Cell value={source} isSource={false} />

			<Cell value={installs} isSource={false} center />
			<DifferenceCell
				value={installs}
				sourceValue={sourceOfTruth?.installs}
				center
			/>
			<DifferenceCell
				relative
				value={installs}
				sourceValue={sourceOfTruth?.installs}
				center
			/>

			<Cell value={revenue} isSource={false} center />
			<DifferenceCell
				value={revenue}
				sourceValue={sourceOfTruth?.revenue}
				center
			/>
			<DifferenceCell
				relative
				value={revenue}
				sourceValue={sourceOfTruth?.revenue}
				center
			/>

			<Cell value={purchases} isSource={false} center />
			<DifferenceCell
				value={purchases}
				sourceValue={sourceOfTruth?.purchases}
				center
			/>
			<DifferenceCell
				relative
				value={purchases}
				sourceValue={sourceOfTruth?.purchases}
				center
			/>
		</>
	);
};

const sourceIsStore = (item: { source: string }) => item.source === "STORE";
