import { SelectCurrentOrganization } from "@/modules/Organizations/components/Select";
import * as React from "react";

type HeaderProps = {
	navigation: JSX.Element;
	user: JSX.Element;
};

export const Header = ({ navigation, user }: HeaderProps) => {
	return (
		<div className="relative flex h-header-height ai-center bg-white border-b border-sidebar-font-light">
			<div className="center-v justify-between w-full h-full px-4 py-2">
				<div className="flex">{navigation}</div>
				<div className="center-v">
					<div className="mr-4">
						<SelectCurrentOrganization />
					</div>
					{user}
				</div>
			</div>
		</div>
	);
};

