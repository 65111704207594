import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { createClass } from "@/reexports";
import { Icon } from "@/views";
import React, { useEffect, useMemo, useState } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";
import { useUpdateManyInput } from "./logic/useUpdateManyInput";

export const DestinationsMatch = (
	fieldName: "bqDestinations" | "s3Destinations"
) =>
	createUIKitMatcher(
		({ field }) => field.name === fieldName || field.name === fieldName
	);

export const DestinationsField = (
	props:
		| UIFieldProps<{
				create: Destination[];
				delete: { id: string }[];
				update: Destination[];
		  }>
		| UIFieldProps<Destination[]>
) => {
	if (props.field.type.endsWith('UpdateManyInput')) {
		return (
			<DestinationsUpdateManyInputField
				{...(props as UIFieldProps<{
					create: Destination[];
					delete: { id: string }[];
					update: Destination[];
				}>)}
			/>
		);
	}

	return (
		<DestinationsCreateInputField
			{...(props as UIFieldProps<Destination[]>)}
		/>
	);
};

export const DestinationsCreateInputField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<Destination[]>) => {
	const fieldPath = path.concat(field.name).join(".");
	const [activeIndex, setActiveIndex] = useState<number>();

	useEffect(() => {
		if (data) {
			return;
		}
		form.setValue(fieldPath, []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const items = useMemo(() => {
		return (!data
			? []
			: data.map((item, index) => {
					return (
						<DestinationItem
							key={index}
							active={index === activeIndex}
							name={item.descriptiveName}
							onItem={() =>
								index === activeIndex
									? setActiveIndex(undefined)
									: setActiveIndex(index)
							}
							onRemove={() => {
								form.setValue(
									fieldPath,
									data.filter((_dest, i) => i !== index)
								);
								if (activeIndex !== undefined) {
									if (activeIndex === index) {
										setActiveIndex(undefined);
										return;
									}
									if (activeIndex >= index) {
										setActiveIndex(activeIndex - 1);
									}
								}
							}}
						/>
					);
			  })
		).concat(
			<Icon
				key="add"
				className="ml-2 hover:font-bold"
				name="plus"
				onClick={() => {
					form.pushFieldValue(fieldPath, {
						descriptiveName: `New ${camelCaseToWords(
							field.name
						).slice(0, -1)}`,
					} as Destination);
				}}
			/>
		);
	}, [activeIndex, data, field.name, fieldPath, form]);

	return (
		<div className="center-v">
			<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			<div>
				<InputContainer items={items} />
				{data &&
					activeIndex !== undefined &&
					activeIndex < data.length && (
						<div className="mt-6 pl-6">
							{field.fields?.map((innerField) => {
								return (
									<UIField
										key={`${innerField.name}`}
										data={
											// @ts-ignore
											data[activeIndex][innerField.name]
										}
										field={innerField}
										form={form}
										path={path.concat(
											`${field.name}[${activeIndex}]`
										)}
										UIField={UIField}
										uiKit={uiKit}
									/>
								);
							})}
						</div>
					)}
			</div>
		</div>
	);
};

export const DestinationsUpdateManyInputField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<{
	create: Destination[];
	delete: { id: string }[];
	update: Destination[];
}>) => {
	const {
		add,
		allItems,
		activeIndex,
		isActive,
		remove,
		toggleActive,
	} = useUpdateManyInput({ data, field, form, path });

	const items = allItems
		.map((item, index) => {
			return (
				<DestinationItem
					key={index}
					active={isActive(index)}
					name={item.descriptiveName}
					onItem={toggleActive(index)}
					onRemove={() => {
						remove(item, index);
					}}
				/>
			);
		})
		.concat(
			<Icon
				key="add"
				className="ml-2 hover:font-bold"
				name="plus"
				onClick={() => {
					add({
						descriptiveName: `New ${camelCaseToWords(
							field.name
						).slice(0, -1)}`,
					} as Destination);
					toggleActive(allItems.length);
				}}
			/>
		);

	return (
		<div className="center-v">
			<div className="w-48 mr-6">{camelCaseToWords(field.name)}</div>
			<div>
				<InputContainer items={items} />
				{activeIndex !== undefined &&
					activeIndex < allItems.length &&
					(activeIndex < data.update.length ? (
						<div className="mt-6 pl-6">
							{(
								(field.fields && field.fields[2]?.fields) ||
								[]
							).map((innerField) => {
								return (
									<UIField
										key={`${innerField.name}`}
										data={
											// @ts-ignore
											data.update[activeIndex][
												innerField.name
											]
										}
										field={innerField}
										form={form}
										path={path.concat(
											field.name,
											`update[${activeIndex}]`
										)}
										UIField={UIField}
										uiKit={uiKit}
									/>
								);
							})}
						</div>
					) : (
						<div className="mt-6 pl-6">
							{(
								(field.fields && field.fields[0]?.fields) ||
								[]
							).map((innerField) => {
								return (
									<UIField
										key={`${innerField.name}`}
										data={
											// @ts-ignore
											data.create[
												activeIndex - data.update.length
											][innerField.name]
										}
										field={innerField}
										form={form}
										path={path.concat(
											field.name,
											`create[${
												activeIndex - data.update.length
											}]`
										)}
										UIField={UIField}
										uiKit={uiKit}
									/>
								);
							})}
						</div>
					))}
			</div>
		</div>
	);
};

const InputContainer = (props: {
	items: JSX.Element[];
	endItem?: JSX.Element;
}) => {
	return (
		<div className="min-h-10 center-v flex-wrap gap-2 w-100 rounded-lg border-gray-500 border py-2 px-4">
			{props.items}
			{props.endItem && (
				<div className="absolute right-0 mr-2">{props.endItem}</div>
			)}
		</div>
	);
};

const DestinationItem = (props: {
	name: string;
	onItem: () => void;
	onRemove: () => void;
	active: boolean;
}) => {
	const { active, onItem, onRemove, name } = props;
	const classes = createClass("center-v p-2 bg-gray-200 rounded zoom-05", {
		"bg-blue-200": active,
	});
	return (
		<div className={classes}>
			<div
				className="center-v text-xs mr-2 cursor-pointer"
				onClick={onItem}
			>
				<div className="">{name || ""}</div>
			</div>
			<Icon
				className="cursor-pointer hover:font-bold"
				name="times"
				size="micro"
				onClick={onRemove}
			/>
		</div>
	);
};

type Destination = {
	id?: string;
	descriptiveName: string;
};
