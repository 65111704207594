import { Icon } from "@/views";
import { Select } from "@/views/Select";
import * as React from "react";
import { useOrganizationsQuery } from "../logic";
import { useCurrentOrganization } from "../logic/Current";

export const SelectCurrentOrganization = () => {
	const state = useOrganizationsQuery();
	const {
		currentOrganization,
		setCurrentOrganization,
	} = useCurrentOrganization();

	const options = React.useMemo(() => {
		if (state.data) {
			return state.data.organizations.map((organization) => {
				return {
					value: organization.id,
					label: organization.name,
				};
			});
		}
		return [];
	}, [state.data]);

	return (
		<Select
			className="cursor-pointer"
			placeholder={<div className="text-gray-400">Organization</div>}
			options={options}
			select={(e) => {
				setCurrentOrganization(e);
			}}
			value={currentOrganization?.id || ""}
			label={({ value, selected, toggle }) => {
				return (
					<div
						className="center-v gap-2 justify-between rounded-lg border-gray-500 border focus:outline-input py-2 px-4"
						onClick={toggle}
					>
						{state.loading ? (
							<Icon
								className="spin"
								name="spinner"
								weight="solid"
							/>
						) : selected ? (
							selected.label
						) : (
							"Nothing selected"
						)}
						<Icon name="chevron-down" />
					</div>
				);
			}}
		/>
	);
};
