import {
	BQCheckBoolArgument,
	BQCheckStringArgument,
	useBigQueryChecksData,
} from "@/modules/Checks/BigQuery";
import { PageControls, PageSizeDropdown } from "@/modules/Pagination";
import { useOpenable } from "@/modules/useOpenable";
import { Icon } from "@/views";
import React, { useState } from "react";
import {
	FilterInput,
	FilterPanel,
	FilterState,
} from "../ConsistencyChecks/Filters/FilterForm";
import { Table } from "./Table/Table";

const filterInputs: FilterInput<
	BQCheckStringArgument,
	BQCheckBoolArgument
>[] = [
	{ name: "Project ID", queryArgument: "projectId_contains", type: "string" },
	{ name: "Dataset name", queryArgument: "dataset_contains", type: "string" },
	{ name: "Table name", queryArgument: "tableName_contains", type: "string" },
	{
		name: "Table suffix",
		queryArgument: "tableSuffix_contains",
		type: "string",
	},
	{ name: "Exists", queryArgument: "tableExists", type: "boolean" },
	{
		name: "Last modified start",
		queryArgument: "lastModified_gte",
		type: "date",
		end: false,
	},
	{
		name: "Last modified end",
		queryArgument: "lastModified_lte",
		type: "date",
		end: true,
	},
	{
		name: "Last updated start",
		queryArgument: "updatedAt_gte",
		type: "date",
		end: false,
	},
	{
		name: "Last updated end",
		queryArgument: "updatedAt_lte",
		type: "date",
		end: true,
	},
	{
		name: "Max date start",
		queryArgument: "maxDate_gte",
		type: "date",
		end: false,
	},
	{
		name: "Max date end",
		queryArgument: "maxDate_lte",
		type: "date",
		end: true,
	},
];

export const View = () => {
	const {
		state,
		pagination,
		filters: savedFilters,
		setFilters: saveFilters,
	} = useBigQueryChecksData();

	const {
		hasNextPage,
		hasPreviousPage,
		nextPage,
		pageSize,
		previousPage,
		setPageSize,
	} = pagination;

	const [filters, setFilters] = useState<
		FilterState<BQCheckStringArgument, BQCheckBoolArgument>
	>(savedFilters);
	const { opened: filtersOpened, toggle: toggleFilters } = useOpenable(false);

	return (
		<div className="p-2 overflow-overlay">
			<div className="flex flex-col gap-2">
				<FilterPanel
					filterInputs={filterInputs}
					filters={filters}
					setFilters={setFilters}
					saveFilters={saveFilters}
					opened={filtersOpened}
					toggle={toggleFilters}
				/>
				<div className="center-v gap-2">
					<PageSizeDropdown
						pageSize={pageSize}
						setPageSize={setPageSize}
					/>
					<PageControls
						hasNextPage={hasNextPage}
						hasPreviousPage={hasPreviousPage}
						nextPage={nextPage}
						previousPage={previousPage}
					/>
				</div>
				<div className="center-v gap-2">
					{state.loading ? (
						<div className="btn-primary center-v gap-2">
							Refetch
							<Icon
								className="spin"
								name="spinner"
								weight="solid"
							/>
						</div>
					) : (
						<div
							className="btn-primary center-v gap-2"
							onClick={() => state.refetch && state.refetch()}
						>
							Refetch
							<Icon name="redo-alt" />
						</div>
					)}
				</div>

				<Table state={state} />

				<PageControls
					hasNextPage={hasNextPage}
					hasPreviousPage={hasPreviousPage}
					nextPage={nextPage}
					previousPage={previousPage}
				/>
			</div>
		</div>
	);
};
