import { Feed } from "@/services/graphApi/graphql-zeus";
import { Multiselect } from "@/views/Multiselect";
import React from "react";

export const FeedMultiselect = ({
	feeds,
	setFeeds,
}: {
	feeds: Feed[];
	setFeeds: (feeds: Feed[]) => void;
}) => {
	return (
		<Multiselect
			value={feeds}
			select={setFeeds}
			options={getFeedOptions()}
		/>
	);
};

export const getFeedOptions: () => { label: string; value: Feed }[] = () => [
	{ label: "Apple", value: Feed.APPLE },
	{ label: "AppleSearchAds", value: Feed.APPLE_SEARCH_ADS },
	{ label: "Applovin", value: Feed.APPLOVIN },
	{
		label: "AppsFlyer campaign",
		value: Feed.APPSFLYER_CAMPAIGN,
	},
	{
		label: "AppsFlyer datalocker",
		value: Feed.APPSFLYER_DATALOCKER,
	},
	{
		label: "AppsFlyer raw installs",
		value: Feed.APPSFLYER_RAW_INSTALLS,
	},
	{
		label: "AppsFlyer raw events",
		value: Feed.APPSFLYER_RAW_EVENTS,
	},
	{ label: "AppsFlyer scraper", value: Feed.APPSFLYER_SCRAPER },
	{ label: "Chartboost", value: Feed.CHARTBOOST },
	{ label: "Facebook ads", value: Feed.FACEBOOK_ADS },
	{ label: "Fairbid user report", value: Feed.FAIRBID_USER_REPORT },
	{ label: "Fixer", value: Feed.FIXER },
	{ label: "Google adwords", value: Feed.GOOGLE_ADWORDS },
	{ label: "Google play", value: Feed.GOOGLE_PLAY },
	{ label: "Harvest", value: Feed.HARVEST },
	{ label: "Iron source", value: Feed.IRON_SOURCE },
	{ label: "Iron source promotion", value: Feed.IRON_SOURCE_PROMOTION },
	{ label: "Libring", value: Feed.LIBRING },
	{ label: "Snapchat", value: Feed.SNAPCHAT },
	{ label: "Tiktok ads audience", value: Feed.TIKTOK_ADS_AUDIENCE },
	{ label: "Unity ads", value: Feed.UNITY_ADS },
	{ label: "Unity advertiser", value: Feed.UNITY_ADVERTISER },
];
