import React, { useEffect } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const ObjectMatch = () =>
	createUIKitMatcher(({ field }) => !!field.fields);

export const ObjectField = ({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<any>) => {
	const fieldPath = path.concat(field.name).join(".");
	useEffect(() => {
		if (data) {
			return;
		}
		form.setValue(fieldPath, field.array ? [] : {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (!field.array) {
		return (
			<>
				{field.fields?.map((fieldItem) => {
					return (
						<UIField
							key={fieldItem.name}
							field={fieldItem}
							form={form}
							data={data && data[fieldItem.name]}
							path={path.concat(field.name)}
							uiKit={uiKit}
							UIField={UIField}
						/>
					);
				})}
			</>
		);
	}

	return (
		<>
			<div>
				<button
					onClick={(e) => {
						e.preventDefault();
						form.pushFieldValue(fieldPath, {});
					}}
				>
					Add {field.name}
				</button>
			</div>
			{(data || []).map((item: any, index: number) => {
				return (
					<React.Fragment key={index}>
						{field.fields?.map((fieldItem) => {
							return (
								<UIField
									key={`${fieldItem.name}${index}`}
									field={fieldItem}
									form={form}
									data={item && item[fieldItem.name]}
									path={path.concat(
										`${field.name}[${index}]`
									)}
									uiKit={uiKit}
									UIField={UIField}
								/>
							);
						})}
						<div>
							<button
								onClick={(e) => {
									e.preventDefault();
									form.removeFieldValue(fieldPath, index);
								}}
							>
								Remove
							</button>
						</div>
					</React.Fragment>
				);
			})}
		</>
	);
};
