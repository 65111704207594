import * as Page from "@/components/Page";
import React from "react";
import { hot } from "react-hot-loader";
import { NotificationContainer } from "@/services/notification";

export const App = hot(module)(() => {
	return (
		<div>
			<NotificationContainer
				position="bottom-right"
				hideProgressBar
			/>
			<Page.View />
		</div>
	);
});
