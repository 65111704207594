import React, { SetStateAction, Dispatch } from "react";
import fp from "lodash/fp";
import { unit } from "@/functions/unit";
import { createClass } from "@/reexports";
import * as Views from "@/views";

const buttonClass = createClass(
	"center",
	"rounded",
	"py-2",
	"px-4",
	"bg-c-accent2",
	"hover:bg-c-accent2-darkened",
	"text-white",
	"cursor-pointer",
	"mb-6 md:mb-0"
);

const inputClass = createClass(
	"block",
	"appearance-none",
	"ellipsis",
	"border",
	"border-gray-400",
	"hover:border-gray-500",
	"text-xs",
	"focus:outline-none",
	"px-4",
	"py-2",
	"w-full",
	"leading-tight",
	"focus:outline-none",
	"focus:shadow-outline"
)

const ToggleFiltersButton = (props: {
	toggle: typeof unit;
	opened: boolean
}) => {
	const {toggle, opened} = props;
	return (
		<div className={buttonClass} onClick={toggle}>
			<Views.Icon name="filter" className="mr-2" />
			<span>
				{ opened ? 'Hide' : 'Show' } filters
			</span>
		</div>
	);
};

type StringInputType<S extends string> =
	| { type: "string", queryArgument: S, name: string }
	| { type: "date", queryArgument: S, end: boolean, name: string }
	| DropdownInputType<S>;

type DropdownInputType<S extends string> = { type: "dropdown"; values: string[]; queryArgument: S, name: string };

type BooleanInputType<B extends string> = { type: "boolean", queryArgument: B, name: string };

export type FilterInput<S extends string, B extends string> =
	StringInputType<S> | BooleanInputType<B>

export type FilterState<S extends string, B extends string> =
	{ [key in S]?: string } & { [key in B]?: boolean }

type NULL_TYPE = "NULL_OPTION";
const NULL_OPTION: NULL_TYPE = "NULL_OPTION";

const DropdownInput = <S extends string>(props: {
	dropdownInput: DropdownInputType<S>;
	onChange: (value: string) => void;
	name: string;
	selectedValue?: string;
}) => {
	const { dropdownInput, onChange, name, selectedValue } = props;
	return <div className="inline-block relative w-full">
		<select
			className={inputClass}
			onChange={(event) => onChange(event.target.value)}
			name={name}
			defaultValue={selectedValue || NULL_OPTION}
		>
			<>
				{dropdownInput.values.map((value) => (<option key={value} value={value} >{value}</option>))}
				<option key="null" value={NULL_OPTION} >Any</option>
			</>
		</select>
		<div className="pointer-events-none absolute inset-y-0 right-0 mx-2 flex items-center px-2 text-gray-700">
			<Views.Icon name="chevron-down" className="fill-current h-4 w-4" />
		</div>
	</div>
}

const BooleanInput = (props: {
	onChange: (value: boolean | NULL_TYPE) => void;
	name: string;
	selectedValue: boolean | NULL_TYPE;
}) => {
	const { onChange, name, selectedValue } = props;

	const toBoolean = (v: string | undefined) => {
		if (v === "true") return true;
		else if (v === "false") return false;
		else return NULL_OPTION;
	}

	return <div className="inline-block relative w-full">
		<select
			className={inputClass}
			onChange={(event) => onChange(toBoolean(event.target.value))}
			name={name}
			defaultValue={selectedValue !== NULL_OPTION ? String(selectedValue) : NULL_OPTION}
		>
			<option key="true" value={"true"} >True</option>
			<option key="false" value={"false"} >False</option>
			<option key="null" value={NULL_OPTION} >Any</option>
		</select>
		<div className="pointer-events-none absolute inset-y-0 right-0 mx-2 flex items-center px-2 text-gray-700">
			<Views.Icon name="chevron-down" className="fill-current h-4 w-4" />
		</div>
	</div>;
}

const FilterInputLabel = (props: { label: string, forName: string }) => {
	const { label, forName } = props;
	return <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={forName}>
		{ label }
	</label>;
}

const FilterFormInput = <S extends string, B extends string>(props: {
	filterInput: FilterInput<S, B>;
	filters: FilterState<S, B>;
	setFilters: Dispatch<SetStateAction<FilterState<S, B>>>;
}) => {
	const { filterInput, filters, setFilters } = props;
	const inputWrappingClass = "w-full md:w-1/4 lg:w-1/6 mb-6 md:mb-0 px-3 mt-3";

	const filterValue = filters[filterInput.queryArgument];

	if (filterInput.type === "dropdown") {
		const inputName = `dropdown_${filterInput.queryArgument}`;
		return <div className={inputWrappingClass}>
			<FilterInputLabel label={filterInput.name} forName={inputName} />
			<DropdownInput
				name={inputName}
				dropdownInput={filterInput}
				onChange={
					(value) => value !== NULL_OPTION
						? setFilters({ ...filters, [filterInput.queryArgument]: value })
						: setFilters(fp.omit(filterInput.queryArgument)(filters))
				}
				selectedValue={filterValue !== undefined ? filterValue : NULL_OPTION}
			/>
		</div>;
	}
	else if (filterInput.type === "boolean") {
		const inputName = `dropdown_${filterInput.queryArgument}`;
		return <div className={inputWrappingClass}>
			<FilterInputLabel label={filterInput.name} forName={inputName} />
			<BooleanInput
				name={inputName}
				onChange={
					(value) => value !== NULL_OPTION
						? setFilters({...filters, [filterInput.queryArgument]: value})
						: setFilters(fp.omit(filterInput.queryArgument)(filters))
				}
				selectedValue={filterValue !== undefined ? Boolean(filterValue) : NULL_OPTION}
			/>
		</div>;
	}
	else if (filterInput.type === "string") {
		const inputName = `input_${filterInput.queryArgument}`;
		return <div className={inputWrappingClass}>
			<FilterInputLabel label={filterInput.name} forName={inputName} />
			<input
				name={inputName}
				className={inputClass}
				type={filterInput.type}
				value={filterValue !== undefined ? filterValue : ""}
				onChange={
					(event) => event.target.value !== ''
						? setFilters({ ...filters, [filterInput.queryArgument]: event.target.value })
						: setFilters(fp.omit(filterInput.queryArgument)(filters))
				}
			/>
		</div>;
	}
	else if (filterInput.type === "date") {
		const inputName = `date_${filterInput.queryArgument}`;

		const makeInclusiveRange = (value: string) => `${value}T${filterInput.end ? '23:59:59' : '00:00:00'}`;

		const value = filterValue as string | undefined;
		const inputValue = value !== undefined ? value.substring(0, 10) : "";

		return <div className={inputWrappingClass}>
			<FilterInputLabel label={filterInput.name} forName={inputName} />
			<input
				name={inputName}
				className={inputClass}
				type="date"
				value={inputValue}
				onChange={
					(event) => {
						event.target.value !== ''
							? setFilters({ ...filters, [filterInput.queryArgument]: makeInclusiveRange(event.target.value) })
							: setFilters(fp.omit(filterInput.queryArgument)(filters));

						event.preventDefault();
					}
				}
			/>
		</div>;
	}
	else return <div/>;
}
const FilterForm = <S extends string, B extends string>(props: {
	filterInputs: FilterInput<S, B>[];
	filters: FilterState<S, B>;
	setFilters: Dispatch<SetStateAction<FilterState<S, B>>>;
	saveFilters: Dispatch<SetStateAction<FilterState<S, B>>>;
}) => {
	const { filterInputs, filters, setFilters, saveFilters } = props;

	const cleanFilters = () => {
		setFilters({});
		saveFilters({});
	}

	return <div className="p-2 border-b border-1 border-gray-300 mb-2" >
		<div className="flex flex-wrap mb-2" >
			{ filterInputs.map((input) => <FilterFormInput filterInput={input} filters={filters} setFilters={setFilters} key={input.queryArgument} /> )}
		</div>
		<div className="mb-2 flex flex-wrap mr-0 ml-auto w-full">
			<button className={createClass(buttonClass, "d-block ml-auto mr-2")} onClick={cleanFilters}>
				Clean filters
			</button>
			<button className={createClass(buttonClass, "d-block mr-0")} onClick={() => saveFilters(filters)}>
				Save filters
			</button>
		</div>
	</div>;
}

// S - string field arguments, B - boolean field arguments
export const FilterPanel = <S extends string, B extends string>(props: {
	filterInputs: FilterInput<S, B>[];
	filters: FilterState<S, B>;
	setFilters: Dispatch<SetStateAction<FilterState<S, B>>>;
	saveFilters: Dispatch<SetStateAction<FilterState<S, B>>>;
	opened: boolean;
	toggle: () => void;
}) => {
	const { filterInputs, filters, setFilters, saveFilters, opened, toggle } = props;

	return <div className="d-block">
		<ToggleFiltersButton toggle={toggle} opened={opened} />
		{
			opened && <FilterForm filterInputs={filterInputs} filters={filters} setFilters={setFilters} saveFilters={saveFilters} /> 
		}
	</div>;
}
