export type Model = {
	leftOperand: {
		value: string;
		label: string;
		type: "string" | "number" | "date";
	};
	operator: Operator;
	value: string | number;
};

export type Operator = {
	value: string;
	label: string;
	type: "string" | "string[]" | "number" | "date";
};

type CreateProps = {
	leftOperand: {
		value: string;
		label: string;
		type: "string" | "number" | "date";
	};
	operator?: string;
	value?: string | number;
};

export const create = (props: CreateProps): Model => {
	const operator = schema[props.leftOperand.type].find(
		item => item.value === props.operator
	) as Operator;
	const defaultOperator = schema[props.leftOperand.type][0];
	return {
		leftOperand: props.leftOperand,
		operator: {
			value: props.operator || operator.value,
			label: props.operator ? operator.label : defaultOperator.label,
			type: props.operator ? operator.type : defaultOperator.type
		},
		value: props.value || props.leftOperand.type === "number" ? 0 : ""
	};
};

export const defaultOperator = (
	type: "string" | "number" | "date"
): Operator => {
	return schema[type][0];
};

export const operatorIsOfArrayType = (operator: string): boolean =>
	operator === "in" || operator === "not_in";

export const getNumberOperators = () => [...schema.number];
export const getStringOperators = () => [...schema.string];
export const getDateOperators = () => [...schema.date];

export type Schema = {
	number: Operator[];
	string: Operator[];
	date: Operator[];
};

const schema: Schema = {
	number: [
		{ value: "equals", label: "=", type: "number" },
		{ value: "not_equals", label: "!=", type: "number" },
		{ value: "greater_than_or_equal", label: ">=", type: "number" },
		{ value: "lesser_than_or_equal", label: "<=", type: "number" }
	],
	string: [
		{ value: "contains", label: "contains", type: "string" },
		{ value: "not_contains", label: "!contains", type: "string" },
		{ value: "equals", label: "=", type: "string" },
		{ value: "not_equals", label: "!=", type: "string" },
		{ value: "starts_with", label: "starts with", type: "string" },
		{ value: "not_starts_with", label: "!starts with", type: "string" },
		{ value: "in", label: "in", type: "string[]" },
		{ value: "not_in", label: "!in", type: "string[]" },
		{ value: "wildcard", label: "?*", type: "string" },
		{ value: "not_wildcard", label: "!?*", type: "string" }
	],
	date: [
		{ value: "equals", label: "=", type: "date" },
		{ value: "not_equals", label: "!=", type: "date" },
		{ value: "greater_than_or_equal", label: ">=", type: "date" },
		{ value: "lesser_than_or_equal", label: "<=", type: "date" }
	]
};
