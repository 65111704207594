import React from "react";

export const View = () => {
	return (
		<div>
			<div>
				<h1>Fok</h1>
			</div>
		</div>
	);
};
