import { createClass } from "@/reexports";
import * as React from "react";

export interface InputProps<T> {
	className?: string;
	value?: T;
	defaultValue?: T;
	onChange: (a: T) => T;
	onConfirm?: (a: T) => T;
	placeholder?: string;
	type?: "string" | "number" | "date" | "checkbox" | "password";
	id?: string;
}

export const ViewInput = React.forwardRef<
	HTMLInputElement,
	InputProps<string | number | boolean>
>(
	(
		{
			className,
			value = undefined,
			defaultValue = undefined,
			onChange,
			onConfirm,
			type = "string",
			placeholder,
			id
		}
	) => {
		const classes = createClass(
			className,
			"border focus:outline-input py-2 px-4"
		);
		const [state, setState] = React.useState<string | number | boolean>(
			value || defaultValue || ""
		);

		const hasValue = value !== undefined && value !== null;
		const parseInputEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (type === "checkbox") return e.target.checked;
			if (type === "number") return parseInt(e.target.value, 10) || "";
			return e.target.value || "";
		};

		const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const val = parseInputEvent(e);
			const afterChange = onChange ? onChange(val) : val;
			if (!hasValue) {
				setState(afterChange);
			}
		};

		const _onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (!onConfirm) return;
			if (e.key !== "Enter") return;
			if (!hasValue) {
				const afterConfirmed = onConfirm(state);
				setState(afterConfirmed);
				return;
			}
			onConfirm(value || "");
		};

		return (
			<input
				className={classes}
				name={id}
				onChange={_onChange}
				onKeyPress={_onKeyPress}
				placeholder={placeholder || `${type}...`}
				type={type}
				id={id}
				{...(type === "checkbox" ? { checked: value as boolean } : {})}
			/>
		);
	}
);
