import { useState } from "react";
import { useOnClickOutside } from "@/functions/hooks/useOnClickOutside";

export const usePopup = (ref: React.MutableRefObject<HTMLElement | null>) => {
	const [opened, setOpened] = useState(false);

	const toggle = () => setOpened(!opened);
	const open = () => setOpened(true);
	const close = () => setOpened(false);

	useOnClickOutside(ref, close)

	return { opened, toggle, open, close };
};
