import { useCallback, useMemo, useState } from "react";

export type PageSize = 10 | 25 | 50 | 100;

export type Pagination = {
	first?: number;
	last?: number;
	before?: string;
	after?: string;
	skip?: number;
};

export type PageInfo = {
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	startCursor?: string;
	endCursor?: string;
};

export const usePagination = (initialPageSize: number = 50) => {
	const [pagination, setPagination] = useState<Pagination>({
		first: initialPageSize,
	});

	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();
	const [pageInfo, setPageInfo] = useState<PageInfo>();
	const [pageSize, _setPageSize] = useState(initialPageSize);
	const [currentPage, setCurrentPage] = useState(1);

	const numberOfPages = useMemo(
		() => Math.floor(count / pageSize) + (count % pageSize === 0 ? 0 : 1),
		[count, pageSize]
	);

	const hasNextPage = useMemo(() => {
		if (loading || error) return false;
		return currentPage * pageSize < count;
	}, [count, currentPage, error, loading, pageSize]);

	const nextPage = useCallback(() => {
		if (!hasNextPage) return;
		setPagination((pagination) => ({
			first: pagination.first || pagination.last,
			after: pageInfo?.endCursor,
		}));
		setCurrentPage((p) => p + 1);
	}, [hasNextPage, pageInfo]);

	const hasPreviousPage = useMemo(() => {
		if (loading || error) return false;
		return currentPage !== 1;
	}, [currentPage, error, loading]);

	const previousPage = useCallback(() => {
		if (!hasPreviousPage) return;
		setPagination((pagination) => ({
			last: pagination.last || pagination.first,
			before: pageInfo?.startCursor,
		}));
		setCurrentPage((p) => p - 1);
	}, [hasPreviousPage, pageInfo]);

	const setPageSize = useCallback((size: number) => {
		setPagination(() => ({
			first: size,
		}));
		_setPageSize(size);
		setCurrentPage(1);
	}, []);

	const reset = useCallback(() => {
		setPagination(() => ({
			first: pageSize,
		}));
		_setPageSize(pageSize);
		setCurrentPage(1);
	}, [pageSize, _setPageSize]);

	return {
		pagination,
		previousPage,
		nextPage,
		setPagination,
		hasNextPage,
		hasPreviousPage,
		setCount,
		setLoading,
		setError,
		setPageSize,
		setPageInfo,
		pageSize,
		currentPage,
		numberOfPages,
		reset,
	};
};
