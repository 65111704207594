import { useTypedLazyQuery, useTypedMutation, useTypedQuery } from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useTiktokAccessesQuery = () =>
	useTypedQuery(
		{
			tiktokAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				TiktokAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useTiktokAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			tiktokAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				TiktokAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useTiktokAccessQuery = () =>
	useTypedLazyQuery(
		{
			tiktokAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				TiktokAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateTiktokAccess = () => {
	return useTypedMutation({
		createTiktokAccess: [
			{
				data: $`data`,
			},
			TiktokAccessSelector,
		],
	});
};
export const useUpdateTiktokAccess = () => {
	return useTypedMutation({
		updateTiktokAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			TiktokAccessSelector,
		],
	});
};
export const useDeleteTiktokAccess = () => {
	return useTypedMutation({
		deleteTiktokAccess: [
			{
				where: $`where`,
			},
			TiktokAccessSelector,
		],
	});
};

export const TiktokAccessSelector = ZeusSelect<ValueTypes["TiktokAccess"]>()({
	id: true,
	descriptiveName: true,
	advertiserId: true,
	accessToken: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
