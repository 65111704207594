import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useAppsFlyerAccessesQuery = () =>
	useTypedQuery(
		{
			appsFlyerAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				AppsFlyerAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useAppsFlyerAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			appsFlyerAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				AppsFlyerAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useAppsFlyerAccessQuery = () =>
	useTypedLazyQuery(
		{
			appsFlyerAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				AppsFlyerAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateAppsFlyerAccess = () => {
	return useTypedMutation({
		createAppsFlyerAccess: [
			{
				data: $`data`,
			},
			AppsFlyerAccessSelector,
		],
	});
};
export const useUpdateAppsFlyerAccess = () => {
	return useTypedMutation({
		updateAppsFlyerAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			AppsFlyerAccessSelector,
		],
	});
};
export const useDeleteAppsFlyerAccess = () => {
	return useTypedMutation({
		deleteAppsFlyerAccess: [
			{
				where: $`where`,
			},
			AppsFlyerAccessSelector,
		],
	});
};

export const AppsFlyerAccessSelector = ZeusSelect<
	ValueTypes["AppsFlyerAccess"]
>()({
	id: true,
	descriptiveName: true,
	apiToken: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
