import React from "react";
import { Field } from "@/services/graphApi";
import { ObjectMatch, ObjectField } from "./Object";
import { StringMatch, StringField } from "./String";
import { BooleanMatch, BooleanField } from "./Boolean";
import { Form } from "@/modules/Form/logic";

export const createUIKitMatcher = (
	fn: (field: { path: string[]; field: Field }) => boolean
) => fn;

export type UIKit<R> = [
	(field: { path: string[]; field: Field }) => boolean,
	(props: UIFieldProps<R>) => JSX.Element | null
];

export type UIFieldProps<R = any> = {
	data: R;
	form: Form;
	path: string[];
	field: Field;
	uiKit: UIKit<any>[];
	UIField: <T = any>(props: UIFieldProps<T>) => JSX.Element | null;
};

export const UIField = <R, uknown>({
	form,
	data,
	field,
	path = [],
	uiKit,
	UIField,
}: UIFieldProps<R>) => {
	const found = uiKit.find(([matchingFn]) =>
		matchingFn({ path, field })
	) as UIKit<R>;
	if (!found) return null;
	const [, Component] = found;
	return (
		<Component
			key={path.concat(field.name).join(".")}
			field={field}
			form={form}
			data={data}
			uiKit={uiKit}
			path={path}
			UIField={UIField}
		/>
	);
};

export const createUIKit = <D, uknown>(dependencies?: D) => <R, uknown>(
	uiKit: UIKit<R>[]
): UIKit<R>[] => uiKit;

export const basicKit = () => [
	[ObjectMatch(), ObjectField],
	[StringMatch(), StringField],
	[BooleanMatch(), BooleanField],
];
