import { FilterState } from "@/components/Page/ConsistencyChecks/Filters/FilterForm";
import { useCurrentOrganization } from "@/modules/Organizations/logic/Current";
import { usePagination } from "@/modules/usePagination";
import { useTypedLazyQuery } from "@/services/graphApi";
import {
	$,
	DailyConsistencyCheckOrderByInput,
	ValueTypes,
	ZeusSelect,
} from "@/services/graphApi/graphql-zeus";
import { useEffect, useState } from "react";

export type DailyConsistencyCheckQueryArgument =
	| "gameId_contains"
	| "platform"
	| "date_gte"
	| "date_lte";

export const useDailyConsistencyChecksData = () => {
	const { currentOrganization } = useCurrentOrganization();
	const pagination = usePagination();
	const [filters, setFilters] = useState<
		FilterState<DailyConsistencyCheckQueryArgument, never>
	>({});

	const [getDailyConsistencyChecks, state] = useDailyConsistencyChecksQuery();

	useEffect(() => {
		pagination.setCount(
			state.data?.dailyConsistencyChecksConnection.aggregate.count || 0
		);
		pagination.setLoading(state.loading);
		pagination.setError(state.error && JSON.stringify(state.error));
		pagination.setPageInfo(
			state.data?.dailyConsistencyChecksConnection.pageInfo
		);
	}, [pagination, state.data, state.error, state.loading]);

	const { reset } = pagination;
	useEffect(() => {
		reset();
	}, [filters, currentOrganization, reset]);

	useEffect(() => {
		getDailyConsistencyChecks({
			variables: {
				...pagination.pagination,
				...filters,
				organizationId: currentOrganization?.id,
			},
		});
	}, [
		currentOrganization,
		pagination.pagination,
		getDailyConsistencyChecks,
		filters,
	]);

	return {
		state,
		pagination,
		filters,
		setFilters,
	};
};

const useDailyConsistencyChecksQuery = () =>
	useTypedLazyQuery(
		{
			dailyConsistencyChecksConnection: [
				{
					first: $`first`,
					last: $`last`,
					before: $`before`,
					after: $`after`,
					skip: $`skip`,
					orderBy: DailyConsistencyCheckOrderByInput.createdAt_DESC,
					where: {
						platform: $`platform`,
						gameId_contains: $`gameId_contains`,
						date_gte: $`date_gte`,
						date_lte: $`date_lte`,
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				DailyConsistencyCheckConnection(),
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

const DailyConsistencyCheckConnection = () =>
	ZeusSelect<ValueTypes["DailyConsistencyCheckConnection"]>()({
		aggregate: {
			count: true,
		},
		edges: {
			node: DailyConsistencyCheckSelector(),
			cursor: true,
		},
		pageInfo: {
			hasNextPage: true,
			hasPreviousPage: true,
			startCursor: true,
			endCursor: true,
		},
	});

const DailyConsistencyCheckSelector = () =>
	ZeusSelect<ValueTypes["DailyConsistencyCheck"]>()({
		id: true,
		createdAt: true,
		updatedAt: true,
		date: true,
		gameId: true,
		platform: true,
		values: [
			{},
			{
				source: true,
				installs: true,
				purchases: true,
				revenue: true,
			},
		],
	});
