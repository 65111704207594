import { useMemo, useState } from "react";
import { UIFieldProps } from "../../uiKit";

export const useUpdateManyInput = <R extends { id?: string }>({
	data,
	field,
	path,
	form,
}: Omit<
	UIFieldProps<{
		create: R[];
		delete: { id: string }[];
		update: R[];
	}>,
	"uiKit" | "UIField"
>) => {
	const fieldPath = path.concat(field.name).join(".");
	const [activeIndex, setActiveIndex] = useState<number>();

	const isActive = (index: number) => index === activeIndex;
	const toggleActive = (index: number) => () =>
		isActive(index) ? setActiveIndex(undefined) : setActiveIndex(index);

	const allItems = useMemo(() => {
		return [...data.update, ...data.create];
	}, [data]);

	const add = (value?: R) =>
		form.pushFieldValue(`${fieldPath}.create`, value || {});

	const remove = (item: { id?: string }, index: number) => {
		form.setValue(fieldPath, {
			create: item.id
				? data.create
				: data.create.filter(
						(_item, i) => i !== index - data.update.length
				  ),
			delete: item.id ? [...data.delete, { id: item.id }] : data.delete,
			update: item.id
				? data.update.filter((_item, i) => i !== index)
				: data.update,
		});
		if (activeIndex !== undefined) {
			if (isActive(index)) {
				setActiveIndex(undefined);
				return
			}
			if (activeIndex >= index) {
				setActiveIndex(activeIndex - 1)
			}
		}
	};

	return {
		add,
		allItems,
		remove,
		activeIndex,
		isActive,
		toggleActive,
	};
};
