import { useOrganizationQuery } from "@/modules/Organizations/logic";
import { useRouter } from "@/modules/Router";
import {
	useInviteUser,
	User,
	useUpdateUserRoles,
	useUsersQuery,
} from "@/modules/UserManagement/logic";
import { UserRole, userRoles } from "@/modules/Viewer/components/Viewer";
import { notifySuccess } from "@/services/notification";
import { LoadingIcon } from "@/ui/views/LoadingIcon";
import { Icon } from "@/views";
import { Multiselect } from "@/views/Multiselect";
import { Searchbar } from "@/views/Searchbar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createStore } from "reusable";
import { Logo } from "./Logo";

enum SORT_OPTIONS {
	CREATED_AT = "CREATED_AT",
	UPDATED_AT = "UPDATED_AT",
	EMAIL = "EMAIL",
}

const useWorkspaceDetailFilters = () => {
	const [search, setSearch] = useState<string>("");
	const [createdAfter, setCreatedAfter] = useState<string>();
	const [createdBefore, setCreatedBefore] = useState<string>();
	const [updatedAfter, setUpdatedAfter] = useState<string>();
	const [updatedBefore, setUpdatedBefore] = useState<string>();

	const [sortBy, setSortBy] = useState<SORT_OPTIONS>(SORT_OPTIONS.EMAIL);

	return {
		search,
		setSearch,
		createdAfter,
		setCreatedAfter,
		createdBefore,
		setCreatedBefore,
		updatedAfter,
		setUpdatedAfter,
		updatedBefore,
		setUpdatedBefore,
		sortBy,
		setSortBy,
	};
};

export const useWorkspaceActiveDetail = createStore(() => {
	const router = useRouter();
	const id = useMemo(() => {
		return router.route.route === "workspaces-detail"
			? router.route.params.id
			: "";
	}, [router.route]);

	return {
		id,
	};
});

export const WorkspaceDetail = ({ id }: { id: string }) => {
	return (
		<div className="flex flex-col mt-6 gap-6 mx-20">
			<Settings id={id} />
			<UserManagement />
		</div>
	);
};

const Settings = ({ id }: { id: string }) => {
	const organization = useOrganizationQuery(id);
	const [name, setName] = useState(
		organization.data?.organization?.name || ""
	);

	useEffect(() => {
		if (organization.data) {
			setName(organization.data?.organization?.name || "");
		}
	}, [organization.data]);

	const disabled = useMemo(() => {
		return !organization.data?.organization;
	}, [organization]);

	return (
		<div className="flex flex-col gap-6 py-8 px-9 rounded-lg shadow-flat-down bg-white">
			<div className="w-full flex">
				<div className="mr-9">
					<Logo id={id} size="gigantic" />
				</div>
				<div className="flex flex-col gap-4 w-full">
					<div className="center-v">
						<label htmlFor="name" className="w-80">
							Name
						</label>
						<input
							readOnly
							disabled={disabled}
							className="input w-full"
							id="name"
							type="text"
							value={name}
						/>
					</div>
					<div className="center-v">
						<label htmlFor="imageUrl" className="w-80">
							Logo
						</label>
						<input
							disabled
							className="input w-full"
							id="imageUrl"
							type="text"
						/>
					</div>
				</div>
			</div>
			<div className="flex justify-end">
				<button className="btn-primary">Save</button>
			</div>
		</div>
	);
};

const UserManagement = () => {
	return (
		<div className="flex flex-col gap-6 py-8 px-9 rounded-lg shadow-flat-down bg-white">
			<div className="center">
				<span className="text-2xl font-bold">Members {"&"} Rights</span>
			</div>
			<UsersControls />
			<UsersTable />
		</div>
	);
};

const UsersControls = () => {
	return (
		<div className="flex gap-4">
			<UsersSearchbar />
			<UserInvite />
		</div>
	);
};
const UsersSearchbar = () => {
	const { search, setSearch } = useWorkspaceDetailFilters();

	return (
		<Searchbar
			className="w-80 bg-white"
			onChange={(e) => setSearch(e.target.value)}
			value={search}
			placeholder="Search...."
		/>
	);
};

const UserInvite = () => {
	const { id } = useWorkspaceActiveDetail();
	const users = useUsersQuery({ organizationId: id });
	const [state, setState] = useState("");
	const [invite, inviteState] = useInviteUser();

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (!state) return;
				invite({
					variables: { email: state, organizationId: id },
					update: () => {
						notifySuccess(`${state} was added to the users`);
						setState("");
						users.refetch();
					},
				});
			}}
			className="w-80 relative center-v gap-2 input"
		>
			<input
				className="w-full bg-transparent outline-none"
				onChange={(e) => setState(e.target.value)}
				value={state}
				pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
				type="email"
				placeholder="email"
			/>
			{inviteState.loading ? (
				<LoadingIcon />
			) : (
				<button
					type="submit"
					className="right-0 transform -translate-x-1 absolute text-sm btn-primary"
				>
					Invite
				</button>
			)}
		</form>
	);
};

const UsersTable = () => {
	return (
		<div className="flex flex-col gap-4">
			<HeaderRow />
			<UserRows />
		</div>
	);
};

const HeaderRow = () => {
	return (
		<div className="center-v mb-4">
			<span className="w-2/12 text-center">Name</span>
			<span className="w-3/12 text-center">E-mail</span>
			<span className="w-3/12 text-center">Roles</span>
			<span className="w-2/12 text-center">Created At</span>
			<span className="w-2/12 text-center">Updated At</span>
		</div>
	);
};

const UserRows = () => {
	const { id } = useWorkspaceActiveDetail();
	const users = useUsersQuery({ organizationId: id });

	if (users.loading)
		return (
			<LoadingIcon className="h-144 center text-blue-300" size="huge" />
		);

	return (
		<div className="flex flex-col gap-4 h-144 overflow-overlay">
			{users.data?.users.map((user) => (
				<UserRow
					key={user.id}
					user={{
						...user,
						organizationMemberships:
							user.organizationMemberships || [],
					}}
				/>
			))}
		</div>
	);
};

const UserRow = ({ user }: { user: User }) => {
	const { id } = useWorkspaceActiveDetail();
	const users = useUsersQuery({ organizationId: id });
	const [update, updateState] = useUpdateUserRoles();
	const getRoles = useCallback(
		() => user.organizationMemberships.map((m) => m.role),
		[user]
	);
	const [dirty, setDirty] = useState(false);
	const [roles, _setRoles] = React.useState<UserRole[]>(() => getRoles());

	const setRoles = useCallback((value: UserRole[]) => {
		setDirty(true);
		_setRoles(value);
	}, []);

	const save = useCallback(() => {
		update({
			variables: {
				organizationId: id,
				roles,
				userId: user.id,
			},
			update: () => {
				setDirty(false);
				users.refetch();
			},
		});
	}, [id, roles, update, user.id, users]);

	const dissmiss = useCallback(() => {
		setDirty(false);
		_setRoles(getRoles());
	}, [getRoles]);

	return (
		<div className="center-v text-xs">
			<span className="ellipsis w-2/12 text-center ">{user.email}</span>
			<span className="w-3/12 text-center">{user.email}</span>
			<span className="w-3/12 center relative">
				<Multiselect
					className="w-full"
					value={roles}
					select={setRoles}
					options={userRoles.map((role) => ({
						label: role,
						value: role,
					}))}
				/>
				{dirty && (
					<div className="absolute center-v gap-2 right-0 transform translate-x-10">
						{updateState.loading ? (
							<LoadingIcon />
						) : (
							<Icon
								className="cursor-pointer text-success"
								name="check"
								onClick={save}
							/>
						)}
						<Icon
							className="cursor-pointer text-danger"
							name="times"
							onClick={dissmiss}
						/>
					</div>
				)}
			</span>
			<span className="w-2/12 text-center">TBA</span>
			<span className="w-2/12 text-center">TBA</span>
		</div>
	);
};
