import { useMemo } from "react";
import {
	AppleAccessSelector,
	useAppleAccessesLazyQuery,
	useAppleAccessesQuery,
	useAppleAccessQuery,
	useCreateAppleAccess,
	useDeleteAppleAccess,
	useUpdateAppleAccess,
} from "./AppleAccess/logic";
import {
	AppleSearchAdsAccessSelector,
	useAppleSearchAdsAccessesLazyQuery,
	useAppleSearchAdsAccessesQuery,
	useAppleSearchAdsAccessQuery,
	useCreateAppleSearchAdsAccess,
	useDeleteAppleSearchAdsAccess,
	useUpdateAppleSearchAdsAccess,
} from "./AppleSearchAdsAccess/logic";
import {
	ApplovinAccessSelector,
	useApplovinAccessesLazyQuery,
	useApplovinAccessesQuery,
	useApplovinAccessQuery,
	useCreateApplovinAccess,
	useDeleteApplovinAccess,
	useUpdateApplovinAccess,
} from "./ApplovinAccess/logic";
import {
	AppsFlyerAccessSelector,
	useAppsFlyerAccessesLazyQuery,
	useAppsFlyerAccessesQuery,
	useAppsFlyerAccessQuery,
	useCreateAppsFlyerAccess,
	useDeleteAppsFlyerAccess,
	useUpdateAppsFlyerAccess,
} from "./AppsFlyerAccess/logic";
import {
	BigQueryProjectSelector,
	useBigQueryProjectQuery,
	useBigQueryProjectsLazyQuery,
	useBigQueryProjectsQuery,
	useCreateBigQueryProject,
	useDeleteBigQueryProject,
	useUpdateBigQueryProject,
} from "./BigQueryProjects/logic";
import {
	ChartboostAccessSelector,
	useChartboostAccessesLazyQuery,
	useChartboostAccessesQuery,
	useChartboostAccessQuery,
	useCreateChartboostAccess,
	useDeleteChartboostAccess,
	useUpdateChartboostAccess,
} from "./ChartboostAccess/logic";
import {
	AwsProjectSelector,
	useAwsProjectQuery,
	useAwsProjectsLazyQuery,
	useAwsProjectsQuery,
	useCreateAwsProject,
	useDeleteAwsProject,
	useUpdateAwsProject,
} from "./AwsProjects/logic";
import {
	FacebookAdsAccessSelector,
	useCreateFacebookAdsAccess,
	useDeleteFacebookAdsAccess,
	useFacebookAdsAccessesLazyQuery,
	useFacebookAdsAccessesQuery,
	useFacebookAdsAccessQuery,
	useUpdateFacebookAdsAccess,
} from "./FacebookAdsAccess/logic";
import {
	FairbidAccessSelector,
	useCreateFairbidAccess,
	useDeleteFairbidAccess,
	useFairbidAccessesLazyQuery,
	useFairbidAccessesQuery,
	useFairbidAccessQuery,
	useUpdateFairbidAccess,
} from "./FairbidAccess/logic";
import {
	GoogleAdsAccessSelector,
	useCreateGoogleAdsAccess,
	useDeleteGoogleAdsAccess,
	useGoogleAdsAccessesLazyQuery,
	useGoogleAdsAccessesQuery,
	useGoogleAdsAccessQuery,
	useUpdateGoogleAdsAccess,
} from "./GoogleAdsAccess/logic";
import {
	GooglePlayAccessSelector,
	useCreateGooglePlayAccess,
	useDeleteGooglePlayAccess,
	useGooglePlayAccessesLazyQuery,
	useGooglePlayAccessesQuery,
	useGooglePlayAccessQuery,
	useUpdateGooglePlayAccess,
} from "./GooglePlayAccess/logic";
import {
	HarvestAccessSelector,
	useCreateHarvestAccess,
	useDeleteHarvestAccess,
	useHarvestAccessesLazyQuery,
	useHarvestAccessesQuery,
	useHarvestAccessQuery,
	useUpdateHarvestAccess,
} from "./HarvestAccess/logic";
import {
	IronSourceAccessSelector,
	useCreateIronSourceAccess,
	useDeleteIronSourceAccess,
	useIronSourceAccessesLazyQuery,
	useIronSourceAccessesQuery,
	useIronSourceAccessQuery,
	useUpdateIronSourceAccess,
} from "./IronSourceAccess/logic";
import {
	LibringAccessSelector,
	useCreateLibringAccess,
	useDeleteLibringAccess,
	useLibringAccessesLazyQuery,
	useLibringAccessesQuery,
	useLibringAccessQuery,
	useUpdateLibringAccess,
} from "./LibringAccess/logic";
import {
	SnapchatAccessSelector,
	useCreateSnapchatAccess,
	useDeleteSnapchatAccess,
	useSnapchatAccessesLazyQuery,
	useSnapchatAccessesQuery,
	useSnapchatAccessQuery,
	useUpdateSnapchatAccess,
} from "./SnapchatAccess/logic";
import {
	TiktokAccessSelector,
	useCreateTiktokAccess,
	useDeleteTiktokAccess,
	useTiktokAccessesLazyQuery,
	useTiktokAccessesQuery,
	useTiktokAccessQuery,
	useUpdateTiktokAccess,
} from "./TiktokAccess/logic";
import {
	UnityAccessSelector,
	useCreateUnityAccess,
	useDeleteUnityAccess,
	useUnityAccessesLazyQuery,
	useUnityAccessesQuery,
	useUnityAccessQuery,
	useUpdateUnityAccess,
} from "./UnityAccess/logic";

export enum AccessType {
	"appleAccesses" = "appleAccesses",
	"appleSearchAdsAccesses" = "appleSearchAdsAccesses",
	"applovinAccesses" = "applovinAccesses",
	"chartboostAccesses" = "chartboostAccesses",
	"appsFlyerAccesses" = "appsFlyerAccesses",
	"bigQueryProjects" = "bigQueryProjects",
	"awsProjects" = "awsProjects",
	"facebookAdsAccesses" = "facebookAdsAccesses",
	"fairbidAccesses" = "fairbidAccesses",
	"googleAdsAccesses" = "googleAdsAccesses",
	"googlePlayAccesses" = "googlePlayAccesses",
	"harvestAccesses" = "harvestAccesses",
	"ironSourceAccesses" = "ironSourceAccesses",
	"libringAccesses" = "libringAccesses",
	"snapchatAccesses" = "snapchatAccesses",
	"tiktokAccesses" = "tiktokAccesses",
	"unityAccesses" = "unityAccesses",
}

export const getGraphqlTypeByAccess = (accessType: AccessType): string =>
	({
		[AccessType.appleAccesses]: "AppleAccess",
		[AccessType.appleSearchAdsAccesses]: "AppleSearchAdsAccess",
		[AccessType.applovinAccesses]: "ApplovinAccess",
		[AccessType.appsFlyerAccesses]: "AppsFlyerAccess",
		[AccessType.bigQueryProjects]: "BigQueryProject",
		[AccessType.chartboostAccesses]: "ChartboostAccess",
		[AccessType.awsProjects]: "AwsProject",
		[AccessType.facebookAdsAccesses]: "FacebookAdsAccess",
		[AccessType.fairbidAccesses]: "FairbidAccess",
		[AccessType.googleAdsAccesses]: "GoogleAdsAccess",
		[AccessType.googlePlayAccesses]: "GooglePlayAccess",
		[AccessType.harvestAccesses]: "HarvestAccess",
		[AccessType.ironSourceAccesses]: "IronSourceAccess",
		[AccessType.libringAccesses]: "LibringAccess",
		[AccessType.snapchatAccesses]: "SnapchatAccess",
		[AccessType.tiktokAccesses]: "TiktokAccess",
		[AccessType.unityAccesses]: "UnityAccess",
	}[accessType]);

export const getGraphqlDataFieldByAccess = (accessType: AccessType) =>
	(({
		[AccessType.appleAccesses]: "appleAccess",
		[AccessType.appleSearchAdsAccesses]: "appleSearchAdsAccess",
		[AccessType.applovinAccesses]: "applovinAccess",
		[AccessType.appsFlyerAccesses]: "appsFlyerAccess",
		[AccessType.bigQueryProjects]: "bigQueryProject",
		[AccessType.chartboostAccesses]: "chartboostAccess",
		[AccessType.awsProjects]: "awsProject",
		[AccessType.facebookAdsAccesses]: "facebookAdsAccess",
		[AccessType.fairbidAccesses]: "fairbidAccess",
		[AccessType.googleAdsAccesses]: "googleAdsAccess",
		[AccessType.googlePlayAccesses]: "googlePlayAccess",
		[AccessType.harvestAccesses]: "harvestAccess",
		[AccessType.ironSourceAccesses]: "ironSourceAccess",
		[AccessType.libringAccesses]: "libringAccess",
		[AccessType.snapchatAccesses]: "snapchatAccess",
		[AccessType.tiktokAccesses]: "tiktokAccess",
		[AccessType.unityAccesses]: "unityAccess",
	} as const)[accessType]);

export const getTitleByAccess = (accessType: AccessType): string =>
	({
		[AccessType.appleAccesses]: "Apple",
		[AccessType.appleSearchAdsAccesses]: "AppleSearchAds",
		[AccessType.appsFlyerAccesses]: "AppsFlyer",
		[AccessType.applovinAccesses]: "Applovin",
		[AccessType.bigQueryProjects]: "Big query project",
		[AccessType.chartboostAccesses]: "Chartboost",
		[AccessType.awsProjects]: "Aws Project",
		[AccessType.facebookAdsAccesses]: "Facebook ads",
		[AccessType.fairbidAccesses]: "Fairbid",
		[AccessType.googleAdsAccesses]: "Google ads",
		[AccessType.googlePlayAccesses]: "Google play",
		[AccessType.harvestAccesses]: "Harvest",
		[AccessType.ironSourceAccesses]: "Iron source",
		[AccessType.libringAccesses]: "Libring",
		[AccessType.snapchatAccesses]: "Snapchat",
		[AccessType.tiktokAccesses]: "Tiktok",
		[AccessType.unityAccesses]: "Unity",
	}[accessType]);

export const getLabelByAccess = (accessType: AccessType): string =>
	({
		[AccessType.appleAccesses]: "Apple accesses",
		[AccessType.appleSearchAdsAccesses]: "AppleSearchAds access",
		[AccessType.appsFlyerAccesses]: "AppsFlyer accesses",
		[AccessType.applovinAccesses]: "Applovin accesses",
		[AccessType.bigQueryProjects]: "BQ Projects",
		[AccessType.chartboostAccesses]: "Chartboost accesses",
		[AccessType.awsProjects]: "Aws Projects",
		[AccessType.facebookAdsAccesses]: "FacebookAds accesses",
		[AccessType.fairbidAccesses]: "Fairbid accesses",
		[AccessType.googleAdsAccesses]: "GoogleAds accesses",
		[AccessType.googlePlayAccesses]: "GooglePlay accesses",
		[AccessType.harvestAccesses]: "Harvest accesses",
		[AccessType.ironSourceAccesses]: "IronSource accesses",
		[AccessType.libringAccesses]: "Libring accesses",
		[AccessType.snapchatAccesses]: "Snapchat accesses",
		[AccessType.tiktokAccesses]: "Tiktok accesses",
		[AccessType.unityAccesses]: "Unity accesses",
	}[accessType]);

export const options: { label: string; value: AccessType }[] = Object.values(
	AccessType
).map((accessType) => ({
	label: getLabelByAccess(accessType),
	value: accessType,
}));

export const getAccessSelector = (accessType: AccessType) =>
	({
		[AccessType.appleAccesses]: AppleAccessSelector,
		[AccessType.appleSearchAdsAccesses]: AppleSearchAdsAccessSelector,
		[AccessType.applovinAccesses]: ApplovinAccessSelector,
		[AccessType.appsFlyerAccesses]: AppsFlyerAccessSelector,
		[AccessType.bigQueryProjects]: BigQueryProjectSelector,
		[AccessType.chartboostAccesses]: ChartboostAccessSelector,
		[AccessType.awsProjects]: AwsProjectSelector,
		[AccessType.facebookAdsAccesses]: FacebookAdsAccessSelector,
		[AccessType.fairbidAccesses]: FairbidAccessSelector,
		[AccessType.googleAdsAccesses]: GoogleAdsAccessSelector,
		[AccessType.googlePlayAccesses]: GooglePlayAccessSelector,
		[AccessType.harvestAccesses]: HarvestAccessSelector,
		[AccessType.ironSourceAccesses]: IronSourceAccessSelector,
		[AccessType.libringAccesses]: LibringAccessSelector,
		[AccessType.snapchatAccesses]: SnapchatAccessSelector,
		[AccessType.tiktokAccesses]: TiktokAccessSelector,
		[AccessType.unityAccesses]: UnityAccessSelector,
	}[accessType]);

export const useAccessesQueries = () => {
	const appleAccesses = useAppleAccessesLazyQuery();
	const appleSearchAdsAccesses = useAppleSearchAdsAccessesLazyQuery();
	const applovinAccesses = useApplovinAccessesLazyQuery();
	const appsFlyerAccesses = useAppsFlyerAccessesLazyQuery();
	const bigQueryProjects = useBigQueryProjectsLazyQuery();
	const chartboostAccesses = useChartboostAccessesLazyQuery();
	const awsProjects = useAwsProjectsLazyQuery();
	const facebookAdsAccesses = useFacebookAdsAccessesLazyQuery();
	const fairbidAccesses = useFairbidAccessesLazyQuery();
	const googleAdsAccesses = useGoogleAdsAccessesLazyQuery();
	const googlePlayAccesses = useGooglePlayAccessesLazyQuery();
	const harvestAccesses = useHarvestAccessesLazyQuery();
	const ironSourceAccesses = useIronSourceAccessesLazyQuery();
	const libringAccesses = useLibringAccessesLazyQuery();
	const snapchatAccesses = useSnapchatAccessesLazyQuery();
	const tiktokAccesses = useTiktokAccessesLazyQuery();
	const unityAccesses = useUnityAccessesLazyQuery();

	const state = useMemo(
		() => ({
			[AccessType.appleAccesses]: appleAccesses,
			[AccessType.appleSearchAdsAccesses]: appleSearchAdsAccesses,
			[AccessType.applovinAccesses]: applovinAccesses,
			[AccessType.appsFlyerAccesses]: appsFlyerAccesses,
			[AccessType.bigQueryProjects]: bigQueryProjects,
			[AccessType.chartboostAccesses]: chartboostAccesses,
			[AccessType.awsProjects]: awsProjects,
			[AccessType.facebookAdsAccesses]: facebookAdsAccesses,
			[AccessType.fairbidAccesses]: fairbidAccesses,
			[AccessType.googleAdsAccesses]: googleAdsAccesses,
			[AccessType.googlePlayAccesses]: googlePlayAccesses,
			[AccessType.harvestAccesses]: harvestAccesses,
			[AccessType.ironSourceAccesses]: ironSourceAccesses,
			[AccessType.libringAccesses]: libringAccesses,
			[AccessType.snapchatAccesses]: snapchatAccesses,
			[AccessType.tiktokAccesses]: tiktokAccesses,
			[AccessType.unityAccesses]: unityAccesses,
		}),
		[
			appleAccesses,
			appleSearchAdsAccesses,
			applovinAccesses,
			appsFlyerAccesses,
			bigQueryProjects,
			chartboostAccesses,
			awsProjects,
			facebookAdsAccesses,
			fairbidAccesses,
			googleAdsAccesses,
			googlePlayAccesses,
			harvestAccesses,
			ironSourceAccesses,
			libringAccesses,
			snapchatAccesses,
			tiktokAccesses,
			unityAccesses,
		]
	);

	return state;
};

export const useAccessesQuery = (accessType: AccessType) =>
	({
		[AccessType.appleAccesses]: useAppleAccessesQuery,
		[AccessType.appleSearchAdsAccesses]: useAppleSearchAdsAccessesQuery,
		[AccessType.applovinAccesses]: useApplovinAccessesQuery,
		[AccessType.appsFlyerAccesses]: useAppsFlyerAccessesQuery,
		[AccessType.bigQueryProjects]: useBigQueryProjectsQuery,
		[AccessType.chartboostAccesses]: useChartboostAccessesQuery,
		[AccessType.awsProjects]: useAwsProjectsQuery,
		[AccessType.facebookAdsAccesses]: useFacebookAdsAccessesQuery,
		[AccessType.fairbidAccesses]: useFairbidAccessesQuery,
		[AccessType.googleAdsAccesses]: useGoogleAdsAccessesQuery,
		[AccessType.googlePlayAccesses]: useGooglePlayAccessesQuery,
		[AccessType.harvestAccesses]: useHarvestAccessesQuery,
		[AccessType.ironSourceAccesses]: useIronSourceAccessesQuery,
		[AccessType.libringAccesses]: useLibringAccessesQuery,
		[AccessType.snapchatAccesses]: useSnapchatAccessesQuery,
		[AccessType.tiktokAccesses]: useTiktokAccessesQuery,
		[AccessType.unityAccesses]: useUnityAccessesQuery,
	}[accessType]());

export const useAccessQuery = (accessType: AccessType) =>
	({
		[AccessType.appleAccesses]: useAppleAccessQuery,
		[AccessType.appleSearchAdsAccesses]: useAppleSearchAdsAccessQuery,
		[AccessType.applovinAccesses]: useApplovinAccessQuery,
		[AccessType.appsFlyerAccesses]: useAppsFlyerAccessQuery,
		[AccessType.bigQueryProjects]: useBigQueryProjectQuery,
		[AccessType.chartboostAccesses]: useChartboostAccessQuery,
		[AccessType.awsProjects]: useAwsProjectQuery,
		[AccessType.facebookAdsAccesses]: useFacebookAdsAccessQuery,
		[AccessType.fairbidAccesses]: useFairbidAccessQuery,
		[AccessType.googleAdsAccesses]: useGoogleAdsAccessQuery,
		[AccessType.googlePlayAccesses]: useGooglePlayAccessQuery,
		[AccessType.harvestAccesses]: useHarvestAccessQuery,
		[AccessType.ironSourceAccesses]: useIronSourceAccessQuery,
		[AccessType.libringAccesses]: useLibringAccessQuery,
		[AccessType.snapchatAccesses]: useSnapchatAccessQuery,
		[AccessType.tiktokAccesses]: useTiktokAccessQuery,
		[AccessType.unityAccesses]: useUnityAccessQuery,
	}[accessType]());

export const useCreate = (accessType: AccessType) =>
	({
		[AccessType.appleAccesses]: useCreateAppleAccess,
		[AccessType.appleSearchAdsAccesses]: useCreateAppleSearchAdsAccess,
		[AccessType.applovinAccesses]: useCreateApplovinAccess,
		[AccessType.appsFlyerAccesses]: useCreateAppsFlyerAccess,
		[AccessType.bigQueryProjects]: useCreateBigQueryProject,
		[AccessType.chartboostAccesses]: useCreateChartboostAccess,
		[AccessType.awsProjects]: useCreateAwsProject,
		[AccessType.facebookAdsAccesses]: useCreateFacebookAdsAccess,
		[AccessType.fairbidAccesses]: useCreateFairbidAccess,
		[AccessType.googleAdsAccesses]: useCreateGoogleAdsAccess,
		[AccessType.googlePlayAccesses]: useCreateGooglePlayAccess,
		[AccessType.harvestAccesses]: useCreateHarvestAccess,
		[AccessType.ironSourceAccesses]: useCreateIronSourceAccess,
		[AccessType.libringAccesses]: useCreateLibringAccess,
		[AccessType.snapchatAccesses]: useCreateSnapchatAccess,
		[AccessType.tiktokAccesses]: useCreateTiktokAccess,
		[AccessType.unityAccesses]: useCreateUnityAccess,
	}[accessType]());

export const useUpdate = (accessType: AccessType) =>
	({
		[AccessType.appleAccesses]: useUpdateAppleAccess,
		[AccessType.appleSearchAdsAccesses]: useUpdateAppleSearchAdsAccess,
		[AccessType.applovinAccesses]: useUpdateApplovinAccess,
		[AccessType.appsFlyerAccesses]: useUpdateAppsFlyerAccess,
		[AccessType.bigQueryProjects]: useUpdateBigQueryProject,
		[AccessType.chartboostAccesses]: useUpdateChartboostAccess,
		[AccessType.awsProjects]: useUpdateAwsProject,
		[AccessType.facebookAdsAccesses]: useUpdateFacebookAdsAccess,
		[AccessType.fairbidAccesses]: useUpdateFairbidAccess,
		[AccessType.googleAdsAccesses]: useUpdateGoogleAdsAccess,
		[AccessType.googlePlayAccesses]: useUpdateGooglePlayAccess,
		[AccessType.harvestAccesses]: useUpdateHarvestAccess,
		[AccessType.ironSourceAccesses]: useUpdateIronSourceAccess,
		[AccessType.libringAccesses]: useUpdateLibringAccess,
		[AccessType.snapchatAccesses]: useUpdateSnapchatAccess,
		[AccessType.tiktokAccesses]: useUpdateTiktokAccess,
		[AccessType.unityAccesses]: useUpdateUnityAccess,
	}[accessType]());

export const useDelete = (accessType: AccessType) =>
	({
		[AccessType.appleAccesses]: useDeleteAppleAccess,
		[AccessType.appleSearchAdsAccesses]: useDeleteAppleSearchAdsAccess,
		[AccessType.applovinAccesses]: useDeleteApplovinAccess,
		[AccessType.appsFlyerAccesses]: useDeleteAppsFlyerAccess,
		[AccessType.bigQueryProjects]: useDeleteBigQueryProject,
		[AccessType.chartboostAccesses]: useDeleteChartboostAccess,
		[AccessType.awsProjects]: useDeleteAwsProject,
		[AccessType.facebookAdsAccesses]: useDeleteFacebookAdsAccess,
		[AccessType.fairbidAccesses]: useDeleteFairbidAccess,
		[AccessType.googleAdsAccesses]: useDeleteGoogleAdsAccess,
		[AccessType.googlePlayAccesses]: useDeleteGooglePlayAccess,
		[AccessType.harvestAccesses]: useDeleteHarvestAccess,
		[AccessType.ironSourceAccesses]: useDeleteIronSourceAccess,
		[AccessType.libringAccesses]: useDeleteLibringAccess,
		[AccessType.snapchatAccesses]: useDeleteSnapchatAccess,
		[AccessType.tiktokAccesses]: useDeleteTiktokAccess,
		[AccessType.unityAccesses]: useDeleteUnityAccess,
	}[accessType]());
