import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useIronSourceAccessesQuery = () =>
	useTypedQuery(
		{
			ironSourceAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				IronSourceAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useIronSourceAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			ironSourceAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				IronSourceAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useIronSourceAccessQuery = () =>
	useTypedLazyQuery(
		{
			ironSourceAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				IronSourceAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateIronSourceAccess = () => {
	return useTypedMutation({
		createIronSourceAccess: [
			{
				data: $`data`,
			},
			IronSourceAccessSelector,
		],
	});
};
export const useUpdateIronSourceAccess = () => {
	return useTypedMutation({
		updateIronSourceAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			IronSourceAccessSelector,
		],
	});
};
export const useDeleteIronSourceAccess = () => {
	return useTypedMutation({
		deleteIronSourceAccess: [
			{
				where: $`where`,
			},
			IronSourceAccessSelector,
		],
	});
};

export const IronSourceAccessSelector = ZeusSelect<ValueTypes["IronSourceAccess"]>()({
	id: true,
	descriptiveName: true,
	refreshToken: true,
	secretKey: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
