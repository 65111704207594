import { urlService } from "@/services/url";
import { useEffect } from "react";

const { getQuery, setQuery, subscribe } = urlService;

type Props = {
	serialize: <R extends Record<string, any>, T extends R>(
		query: R,
		setQuery: (query: T) => void
	) => void;
	deserialize: (query: Record<string, any>) => void;
	equals: (query: Record<string, any>) => boolean;
};
export const useSerialization = ({ serialize, deserialize, equals }: Props) => {
	useEffect(() => {
		if (equals(getQuery())) return;
		deserialize(getQuery());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (equals(getQuery())) return;
		serialize(getQuery(), setQuery);
	}, [equals, serialize]);

	useEffect(() => {
		const unsubscribe = subscribe({
			query: (query) => {
				if (equals(query)) return;
				deserialize(query);
			},
		});
		return () => {
			unsubscribe();
		};
	}, [deserialize, equals]);
};
