import { createGuid } from "@/functions/createGuid";
import { useReducer } from "react";

export const useFilters = () => useReducer(update, []);

export type Model = Filter<string>[];

export type Filter<R> = {
    id: Id;
    filterType: string;
    operator: string;
    value: R;
};

type Id = string;

export type FilterOption = {
    value: string;
    label: string;
    type: "string" | "date";
};

export type Msg =
    | {
          type: "CREATE";
          payload: {
              filterType: FilterOption["value"];
              operator?: string;
              value?: string;
          };
      }
    | {
          type: "UPDATE";
          payload: {
              id: Id;
              filterType?: FilterOption["value"];
              operator?: string;
              value?: string;
          };
      }
    | {
          type: "DELETE";
          payload: { id: Id };
      };

export const update = (model: Model, msg: Msg): Model => {
    switch (msg.type) {
        case "CREATE": {
            return model.concat({
                id: createGuid(),
                filterType: msg.payload.filterType,
                operator: msg.payload.operator || "equals",
                value: msg.payload.value || ""
            });
        }
        case "UPDATE":
            return model.map(item => {
                if (item.id === msg.payload.id) {
                    return { ...item, ...msg.payload };
                }
                return item;
            });
        case "DELETE":
            return model.filter(item => item.id !== msg.payload.id);
    }
};

export const all: FilterOption[] = [
    { value: "project", label: "Project", type: "string" },
    { value: "platform", label: "Platform", type: "string" },
    { value: "campaign_name", label: "Campaign name", type: "string" },
    { value: "adset_name", label: "Adset name", type: "string" },
    { value: "ad_name", label: "Ad name", type: "string" },
    { value: "cohort_date", label: "Cohort date", type: "date" },
    { value: "activity_date", label: "Activity date", type: "date" },
    { value: "install_date", label: "Install date", type: "date" },
    { value: "campaign_id", label: "Campaign", type: "string" },
    { value: "adset_id", label: "Adset", type: "string" },
    { value: "ad_id", label: "Ad", type: "string" },
    { value: "cohort_hash", label: "Cohort hash", type: "string" }
];
