import { UserRole } from "@/modules/Viewer/components/Viewer";
import { useTypedMutation, useTypedQuery } from "@/services/graphApi";
import { $ } from "@/services/graphApi/graphql-zeus";

export type User = {
	id: string;
	email: string;
	organizationMemberships: {
		id: string;
		role: UserRole;
		createdAt: any;
		updatedAt: any;
	}[];
};

export const useUsersQuery = ({ organizationId }: { organizationId: string }) =>
	useTypedQuery(
		{
			users: [
				{
					where: {
						organizationMemberships_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				{
					id: true,
					email: true,
					organizationMemberships: [
						{
							where: { organization: { id: $`organizationId` } },
						},
						{
							id: true,
							role: true,
							createdAt: true,
							updatedAt: true,
							organization: {
								id: true,
								name: true,
							},
						},
					],
				},
			],
		},
		{
			variables: {
				organizationId,
			},
		}
	);

export const useUpdateUserRoles = () =>
	useTypedMutation({
		setUserRoles: [
			{
				organizationId: $`organizationId`,
				roles: $`roles`,
				userId: $`userId`,
			},
			{
				id: true,
			},
		],
	});

export const useInviteUser = (
	options?: Parameters<typeof useTypedMutation>["1"]
) =>
	useTypedMutation(
		{
			inviteUser: [
				{
					email: $`email`,
					organizationId: $`organizationId`,
				},
				{
					id: true,
				},
			],
		},
		// @ts-ignore
		options
	);
