import { getInputFields } from "@/services/graphApi";
import { UIField } from "@/ui/components/Form/uiKit";
import React, { useMemo } from "react";

type FormProps = {
	className?: string;
	input: string;
	ui: any;
	data: any;
	form: any;
	Buttons?: () => JSX.Element;
};

export const Form = ({
	className,
	data,
	ui,
	input,
	form,
	Buttons
}: FormProps) => {
	const fields = useMemo(() => {
		return getInputFields(input);
	}, [input]);

	return (
		<div className={className}>
			<form className="flex flex-col gap-2">
				{fields.map((field) => {
					return (
						<UIField
							key={`${field.name}`}
							field={field}
							form={form}
							data={data && data[field.name]}
							path={[]}
							uiKit={ui}
							UIField={UIField}
						/>
					);
				})}
			</form>
			{Buttons && <Buttons />}
		</div>
	);
};
