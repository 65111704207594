import React from "react";
import { useOpenable } from "@/modules/useOpenable";
import { Dropdown } from ".";
import { createClass } from "@/reexports";

type SelectProps = {
	className?: string;
	placeholder?: JSX.Element;
	value?: string;
	options: { label: string; value: string }[];
	select: (value: string) => void;
	label?: (props: LabelProps) => JSX.Element;
};

type LabelProps = {
	selected:
		| {
				label: string;
				value: string;
		  }
		| undefined;
	options: { label: string; value: string }[];
	value?: string;
	opened: boolean;
	toggle: () => void;
	open: () => void;
	close: () => void;
};

export const Select = ({
	className,
	value,
	options,
	placeholder,
	select,
	label,
}: SelectProps) => {
	const { open, opened, toggle, close } = useOpenable(false);
	const selected = options.find((item) => item.value === value);
	const classes = createClass(className);

	return (
		<Dropdown.View
			className={classes}
			opened={opened}
			onClickOutside={close}
			label={
				label ? (
					label({
						close,
						open,
						opened,
						toggle,
						options,
						selected,
						value,
					})
				) : (
					<div onClick={toggle}>
						<div className="h-9 border focus:outline-input py-2 px-4">
							{selected ? selected.label : placeholder || ""}
						</div>
					</div>
				)
			}
		>
			<Dropdown.Menu className="overflow-overlay max-h-96">
				{options.map((item) => {
					return (
						<Dropdown.Item
							key={item.value}
							selected={item.value === value}
							onClick={() => {
								select(item.value);
								close();
							}}
						>
							{item.label}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown.View>
	);
};
