import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useAwsProjectsQuery = () =>
	useTypedQuery(
		{
			awsProjects: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				AwsProjectSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useAwsProjectsLazyQuery = () =>
	useTypedLazyQuery(
		{
			awsProjects: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				AwsProjectSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useAwsProjectQuery = () =>
	useTypedLazyQuery(
		{
			awsProject: [
				{
					where: {
						id: $`id`,
					},
				},
				AwsProjectSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateAwsProject = () => {
	return useTypedMutation({
		createAwsProject: [
			{
				data: $`data`,
			},
			AwsProjectSelector,
		],
	});
};
export const useUpdateAwsProject = () => {
	return useTypedMutation({
		updateAwsProject: [
			{
				where: $`where`,
				data: $`data`,
			},
			AwsProjectSelector,
		],
	});
};
export const useDeleteAwsProject = () => {
	return useTypedMutation({
		deleteAwsProject: [
			{
				where: $`where`,
			},
			AwsProjectSelector,
		],
	});
};

export const AwsProjectSelector = ZeusSelect<ValueTypes["AwsProject"]>()({
	id: true,
	descriptiveName: true,
	accessKeyId: true,
	secretAccessKey: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
