import React from "react";
import { Dropdown } from "@/views";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";

export const PAGE_SIZES: number[] = [10, 25, 50, 100];

export const PageSizeDropdown = (props: {
	pageSize: number;
	setPageSize: (size: number) => void;
}) => {
	const { pageSize, setPageSize } = props;
	const { opened, toggle, close } = useOpenable(false);

	const onOptionClick = (option: number) => {
		setPageSize(option);
		close();
	};

	return (
		<Dropdown.View
			onClickOutside={close}
			opened={opened}
			label={
				<button
					className={
						"font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
					}
					type="button"
					onClick={toggle}
				>
					{pageSize}
				</button>
			}
		>
			<Dropdown.Menu>
				{PAGE_SIZES.map((s) => (
					<Dropdown.Item key={s} onClick={() => onOptionClick(s)}>
						{s}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown.View>
	);
};

export const PageControls = (props: {
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	nextPage: () => void;
	previousPage: () => void;
}) => {
	const { hasNextPage, hasPreviousPage, nextPage, previousPage } = props;
	const classes = "bg-gray-300 text-gray-800 font-bold py-2 px-4";
	const disabledClasses = "opacity-50";
	const allowedClasses = "hover:bg-gray-400";
	const prevClasses = createClass(classes, "rounded-l", {
		[allowedClasses]: hasPreviousPage,
		[disabledClasses]: !hasPreviousPage,
	});
	const nextClasses = createClass(classes, "rounded-r", {
		[allowedClasses]: hasNextPage,
		[disabledClasses]: !hasNextPage,
	});

	return (
		<div>
			<button
				className={prevClasses}
				onClick={previousPage}
				disabled={!hasPreviousPage}
			>
				Prev
			</button>
			<button
				className={nextClasses}
				onClick={nextPage}
				disabled={!hasNextPage}
			>
				Next
			</button>
		</div>
	);
};

export const PageInfo = (props: {
	numberOfPages: number;
	currentPage: number;
}) => {
	return (
		<div>
			page {props.currentPage} out of {props.numberOfPages}
		</div>
	)
}