import { unit } from "@/functions/unit";
import { createClass } from "@/reexports";
import * as React from "react";
import { createPortal } from "react-dom";

interface Props {
	isOpened: boolean;
	className?: string;
	onOpen?: typeof unit;
	onBeforeOpen?: typeof unit;
	onClose?: typeof unit;
	children: string | JSX.Element;
}

export const Modal = ({
	isOpened,
	className,
	onOpen,
	onBeforeOpen,
	onClose,
	children
}: Props) => {
	if (!isOpened) return null;
	if (onBeforeOpen) onBeforeOpen();
	if (onOpen) onOpen();

	const classes = createClass("bg-white rounded shadow-flat-down", className);

	const onClick = () => {
		if (onClose) {
			onClose();
		}
	};

	const stopPropagationClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();
	};

	const portal = (
		<div
			className="center fixed top-0 left-0 w-full h-full bg-overlay z-50"
			onClick={onClick}
		>
			<div className={classes} onClick={stopPropagationClick}>
				{children}
			</div>
		</div>
	);

	return createPortal(portal, document.body);
};
