import axios, { AxiosInstance, AxiosResponse } from "axios";
import { requestWithCognito } from "./auth";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { GRAPH_ENDPOINT } from "./graphApi";

export const getRequest = <R>(...params: Parameters<AxiosInstance>) => {
	return requestWithCognito((token: string) => {
		const instance = axios.create({
			baseURL: process.env.REACT_APP_UA_API,
			headers: { Authorization: `${token}`, Accept: "application/json" },
		});
		return instance.get<any, AxiosResponse<R>>(...params);
	});
};

export const postRequest = <R>(
	...params: Parameters<AxiosInstance["post"]>
) => {
	return requestWithCognito((token: string) => {
		const instance = axios.create({
			baseURL: process.env.REACT_APP_UA_API,
			headers: { Authorization: `${token}`, Accept: "application/json" },
		});
		return instance.post<any, AxiosResponse<R>>(...params);
	});
};

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: createHttpLink({
		uri: process.env.REACT_APP_UA_API as string,
		// @ts-ignore
		fetch: (uri: string, options) => {
			const cognitoRequest = requestWithCognito((token: string) => {
				const instance = axios.create({
					baseURL: uri,
					headers: {
						Authorization: `${token}`,
						Accept: "application/json",
					},
				});
				return instance.post<any, AxiosResponse<any>>(
					GRAPH_ENDPOINT,
					options?.body
				);
			});
			return cognitoRequest.then((response) => {
				return {
					text: async () => JSON.stringify(response),
				};
			});
		},
	}),
});
