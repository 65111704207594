import {
	useTypedLazyQuery,
	useTypedMutation,
	useTypedQuery,
} from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useFacebookAdsAccessesQuery = () =>
	useTypedQuery(
		{
			facebookAdsAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				FacebookAdsAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useFacebookAdsAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			facebookAdsAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				FacebookAdsAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useFacebookAdsAccessQuery = () =>
	useTypedLazyQuery(
		{
			facebookAdsAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				FacebookAdsAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateFacebookAdsAccess = () => {
	return useTypedMutation({
		createFacebookAdsAccess: [
			{
				data: $`data`,
			},
			FacebookAdsAccessSelector,
		],
	});
};
export const useUpdateFacebookAdsAccess = () => {
	return useTypedMutation({
		updateFacebookAdsAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			FacebookAdsAccessSelector,
		],
	});
};
export const useDeleteFacebookAdsAccess = () => {
	return useTypedMutation({
		deleteFacebookAdsAccess: [
			{
				where: $`where`,
			},
			FacebookAdsAccessSelector,
		],
	});
};

export const FacebookAdsAccessSelector = ZeusSelect<
	ValueTypes["FacebookAdsAccess"]
>()({
	id: true,
	descriptiveName: true,
	accessToken: true,
	accountId: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
