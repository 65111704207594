import { Reducer, useState } from "react";
import { DropResult } from "react-beautiful-dnd";

export const useMetrics = () => {
	const [metrics, setMetrics] = useState<Model>([]);

	const dispatch = (msg: Msg) => {
		setMetrics(update(metrics, msg));
	};

	const serializeModelToQuery = () => {
		return metrics.map(item => {
			const cumulativePart = item.day_of_activity
				? `${item.day_of_activity}_`
				: "";
			return `${cumulativePart}${item.value}`;
		});
	};

	const deserializeQueryToModel = (
		query: ReturnType<typeof serializeModelToQuery>
	) => {
		return setMetrics(query.map(getInstance));
	};

	return [
		metrics,
		dispatch,
		serializeModelToQuery,
		deserializeQueryToModel
	] as [
		typeof metrics,
		typeof dispatch,
		typeof serializeModelToQuery,
		typeof deserializeQueryToModel
	];
};
// export const useB_metrics = createStore(() => useMetrics());

export type Model = MetricInstance[];

export type MetricInstance = {
	value: Value;
	label: string;
	day_of_activity?: number;
};

export type MetricSchema<R> = {
	value: R;
	label: string;
	type: "number" | "date";
	format?: Format;
	params?: {
		day_of_activity?: DayOfActivity;
	};
};

type Format = {
	round?: number;
	postfix?: string;
	prefix?: string;
};

type DayOfActivity = {
	required: boolean;
};

export type Msg =
	| { type: "SELECT"; payload: MetricInstance[] }
	| { type: "UNSELECT"; payload: MetricInstance[] }
	| { type: "CHANGE_ORDER_OF_SELECTED"; payload: DropResult };

export const update: Reducer<Model, Msg> = (model, msg) => {
	switch (msg.type) {
		case "SELECT":
			return model.concat(msg.payload);
		case "UNSELECT":
			return model.filter(item => !msg.payload.some(equals(item)));
		case "CHANGE_ORDER_OF_SELECTED":
			if (!msg.payload.destination) return model;
			if (msg.payload.source.index === msg.payload.destination.index)
				return model;

			const sourceIndex = msg.payload.source.index;
			const destinationIndex = msg.payload.destination.index;
			const item = model[sourceIndex];

			if (sourceIndex < destinationIndex) {
				return [
					...model.slice(0, sourceIndex),
					...model.slice(sourceIndex + 1, destinationIndex + 1),
					item,
					...model.slice(destinationIndex + 1)
				];
			}
			return [
				...model.slice(0, destinationIndex),
				item,
				...model.slice(destinationIndex, sourceIndex),
				...model.slice(sourceIndex + 1)
			];
	}
};

export const select = (metrics: MetricInstance[]): Msg => ({
	type: "SELECT",
	payload: metrics
});
export const unselect = (metrics: MetricInstance[]): Msg => ({
	type: "UNSELECT",
	payload: metrics
});
export const changeOrder = (event: DropResult): Msg => ({
	type: "CHANGE_ORDER_OF_SELECTED",
	payload: event
});

export const equals = (metric1: MetricInstance) => (
	metric2: MetricInstance
): boolean =>
	metric1.value === metric2.value &&
	metric1.day_of_activity === metric2.day_of_activity;

export const isSelected = (model: Model) => (metric: MetricInstance): boolean =>
	model.some(equals(metric));

export const isLifetime = (metric: MetricSchema<Value>): boolean =>
	!metric.params || metric.params.day_of_activity?.required === false;

export const isCumulative = (metric: MetricSchema<Value>): boolean =>
	!!metric.params;

export const isInstanceCumulative = (metric: MetricInstance): boolean =>
	!!metric.day_of_activity;

export const getSchemaOfMetric = (
	metric: MetricInstance
): MetricSchema<Value> =>
	all.find(item => item.value === metric.value) as MetricSchema<Value>;

export const getLabel = (metric: MetricInstance) =>
	isInstanceCumulative(metric)
		? `D${metric.day_of_activity} ${metric.label}`
		: metric.label;

export const getValue = (metric: MetricInstance) =>
	isInstanceCumulative(metric)
		? `d${metric.day_of_activity}_${metric.value}`
		: metric.value;

export const getInstance = (compositeMetric: string) => {
	const checked = compositeMetric.match(/^d(\d+)_(.*)$/);
	return checked
		? createMetric(checked[2] as Value, parseInt(checked[1], 10))
		: createMetric(compositeMetric as Value);
};

const all: MetricSchema<Value>[] = [
	{
		value: "spend",
		label: "Spend",
		type: "number",
		format: { prefix: "$", round: 3 }
	},
	{ value: "clicks", label: "Clicks", type: "number", format: { round: 0 } },
	{
		value: "impressions",
		label: "Impressions",
		type: "number",
		format: { round: 0 }
	},
	{
		value: "installs",
		label: "Installs",
		type: "number",
		format: { round: 0 }
	},
	{
		value: "purchases",
		label: "Purchases",
		type: "number",
		format: { round: 0 }
	},
	{ value: "payers", label: "Payers", type: "number", format: { round: 0 } },
	{
		value: "conversion",
		label: "Conversion",
		type: "number",
		format: { postfix: "%", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "eligible_players",
		label: "Eligible players",
		type: "number",
		format: { round: 0 },
		params: { day_of_activity: { required: false } }
	},
	{ value: "start_day", label: "Start day", type: "date" },
	{ value: "last_active_day", label: "Last active day", type: "date" },
	{
		value: "eligible_days",
		label: "Eligible days",
		type: "number",
		format: { round: 0 }
	},
	{
		value: "daily_users",
		label: "Daily users",
		type: "number",
		format: { round: 0 }
	},
	{
		value: "gross_iap_revenue",
		label: "Gross IAPs",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "_gross_ad_revenue",
		label: "!Gross Ads",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "gross_total_revenue",
		label: "Gross Total",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "net_iap_revenue",
		label: "Net IAPs",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "net_ad_revenue",
		label: "Net Ads",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "net_total_revenue",
		label: "Net Total",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "cost_per_install",
		label: "CPI",
		type: "number",
		format: { prefix: "$", round: 3 }
	},
	{
		value: "click_to_install_rate",
		label: "CTI",
		type: "number",
		format: { postfix: "%", round: 3 }
	},
	{
		value: "click_through_rate",
		label: "CTR",
		type: "number",
		format: { postfix: "%", round: 3 }
	},
	{
		value: "_average_cohort_lifetime_items_numerator",
		label: "!Average cohort lifetime items numerator",
		type: "number",
		format: { round: 3 }
	},
	{
		value: "_average_cohort_lifetime_items_denominator",
		label: "!Average cohort lifetime items denominator",
		type: "number",
		format: { round: 3 }
	},
	{
		value: "average_cohort_lifetime",
		label: "Average cohort lifetime",
		type: "number",
		format: { round: 3 }
	},
	{
		value: "gross_average_revenue_per_user",
		label: "Gross ARPU",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "net_average_revenue_per_user",
		label: "Net ARPU",
		type: "number",
		format: { prefix: "$", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "return_on_investment",
		label: "ROI",
		type: "number",
		format: { postfix: "%", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "gross_return_on_active_spend",
		label: "Gross ROAS",
		type: "number",
		format: { postfix: "%", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "net_return_on_active_spend",
		label: "Net ROAS",
		type: "number",
		format: { postfix: "%", round: 3 },
		params: { day_of_activity: { required: false } }
	},
	{
		value: "_exact_retention_value",
		label: "!Exact retention value",
		type: "number",
		format: { round: 3 },
		params: { day_of_activity: { required: true } }
	},
	{
		value: "exact_retention",
		label: "Exact retention",
		type: "number",
		format: { postfix: "%", round: 3 },
		params: { day_of_activity: { required: true } }
	}
];

export const lifetime = all.filter((metric): metric is MetricSchema<Lifetime> =>
	isLifetime(metric)
);
export const cumulative = all.filter((metric): metric is MetricSchema<
	Cumulative
> => isCumulative(metric));

export const createMetric = (
	metric: Value,
	day_of_activity?: number
): MetricInstance => {
	switch (metric) {
		case "spend":
			return {
				value: "spend",
				label: "Spend"
			};
		case "clicks":
			return {
				value: "clicks",
				label: "Clicks"
			};
		case "impressions":
			return {
				value: "impressions",
				label: "Impressions"
			};
		case "installs":
			return {
				value: "installs",
				label: "Installs"
			};
		case "purchases":
			return {
				value: "purchases",
				label: "Purchases"
			};
		case "payers":
			return {
				value: "payers",
				label: "Payers"
			};
		case "conversion":
			return {
				value: "conversion",
				label: "Conversion",
				...(day_of_activity && { day_of_activity })
			};
		case "eligible_players":
			return {
				value: "eligible_players",
				label: "Eligible players",
				...(day_of_activity && { day_of_activity })
			};
		case "start_day":
			return { value: "start_day", label: "Start day" };
		case "last_active_day":
			return {
				value: "last_active_day",
				label: "Last active day"
			};
		case "eligible_days":
			return {
				value: "eligible_days",
				label: "Eligible days"
			};
		case "daily_users":
			return {
				value: "daily_users",
				label: "Daily users"
			};
		case "gross_iap_revenue":
			return {
				value: "gross_iap_revenue",
				label: "Gross IAPs",
				...(day_of_activity && { day_of_activity })
			};
		case "_gross_ad_revenue":
			return {
				value: "_gross_ad_revenue",
				label: "!Gross Ads",
				...(day_of_activity && { day_of_activity })
			};
		case "gross_total_revenue":
			return {
				value: "gross_total_revenue",
				label: "Gross Total",
				...(day_of_activity && { day_of_activity })
			};
		case "net_iap_revenue":
			return {
				value: "net_iap_revenue",
				label: "Net IAPs",
				...(day_of_activity && { day_of_activity })
			};
		case "net_ad_revenue":
			return {
				value: "net_ad_revenue",
				label: "Net Ads",
				...(day_of_activity && { day_of_activity })
			};
		case "net_total_revenue":
			return {
				value: "net_total_revenue",
				label: "Net Total",
				...(day_of_activity && { day_of_activity })
			};
		case "cost_per_install":
			return {
				value: "cost_per_install",
				label: "CPI"
			};
		case "click_to_install_rate":
			return {
				value: "click_to_install_rate",
				label: "CTI"
			};
		case "click_through_rate":
			return {
				value: "click_through_rate",
				label: "CTR"
			};
		case "_average_cohort_lifetime_items_numerator":
			return {
				value: "_average_cohort_lifetime_items_numerator",
				label: "!Average cohort lifetime items numerator"
			};
		case "_average_cohort_lifetime_items_denominator":
			return {
				value: "_average_cohort_lifetime_items_denominator",
				label: "!Average cohort lifetime items denominator"
			};
		case "average_cohort_lifetime":
			return {
				value: "average_cohort_lifetime",
				label: "Average cohort lifetime"
			};
		case "gross_average_revenue_per_user":
			return {
				value: "gross_average_revenue_per_user",
				label: "Gross ARPU",
				...(day_of_activity && { day_of_activity })
			};
		case "net_average_revenue_per_user":
			return {
				value: "net_average_revenue_per_user",
				label: "Net ARPU",
				...(day_of_activity && { day_of_activity })
			};
		case "return_on_investment":
			return {
				value: "return_on_investment",
				label: "ROI",
				...(day_of_activity && { day_of_activity })
			};
		case "gross_return_on_active_spend":
			return {
				value: "gross_return_on_active_spend",
				label: "Gross ROAS",
				...(day_of_activity && { day_of_activity })
			};
		case "net_return_on_active_spend":
			return {
				value: "net_return_on_active_spend",
				label: "Net ROAS",
				...(day_of_activity && { day_of_activity })
			};
		case "_exact_retention_value":
			return {
				value: "_exact_retention_value",
				label: "!Exact retention value",
				day_of_activity
			};
		case "exact_retention":
			return {
				value: "exact_retention",
				label: "Exact retention",
				day_of_activity
			};
	}
};

export type Value = Lifetime | Cumulative;

// type Value =
// 	| "spend"
// 	| "clicks"
// 	| "impressions"
// 	| "installs"
// 	| "purchases"
// 	| "payers"
// 	| "conversion"
// 	| "eligible_players"
// 	| "start_day"
// 	| "last_active_day"
// 	| "eligible_days"
// 	| "daily_users"
// 	| "gross_iap_revenue"
// 	| "_gross_ad_revenue"
// 	| "gross_total_revenue"
// 	| "net_iap_revenue"
// 	| "net_ad_revenue"
// 	| "net_total_revenue"
// 	| "cost_per_install"
// 	| "click_to_install_rate"
// 	| "click_through_rate"
// 	| "_average_cohort_lifetime_items_numerator"
// 	| "_average_cohort_lifetime_items_denominator"
// 	| "average_cohort_lifetime"
// 	| "gross_average_revenue_per_user"
// 	| "net_average_revenue_per_user"
// 	| "return_on_investment"
// 	| "gross_return_on_active_spend"
// 	| "net_return_on_active_spend"
// 	| "_exact_retention_value"
// 	| "exact_retention";

export type Lifetime =
	| "spend"
	| "clicks"
	| "impressions"
	| "installs"
	| "purchases"
	| "payers"
	| "conversion"
	| "eligible_players"
	| "start_day"
	| "last_active_day"
	| "eligible_days"
	| "daily_users"
	| "gross_iap_revenue"
	| "_gross_ad_revenue"
	| "gross_total_revenue"
	| "net_iap_revenue"
	| "net_ad_revenue"
	| "net_total_revenue"
	| "cost_per_install"
	| "click_to_install_rate"
	| "click_through_rate"
	| "_average_cohort_lifetime_items_numerator"
	| "_average_cohort_lifetime_items_denominator"
	| "average_cohort_lifetime"
	| "gross_average_revenue_per_user"
	| "net_average_revenue_per_user"
	| "return_on_investment"
	| "gross_return_on_active_spend"
	| "net_return_on_active_spend";

export type Cumulative =
	| "conversion"
	| "eligible_players"
	| "gross_iap_revenue"
	| "_gross_ad_revenue"
	| "gross_total_revenue"
	| "net_iap_revenue"
	| "net_ad_revenue"
	| "net_total_revenue"
	| "gross_average_revenue_per_user"
	| "net_average_revenue_per_user"
	| "return_on_investment"
	| "gross_return_on_active_spend"
	| "net_return_on_active_spend"
	| "exact_retention"
	| "_exact_retention_value";
