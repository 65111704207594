import { useState, useEffect } from "react";

export const useDebouncedValue = <R>(value: R, delay: number) => {
	const [state, setState] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => setState(value), delay);
		return () => clearTimeout(handler);
	}, [value, delay]);

	return state;
};
