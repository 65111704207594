import { useTypedLazyQuery, useTypedMutation, useTypedQuery } from "@/services/graphApi";
import { $, ValueTypes, ZeusSelect } from "@/services/graphApi/graphql-zeus";

export const useGooglePlayAccessesQuery = () =>
	useTypedQuery(
		{
			googlePlayAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				GooglePlayAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useGooglePlayAccessesLazyQuery = () =>
	useTypedLazyQuery(
		{
			googlePlayAccesses: [
				{
					where: {
						organizationAttributes_some: {
							organization: { id: $`organizationId` },
						},
					},
				},
				GooglePlayAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useGooglePlayAccessQuery = () =>
	useTypedLazyQuery(
		{
			googlePlayAccess: [
				{
					where: {
						id: $`id`,
					},
				},
				GooglePlayAccessSelector,
			],
		},
		{
			notifyOnNetworkStatusChange: true,
		}
	);

export const useCreateGooglePlayAccess = () => {
	return useTypedMutation({
		createGooglePlayAccess: [
			{
				data: $`data`,
			},
			GooglePlayAccessSelector,
		],
	});
};
export const useUpdateGooglePlayAccess = () => {
	return useTypedMutation({
		updateGooglePlayAccess: [
			{
				where: $`where`,
				data: $`data`,
			},
			GooglePlayAccessSelector,
		],
	});
};
export const useDeleteGooglePlayAccess = () => {
	return useTypedMutation({
		deleteGooglePlayAccess: [
			{
				where: $`where`,
			},
			GooglePlayAccessSelector,
		],
	});
};

export const GooglePlayAccessSelector = ZeusSelect<ValueTypes["GooglePlayAccess"]>()({
	id: true,
	descriptiveName: true,
	bucketId: true,
	packageIdentifier: true,
	sourceClientEmail: true,
	sourcePrivateKey: true,
	sourceProjectId: true,
	organizationAttributes: [
		{},
		{
			id: true,
			organization: {
				id: true,
				name: true,
			},
			role: true,
		},
	],
});
