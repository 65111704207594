import { useEffect, useState } from "react";
import { getAnalyticsData } from "@/Api/Analytics";
import { useMetricsStore, useFiltersStore, useDimensionsStore } from "@/model";
import { PromiseType } from "utility-types";
import { Metrics, Filters, Dimensions } from ".";

export const useAnalyticsData = () => {
	const [b_metrics] = useMetricsStore();
	const [b_filters] = useFiltersStore();
	const [b_dimensions] = useDimensionsStore();

	const [state, setState] = useState<
		AsyncState<PromiseType<ReturnType<typeof getAnalyticsData>>>
	>({ tag: "loading" });

	useEffect(() => {
		setState({ tag: "loading" });

		getAnalyticsData(
			prepareFetchArguments(b_metrics, b_filters, b_dimensions)
		)
			.then(res => {
				setState({ tag: "loaded", data: res });
			})
			.catch(e => {
				setState({ tag: "error", error: e });
			});
	}, [b_metrics, b_filters, b_dimensions]);

	return state;
};

type AsyncState<T> =
	| { tag: "notLoading" }
	| { tag: "loading" }
	| { tag: "error"; error: string }
	| { tag: "loaded"; data: T };

const prepareFetchArguments = (
	metrics: Metrics.Model,
	filters: Filters.Model,
	dimensions: Dimensions.Model
): { metrics: Metric[]; breakdowns: Breakdown[]; filterBy: FilterBy } => {
	const breakdowns: Breakdown[] = dimensions.map(
		(dimension): Breakdown => {
			return {
				groupBy: dimension.value,
				...(dimension.filters.length > 0 && {
					filterBy: dimension.filters.reduce(
						(acc, filter): BreakdownFilterBy => {
							if (!acc[filter.value]) {
								acc[filter.value] = [];
							}
							acc[filter.value].push({
								...(filter.day_of_activity && {
									params: {
										day_of_activity: filter.day_of_activity
									}
								}),
								[filter.operator]: filter.input
							});

							return acc;
						},
						{} as BreakdownFilterBy
					)
				}),
				...(dimension.ordering.length > 0 && {
					orderBy: dimension.ordering.map(
						(item): BreakdownOrderBy => ({
							metric: {
								value: item.value,
								...(item.day_of_activity && {
									params: {
										day_of_activity: item.day_of_activity
									}
								})
							},
							order: item.ordering
						})
					)
				}),
				size: 10
			};
		}
	);

	const filterBy: FilterBy = filters.reduce((acc, item): FilterBy => {
		if (!acc[item.filterType]) {
			acc[item.filterType] = [];
		}
		acc[item.filterType].push({ [item.operator]: item.value });
		return acc;
	}, {} as FilterBy);

	return { metrics, breakdowns, filterBy };
};

type Metric = {
	value: string;
	params?: { day_of_activity: number };
};

type Breakdown = {
	groupBy: string;
	size?: number;
	filterBy?: BreakdownFilterBy;
	orderBy?: BreakdownOrderBy[];
};

type BreakdownFilterBy = {
	[key: string]: {
		[key: string]: string | number | { day_of_activity: number };
	}[];
};

type BreakdownOrderBy = {
	metric: {
		value: string;
		params?: { day_of_activity: number };
	};
	order: "ASC" | "DESC";
};

type FilterBy = {
	[key: string]: {
		[key: string]: string | number;
	}[];
};
